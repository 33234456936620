import { Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { FontWeightValues } from "../../../types/fontWeightTypes";

export interface RequestcancelledSectionProps {
    isMobile: boolean;
}

const RequestcancelledSection: React.FC<RequestcancelledSectionProps> = ({
    isMobile,
}) => {
    const { t } = useTranslation();
    return (
        <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            height={"100%"}
        >
            <img
                src={`/images/icon/cancelled.svg`}
                width={isMobile ? 48 : 80}
            />
            <Grid mt={4} sx={{ textAlign: "center" }}>
                <Typography
                    variant={isMobile ? "f18h26B" : "f48h64EB"}
                    fontWeight={FontWeightValues.REGULAR}
                    color="mono.600"
                >
                    {t("quest.abortRequestMessage")}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default RequestcancelledSection;
