import { AnnouncementMessage } from "../api-interfaces/announcement";

import apiInstance from "./base";

/**
 * 챗 불러올 때 해당정보도 불러오니, 실질적으로 필요없는 함수 (하지만 혹시 몰라서 놔둠.)
 *
 * @param chatID
 * @returns
 */
export const getAnnouncement = async (chatID: string) => {
    const query = `v1/announce/${chatID}`;
    const anns = await apiInstance.get(query).json<AnnouncementMessage[]>();
    return anns;
};

export const pushAnnouncement = async (chatID: string, content: string) => {
    const query = `v1/announce/${chatID}`;
    const anns = await apiInstance
        .post(query, { json: { content } })
        .json<AnnouncementMessage[]>();
    return anns;
};

export const popAnnouncement = async (chatID: string) => {
    const query = `v1/announce/${chatID}`;
    const anns = await apiInstance.patch(query).json<AnnouncementMessage[]>();
    return anns;
};
