import React, { forwardRef, Ref } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../../theme";
import RequestButton from "../../buttons/RequestButton";

/**
 * 수입 수출 컨설팅 박스 되어 있는 거
 *
 * @example
 * `수출 전문가 매칭 플랫폼
 * TRADEFORCE
 * 딱! 맞는 베테랑 해외 영업 전문가를 연결해
 * 수출 첫 걸음부터 무역 거래까지 성사 해드립니다`
 */
const Section2 = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();

    return (
        <Grid
            ref={ref}
            container
            sx={{
                minHeight: "100vh",
                width: "100%",
                backgroundColor: "black",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                overflowX: "hidden",
            }}
            p={4}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                sx={{ animation: "fadeIn 3s" }}
            >
                <Typography
                    color={"white"}
                    variant={isMobile ? "f32h40R" : "f48h64R"}
                    sx={{ textAlign: "center" }}
                >
                    {t("home.section20t")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    sx={{ textAlign: "center" }}
                >
                    {t("home.section21t")}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mt={4}
                sx={{ animation: "fadeIn 3s" }}
            >
                <Typography
                    color={"white"}
                    variant={isMobile ? "f16h20B" : "f18h26B"}
                    sx={{ textAlign: "center" }}
                >
                    {t("home.section20st")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isMobile ? "f16h20B" : "f18h26B"}
                    sx={{ textAlign: "center" }}
                >
                    {t("home.section21st")}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                width={"100%"}
                pt={10}
                flexDirection={isMobile ? "column" : "row"}
                alignItems={isMobile ? "center" : undefined}
            >
                <RequestButton
                    text={t("home.section20")}
                    isMobile={isMobile}
                    subText={t("home.section20d")}
                />
                <RequestButton
                    text={t("home.section21")}
                    isMobile={isMobile}
                    subText={t("home.section21d")}
                />
                <RequestButton
                    text={t("home.section22")}
                    isMobile={isMobile}
                    subText={t("home.section22d")}
                />
            </Box>
        </Grid>
    );
});

export default Section2;
