import React from "react";

import { Grid, Typography, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";

import { FontWeightValues } from "../../types/fontWeightTypes";

export interface ManagerAccInfoCardProps {
    showInfo?: boolean;
    accNum?: string;
    bankName?: string;
    contact?: string;
    email?: string;
}

const MANAGER_INFO_ACCNUM = process.env.REACT_APP_MANAGER_INFO_ACCNUM;
const MANAGER_INFO_BANKNAME = process.env.REACT_APP_MANAGER_INFO_BANKNAME;
const MANAGER_INFO_CONTACT = process.env.REACT_APP_MANAGER_INFO_CONTACT;
const MANAGER_INFO_EMAIL = process.env.REACT_APP_MANAGER_INFO_EMAIL;

const ManagerAccInfoCard: React.FC<ManagerAccInfoCardProps> = ({
    accNum = "******-**-******",
    bankName = "**은행",
    contact = "010-****-****",
    email = "*****@*****.***",
    showInfo = false,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Grid
            sx={{
                borderRadius: "10px",
                bgcolor: theme.palette.mono[300],
                p: 2,
                mt: 2,
                mb: 2,
            }}
        >
            <Grid mb={2}>
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    fontWeight={FontWeightValues.BOLD}
                >
                    {t("quest.accountNumber")}
                </Typography>
                <br />
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    sx={{ wordBreak: "break-all" }}
                >
                    {showInfo ? accNum : `${MANAGER_INFO_ACCNUM}`}
                </Typography>
            </Grid>
            <Grid mb={2}>
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    fontWeight={FontWeightValues.BOLD}
                >
                    {t("quest.bank")}
                </Typography>
                <br />
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    sx={{ wordBreak: "break-all" }}
                >
                    {showInfo ? bankName : `${MANAGER_INFO_BANKNAME}`}
                </Typography>
            </Grid>
            <Grid mb={2}>
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    fontWeight={FontWeightValues.BOLD}
                >
                    {t("quest.adminContact")}
                </Typography>
                <br />
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    sx={{ wordBreak: "break-all" }}
                >
                    {showInfo ? contact : `${MANAGER_INFO_CONTACT}`}
                </Typography>
            </Grid>
            <Grid mb={2}>
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    fontWeight={FontWeightValues.BOLD}
                >
                    {t("quest.adminEmail")}
                </Typography>
                <br />
                <Typography
                    variant="f14h20"
                    color={"mono.600"}
                    sx={{ wordBreak: "break-all" }}
                >
                    {showInfo ? email : `${MANAGER_INFO_EMAIL}`}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ManagerAccInfoCard;
