import React from "react";

import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { mobileMaxWidthMediaQuery } from "../../theme";

interface RowData {
    title: string;
    body: string;
}

export interface CollapsibleTableProps {
    rows: RowData[];
}

const Row: React.FC<{ row: RowData }> = ({ row }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { title, body } = row;
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <TableRow>
            <TableCell
                sx={{ cursor: "pointer", pl: 0, pr: 0 }}
                onClick={handleClick}
            >
                <Grid
                    container
                    sx={{
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                    }}
                >
                    <Grid item sx={{ maxWidth: 768, width: "90%" }}>
                        <Typography
                            sx={{
                                fontFamily: "nanumsquare",
                                fontWeight: 800,
                                fontSize: isMobile ? 16 : 18,
                            }}
                        >
                            {title}
                        </Typography>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Grid sx={{ mb: 3, mt: 4 }}>
                                <Typography
                                    style={{
                                        wordWrap: "break-word",
                                        fontFamily: "nanumsquare",
                                        fontWeight: 400,
                                        fontSize: isMobile ? 16 : 18,
                                    }}
                                >
                                    {body}
                                </Typography>
                            </Grid>
                        </Collapse>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "10%",
                        }}
                    >
                        <IconButton size="small" onClick={handleClick}>
                            {open ? (
                                <img src={"./images/icon/chevron-up.svg"} />
                            ) : (
                                <img src={"./images/icon/chevron-down.svg"} />
                            )}
                        </IconButton>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

const CollapsibleTable: React.FC<CollapsibleTableProps> = ({ rows }) => {
    return (
        <TableContainer component={Paper} elevation={0}>
            <Table aria-label="FAQ collapsible table">
                <TableHead>
                    <TableCell sx={{ padding: 0 }}></TableCell>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.title} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CollapsibleTable;
