import { useState } from "react";

import GTranslateIcon from "@mui/icons-material/GTranslate";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import i18next from "../../i18n/lang-set";
import { FontWeightValues } from "../../types/fontWeightTypes";

const LangDropdown: React.FC<{
    color?: string;
    list?: boolean;
}> = ({ color, list }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isLanguageMenuOpen = Boolean(anchorEl);

    const changeLanguage = (lang: string) => {
        i18next.changeLanguage(lang);
        handleClose();
    };

    const open = (
        event: React.MouseEvent<HTMLButtonElement & HTMLDivElement>
    ) => {
        if (event) {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(() => null);
    };

    return (
        <>
            {list ? (
                <ListItemButton onClick={open}>
                    <Typography
                        variant="f18h20B"
                        sx={{
                            fontWeight: FontWeightValues.REGULAR,
                            whiteSpace: "nowrap",
                        }}
                    >
                        Language
                    </Typography>
                    <GTranslateIcon htmlColor={color} />
                </ListItemButton>
            ) : (
                <IconButton onClick={open}>
                    <GTranslateIcon htmlColor={color} />
                </IconButton>
            )}
            <Menu
                anchorEl={anchorEl}
                open={isLanguageMenuOpen}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        changeLanguage("ko");
                    }}
                >
                    한국어
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        changeLanguage("en");
                    }}
                >
                    English
                </MenuItem>
            </Menu>
        </>
    );
};

export default LangDropdown;
