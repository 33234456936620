import React from "react";

import { Box, Typography } from "@mui/material";

export interface InfoListProps {
    data: { title: string; content: string }[];
}

const InfoList: React.FC<InfoListProps> = ({ data = [] }) => {
    return (
        <>
            {data.map((datum, idx) => (
                <Box key={`req-info-${idx}`} mb={3}>
                    <Typography
                        component="div"
                        variant="f14h20"
                        color={"mono.600"}
                        sx={{ mb: 1 }}
                    >
                        {datum.title}
                    </Typography>

                    <Typography
                        variant="f18h26B"
                        sx={{ wordBreak: "break-all" }}
                    >
                        <pre style={{ fontFamily: "inherit", margin: 0 }}>
                            {datum.content}
                        </pre>
                    </Typography>
                </Box>
            ))}
        </>
    );
};

export default InfoList;
