import React, { useEffect, useRef } from "react";

import { useSetRecoilState } from "recoil";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import ServiceThirdTile from "../components/sections/service-introduction/ServiceThirdTile";
import { navThemeRecoil } from "../components/states/recoil";
import { tabletMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

const Service = () => {
    const isMobile = useMediaQuery(tabletMaxWidthMediaQuery);
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const ref1 = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const changeNavbarColor = () => {
        const rh1 = isMobile ? 770 + 64 : 850 + 64;
        if (window.scrollY >= (ref1.current?.scrollHeight ?? rh1) - 64) {
            setNavTheme(navThemeValues.WHITE);
        } else {
            setNavTheme(navThemeValues.WHITE_TRANS);
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        changeNavbarColor();
        window.addEventListener("scroll", changeNavbarColor);
        return () => window.removeEventListener("scroll", changeNavbarColor);
    }, [isMobile]);

    useEffect(() => {
        setNavTheme(navThemeValues.WHITE_TRANS);
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            {/* 상단 이미지 */}
            <Box
                sx={{
                    position: "relative",
                    height: "100vh",
                    alignSelf: "stretch",
                }}
                ref={ref1}
            >
                <img
                    src={"./images/service/service-info-bg.jpg"}
                    width={"100%"}
                    height="100%"
                    style={{ objectFit: "cover" }}
                />
                <Box
                    sx={{
                        position: "absolute",
                        width: "100%",
                        textAlign: "center",
                        top: "43%",
                    }}
                >
                    <Typography
                        color="white"
                        sx={{
                            textShadow: "0.3vh 0.3vh #000",
                            fontSize: "6.6vh",
                            fontWeight: "bold",
                        }}
                    >
                        {t("serviceIntroduction.t10")}
                    </Typography>
                </Box>
            </Box>

            {/* 1번 색션 */}
            <Box sx={{ maxWidth: 1264, px: 3, pt: 10 }}>
                {/* first paragraph */}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        pb: 10,
                        flexDirection: isMobile ? "column" : "row",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: isMobile ? "100%" : "50%",
                            textAlign: "start",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            fontSize={isMobile ? 32 : 45}
                            fontWeight={800}
                        >
                            We Bridge,
                        </Typography>
                        <Typography
                            fontSize={isMobile ? 32 : 45}
                            fontWeight={800}
                        >
                            You Success!
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            display: "flex",
                            width: isMobile ? "100%" : "50%",
                            pt: 3,
                        }}
                        component="div"
                    >
                        {t("serviceIntroduction.t20")}
                    </Typography>
                </Box>
                {/* second paragraph */}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        pb: 10,
                        flexDirection: isMobile ? "column" : "row",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? 32 : 45,
                            fontWeight: 800,
                            width: isMobile ? "100%" : "50%",
                            textAlign: "start",
                        }}
                        component="div"
                    >
                        Who We Are,
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            width: isMobile ? "100%" : "50%",
                            textAlign: "start",
                            flexDirection: "column",
                            pt: 3,
                        }}
                    >
                        <Typography>{t("serviceIntroduction.t21")}</Typography>
                        <Typography
                            sx={{
                                pt: 1,
                            }}
                        >
                            {t("serviceIntroduction.t22")}
                        </Typography>
                    </Box>
                </Box>

                {/* third paragraph */}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: isMobile ? "column" : "row",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? 32 : 45,
                            fontWeight: 800,
                            display: "flex",
                            width: isMobile ? "100%" : "50%",
                            textAlign: "start",
                        }}
                        component="div"
                    >
                        What We Do,
                    </Typography>
                    <Box
                        sx={{
                            width: isMobile ? "100%" : "50%",
                            pt: 3,
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 800,
                            }}
                        >
                            {t("serviceIntroduction.t23")}
                        </Typography>
                        <Typography
                            sx={{
                                pt: 1,
                            }}
                        >
                            {t("serviceIntroduction.t24")}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 800,
                                pt: 3,
                            }}
                        >
                            {t("serviceIntroduction.t25")}
                        </Typography>
                        <Typography
                            sx={{
                                pt: 1,
                            }}
                        >
                            {t("serviceIntroduction.t26")}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/* 2번 섹션 (서비스란 ?) */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    maxWidth: 1264,
                    px: 3,
                    py: 10,
                }}
            >
                <Typography
                    fontSize={isMobile ? 32 : 45}
                    fontWeight={800}
                    component={"div"}
                >
                    What is Our Service
                </Typography>

                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={isMobile ? {} : { xs: 1, sm: 2, md: 3 }}
                    sx={{ pt: 3 }}
                >
                    <ServiceThirdTile
                        imgSrc="./images/service/service-info-match.png"
                        title={t("serviceIntroduction.t31")}
                        desc={t("serviceIntroduction.t32")}
                    />
                    <ServiceThirdTile
                        imgSrc="./images/service/service-info-professional.png"
                        title={t("serviceIntroduction.t33")}
                        desc={t("serviceIntroduction.t34")}
                    />
                    <ServiceThirdTile
                        imgSrc="./images/service/service-info-safety.png"
                        title={t("serviceIntroduction.t35")}
                        desc={`${t("serviceIntroduction.t36")}${t(
                            "serviceIntroduction.t365"
                        )}`}
                    />
                    <ServiceThirdTile
                        imgSrc="./images/service/service-info-solution.png"
                        title={t("serviceIntroduction.t37")}
                        desc={t("serviceIntroduction.t38")}
                    />
                </Grid>
            </Box>
            {/* 3번 섹션 */}
            <Box
                sx={{
                    maxHeight: 854,
                    width: "100%",
                    maxWidth: 1520,
                }}
            >
                {/* 이거 이미지 밖꿔야됨 */}
                <video width="100%" height="100%" controls>
                    <source
                        src={"./images/service/tfo-vid.mp4"}
                        type="video/mp4"
                    />
                </video>
            </Box>
            {/* 4번 섹션 */}
            <Box
                sx={{
                    display: "flex",
                    maxWidth: 1264,
                    px: 3,
                    pt: 10,
                    width: "100%",
                    flexDirection: isMobile ? "column" : "row",
                }}
            >
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    width={isMobile ? "100%" : undefined}
                    pr={isMobile ? undefined : 10}
                >
                    <Box display={"flex"} width={"100%"}>
                        <img
                            src={"./images/service/info_1.png"}
                            style={{ objectFit: "fill", alignSelf: "center" }}
                            width={isMobile ? "100%" : "auto"}
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} pt={3}>
                        <Typography display={"flex"} fontWeight={800}>
                            {t("serviceIntroduction.t40")}
                        </Typography>
                        <Typography display={"flex"} pt={1} color={"#AAAAAA"}>
                            {t("serviceIntroduction.t41")}
                        </Typography>
                        <Typography display={"flex"} pt={1} color={"#AAAAAA"}>
                            2021.11.26
                        </Typography>
                    </Box>
                </Box>

                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    pt={isMobile ? 3 : undefined}
                    width={isMobile ? "100%" : undefined}
                >
                    <Box display={"flex"} width={"100%"}>
                        <img
                            src={"./images/service/info_2.png"}
                            style={{ objectFit: "fill" }}
                            width={isMobile ? "100%" : "auto"}
                        />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} pt={3}>
                        <Typography display={"flex"} fontWeight={800}>
                            {t("serviceIntroduction.t40")}
                        </Typography>
                        <Typography display={"flex"} pt={1} color={"#AAAAAA"}>
                            {t("serviceIntroduction.t42")}
                        </Typography>
                        <Typography display={"flex"} pt={1} color={"#AAAAAA"}>
                            2021.12.06
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* 5번 섹션 */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 1264,
                    px: 3,
                    py: 10,
                }}
            >
                <Box display={"flex"} flexDirection={"column"} pt={3}>
                    <Typography
                        color={"#568FFC"}
                        fontWeight={800}
                        fontSize={18}
                    >
                        {t("serviceIntroduction.t365")}
                    </Typography>
                    <Typography display={"flex"} pt={1}>
                        {t("serviceIntroduction.t51")}
                    </Typography>
                </Box>
                <Box display={"flex"} flexDirection={"column"} pt={6}>
                    <Typography
                        display={"flex"}
                        color={"#568FFC"}
                        fontWeight={800}
                        fontSize={18}
                    >
                        {t("serviceIntroduction.t52")}
                    </Typography>
                    <Typography display={"flex"} pt={1}>
                        {t("serviceIntroduction.t53")}
                    </Typography>
                </Box>
                <Box display={"flex"} flexDirection={"column"} pt={6}>
                    <Typography
                        display={"flex"}
                        color={"#568FFC"}
                        fontWeight={800}
                        fontSize={18}
                    >
                        {t("serviceIntroduction.t54")}
                    </Typography>
                    <Typography display={"flex"} pt={1}>
                        {t("serviceIntroduction.t55")}
                    </Typography>
                </Box>
            </Box>
            {/* 6번 섹션 */}
            {/* <Grid
                container
                flexDirection={"column"}
                sx={{ alignSelf: "center", maxWidth: 1264 }}
                pl={3}
                pr={3}
                pb={10}
                pt={10}
            >
                <Box display={"flex"} pt={3}>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid item xs={isMobile ? 12 : 6} pb={2}>
                            <Box
                                height={190}
                                p={5}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                sx={{ backgroundColor: "#FAFAFA" }}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography
                                        display={"flex"}
                                        fontSize={20}
                                        fontWeight={800}
                                    >
                                        Platform A
                                    </Typography>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography display={"flex"} pt={1}>
                                        기업 Network
                                    </Typography>
                                    <Typography display={"flex"}>
                                        수출 기업과 소싱기업
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6} pb={2}>
                            <Box
                                height={190}
                                p={5}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                sx={{ backgroundColor: "#FAFAFA" }}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography
                                        display={"flex"}
                                        fontSize={20}
                                        fontWeight={800}
                                    >
                                        Platform B
                                    </Typography>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography display={"flex"} pt={1}>
                                        직접 무역 전문가 Network
                                    </Typography>
                                    <Typography display={"flex"}>
                                        퇴직자 및 파트타임
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6} pb={2}>
                            <Box
                                height={190}
                                p={5}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                sx={{ backgroundColor: "#FAFAFA" }}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography
                                        display={"flex"}
                                        fontSize={20}
                                        fontWeight={800}
                                    >
                                        Platform C
                                    </Typography>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography display={"flex"} pt={1}>
                                        간접 무역 전문가 Network
                                    </Typography>
                                    <Typography display={"flex"}>
                                        언어, 지역, 사업, 기술 무역행정 전문가
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid> */}
            {/* 7번 섹션 (HOW)*/}
            <Grid
                container
                sx={{ backgroundColor: "black", alignSelf: "center" }}
                justifyContent={"center"}
            >
                <Grid
                    container
                    flexDirection={"column"}
                    sx={{
                        alignSelf: "center",
                        maxWidth: 1264,
                    }}
                    pl={3}
                    pr={3}
                    pt={10}
                    pb={isMobile ? 10 : 30}
                >
                    <Box display={"flex"}>
                        <Typography
                            fontSize={isMobile ? 32 : 45}
                            fontWeight={800}
                            color={"white"}
                        >
                            How You Do
                        </Typography>
                    </Box>
                    {/* 의뢰고객 테이블 */}
                    <Box display={"flex"} pt={10} flexDirection={"column"}>
                        <Typography
                            display={"flex"}
                            color={"#568FFC"}
                            fontWeight={800}
                            fontSize={20}
                        >
                            {t("serviceIntroduction.t60")}
                        </Typography>
                        <Grid container flexDirection={"row"} pt={3}>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    01
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t611")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t6112")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    02
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t612")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t6122")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    03
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t613")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t6132")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    04
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t614")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t6142")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                mt={isMobile ? 2 : undefined}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    05
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t615")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t6152")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        display={"flex"}
                        pt={3}
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent={"space-between"}
                        alignItems={isMobile ? "center" : undefined}
                    >
                        <Typography color={"#AAAAAA"} fontWeight={800}>
                            {t("serviceIntroduction.t71")}
                        </Typography>
                        {isMobile ? (
                            <img
                                src={"./images/icon/arrow_down.svg"}
                                style={{ padding: 10 }}
                            />
                        ) : (
                            <></>
                        )}
                        <Typography color={"#AAAAAA"} fontWeight={800}>
                            {t("serviceIntroduction.t72")}
                        </Typography>
                        {isMobile ? (
                            <img
                                src={"./images/icon/arrow_down.svg"}
                                style={{ padding: 10 }}
                            />
                        ) : (
                            <></>
                        )}
                        <Typography color={"#AAAAAA"} fontWeight={800}>
                            {t("serviceIntroduction.t73")}
                        </Typography>
                    </Box>
                    <Box pt={3}>
                        <Typography color={"#AAAAAA"}>
                            {t("serviceIntroduction.t74")}
                        </Typography>
                        <Typography color={"#AAAAAA"}>
                            {t("serviceIntroduction.t75")}
                        </Typography>
                    </Box>
                    {/* 전문가 테이블 */}
                    <Box display={"flex"} pt={10} flexDirection={"column"}>
                        <Typography
                            display={"flex"}
                            color={"#2DB961"}
                            fontWeight={800}
                            fontSize={20}
                        >
                            {t("serviceIntroduction.t80")}
                        </Typography>
                        <Grid container flexDirection={"row"} pt={3}>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    01
                                </Typography>
                                <Box>
                                    <Typography pt={1} fontWeight={800}>
                                        {t("serviceIntroduction.t811")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t8112")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    02
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t812")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t8122")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    03
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t813")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t8132")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                mr={isMobile ? undefined : 2}
                                mt={isMobile ? 2 : undefined}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                p={5}
                            >
                                <Typography
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    04
                                </Typography>
                                <Box>
                                    <Typography
                                        pt={1}
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t814")}
                                    </Typography>
                                    <Typography fontWeight={800}>
                                        {t("serviceIntroduction.t8142")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={isMobile ? 12 : 2.2}
                                sx={{ backgroundColor: "white", height: 200 }}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"space-between"}
                                mt={isMobile ? 2 : undefined}
                                p={5}
                            >
                                <Typography
                                    display={"flex"}
                                    color={"#568FFC"}
                                    fontWeight={800}
                                    fontSize={20}
                                >
                                    05
                                </Typography>
                                <Box>
                                    <Typography
                                        sx={{ whiteSpace: "nowrap" }}
                                        pt={1}
                                        fontWeight={800}
                                    >
                                        {t("serviceIntroduction.t815")}
                                    </Typography>
                                    <Typography
                                        fontWeight={800}
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("serviceIntroduction.t8152")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box
                            display={"flex"}
                            pt={3}
                            flexDirection={isMobile ? "column" : "row"}
                            justifyContent={"space-between"}
                            alignItems={isMobile ? "center" : undefined}
                        >
                            <Typography color={"#AAAAAA"} fontWeight={800}>
                                {t("serviceIntroduction.t91")}
                            </Typography>
                            {isMobile ? (
                                <img
                                    src={"./images/icon/arrow_down.svg"}
                                    style={{ padding: 10 }}
                                />
                            ) : (
                                <></>
                            )}
                            <Typography color={"#AAAAAA"} fontWeight={800}>
                                {t("serviceIntroduction.t72")}
                            </Typography>
                            {isMobile ? (
                                <img
                                    src={"./images/icon/arrow_down.svg"}
                                    style={{ padding: 10 }}
                                />
                            ) : (
                                <></>
                            )}
                            <Typography color={"#AAAAAA"} fontWeight={800}>
                                {t("serviceIntroduction.t93")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Service;
