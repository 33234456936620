import Button from "@mui/material/Button";

export interface LogoProps {
    inverse?: boolean;
}

const Logo: React.FC<LogoProps> = ({ inverse }) => {
    return (
        <Button
            sx={{
                position: "relative",
                p: 0,
            }}
            href={"/"}
        >
            <img
                src={`/images/logo/logo_horizon_${
                    inverse ? "black" : "white"
                }.svg`}
                alt="logo"
                style={{ objectFit: "scale-down", height: "24px" }}
            />
        </Button>
    );
};

export default Logo;
