import React, { useEffect, useState } from "react";

import { Button, Grid, TextField, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { EstimateReportInfo } from "../../../api-interfaces/quest";
import { suggestQuestEstimation } from "../../../apis/quest";

export interface EstiMateModifySectionProps {
    isMobile: boolean;
    questID: string;
    reportInfo: EstimateReportInfo;
    setReportInfo: React.Dispatch<React.SetStateAction<EstimateReportInfo>>;
}

const EstimateModifySection: React.FC<EstiMateModifySectionProps> = ({
    isMobile,
    questID,
    reportInfo,
    setReportInfo,
}) => {
    const [isModifying, setIsModifying] = useState(false);
    const { t } = useTranslation();

    const returnSubmit = (isDeleting?: boolean) => async () => {
        try {
            if (isDeleting) {
                if (!confirm(t("quest.deleteAskMessage") as string)) {
                    return;
                }
            }
            const patched = await suggestQuestEstimation(
                questID,
                reportInfo,
                isDeleting
            );
            console.log(patched);

            window.alert(t("quest.transferSuccess"));
            if (isDeleting) {
                location.replace("/mypage");
            } else {
                location.reload();
            }
        } catch (e) {
            window.alert(t("error.transferFailed"));
            return;
        }
    };

    useEffect(() => {
        setReportInfo;
    }, []);

    return (
        <Grid container width={"100%"} flexDirection={"column"}>
            <Grid
                item
                sx={
                    isMobile
                        ? { mb: 1, mt: 2 }
                        : {
                              bgcolor: "#FAFAFA",
                              border: "solid 1px #AAAAAA",
                              p: 3,
                              mb: 3,
                          }
                }
            >
                <Grid container justifyContent={"space-between"}>
                    <Grid>
                        <Typography variant="f20h28EB">
                            {t("quest.proposedEstimate")}
                        </Typography>
                    </Grid>
                    {!isModifying ? (
                        <Grid>
                            <Grid container>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        height: "34px",
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        setIsModifying(() => true);
                                    }}
                                    disabled={isModifying}
                                >
                                    <Typography variant="f16h20B">
                                        {t("status.modify")}
                                    </Typography>
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        height: "34px",
                                        ml: 1,
                                    }}
                                    color="pRed"
                                    onClick={returnSubmit(true)}
                                >
                                    <Typography variant="f16h20B">
                                        {t("status.delete")}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid>
                            <Grid container>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        height: "34px",
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        setIsModifying(() => false);
                                    }}
                                    disabled={!isModifying}
                                >
                                    <Typography variant="f16h20B">
                                        {t("status.cancel")}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {/* 글쓰는 곳 시작 */}
                <Grid mt={3} mb={1}>
                    <Typography variant="f16h20B">
                        {t("quest.proposedDate")}
                    </Typography>
                </Grid>
                <TextField
                    disabled={!isModifying}
                    placeholder={t("quest.dateWithPlaceholder") as string}
                    value={reportInfo.beginDate}
                    onChange={(e) =>
                        setReportInfo((prev) => {
                            return {
                                ...prev,
                                beginDate: e.target.value,
                            };
                        })
                    }
                />
                <Grid mt={3} mb={1}>
                    <Typography variant="f16h20B">
                        {t("quest.commissionFee")}
                    </Typography>
                </Grid>
                <TextField
                    disabled={!isModifying}
                    placeholder={t("quest.percentageWithPlaceholder") as string}
                    value={reportInfo.feeRate}
                    onChange={(e) =>
                        setReportInfo((prev) => {
                            return {
                                ...prev,
                                feeRate: e.target.value,
                            };
                        })
                    }
                />

                <Grid mt={3} mb={1}>
                    <Typography variant="f16h20B">
                        {t("quest.estimateDetail")}
                    </Typography>
                </Grid>
                <TextField
                    multiline
                    rows={6}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            height: "150px",
                            fontSize: "14px",
                            lineHeight: 1.43,
                            fontWeight: 600,
                        },
                    }}
                    placeholder={t("quest.detailsPlaceholder") as string}
                    value={reportInfo.detailInfo}
                    onChange={(e) =>
                        setReportInfo((prev) => {
                            return {
                                ...prev,
                                detailInfo: e.target.value,
                            };
                        })
                    }
                    disabled={!isModifying}
                />
                <Grid container mt={3} justifyContent={"flex-end"}>
                    <Button
                        variant="outlined"
                        sx={{
                            height: "34px",
                        }}
                        color="primary"
                        onClick={returnSubmit()}
                        disabled={!isModifying}
                    >
                        <Typography variant="f16h20B">
                            {t("status.submit")}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EstimateModifySection;
