import { MongoDBIncluding } from "./baseType";
import { QuestStatus } from "./quest";

/**
 * 0: 인증 안 된 회사
 * 1: 인증 된 회사
 * 2: 인증 안 된 전문가
 * 3: 인증 된 전문가
 * 8: 관리자
 */
export enum UserRole {
    GUEST = 0,
    COMPANY = 1,
    UNAPPROVED_EXPERT = 2,
    EXPERT = 3,
    ADMIN = 8,
}

export type UserQuestStat = {
    [id in QuestStatus]: string[];
};

export interface UserBase extends MongoDBIncluding {
    email: string;
    password: string;
    name: string;
    contact: string;
    nickname: string;

    role: UserRole;
    /**
     * profile image src
     * NOTE 필드명 바꿔야함
     */
    image: string;

    /**
     * 회사명
     */
    companyName?: string;
    /**
     * 회사주소
     */
    companyAddr?: string;
    /**
     * 사업자번호
     */
    businessNum?: string;

    /**
     * 자기소개 한마디
     * NOTE 바꿔야함 필드명
     */
    subtitle?: string;

    /**
     * 좋아요를 누른 Post 들의 _id
     */
    likedPosts?: string[];

    /**
     * 나이 (추가됨)
     * 설정안하면 -1 살
     */
    age?: number;

    /**
     * 사업자등록증이 들어가있는 aws 필드
     */
    businessLicense?: string;
    /**
     * 경력증명서 url
     */
    careerCertification?: string;
    /**
     * 통장사본 url
     */
    copyBankAccount?: string;

    /**
     * TM-20 에 따른 케이스
     */
    wasApplied?: boolean;
}

export interface ParsedUser extends UserBase {}
export interface UserModel extends UserBase {}

export interface RequestPostUser {
    email: string;
    name: string;
    contact: string;
    pw: string;
    companyName: string;
    role: UserRole;
}

export interface RequestPatchUser {
    name?: string;
    contact?: string;
    nickname?: string;
    password?: string;
    questStat?: UserQuestStat;
    companyName?: string;
    companyAddr?: string;
    /**
     * 나이 (추가됨)
     */
    age?: number;

    /**
     * profile image src
     * NOTE 필드명 바꿔야함
     */
    image?: string;

    /**
     * 자기소개 한마디
     * NOTE 바꿔야함 필드명
     */
    subtitle?: string;
    role?: UserRole;
    businessNum?: string;
    /**
     * 사업자등록증이 들어가있는 aws 필드
     */
    businessLicense?: string;
    /**
     * 경력증명서 url
     */
    careerCertification?: string;
    /**
     * 통장사본 url
     */
    copyBankAccount?: string;

    /**
     * TM-20 에 따른 케이스
     */
    wasApplied?: boolean;
}

export enum LogInUnique {
    SIGN_UP = "signup",
}

export interface ResponseLogIn {
    /**
     * 로그인 시 `true` 로그아웃 시 `false`
     */
    isLoggedIn: boolean;

    /**
     * 특이사항
     */
    unique?: LogInUnique;
}

export interface SimpleUserData {
    image: string;
    nickname: string;
    subtitle: string;
    role: UserRole;
    contact: string;
    email: string;
}

export interface QuestLeftSideExpertInfo {
    name: string;
    contact: string;
    email: string;
    beginDate: string;
    feeRate: string;
}

export interface RequestFindingPostForgotUserData {
    name: string;
    contact: string;
    /**
     * 이걸 첨부하면 비밀번호 찾기로...
     */
    email?: string;
}

export interface RequestPostResetPassword {
    /**
     * 쉽게 탈취하기 어렵게 하기위해 password 라는 필드명을 사용하지 않음
     */
    rsp: string;
}
