import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            // eslint-disable-next-line import/no-named-as-default-member
            ReactGA.initialize(gaTrackingId as string);
            console.log(gaTrackingId);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            // eslint-disable-next-line import/no-named-as-default-member
            ReactGA.set({ page: location.pathname });
            // eslint-disable-next-line import/no-named-as-default-member
            ReactGA.send("pageview");
        }
    }, [initialized, location]);

    useEffect(() => {
        // eslint-disable-next-line import/no-named-as-default-member
        ReactGA.initialize(gaTrackingId as string);
        // eslint-disable-next-line import/no-named-as-default-member
        ReactGA.set({ page: location.pathname });
        // eslint-disable-next-line import/no-named-as-default-member
        ReactGA.send("pageview");
    }, [location]);

    return null;
};

export default RouteChangeTracker;
