import {
    CategoryType,
    RequestPatchComment,
    RequestPatchPost,
    RequestPostComment,
    RequestPostPost,
    ResponseGetPost,
    ResponsePatchComment,
    ResponsePostLike,
} from "../api-interfaces/post";

import apiInstance, { BACKEND_URL } from "./base";

export const registerPost = async (postInfo: RequestPostPost) => {
    const query = `v1/post`;
    const newPost = await apiInstance
        .post(query, { json: postInfo })
        .json<ResponseGetPost>();

    return newPost;
};

export const getPosts = async (
    filter?: CategoryType | string,
    limit?: number,
    skip?: number
) => {
    const query = new URL(`/v1/post`, BACKEND_URL);
    if (filter) query.searchParams.set("filter", filter);
    if (limit) query.searchParams.set("limit", limit.toString());
    if (skip) query.searchParams.set("skip", skip.toString());

    const fetchedPosts = await apiInstance
        .get(`v1/post` + query.search)
        .json<ResponseGetPost[]>();

    return fetchedPosts;
};

export const getOnePost = async (postId: string) => {
    const query = `v1/post/${postId}`;
    const fetchedPost = await apiInstance.get(query).json<ResponseGetPost>();

    return fetchedPost;
};

export const deletePost = async (postId: string) => {
    const query = `v1/post/${postId}`;
    const result = await apiInstance.delete(query);

    return result;
};

export const patchPost = async (postId: string, postInfo: RequestPatchPost) => {
    const query = `v1/post/${postId}`;
    const modifiedPost = await apiInstance.patch(query, { json: postInfo });

    return modifiedPost;
};

export const likePost = async (postId: string) => {
    const query = `v1/post/${postId}/like`;
    const like = await apiInstance.post(query).json<ResponsePostLike>();
    return like;
};

export const postComment = async (
    postId: string,
    commentInfo: RequestPostComment
) => {
    const query = `v1/post/${postId}/comment`;
    const updatedPost = await apiInstance
        .post(query, { json: commentInfo })
        .json<ResponsePatchComment>();

    return updatedPost;
};

export const deleteOrEditComment = async (
    postId: string,
    commentInfo: RequestPatchComment
) => {
    const query = `v1/post/${postId}/comment`;
    const updatedPost = await apiInstance
        .patch(query, { json: commentInfo })
        .json<ResponsePatchComment>();

    return updatedPost;
};
