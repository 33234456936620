import React from "react";

import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

export interface DownloadButtonProps {
    /**
     * 다운로드
     */
    href: string;
    /**
     * 보여주냐 마냐
     */
    show?: boolean;
    /**
     * 종류
     */
    label: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
    href,
    show = true,
    label,
}) => {
    const { t } = useTranslation();
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
                display: show ? undefined : "none",
                mt: 7,
            }}
        >
            <Typography variant="f18h20B">{label}</Typography>
            <a href={href} download style={{ textDecoration: "none" }}>
                <Button
                    color="pBlue"
                    sx={{
                        fontSize: 16,
                        height: "24px",
                    }}
                >
                    <FileDownloadOutlined />
                    <Typography variant="f16h20B">
                        {t("common.download")}
                    </Typography>
                </Button>
            </a>
        </Box>
    );
};

export default DownloadButton;
