import React, { useEffect, useState } from "react";

import { TuneRounded } from "@mui/icons-material";
import {
    Box,
    Grid,
    IconButton,
    Pagination,
    Typography,
    useMediaQuery,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { QuestFormTypes, QuestModel } from "../../../api-interfaces/quest";
import { getMyQuests } from "../../../apis/quest";
import { mobileMaxWidthMediaQuery } from "../../../theme";
import RequestCard from "../../cards/RequestCard";
import QuestLevelFilterModal from "../../modals/QuestLevelFilterModal";

import ReqTypeSelectSection from "./ReqTypeSelectSection";

const LIMIT = 5;

export interface MyRequestsSectionProps {}

const MyRequestsSection: React.FC<MyRequestsSectionProps> = ({}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    const [openFilterModal, setOpenFilterModal] = useState(false);

    const [reqType, setReqType] = useState<QuestFormTypes>(
        QuestFormTypes.EXPORT
    );
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [levelFilter, setLevelFilter] = useState<number>(-1);

    const [quests, setQuests] = useState<QuestModel[]>([]);
    const [filterCount, setFilterCount] = useState({ iCount: 0, eCount: 0 });

    const [isPaging, setIsPaging] = useState(false);
    const { t } = useTranslation();

    const handleChangeTypeFilterHOF = (qft: QuestFormTypes) => () => {
        setIsPaging(() => false);
        setCurrentPage(() => 1);
        setReqType(() => qft);
    };

    const handleChangeLevelFilter = (level: number) => {
        setLevelFilter(() => level);
    };

    useEffect(() => {
        (async () => {
            try {
                if (reqType && levelFilter !== undefined && currentPage) {
                    // 카테고리나 레벨이 바뀔 때는 1페이지에 관한 정보로
                    const fetchedQuests = await getMyQuests(
                        isPaging ? (currentPage - 1) * LIMIT : 0,
                        LIMIT,
                        reqType,
                        levelFilter
                    );

                    if (fetchedQuests.cobj !== undefined) {
                        setFilterCount(() => fetchedQuests.cobj);
                    }

                    setQuests(() => fetchedQuests.quests);
                    console.log("fetched quests: ", fetchedQuests);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [reqType, levelFilter, currentPage, isPaging]);

    return (
        <>
            <Grid>
                {/*  필터섹션 */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        my: 2,
                    }}
                >
                    <ReqTypeSelectSection
                        variant="round"
                        reqType={reqType}
                        importCount={filterCount.iCount}
                        exportCount={filterCount.eCount}
                        onChangeTypeFilterHOF={handleChangeTypeFilterHOF}
                    />
                    {/* 레벨 필터 모달 버튼 */}
                    {!isMobile && (
                        <IconButton
                            size="small"
                            onClick={() => setOpenFilterModal(true)}
                        >
                            <TuneRounded />
                        </IconButton>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        mb: 2,
                    }}
                >
                    {/* 페이지네이션 */}
                    <Pagination
                        count={Math.ceil(
                            (reqType === QuestFormTypes.EXPORT
                                ? filterCount.eCount
                                : filterCount.iCount) / LIMIT
                        )}
                        page={currentPage}
                        onChange={(_e, v) => {
                            setIsPaging(() => true);
                            setCurrentPage(() => v);
                        }}
                    />
                    {isMobile && (
                        <IconButton
                            size="small"
                            onClick={() => setOpenFilterModal(true)}
                        >
                            <TuneRounded />
                        </IconButton>
                    )}
                </Box>

                {quests.length === 0 && (
                    <Grid width={"100%"} mt={10}>
                        <Typography sx={{ textAlign: "center" }}>
                            {t("mypage.noQuestPrompt")}
                        </Typography>
                    </Grid>
                )}
                {quests.map((req, idx) => (
                    <RequestCard
                        key={`req-${req._id}`}
                        isMobile={isMobile}
                        reqId={req._id}
                        idx={idx + 1}
                        reqType={req.type}
                        level={req.level}
                        companyName={req.companyName}
                        date={req.date}
                        productName={req.productName}
                        title={req.title}
                        showMoreButton
                    />
                ))}
            </Grid>
            {/* 의뢰단계 필터 다이알로그 */}
            <QuestLevelFilterModal
                isMobile={isMobile}
                open={openFilterModal}
                handleClose={() => {
                    setOpenFilterModal(false);
                }}
                onChangeFilter={handleChangeLevelFilter}
            />
        </>
    );
};

export default MyRequestsSection;
