import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { Box, Button, Grid, Input, Typography } from "@mui/material";

import {
    RequestPostAndPatchTradefirm,
    Tradefirm,
} from "../../api-interfaces/tradefirm";
import { deleteUserAdminControl, patchTradefirm } from "../../apis/tradefirm";
import { uploadImage } from "../../apis/upload";
import { DEFAULT_PROFILE_IMG_PATH } from "../../utils/consts";
import SolidTextButtonWithColor from "../buttons/SolidTextButtonWithColor";
import ModalDialog from "../modals/ModalDialog";
import YesOrNoModal from "../modals/YesOrNoModal";
import { renderUpdateComponentRecoil } from "../states/recoil";

import CompanyTileMain from "./CompanyTileMain";

interface AdminTFTileProps {
    data: Tradefirm;
    isMobile?: boolean;
}

const AdminTFTile: React.FC<AdminTFTileProps> = ({
    data,
    // isMobile = false,
}) => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const [image, setImage] = useState<File>();
    /**
     * 기업명
     */
    const [companyName, setCompanyName] = useState<string>("");
    /**
     * 수출 품목
     */
    const [exportItem, setExportItem] = useState<string>("");
    /**
     * 자신있는 수출 품목
     */
    const [proudExportItem, setProudExportItem] = useState<string>("");
    /**
     *
     */
    const [desirableSourcingItemSet, setDesirableSourcingItemSet] =
        useState<string>("");
    /**
     * 주요 취급 품목군
     */
    const [mainDealingItem, setMainDealingItem] = useState<string>("");
    /**
     * 홈페이지 url 주소
     */
    const [homepage, setHomepage] = useState<string>("");
    /**
     * 전문무역상사 기업형태
     */
    const [companyForm, setCompnayForm] = useState<string>("");
    /**
     * 수출중인국가
     */
    const [exportingNations, setExportingNations] = useState<string>("");
    /**
     * 기업소개
     */
    const [companyIntroduction, setCompanyIntroduction] = useState<string>("");

    const [reRenderData, setReRenderData] = useRecoilState(
        renderUpdateComponentRecoil
    );

    const handleDelete = async () => {
        await deleteUserAdminControl(data._id).then(() =>
            console.log(data.companyName, "님 삭제가 완료되었습니다.")
        );
        setShowDeleteModal(false);
        console.log(reRenderData, "current");
        setReRenderData(!reRenderData);
    };

    const onUploadPicture = async (image: File) => {
        console.log(image.name);
        try {
            if (data) {
                const formData = new FormData();
                formData.append("image", image);
                const res = await uploadImage(data._id, formData);
                console.log(res, "HERE IS RES");
                return res;
            }
        } catch (e) {
            window.alert("이미지 업로드 과정 중에 문제가 발생했습니다.");
        }
    };

    const handleEdit = async () => {
        let imgSrc;
        if (image) imgSrc = await onUploadPicture(image);

        const changed: RequestPostAndPatchTradefirm = {
            /**
             * 프로필사진 (기업사진)
             */
            image: imgSrc ? imgSrc.src : data.image,
            /**
             * 기업명
             */
            companyName: companyName !== "" ? companyName : data.companyName,
            /**
             * 수출 품목
             */
            exportItem: exportItem !== "" ? exportItem : data.exportItem,
            /**
             * 자신있는 수출 품목
             */
            proudExportItem:
                proudExportItem !== "" ? proudExportItem : data.proudExportItem,
            /**
             *
             */
            desirableSourcingItemSet:
                desirableSourcingItemSet !== ""
                    ? desirableSourcingItemSet
                    : data.desirableSourcingItemSet,
            /**
             * 주요 취급 품목군
             */
            mainDealingItem:
                mainDealingItem !== "" ? mainDealingItem : data.mainDealingItem,
            /**
             * 홈페이지 url 주소
             */
            homepage: homepage !== "" ? homepage : data.homepage,
            /**
             * 전문무역상사 기업형태
             */
            companyForm: companyForm !== "" ? companyForm : data.companyForm,
            /**
             * 수출중인국가
             */
            exportingNations:
                exportingNations !== ""
                    ? exportingNations
                    : data.exportingNations,
            /**
             * 기업소개
             */
            companyIntroduction:
                companyIntroduction !== ""
                    ? companyIntroduction
                    : data.companyIntroduction,
        };
        await patchTradefirm(data._id, changed).then(() =>
            console.log(data.companyName, " 정보가 수정되었습니다.")
        );
        setReRenderData(!reRenderData);
    };

    return (
        <Grid container flexDirection={"row"} width={"100%"} minWidth={1200}>
            <Grid container width={200}>
                <CompanyTileMain
                    data={data}
                    isMobile={true}
                    width={200}
                    height={300}
                />
            </Grid>
            <Grid
                container
                sx={{ border: "solid 1px #AAAAAA" }}
                mb={3}
                flexDirection={"column"}
                height={400}
                ml={2}
                width={"auto"}
                justifyContent={"space-between"}
            >
                <Grid container justifyContent={"space-between"}>
                    <Grid
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"flex-start"}
                        p={3}
                    >
                        <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            width={250}
                        >
                            <Box display={"flex"}>
                                <Typography variant={"f16h20B"}>
                                    {data.companyName}
                                </Typography>
                            </Box>
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                pt={2}
                                justifyContent={"flex-end"}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        기업명
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.companyName}
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    pt={2}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        소싱 희망 품목
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.desirableSourcingItemSet}
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    pt={2}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        전문무역상사 기업 형태
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.companyForm}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            pl={5}
                            justifyContent={"flex-start"}
                            mt={2.5}
                            width={250}
                        >
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                pt={2}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        수출 품목
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.exportItem}
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    pt={2}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        주요 취급 품목군
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.mainDealingItem}
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    pt={2}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        수출 중인 국가
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.exportingNations}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            display={"flex"}
                            flexDirection={"column"}
                            pl={5}
                            justifyContent={"flex-start"}
                            mt={2.5}
                        >
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                pt={2}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        수출에 자신 있는 품목
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.proudExportItem}
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    pt={2}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            fontColor: "#AAAAAA",
                                        }}
                                    >
                                        홈페이지
                                    </Typography>
                                    <Typography variant={"f16h20B"}>
                                        {data.homepage}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-end"}
                        justifyContent={"space-between"}
                        m={3}
                    >
                        <Box display={"flex"}>
                            <SolidTextButtonWithColor
                                text={"수정"}
                                size={15}
                                sx={{ width: 70, height: 35, mr: 3 }}
                                onClick={() => setShowEditModal(true)}
                            />
                            <SolidTextButtonWithColor
                                text={"삭제"}
                                size={15}
                                color={"red"}
                                sx={{
                                    color: "red",
                                    width: 70,
                                    height: 35,
                                }}
                                onClick={() => setShowDeleteModal(true)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        pt={2}
                        width={800}
                        p={3}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontColor: "#AAAAAA",
                            }}
                        >
                            기업소개
                        </Typography>
                        <Typography variant={"f16h20B"}>
                            {data.companyIntroduction}
                        </Typography>
                    </Box>
                    {/* <Box display={"flex"} p={3}>
                        <SolidTextButtonWithColor
                            text={"완료"}
                            size={15}
                            sx={{ width: 70, height: 35 }}
                        />
                    </Box> */}
                </Grid>
            </Grid>
            <YesOrNoModal
                isMobile={false}
                onYes={() => handleDelete()}
                title={"정말로 삭제하시겠습니까?"}
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                fullScreen
                PaperProps={{
                    sx: {
                        height: "300px",
                        mt: "5vh",
                        width: "500px",
                        borderRadius: "0px 0px 0px 0px",
                    },
                }}
            />

            {/* 수정 모달 */}
            <ModalDialog
                isMobile={false}
                title={"무역상사 수정"}
                open={showEditModal}
                onClose={() => setShowEditModal(false)}
                fullScreen
                PaperProps={{
                    sx: {
                        height: 800,
                        mt: "5vh",
                        borderRadius: "0px 0px 0px 0px",
                        overflowY: "auto",
                    },
                }}
            >
                <Grid container flexDirection={"column"}>
                    <Grid display={"flex"} flexDirection={"row"}>
                        <Box
                            display={"flex"}
                            width={"auto"}
                            height={150}
                            mr={1}
                        >
                            {image ? (
                                <img
                                    src={URL.createObjectURL(image)}
                                    width={150}
                                    style={{ objectFit: "contain" }}
                                />
                            ) : (
                                <img
                                    src={data.image || DEFAULT_PROFILE_IMG_PATH}
                                    width={150}
                                    style={{ objectFit: "contain" }}
                                />
                            )}
                        </Box>
                        <Box
                            display={"flex"}
                            border={"dotted 1px #568FFC"}
                            width={150}
                            height={150}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                        >
                            <Box display={"flex"}>
                                <img
                                    src={"./images/icon/image-plus.svg"}
                                    width={26}
                                    height={26}
                                />
                            </Box>
                            <Button
                                sx={{ p: 1, height: "26px" }}
                                // onClick={}
                                component="label"
                            >
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/png"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            setImage(e.target.files[0]);
                                        }
                                    }}
                                />
                                <Typography variant="f12h20">
                                    프로필 사진 변경
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">기업명</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">수출품목</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.exportItem}
                            onChange={(e) => setExportItem(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            수출에 자신있는 품목
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.proudExportItem}
                            onChange={(e) => setProudExportItem(e.target.value)}
                        />
                    </Grid>

                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            소싱 희망 품목
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.desirableSourcingItemSet}
                            onChange={(e) =>
                                setDesirableSourcingItemSet(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            주요 취급 품목군
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.mainDealingItem}
                            onChange={(e) => setMainDealingItem(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">홈페이지</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.homepage}
                            onChange={(e) => setHomepage(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            전문무역상사 기업형태
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.companyForm}
                            onChange={(e) => setCompnayForm(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            수출 중인 국가
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.exportingNations}
                            onChange={(e) =>
                                setExportingNations(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">기업소개</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            defaultValue={data.companyIntroduction}
                            onChange={(e) =>
                                setCompanyIntroduction(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignSelf={"flex-end"}
                        mt={4}
                    >
                        <SolidTextButtonWithColor
                            text={"완료"}
                            size={15}
                            sx={{
                                width: 70,
                                height: 35,
                                backgroundColor: "black",
                            }}
                            color={"white"}
                            onClick={() => {
                                handleEdit();
                                setShowEditModal(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </ModalDialog>
        </Grid>
    );
};

export default AdminTFTile;
