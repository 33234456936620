import { ChatModel, ChatParsed } from "../api-interfaces/chat";
import {
    EstimateReportInfo,
    ExpertSettlement,
    QuestFormTypes,
    QuestModel,
    RequestPostQuest,
    RequestSelectExpertPatch,
} from "../api-interfaces/quest";

import apiInstance from "./base";

export const registerQuest = async (questInfo: RequestPostQuest) => {
    const query = `v1/quest`;
    const newQuest = await apiInstance
        .post(query, { json: questInfo })
        .json<QuestModel>();

    return newQuest;
};

/**
 * 전문가가 현재 널려있는 요청사항들 볼 때 사용하는 api
 */
export const getProposalLevelQuests = async () => {
    const query = `v1/quest/proposal`;
    const proposalQuests = await apiInstance.get(query).json<QuestModel[]>();
    return proposalQuests;
};

/**
 * 전문가가 제안함 (챗 생성) 및 수정/삭제함
 *
 * @param expertID
 * @param questID
 * @returns
 */
export const suggestQuestEstimation = async (
    questID: string,
    reportInfo: EstimateReportInfo,
    isDeleting?: boolean
) => {
    const query = `v1/quest/${questID}/attach-estimate${
        isDeleting ? "?isDeleting=1" : ""
    }`;
    const promotedQuest = await apiInstance
        .patch(query, { json: reportInfo })
        .json<QuestModel>();
    return promotedQuest;
};

/**
 * 제안 들어온 견적서의 해당하는 채팅들 읽어오기
 *
 * @param questID - 회사 유저의 mongodb ID
 * @returns
 */
export const getEstimatedChats = async (questID: string) => {
    const query = `v1/quest/${questID}/estimated`;

    const ecs = await apiInstance.get(query).json<ChatModel[]>();
    const estimatedChats: ChatParsed[] = ecs.map((ec) => {
        return {
            ...ec,
            logs: JSON.parse(ec.logs),
            reportInfo: JSON.parse(ec.reportInfo),
            expertInfo: JSON.parse(ec.expertInfo),
            announcements: JSON.parse(ec.announcements),
        };
    });

    return estimatedChats;
};

/**
 * 전문가 선택하기
 * NOTE 챗들을 쭉 불러올 때
 * @param data
 * @returns
 */
export const selectExpert = async (data: RequestSelectExpertPatch) => {
    const query = `v1/quest/select-expert`;

    const selectedQuest = await apiInstance
        .patch(query, { json: data })
        .json<QuestModel>();
    return selectedQuest;
};

/**
 * 의뢰를 취소함
 * 2번째 스테이지에서 고객이 취소함
 * 결과를 내뱉긴 하지만 취소한 이후부터 클라이언트랑 전문가는 해당화면을 볼수 없음
 *
 * @param questID - 취소할 의뢰 아이디
 * @returns
 */
export const abortQuest = async (questID: string) => {
    const query = `v1/quest/abort/${questID}`;

    const abortedQuest = await apiInstance.patch(query).json<QuestModel>();
    return abortedQuest;
};

/**
 * 기업 측에서 저 돈 입금했어요 하고 어드민에게 알려주는 api
 * @param questID
 * @returns
 */
export const pleaseCheckPay = async (questID: string) => {
    const query = `v1/quest/${questID}/check-pay`;
    const paidQuest = await apiInstance.patch(query).json<QuestModel>();
    return paidQuest;
};

/**
 * 어드민에서 아 그렇구나 잘 받았다 이제 진행해라 하고 상태 바꿔주는 api
 *
 * @param questID
 * @returns
 */
export const confirmPayment = async (questID: string) => {
    const query = `v1/quest/${questID}/confirm-pay`;
    const paidQuest = await apiInstance.patch(query).json<QuestModel>();
    return paidQuest;
};

/**
 * 퀘스트 하나 가져오는 api
 * @param questID
 * @returns
 */
export const getQuestById = async (questID: string) => {
    const query = `v1/quest/getone/${questID}`;
    const oneQuest = await apiInstance.get(query).json<QuestModel>();
    return oneQuest;
};

/**
 * 전문가가 정산정보를 입력해요!
 *
 * @param questID
 * @param settleInfo
 */
export const inputSettlement = async (
    questID: string,
    settleInfo: ExpertSettlement
) => {
    const query = `v1/quest/${questID}/input-settlement`;
    const settledQuest = await apiInstance
        .patch(query, { json: settleInfo })
        .json<QuestModel>();
    return settledQuest;
};
/**
 * 기업이 정산정보를 반려해요
 *
 * @param questID
 */
export const denySettlement = async (questID: string) => {
    const query = `v1/quest/${questID}/deny-settlement`;
    const settledQuest = await apiInstance.patch(query).json<QuestModel>();
    return settledQuest;
};
/**
 * 기업이 정산정보를 승인해요
 *
 * @param questID
 */
export const approveSettlement = async (questID: string) => {
    const query = `v1/quest/${questID}/approve-settlement`;
    const settledQuest = await apiInstance.patch(query).json<QuestModel>();
    return settledQuest;
};

/**
 * 기업이 정산정보를 확인 후 완료해요!
 *
 * @param questID
 * @param settleInfo
 */
export const completeQuest = async (questID: string) => {
    const query = `v1/quest/${questID}/complete`;
    const settledQuest = await apiInstance.patch(query).json<QuestModel>();
    return settledQuest;
};

/**
 * 자기가 진행중인 모든 의뢰들을 가져와요
 *
 * @deprecated
 *
 * @param questID
 * @returns
 */
export const getAllQuestsThatIncludesMe = async () => {
    const query = `v1/quest/all/my`;
    const settledQuest = await apiInstance.get(query).json<QuestModel[]>();
    return settledQuest;
};

/**
 * 자기가 진행중인 모든 의뢰 가져오기
 *
 * @param skip
 * @param limit
 * @param type
 * @param filter - 필터링 (-1: 전체, 0~ 단계, 6은 6,7단계 전부)
 * @returns
 */
export const getMyQuests = async (
    skip?: number,
    limit?: number,
    type?: QuestFormTypes,
    level?: number
) => {
    const query = `v1/quest/my${skip || limit || type || level ? "?" : ""}${
        skip ? `skip=${skip}` : ""
    }${skip && limit ? "&" : ""}${limit ? `limit=${limit}` : ""}${
        limit && type ? "&" : ""
    }${type ? `type=${type}` : ""}${type && level !== undefined ? "&" : ""}${
        level !== undefined ? `level=${level}` : ""
    }`;
    const res = await apiInstance.get(query).json<{
        quests: QuestModel[];
        cobj: { iCount: number; eCount: number };
    }>();
    console.log("res: ", res);

    return res;
};
