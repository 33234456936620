import React from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../../theme";

const CompanyInfoSecond = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 1264,
                width: "100%",
                mt: isMobile ? 5 : 10,
                mb: isMobile ? 10 : 25,
                px: 3,
            }}
        >
            <Box sx={{ animation: "fadeIn 3s", mb: isMobile ? 5 : 10 }}>
                <Typography
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    component={"div"}
                >
                    {t("companyInfo.t21")}
                </Typography>
                <Typography
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    component={"div"}
                >
                    {t("companyInfo.t22")}
                </Typography>
            </Box>

            <img
                src={"./images/company-info/company-info_1.png"}
                style={{
                    maxWidth: 1264,
                    height: isMobile ? 420 : "100%",
                    objectFit: isMobile ? "fill" : "cover",
                    imageResolution: "300dpi",
                }}
            />
        </Box>
    );
};

export default CompanyInfoSecond;
