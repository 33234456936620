import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";

import {
    Button,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";

import { CategoryType, FreeBoardCategoryType } from "../api-interfaces/post";
import { getOnePost, patchPost, registerPost } from "../apis/post";
import BackButton from "../components/buttons/BackButton";
import { navThemeRecoil, userRecoil } from "../components/states/recoil";
import { FontWeightValues } from "../types/fontWeightTypes";
import { navThemeValues } from "../types/navThemeTypes";

export interface PostEditProps {}

const PostEdit: React.FC<PostEditProps> = () => {
    const { postId } = useParams();
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState<CategoryType>(
        FreeBoardCategoryType.CAR_PART
    );
    const [contents, setContents] = useState("");
    const userData = useRecoilValue(userRecoil);

    const submit = () => {
        if (!userData) {
            alert("먼저 로그인 후 작성해주세요");
        } else {
            if (confirm("저징하시겠습니까?")) {
                if (postId) {
                    patchPost(postId, { title, category, contents })
                        .then(() => {
                            alert("수정되었습니다.");
                            window.location.assign(`/bulletinboard/${postId}`);
                        })
                        .catch((e) => {
                            alert("저장에 실패했습니다.");
                            console.log(e);
                        });
                } else {
                    registerPost({
                        title,
                        category,
                        contents,
                    })
                        .then((res) => {
                            alert("게시물이 포스팅되었습니다.");
                            window.location.assign(`/bulletinboard/${res._id}`);
                        })
                        .catch((e) => {
                            alert("포스팅에 실패했습니다.");
                            console.log(e);
                        });
                }
            }
        }
    };

    useEffect(() => {
        if (postId) {
            getOnePost(postId).then((post) => {
                setTitle(post.title);
                setCategory(post.category);
                setContents(post.contents);
            });
        }
    }, [postId]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    return (
        <Grid container flexDirection={"column"} alignItems={"center"} p={2.5}>
            <Grid
                sx={{
                    maxWidth: "750px",
                    width: "100%",
                    // flexWrap: "nowrap",
                    mt: 15,
                }}
            >
                <Grid container mb={2} alignItems={"center"}>
                    <BackButton />

                    <Typography
                        variant="f24h36B"
                        fontWeight={FontWeightValues.EXTRA_BOLD}
                    >
                        게시글 작성
                    </Typography>
                </Grid>
                <Grid container mb={2}>
                    <Grid container flex={1}>
                        <TextField
                            placeholder="제목을 입력해주세요"
                            variant="standard"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Select
                        variant="standard"
                        value={category}
                        onChange={(e: SelectChangeEvent) => {
                            setCategory(e.target.value as CategoryType);
                        }}
                        sx={{ pl: 2 }}
                    >
                        <MenuItem value="">
                            <em>카테고리</em>
                        </MenuItem>
                        <MenuItem value={FreeBoardCategoryType.MACHINE}>
                            기계
                        </MenuItem>
                        <MenuItem value={FreeBoardCategoryType.CAR_PART}>
                            자동차부품
                        </MenuItem>
                        <MenuItem value={FreeBoardCategoryType.HEAVY_EQUIP}>
                            중장비
                        </MenuItem>
                        <MenuItem value={FreeBoardCategoryType.ROBOT_AUTO}>
                            로봇자동화
                        </MenuItem>
                    </Select>
                </Grid>
                <TextField
                    multiline
                    rows={25}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            height: "550px",
                            fontSize: "14px",
                            lineHeight: 1.43,
                            fontWeight: 600,
                        },
                    }}
                    placeholder={"내용을 입력해주세요"}
                    value={contents}
                    onChange={(e) => setContents(e.target.value)}
                />
                <Grid mt={2}>
                    <Button variant="contained" fullWidth onClick={submit}>
                        완료
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PostEdit;
