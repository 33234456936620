import React from "react";

import { Box, DialogTitle, Grid, Typography } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { FontWeightValues } from "../../types/fontWeightTypes";
import SolidTextButtonWithColor from "../buttons/SolidTextButtonWithColor";

const Transtion = (
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) => {
    return <Slide direction="up" ref={ref} {...props} />;
};

const ForwardedSlideUpTransition = React.forwardRef(Transtion);

interface YesOrNoModalProps extends DialogProps {
    isMobile: boolean;
    title: string;
    onClose: () => void;
    onYes: () => void;
    themeMode?: "Dark" | "Light";
    errorText?: string;
}

const YesOrNoModal: React.FC<YesOrNoModalProps> = ({
    isMobile,
    title,
    onClose,
    onYes,
    themeMode = "Light",
    errorText = "",
    ...others
}) => {
    return (
        <Dialog
            // fullScreen={isMobile}
            TransitionComponent={ForwardedSlideUpTransition}
            maxWidth={"md"}
            scroll={"paper"}
            {...others}
            PaperProps={{
                ...others?.PaperProps,
                elevation: 1,
                sx: {
                    borderRadius: 0,
                    p: 4,
                    width: isMobile ? "100%" : "680px",
                    bgcolor: themeMode === "Dark" ? "mono.800" : "mono.50",
                    color: themeMode === "Dark" ? "mono.50" : "mono.800",
                    ...others?.PaperProps?.sx,
                    alignItems: "center",
                    justifyContent: "center",
                },
            }}
        >
            <DialogTitle
                sx={{
                    p: 0,
                    pb: 2,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                }}
            >
                <Grid container justifyContent={"center"} alignItems={"center"}>
                    <Typography
                        variant="f24h36B"
                        fontWeight={FontWeightValues.EXTRA_BOLD}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid
                    display={"flex"}
                    flexDirection={"row"}
                    pt={3}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Box display={"flex"}>
                        <SolidTextButtonWithColor
                            text={"완료"}
                            size={15}
                            sx={{ width: 100, height: 35 }}
                            onClick={onYes}
                        />
                    </Box>
                    <Box display={"flex"} pl={1}>
                        <SolidTextButtonWithColor
                            text={"아니요"}
                            size={15}
                            sx={{
                                width: 100,
                                height: 35,
                                backgroundColor: "black",
                            }}
                            color={"white"}
                            onClick={onClose}
                        />
                    </Box>
                </Grid>
                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    pt={2}
                    position={"absolute"}
                    bottom={40}
                >
                    <Typography
                        fontWeight={FontWeightValues.EXTRA_BOLD}
                        color={"red"}
                    >
                        {errorText}
                    </Typography>
                </Grid>
            </DialogTitle>
        </Dialog>
    );
};

export default YesOrNoModal;
