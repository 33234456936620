export const validateEmail = (email: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(email).toLowerCase()
    );
};

export const validateIdNum = (idNum: string): boolean => {
    return /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]\d{6}/.test(
        String(idNum)
    );
};

/**
 * 대소문자, 숫자, 8자리 이상
 */
export const validatePw = (pw: string): boolean => {
    return /(?=.*\d)(?=.*[a-zA-Z]).{6,}/.test(String(pw));
};
