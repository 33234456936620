import React from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

interface ViewMoreButtonProps {
    text: string;
    onClick?: () => void;
    pt?: number;
}

const ViewMoreButton: React.FC<ViewMoreButtonProps> = ({
    text,
    onClick,
    pt,
}) => {
    return (
        <Box
            display={"flex"}
            pt={pt ?? 5}
            width={"100%"}
            justifyContent={"flex-end"}
            alignItems={"center"}
        >
            <Typography className="cocosharp" color="black" variant="f18h20B">
                {text}
            </Typography>
            <Box display={"flex"} height={"100%"} onClick={onClick}>
                <IconButton size="small" href={"/community"}>
                    <img src={"./images/icon/arrow-narrow-right_black.svg"} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ViewMoreButton;
