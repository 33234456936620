import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSetRecoilState } from "recoil";

import { Button, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

import { FreeBoardCategoryType, ResponseGetPost } from "../api-interfaces/post";
import { getPosts } from "../apis/post";
import AddWithTextButton from "../components/buttons/AddWithTextButton";
import FilterButton from "../components/buttons/FilterButton";
import BulletinBoardCard from "../components/cards/BulletinBoardCard";
import { navThemeRecoil } from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

export interface BulletinBoardProps {
    isLandingPage?: boolean;
}

const BulletinBoard: React.FC<BulletinBoardProps> = ({ isLandingPage }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const navigate = useNavigate();
    const [selectedFilter, setSelectedFilter] = useState<number>(0);
    const [loadCount, setloadCount] = useState(4);
    const { t } = useTranslation();
    const handleFilter = (id: number) => {
        setloadCount(4);
        setSelectedFilter(id);
    };
    const [bulletinData, setBulletinData] = useState<ResponseGetPost[]>([]);
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const BulletinFilterContents = [
        "전체",
        ...Object.values(FreeBoardCategoryType),
    ];

    const viewMore = () => {
        setloadCount((prev) => prev + 4);
    };
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    useEffect(() => {
        console.log(BulletinFilterContents[selectedFilter]);
        getPosts(
            selectedFilter === 0
                ? undefined
                : BulletinFilterContents[selectedFilter],
            loadCount
        ).then((posts) => setBulletinData(posts));
    }, [selectedFilter, loadCount]);

    return (
        <Grid
            container
            sx={{
                width: "100%",
                backgroundColor: "white",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                p: isMobile ? 2 : 4,
                pt: 20,
            }}
        >
            <Box
                display={"flex"}
                justifyContent={"flex-start"}
                textAlign={"start"}
                width={"100%"}
            >
                <Typography variant={isMobile ? "f32h40EB" : "f48h64EB"}>
                    {t("forum.title")}
                </Typography>
            </Box>
            <Grid
                container
                justifyContent={"space-between"}
                pt={isMobile ? 5 : 6}
            >
                <Grid container flex={1}>
                    {BulletinFilterContents.map((data, idx) => {
                        return (
                            <FilterButton
                                key={idx}
                                selected={selectedFilter === idx ? true : false}
                                text={t(`quest.${data}`)}
                                onClick={() => handleFilter(idx)}
                            />
                        );
                    })}
                </Grid>
                <Button
                    variant="outlined"
                    sx={{ height: 34 }}
                    href={"/bulletinboard/edit"}
                >
                    <Typography variant="f14h20">{t("forum.write")}</Typography>
                </Button>
            </Grid>
            <Grid
                container
                pt={4}
                pb={5}
                spacing={2}
                // 이거 꿀팁이 있을까요... 왼쪽 오른쪽.. 하 -> Ben이 해결완료~!
            >
                {bulletinData.map((data, idx) => {
                    return (
                        <Grid
                            item
                            xs={isMobile ? undefined : 6}
                            key={`ambassador_data_${idx}`}
                            width={"100%"}
                        >
                            <BulletinBoardCard
                                isMobile={isMobile}
                                data={data}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            {(bulletinData.length % 4 == 0 || isLandingPage) &&
                bulletinData.length > 0 && (
                    <Grid container>
                        <AddWithTextButton
                            text={t("quest.showMore")}
                            onClick={
                                isLandingPage
                                    ? () => navigate("/bulletinboard")
                                    : viewMore
                            }
                        />
                    </Grid>
                )}
            {bulletinData.length == 0 && (
                <Grid>
                    <Typography>{t("forum.noPostMessage")}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default BulletinBoard;
