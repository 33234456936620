import React from "react";

import Typography from "@mui/material/Typography";

interface NanumEBProps {
    text: string;
    size?: number;
    color?: string;
}

const NanumEB: React.FC<NanumEBProps> = ({ text, color, size }) => {
    return (
        <Typography
            fontFamily={"nanumsquare"}
            fontWeight={"700"}
            color={color ? color : "white"}
            fontSize={size ? size : 48}
            sx={{ wordBreak: "keep-all" }}
        >
            {text}
        </Typography>
    );
};

export default NanumEB;
