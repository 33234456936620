import React from "react";

import { FiberManualRecord } from "@mui/icons-material";
import { Grid, Typography, useTheme } from "@mui/material";

export interface AnouncementBlockProps {
    isRead: boolean;
    onRead: () => void;
    date: string;
    content: string;
}

const AnouncementBlock: React.FC<AnouncementBlockProps> = ({
    isRead,
    onRead,
    date,
    content,
}) => {
    const theme = useTheme();
    return (
        <Grid mb={2}>
            <Grid container alignItems={"center"} mb={0.5}>
                <FiberManualRecord
                    htmlColor={
                        isRead
                            ? theme.palette.mono[600]
                            : theme.palette.pBlue.main
                    }
                    onClick={onRead}
                    sx={{ cursor: "pointer", fontSize: "13px", mr: 1 }}
                />
                <Typography variant="f14h20" color="mono.600">
                    {date}
                </Typography>
            </Grid>
            <Typography variant="f16h24B">{content || "..."}</Typography>
        </Grid>
    );
};

export default AnouncementBlock;
