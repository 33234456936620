import React from "react";

import Box from "@mui/material/Box";

/**
 * 호버할 때 컴포넌트를 하얀색으로 마스킹
 *
 * @remarks
 * 상위컴포넌트각 relative 여야함
 */
const HoveringWhiteMask = () => {
    return (
        <Box
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 180,
                "&:hover": {
                    backgroundColor: "rgba(255,255,255,20%)",
                },
            }}
        />
    );
};

export default HoveringWhiteMask;
