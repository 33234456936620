// import Badge from "@mui/material/Badge";
import React from "react";

import { useRecoilValue } from "recoil";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { UserRole } from "../../api-interfaces/user";
import { FontWeightValues } from "../../types/fontWeightTypes";
import { userRecoil } from "../states/recoil";

import DropDownNav from "./DropDownNav";

/**
 * Header 컴포넌트
 */
interface NavTabProps {
    color?: "black" | "white";
}
const NavTab: React.FC<NavTabProps> = ({ color = "white" }) => {
    const userData = useRecoilValue(userRecoil);
    const { t } = useTranslation();
    const navList = [
        {
            href:
                userData?.role === UserRole.EXPERT
                    ? "/requests"
                    : userData?.role === UserRole.UNAPPROVED_EXPERT ||
                      userData?.role === UserRole.GUEST
                    ? "/verification"
                    : "/service-request",
            text:
                userData?.role === UserRole.EXPERT ||
                userData?.role === UserRole.UNAPPROVED_EXPERT
                    ? t("nav.exploreRequests")
                    : userData?.role === UserRole.COMPANY ||
                      userData?.role === UserRole.GUEST
                    ? t("nav.serviceRequest")
                    : t("nav.serviceRequest"),
        },
        {
            href: "/service",
            text: t("nav.serviceIntroduction"),
        },
        {
            href: "/company-info",
            text: t("nav.companyIntroduction"),
        },
        // NOTE: 일단 뷰때문에 블랭크처리
        {
            href: "/ambassador",
            text: t("nav.ambassador"),
        },
        // {
        //     href: "/community",
        //     text: t("nav.community"),
        // },
    ];

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                height: "100%",
                justifyContent: "space-around",

                width: "90%",
            }}
        >
            {navList
                .filter(
                    (nav, idx) =>
                        (userData?.role !== UserRole.ADMIN || idx !== 0) &&
                        nav.text !== "커뮤니티"
                )
                .map((nav, idx) => (
                    <Button
                        key={`nav-button-${nav.text}`}
                        href={
                            userData?.role !== undefined || idx !== 0
                                ? nav.href
                                : "/signup"
                        }
                        sx={{
                            pl: 2,
                            pr: 2,
                        }}
                    >
                        <Typography
                            variant="f18h20B"
                            color={color}
                            sx={{
                                fontWeight: FontWeightValues.REGULAR,
                                whiteSpace: "nowrap",
                            }}
                        >
                            {nav.text}
                        </Typography>
                    </Button>
                ))}

            <DropDownNav
                contents={[
                    {
                        text: t("nav.community"),
                        href: "/community",
                    },
                    {
                        text: t("nav.freeboard"),
                        href: "/bulletinboard",
                    },
                    {
                        text: "TF.com",
                        href: "/company",
                    },
                ]}
                login={false}
                buttonComponent={() => (
                    <Button
                        sx={{
                            pl: 2,
                            pr: 2,
                        }}
                    >
                        <Typography
                            variant="f18h20B"
                            color={color}
                            sx={{
                                fontWeight: FontWeightValues.REGULAR,
                                whiteSpace: "nowrap",
                            }}
                        >
                            {t("nav.community")}
                        </Typography>
                    </Button>
                )}
            />
        </Box>
    );
};

export default NavTab;
