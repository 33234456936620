import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import {
    Box,
    Button,
    Grid,
    Input,
    Pagination,
    Typography,
} from "@mui/material";

import {
    RequestPostAndPatchTradefirm,
    Tradefirm,
} from "../../api-interfaces/tradefirm";
import {
    getTradeFirmDoccount,
    getTradefirms,
    postTradefirm,
} from "../../apis/tradefirm";
import { uploadImage } from "../../apis/upload";
import SolidTextButtonWithColor from "../buttons/SolidTextButtonWithColor";
import Bingle from "../indicators/Bingle";
import ModalDialog from "../modals/ModalDialog";
import { renderUpdateComponentRecoil, userRecoil } from "../states/recoil";

import AdminTFTile from "./AdminTFTile";

const AdminTFT = () => {
    // TC.com 관리 필드
    const [userData, _setUserData] = useRecoilState(userRecoil);

    const [tradeFirms, setTradeFirms] = useState<Tradefirm[]>();

    const [image, setImage] = useState<File>();
    const [companyName, setCompanyName] = useState<string>("");
    const [exportItem, setExportItem] = useState<string>("");
    const [proudExportItem, setProudExportItem] = useState<string>("");
    const [desirableSourcingItemSet, setDesirableSourcingItemSet] =
        useState<string>("");
    const [mainDealingItem, setMainDealingItem] = useState<string>("");
    const [homepage, setHomepage] = useState<string>("");
    const [companyForm, setCompnayForm] = useState<string>("");
    const [exportingNations, setExportingNations] = useState<string>("");
    const [companyIntroduction, setCompanyIntroduction] = useState<string>("");

    const [docLimit, setDocLimit] = useState<number>(1);

    const [selectedPage, setSelectedPage] = useState(1);
    const [reRenderData, setReRenderData] = useRecoilState(
        renderUpdateComponentRecoil
    );

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onUploadPicture = async (image: File) => {
        console.log(image.name);
        try {
            if (userData) {
                const formData = new FormData();
                formData.append("image", image);
                const res = await uploadImage(userData._id, formData);
                console.log(res, "HERE IS RES");
                return res;
            }
        } catch (e) {
            window.alert("이미지 업로드 과정 중에 문제가 발생했습니다.");
        }
    };

    const handleAddTC = async () => {
        let imgSrc;
        if (image) imgSrc = await onUploadPicture(image);
        const changed: RequestPostAndPatchTradefirm = {
            image: imgSrc ? imgSrc.src : "./images/company/company_1.png",
            companyName: companyName,
            exportItem: exportItem,
            proudExportItem: proudExportItem,
            desirableSourcingItemSet: desirableSourcingItemSet,
            mainDealingItem: mainDealingItem,
            homepage: homepage,
            companyForm: companyForm,
            exportingNations: exportingNations,
            companyIntroduction: companyIntroduction,
        };
        await postTradefirm(changed).then(() => {
            console.log(" TC 가 추가되었습니다.");
            setReRenderData(!reRenderData);
        });
    };
    useEffect(() => {
        (async () => {
            try {
                const dt = await getTradefirms((selectedPage - 1) * 3, 3);
                console.log(dt, "customer");
                setTradeFirms(dt);
            } catch (e) {
                console.log(e, "업로드 문제");
            }
        })();
    }, [selectedPage, reRenderData]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            //TC.com
            const tfs = await getTradefirms(0, 3);
            setTradeFirms(tfs);

            const lim = await getTradeFirmDoccount();
            setDocLimit(Math.ceil(lim.count / 3));
            setIsLoading(false);
        })();
    }, [reRenderData]);

    return (
        <>
            <Grid container mb={3} flexDirection={"column"}>
                <Box
                    display={"flex"}
                    border={"solid 1px #AAAAAA"}
                    height={34}
                    width={237}
                    alignItems={"center"}
                    p={1}
                >
                    <img
                        src={"./images/icon/search.svg"}
                        width={20}
                        height={20}
                    />
                    <Box display={"flex"} pl={1}>
                        <Typography color={"#AAAAAA"}>검색</Typography>
                    </Box>
                </Box>
                <Box display={"flex"} mt={3}>
                    <SolidTextButtonWithColor
                        text={"추가"}
                        size={15}
                        sx={{ width: 70, height: 35 }}
                        onClick={() => setShowAddModal(true)}
                    />
                </Box>
                <Box display={"flex"} mt={3}>
                    <Pagination
                        count={docLimit}
                        page={selectedPage}
                        onChange={(
                            event: React.ChangeEvent<unknown>,
                            value: number
                        ) => setSelectedPage(value)}
                    />
                </Box>
            </Grid>
            <Box display={"flex"} flexDirection={"column"}>
                {isLoading ? (
                    <Bingle height={300} />
                ) : (
                    <>
                        {tradeFirms
                            ? tradeFirms.map((e, idx) => (
                                  <AdminTFTile key={idx} data={e} />
                              ))
                            : ""}
                    </>
                )}
            </Box>
            {/* 추가 모달 */}
            <ModalDialog
                isMobile={false}
                title={"무역상사 추가"}
                open={showAddModal}
                onClose={() => setShowAddModal(false)}
                fullScreen
                PaperProps={{
                    sx: {
                        height: 900,
                        mt: "5vh",
                        borderRadius: "0px 0px 0px 0px",
                        overflowY: "auto",
                        maxHeight: "80vh",
                    },
                }}
            >
                <Grid container flexDirection={"column"}>
                    <Grid display={"flex"} flexDirection={"row"}>
                        <Box
                            display={"flex"}
                            width={"auto"}
                            height={150}
                            mr={1}
                        >
                            {image ? (
                                <img
                                    src={URL.createObjectURL(image)}
                                    width={150}
                                    style={{ objectFit: "contain" }}
                                />
                            ) : (
                                <></>
                            )}
                        </Box>
                        <Box
                            display={"flex"}
                            border={"dotted 1px #568FFC"}
                            width={150}
                            height={150}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                        >
                            <Box display={"flex"}>
                                <img
                                    src={"./images/icon/image-plus.svg"}
                                    width={26}
                                    height={26}
                                />
                            </Box>
                            <Button
                                sx={{ p: 1, height: "26px" }}
                                // onClick={}
                                component="label"
                            >
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/png"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            setImage(e.target.files[0]);
                                        }
                                    }}
                                />
                                <Typography variant="f12h20">
                                    프로필 사진 추가
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">기업명</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">수출품목</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setExportItem(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            수출에 자신있는 품목
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setProudExportItem(e.target.value)}
                        />
                    </Grid>

                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            소싱 희망 품목
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) =>
                                setDesirableSourcingItemSet(e.target.value)
                            }
                        />
                    </Grid>

                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            주요 취급 품목군
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setMainDealingItem(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">홈페이지</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setHomepage(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            전문무역상사 기업형태
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setCompnayForm(e.target.value)}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            수출 중인 국가
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) =>
                                setExportingNations(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">기업소개</Typography>
                        <Input
                            sx={{
                                height: 150,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                                textAlign: "start",
                            }}
                            onChange={(e) =>
                                setCompanyIntroduction(e.target.value)
                            }
                        />
                    </Grid>
                    <Grid
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignSelf={"flex-end"}
                        mt={4}
                    >
                        <SolidTextButtonWithColor
                            text={"완료"}
                            size={15}
                            sx={{
                                width: 70,
                                height: 35,
                                backgroundColor: "black",
                            }}
                            color={"white"}
                            onClick={() => {
                                handleAddTC();
                                setShowAddModal(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </ModalDialog>
        </>
    );
};

export default AdminTFT;
