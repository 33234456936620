import React from "react";

import { Box, Typography } from "@mui/material";
// import IconButton from "@mui/material/IconButton";

interface RequestButtonProps {
    text: string;
    subText: string;
    isMobile: boolean;
}

const RequestButton: React.FC<RequestButtonProps> = ({
    text,
    isMobile,
    subText,
}) => {
    return (
        <Box
            sx={{
                width: isMobile ? "100%" : "33.33%",
                height: isMobile ? 64 : 168,
                borderRadius: 0,
                backgroundColor: "#191919",
                ml: isMobile ? 0 : 3,
                mr: isMobile ? 0 : 3,
                display: "flex",
                flexDirection: isMobile ? "row" : "column",
                alignItems: isMobile ? "center" : undefined,
                justifyContent: "space-between",
                mb: isMobile ? 2 : 0,
            }}
        >
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}
                p={3}
            >
                <Box
                    display={"flex"}
                    sx={{ justifyContent: "space-between", width: "100%" }}
                >
                    <Box display={"flex"}>
                        <Typography
                            sx={{
                                fontSize: isMobile ? 18 : 24,
                                color: "white",
                                display: "flex",
                            }}
                            component={"div"}
                        >
                            {text}
                        </Typography>
                    </Box>
                    {/* {!isMobile ? (
                        <Box display={"flex"}>
                            <IconButton size="small">
                                <img
                                    src={"./images/icon/arrow-narrow-right.svg"}
                                />
                            </IconButton>
                        </Box>
                    ) : (
                        <></>
                    )} */}
                </Box>
            </Box>
            {isMobile ? (
                // <Box display={"flex"} pr={4}>
                //     <IconButton size="small">
                //         <img src={"./images/icon/arrow-narrow-right.svg"} />
                //     </IconButton>
                // </Box>
                <></>
            ) : (
                <Box display={"flex"} p={3} maxWidth={330} height={100}>
                    <Typography
                        sx={{
                            fontSize: isMobile ? 12 : 14,
                            color: "white",
                            display: "flex",
                        }}
                        component={"div"}
                    >
                        {subText}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default RequestButton;
