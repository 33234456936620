import React from "react";

import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { AmbassadorDataType } from "../../interfaces/ambassador/ambassadorDataType";

import ForwardedSlideUpTransition from "./ForwardedSlideUpTransition";
import ModalDialog from "./ModalDialog";

interface AmbassadorModalProps {
    isOpen: boolean;
    onClose: () => void;
    isMobile: boolean;
    data: AmbassadorDataType;
}

const AmbassadorModal: React.FC<AmbassadorModalProps> = ({
    isOpen,
    onClose,
    isMobile,
    data,
}) => {
    const { t } = useTranslation();
    return (
        <ModalDialog
            isMobile={isMobile}
            TransitionComponent={ForwardedSlideUpTransition}
            open={isOpen}
            title={""}
            onClose={() => onClose()}
            themeMode={"Dark"}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    p: 0,
                    position: "absolute",
                    top: 40,
                    pl: 2,
                }}
            >
                <Box display={"flex"}>
                    <Typography variant={"f24h36B"}>{data.k_name}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent
                tabIndex={-1}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    outline: "none",
                    p: 0,
                    width: "100%",
                }}
            >
                <Grid
                    container
                    mb={3}
                    flexDirection={"column"}
                    ml={2}
                    width={"auto"}
                    justifyContent={"space-between"}
                >
                    <Grid container justifyContent={"space-between"}>
                        <Grid
                            display={"flex"}
                            sx={{ flexDirection: isMobile ? "column" : "row" }}
                            justifyContent={"flex-start"}
                        >
                            <Grid
                                display={"flex"}
                                flexDirection={"column"}
                                width={isMobile ? 150 : 250}
                                minWidth={150}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"flex-end"}
                                    pt={2}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("ambassador.field")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.part}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("ambassador.industryGroup")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.industry}
                                        </Typography>
                                    </Box>
                                    {/* <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {data.location_title}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.location_text}
                                        </Typography>
                                    </Box> */}
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("ambassador.coutryInCharge")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.proCountry}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("ambassador.highlightingCareer")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.career}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{ display: "flex", height: 60, padding: 0, pt: 3 }}
            ></DialogActions>
        </ModalDialog>
    );
};

export default AmbassadorModal;
