import React, { useEffect, useState } from "react";

import { useSetRecoilState } from "recoil";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { QuestModel } from "../api-interfaces/quest";
import { getProposalLevelQuests } from "../apis/quest";
import BackButton from "../components/buttons/BackButton";
import ModalDialog from "../components/modals/ModalDialog";
import AllRequestListsSection from "../components/sections/allRequests/AllRequestListsSection";
import EstimateWriteSection from "../components/sections/allRequests/EstimateWriteSection";
import { navThemeRecoil } from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

const AllRequests = () => {
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const [selectedReq, setSelectedReq] = useState<QuestModel | null>(null);
    const [quests, setQuests] = useState<QuestModel[]>([]);

    const { t } = useTranslation();

    const fetchQuests = async () => {
        try {
            const fetchedQuests = await getProposalLevelQuests();
            setQuests(fetchedQuests);
        } catch (e) {
            console.log(e);
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
        fetchQuests();
    }, []);

    useEffect(() => {
        console.log(quests);
    }, [quests]);

    return (
        <>
            <Grid
                container
                flexDirection={"column"}
                alignItems={"center"}
                p={isMobile ? 2.5 : 3}
            >
                <Grid
                    container
                    sx={{
                        flexDirection: "column",
                        width: "100%",
                        maxWidth: "1600px",
                        flexWrap: "nowrap",
                        mt: 8,
                    }}
                >
                    {/* title */}
                    <Grid
                        mb={5}
                        mt={2}
                        sx={{ display: "flex", alignItems: "center", mr: 1 }}
                    >
                        <BackButton />
                        <Typography variant="f32h40EB">
                            {t("quest.aqt0")}
                        </Typography>
                    </Grid>

                    <Grid container spacing={2.5}>
                        {/* Info Box */}
                        <Grid
                            item
                            xs={isMobile || selectedReq === null ? 12 : 3}
                        >
                            <AllRequestListsSection
                                quests={quests}
                                isMobile={isMobile}
                                onSelectReq={setSelectedReq}
                            />
                        </Grid>

                        {selectedReq !== null && (
                            <Grid item xs={isMobile ? 12 : 9}>
                                <EstimateWriteSection
                                    isMobile={isMobile}
                                    quest={selectedReq}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {selectedReq !== null && (
                <ModalDialog
                    isMobile={isMobile}
                    title={t("quest.aqt1") as string}
                    open={isMobile && selectedReq !== null}
                    onClose={() => setSelectedReq(null)}
                    fullScreen
                    PaperProps={{
                        sx: {
                            height: "95vh",
                            mt: "5vh",
                            borderRadius: "20px 20px 0px 0px",
                        },
                    }}
                >
                    <EstimateWriteSection
                        isMobile={isMobile}
                        quest={selectedReq}
                    />
                </ModalDialog>
            )}
        </>
    );
};

export default AllRequests;
