import React from "react";

import { Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";

interface FilterButtonProps extends ButtonProps {
    selected: boolean;
    onClick?: () => void;
    text: string;
}

const FilterButton: React.FC<FilterButtonProps> = ({
    selected,
    sx,
    text,
    onClick,
}) => {
    return (
        <Button
            sx={{
                "&:hover": {
                    backgroundColor: selected ? "#191919" : "#F0F0F0",
                },
                display: "flex",
                borderRadius: 0,
                backgroundColor: selected ? "#191919" : "transparent",
                pl: 2,
                pr: 2,
                height: 34,
                justifyContent: "center",
                alignItems: "center",
                mr: 1,
                ...sx,
            }}
            onClick={onClick}
        >
            <Typography
                variant="f14h20"
                color={selected ? "mono.50" : "mono.600"}
            >
                {text}
            </Typography>
        </Button>
    );
};

export default FilterButton;
