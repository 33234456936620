import React, { useState } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { generateFontStyles } from "../../theme";
import { FontWeightValues } from "../../types/fontWeightTypes";

export interface FileUploaderProps {
    onUpload?: (file: File) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onUpload }) => {
    const [file, setFile] = useState<File>();
    const { t } = useTranslation();
    return (
        <Grid container columnSpacing={1}>
            <Grid item>
                <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                        width: "105px",
                    }}
                    component="label"
                >
                    <input
                        type="file"
                        hidden
                        onChange={(e) => {
                            if (e.target.files) {
                                setFile(e.target.files[0]);
                                if (onUpload) onUpload(e.target.files[0]);
                            }
                        }}
                    />
                    <Typography
                        sx={{
                            ...generateFontStyles(18, FontWeightValues.BOLD),
                        }}
                    >
                        {t("buttons.fileSelect")}
                    </Typography>
                </Button>
            </Grid>
            <Grid item flex={1}>
                <TextField
                    disabled={file ? false : true}
                    placeholder={t("buttons.noSelectedFile") ?? ""}
                    value={file?.name}
                />
            </Grid>
        </Grid>
    );
};

export default FileUploader;
