import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Button, Grid, Input, Typography } from "@mui/material";

import {
    RequestPatchUserAdminControl,
    ResponseGetUserAdminControl,
} from "../../api-interfaces/admin";
import {
    approveUser,
    declineUser,
    deleteUserAdminControl,
    patchUserAdminControl,
} from "../../apis/admin";
import { uploadFile, uploadImage } from "../../apis/upload";
import { DEFAULT_PROFILE_IMG_PATH } from "../../utils/consts";
import SolidTextButtonWithColor from "../buttons/SolidTextButtonWithColor";
import FileUploader from "../inputs/FileUploader";
import ModalDialog from "../modals/ModalDialog";
import YesOrNoModal from "../modals/YesOrNoModal";
import { renderUpdateComponentRecoil } from "../states/recoil";

import AmbassadorTileMain from "./AmbassadorTileMain";

interface AdminProfessionalTileProps {
    data: ResponseGetUserAdminControl;
    unexpert: boolean;
    isMobile?: boolean;
}

const AdminProfessionalTile: React.FC<AdminProfessionalTileProps> = ({
    data,
    unexpert,
    // isMobile = false,
}) => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showValidateModal, setShowValidateModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [contact, setContact] = useState<string>("");
    const [image, setImage] = useState<File>();
    const [companyName, setCompanyName] = useState<string>("");
    const [businessLicenseFile, setBusinessLicenseFile] = useState<File>();
    const [bankAccountFile, setBankAccountFile] = useState<File>();
    const [businessNum, setBusinessNum] = useState<string>("");
    const [subtitle, setSubtitle] = useState<string>("");
    const [companyAddr, setCompanyAddr] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const [age, setAge] = useState<number>();

    const [reRenderData, setReRenderData] = useRecoilState(
        renderUpdateComponentRecoil
    );

    const handleVerification = async () => {
        if (
            (data.copyBankAccount == "" || data.careerCertification == "") &&
            errorText == ""
        ) {
            console.log(data.name, "님의 경력증명서 또는 통장사본이 없습니다.");
            setErrorText("경력증명서 또는 통장사본이 없습니다.");
            return;
        }
        await approveUser(data._id).then(() =>
            console.log(data.name, "님 승인이 완료되었습니다.")
        );
        setErrorText("");
        setShowValidateModal(false);
        console.log(reRenderData, "current");
        setReRenderData(!reRenderData);
    };

    const handleDelete = async () => {
        await deleteUserAdminControl(data._id).then(() =>
            console.log(data.name, "님 삭제가 완료되었습니다.")
        );
        setShowDeleteModal(false);
        console.log(reRenderData, "current");
        setReRenderData(!reRenderData);
    };

    const handleTurnDown = async () => {
        await declineUser(data._id);
        setShowDeleteModal(false);
        console.log(reRenderData, "current");
        setReRenderData(!reRenderData);
    };

    const onUploadFile = async (file: File) => {
        try {
            if (data) {
                const formData = new FormData();
                formData.append("userfile", file);
                const res = await uploadFile(data._id, formData);

                console.log(res); // 로그용

                return res;
            }
        } catch (e) {
            window.alert("사업자 등록증 업로드 과정 중에 문제가 발생했습니다.");
        }
    };

    const onUploadBankFile = async (file: File) => {
        try {
            if (data) {
                const formData = new FormData();
                formData.append("userfile", file);
                const res = await uploadFile(data._id, formData);

                console.log(res); // 로그용

                return res;
            }
        } catch (e) {
            window.alert("통장사본 업로드 과정 중에 문제가 발생했습니다.");
        }
    };

    const onUploadPicture = async (image: File) => {
        console.log(image.name);
        try {
            if (data) {
                const formData = new FormData();
                formData.append("image", image);
                const res = await uploadImage(data._id, formData);
                console.log(res, "HERE IS RES");
                return res;
            }
        } catch (e) {
            window.alert("이미지 업로드 과정 중에 문제가 발생했습니다.");
        }
    };

    const handleEdit = async () => {
        let imgSrc;
        let busLicSrc;
        let bankSrc;
        if (image) imgSrc = await onUploadPicture(image);
        if (businessLicenseFile)
            busLicSrc = await onUploadFile(businessLicenseFile);
        if (bankAccountFile) bankSrc = await onUploadBankFile(bankAccountFile);
        const changed: RequestPatchUserAdminControl = {
            name: name !== "" ? name : data.name,
            email: email !== "" ? email : data.email,
            contact: contact !== "" ? contact : data.contact,
            nickname: name !== "" ? name : data.name,
            image: imgSrc ? imgSrc.src : data.image,
            companyName: companyName !== "" ? companyName : data.companyName,
            businessNum: businessNum !== "" ? businessNum : data.businessNum,
            subtitle: subtitle !== "" ? subtitle : data.subtitle,
            companyAddr: companyAddr !== "" ? companyAddr : data.companyAddr,
            age: age !== undefined ? age : data.age,
            businessLicense: busLicSrc ? busLicSrc.src : data.businessLicense,
            copyBankAccount: bankSrc ? bankSrc.src : data.copyBankAccount,
        };
        await patchUserAdminControl(data._id, changed).then(() =>
            console.log(name, "님 정보가 수정되었습니다.")
        );
        setReRenderData(!reRenderData);
    };
    return (
        <Grid container flexDirection={"row"} width={"100%"} minWidth={1200}>
            <Grid container sx={{ maxWidth: 230 }} width={"30%"}>
                <AmbassadorTileMain
                    isClicked={false}
                    data={data}
                    isMobile={true}
                    height={400}
                />
            </Grid>
            <Grid
                container
                sx={{ border: "solid 1px #AAAAAA" }}
                mb={3}
                flexDirection={"row"}
                height={400}
                width={"70%"}
                ml={2}
                justifyContent={"space-between"}
            >
                <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    p={3}
                >
                    <Grid
                        mb={2}
                        container
                        justifyContent={"space-around"}
                        alignItems={"center"}
                    >
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{
                                display:
                                    data.businessLicense == ""
                                        ? "none"
                                        : undefined,
                            }}
                        >
                            <Typography variant="f18h20B">
                                사업자등록증
                            </Typography>
                            <a
                                href={data.businessLicense}
                                download
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    color="pBlue"
                                    sx={{ fontSize: 16, height: "24px" }}
                                >
                                    <FileDownloadOutlined />
                                    <Typography variant="f16h20B">
                                        다운로드
                                    </Typography>
                                </Button>
                            </a>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{
                                display:
                                    data.careerCertification == ""
                                        ? "none"
                                        : undefined,
                            }}
                        >
                            <Typography variant="f18h20B">
                                경력증명서
                            </Typography>
                            <a
                                href={data.careerCertification}
                                download
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    color="pBlue"
                                    sx={{ fontSize: 16, height: "24px" }}
                                >
                                    <FileDownloadOutlined />
                                    <Typography variant="f16h20B">
                                        다운로드
                                    </Typography>
                                </Button>
                            </a>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{
                                display:
                                    data.copyBankAccount == ""
                                        ? "none"
                                        : undefined,
                            }}
                        >
                            <Typography variant="f18h20B">통장사본</Typography>
                            <a
                                href={data.copyBankAccount}
                                download
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    color="pBlue"
                                    sx={{ fontSize: 16, height: "24px" }}
                                >
                                    <FileDownloadOutlined />
                                    <Typography variant="f16h20B">
                                        다운로드
                                    </Typography>
                                </Button>
                            </a>
                        </Box>
                    </Grid>

                    <Box display={"flex"} flexDirection={"column"} pt={4}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontColor: "#AAAAAA",
                                }}
                            >
                                소속
                            </Typography>
                            <Typography variant={"f16h20B"}>
                                {data.companyName}
                            </Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} pt={4}>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontColor: "#AAAAAA",
                                }}
                            >
                                이메일
                            </Typography>
                            <Typography variant={"f16h20B"}>
                                {data.email}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} pt={4}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontColor: "#AAAAAA",
                                }}
                            >
                                나이
                            </Typography>
                            <Typography variant={"f16h20B"}>
                                {data.age === -1 ? "미설정" : data.age}
                            </Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} pt={4}>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontColor: "#AAAAAA",
                                }}
                            >
                                기타정보
                            </Typography>
                            <Typography variant={"f16h20B"}>
                                {data.subtitle}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    m={3}
                >
                    <Box display={"flex"}>
                        <SolidTextButtonWithColor
                            text={"수정"}
                            size={15}
                            sx={{ width: 70, height: 35, mr: 3 }}
                            onClick={() => setShowEditModal(true)}
                        />
                        <SolidTextButtonWithColor
                            text={unexpert ? "반려" : "삭제"}
                            size={15}
                            color={"red"}
                            sx={{
                                color: "red",
                                width: 70,
                                height: 35,
                            }}
                            onClick={() => setShowDeleteModal(true)}
                        />
                    </Box>
                    <Box display={"flex"}>
                        <SolidTextButtonWithColor
                            text={unexpert ? "승인" : "완료"}
                            size={15}
                            sx={{ width: 70, height: 35 }}
                            onClick={() =>
                                unexpert ? setShowValidateModal(true) : {}
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
            <YesOrNoModal
                onYes={() => (unexpert ? handleTurnDown() : handleDelete())}
                isMobile={false}
                title={
                    unexpert
                        ? "반려 안내 이메일을 발송하시겠습니까?"
                        : "정말로 삭제하시겠습니까?"
                }
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                fullScreen
                PaperProps={{
                    sx: {
                        height: "300px",
                        mt: "5vh",
                        width: "500px",
                        borderRadius: "0px 0px 0px 0px",
                    },
                }}
            />
            <YesOrNoModal
                onYes={() => handleVerification()}
                isMobile={false}
                title={
                    errorText
                        ? "그래도 진행하시겠습니까?"
                        : "정말로 승인하시겠습니까?"
                }
                open={showValidateModal}
                onClose={() => {
                    setShowValidateModal(false);
                }}
                errorText={errorText}
                fullScreen
                PaperProps={{
                    sx: {
                        height: "300px",
                        mt: "5vh",
                        width: "500px",
                        borderRadius: "0px 0px 0px 0px",
                    },
                }}
            />
            <ModalDialog
                isMobile={false}
                title={"전문가 수정"}
                open={showEditModal}
                onClose={() => setShowEditModal(false)}
                fullScreen
                PaperProps={{
                    sx: {
                        maxHeight: "80vh",
                        height: 800,
                        mt: "5vh",
                        borderRadius: "0px 0px 0px 0px",
                        overflowY: "auto",
                    },
                }}
            >
                <Grid container flexDirection={"column"}>
                    <Grid display={"flex"} flexDirection={"row"}>
                        <Box display={"flex"} width={150} height={150} mr={1}>
                            {image ? (
                                <img
                                    src={URL.createObjectURL(image)}
                                    width={150}
                                    style={{ objectFit: "contain" }}
                                />
                            ) : (
                                <img
                                    src={data.image || DEFAULT_PROFILE_IMG_PATH}
                                    width={150}
                                    style={{ objectFit: "contain" }}
                                />
                            )}
                        </Box>
                        <Box
                            display={"flex"}
                            border={"dotted 1px #568FFC"}
                            width={150}
                            height={150}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                        >
                            <Box display={"flex"}>
                                <img
                                    src={"./images/icon/image-plus.svg"}
                                    width={26}
                                    height={26}
                                />
                            </Box>
                            <Button
                                sx={{ p: 1, height: "26px" }}
                                // onClick={}
                                component="label"
                            >
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/png"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            setImage(e.target.files[0]);
                                        }
                                    }}
                                />
                                <Typography variant="f12h20">
                                    프로필 사진 변경
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">이름</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setName(e.target.value)}
                            defaultValue={data.name}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">나이</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setAge(Number(e.target.value))}
                            defaultValue={data.age?.toString()}
                            type={"number"}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">이메일</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                            defaultValue={data.email}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">전화번호</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setContact(e.target.value)}
                            defaultValue={data.contact}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">소속</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setCompanyName(e.target.value)}
                            defaultValue={data.companyName}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">회사주소</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setCompanyAddr(e.target.value)}
                            defaultValue={data.companyAddr}
                        />
                    </Grid>
                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">
                            사업자등록번호
                        </Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setBusinessNum(e.target.value)}
                            defaultValue={data.businessNum}
                        />
                    </Grid>

                    <Grid display={"flex"} flexDirection="column" pt={3}>
                        <Typography variant="f18h20B">기타정보</Typography>
                        <Input
                            sx={{
                                height: 40,
                                mt: 2,
                                border: "solid 1px #AAAAAA",
                                pl: 2,
                            }}
                            onChange={(e) => setSubtitle(e.target.value)}
                            defaultValue={data.subtitle}
                        />
                    </Grid>
                    <Grid mb={2} mt={5}>
                        <Typography variant="f18h20B">통장사본</Typography>
                    </Grid>
                    <FileUploader onUpload={setBusinessLicenseFile} />

                    <Grid mb={2} mt={5}>
                        <Typography variant="f18h20B">경력증명서</Typography>
                    </Grid>
                    <FileUploader onUpload={setBankAccountFile} />

                    <Grid
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignSelf={"flex-end"}
                        mt={4}
                    >
                        <SolidTextButtonWithColor
                            text={unexpert ? "승인" : "완료"}
                            size={15}
                            sx={{
                                width: 70,
                                height: 35,
                                backgroundColor: "black",
                            }}
                            color={"white"}
                            onClick={() => {
                                handleEdit();
                                setShowEditModal(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </ModalDialog>
        </Grid>
    );
};

export default AdminProfessionalTile;
