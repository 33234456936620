import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

export interface ButtonSelectorProps {
    options: string[];
    onSelect: (index: number, value?: string) => void;
    hasEtc?: boolean;
    initialSelectIdx?: number;
}

const ButtonSelector: React.FC<ButtonSelectorProps> = ({
    options,
    onSelect,
    hasEtc = false,
    initialSelectIdx,
}) => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const [etcValue, setEtcValue] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (initialSelectIdx) {
            setCurrentIdx(initialSelectIdx);
        }
    }, [initialSelectIdx]);

    return (
        <Grid container spacing={1}>
            {options.map((opt, idx) => (
                <Grid item key={`button-selector-${opt}-${idx}`}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setCurrentIdx(idx);
                            onSelect(idx, opt);
                        }}
                        disabled={idx == 2 && opt.includes("Coming")}
                        sx={
                            currentIdx === idx
                                ? { borderColor: "#000", borderWidth: 1.5 }
                                : { color: "#DDDDDD", borderColor: "#AAAAAA" }
                        }
                    >
                        {opt}
                    </Button>
                </Grid>
            ))}
            {hasEtc && (
                <Grid item>
                    <TextField
                        placeholder={t("quest.etcInput") as string}
                        value={etcValue}
                        onClick={() => {
                            setCurrentIdx(options.length);
                            onSelect(options.length, etcValue);
                        }}
                        onChange={(e) => {
                            setEtcValue(e.target.value);
                            onSelect(options.length, etcValue);
                        }}
                        sx={{
                            width: 200,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor:
                                        currentIdx === options.length
                                            ? "#000"
                                            : "#AAAAAA",
                                    borderWidth:
                                        currentIdx === options.length ? 1.5 : 1,
                                },
                                ...(currentIdx !== options.length && {
                                    color: "#DDDDDD",
                                }),
                            },
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ButtonSelector;
