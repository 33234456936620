import React, { useEffect, useState } from "react";

import { TuneRounded } from "@mui/icons-material";
import {
    Grid,
    IconButton,
    Pagination,
    Typography,
    useMediaQuery,
} from "@mui/material";

import { QuestFormTypes, QuestModel } from "../../../api-interfaces/quest";
import { getAllQuestsV2 } from "../../../apis/admin";
import { mobileMaxWidthMediaQuery } from "../../../theme";
import RequestCard from "../../cards/RequestCard";
import QuestLevelFilterModal from "../../modals/QuestLevelFilterModal";
import ReqTypeSelectSection from "../mypage/ReqTypeSelectSection";

export interface MyRequestsSectionAdminProps {}

const LIMIT = 5;

const MyRequestsSectionAdmin: React.FC<MyRequestsSectionAdminProps> = ({}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    const [openFilterModal, setOpenFilterModal] = useState(false);

    const [reqType, setReqType] = useState<QuestFormTypes>(
        QuestFormTypes.EXPORT
    );
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [levelFilter, setLevelFilter] = useState<number>(-1);

    const [quests, setQuests] = useState<QuestModel[]>([]);
    const [filterCount, setFilterCount] = useState({ iCount: 0, eCount: 0 });

    const [isPaging, setIsPaging] = useState(false);

    /**
     * 페이징 변경이 아닐경우
     */
    const handleChangeTypeFilterHOF = (qft: QuestFormTypes) => () => {
        setIsPaging(() => false);
        setCurrentPage(() => 1);
        setReqType(() => qft);
    };

    const handleChangeLevelFilter = (level: number) => {
        setLevelFilter(() => level);
    };

    useEffect(() => {
        (async () => {
            try {
                if (reqType && levelFilter !== undefined && currentPage) {
                    // 카테고리나 레벨이 바뀔 때는 1페이지에 관한 정보로
                    const fetchedQuests = await getAllQuestsV2(
                        isPaging ? (currentPage - 1) * LIMIT : 0,
                        LIMIT,
                        reqType,
                        levelFilter
                    );

                    if (fetchedQuests.cobj !== undefined) {
                        setFilterCount(() => fetchedQuests.cobj);
                    }

                    setQuests(() => fetchedQuests.quests);
                    console.log("fetched quests: ", fetchedQuests);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [reqType, levelFilter, currentPage, isPaging]);

    return (
        <>
            <Grid>
                {/* 필터섹션 */}
                <Grid container justifyContent={"space-between"} mt={2} mb={2}>
                    {/* 카테고리 필터 */}
                    <ReqTypeSelectSection
                        variant="round"
                        reqType={reqType}
                        importCount={filterCount.iCount}
                        exportCount={filterCount.eCount}
                        onChangeTypeFilterHOF={handleChangeTypeFilterHOF}
                    />
                    {/* 레벨 필터 모달 버튼*/}
                    <IconButton
                        size="small"
                        onClick={() => setOpenFilterModal(true)}
                    >
                        <TuneRounded />
                    </IconButton>
                </Grid>

                {/* 페이지네이션 */}
                <Pagination
                    count={Math.ceil(
                        (reqType === QuestFormTypes.EXPORT
                            ? filterCount.eCount
                            : filterCount.iCount) / LIMIT
                    )}
                    page={currentPage}
                    onChange={(_e, v) => {
                        setIsPaging(() => true);
                        setCurrentPage(() => v);
                    }}
                />

                {quests.length === 0 && (
                    <Grid width={"100%"} mt={10}>
                        <Typography sx={{ textAlign: "center" }}>
                            의뢰가 없습니다
                        </Typography>
                    </Grid>
                )}
                {quests.map((req, idx) => (
                    <RequestCard
                        key={`req-${req._id}`}
                        isMobile={isMobile}
                        reqId={req._id}
                        idx={idx + 1}
                        reqType={req.type}
                        level={req.level}
                        companyName={req.companyName}
                        date={req.date}
                        productName={req.productName}
                        title={req.title}
                        showMoreButton
                    />
                ))}
            </Grid>
            <QuestLevelFilterModal
                isMobile={isMobile}
                open={openFilterModal}
                handleClose={() => {
                    setOpenFilterModal(false);
                }}
                onChangeFilter={handleChangeLevelFilter}
            />
        </>
    );
};

export default MyRequestsSectionAdmin;
