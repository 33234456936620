import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { UserRole } from "../api-interfaces/user";
import { fetchMe, signUpUser } from "../apis/user";
import BackButton from "../components/buttons/BackButton";
import Bingle from "../components/indicators/Bingle";
import {
    isUserLoadedRecoil,
    navThemeRecoil,
    userRecoil,
} from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";
import { validateEmail, validatePw } from "../utils/validation";

const unselectedButtonSx = {
    color: "#888888",
    borderColor: "#DDD",
    borderWidth: 1,
};

interface WelcomeAfterSignupProps {
    nickname?: string;
}

const WelcomeAfterSignup: React.FC<WelcomeAfterSignupProps> = ({
    nickname,
}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const navi = useNavigate();
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
            }}
        >
            <img
                src="/images/icon/check_animation.gif"
                width={60}
                height={60}
            />
            <Box mb={2} mt={4}>
                <Typography variant={isMobile ? "f24h36B" : "f32h40EB"}>
                    {`${t("common.hi")}${nickname ?? t("account.nonamed")} ${t(
                        "common.님"
                    )}`}
                </Typography>
            </Box>

            <Typography
                variant={isMobile ? "f16h20B" : "f24h36B"}
                sx={{
                    textAlign: "center",
                }}
            >
                {t("account.welcome1")}
                <br />
                {t("account.welcome2")}
                <br />
                {t("account.welcome3")}
            </Typography>
            <Button
                variant="outlined"
                sx={{ width: "236px", mb: 5, mt: 4 }}
                onClick={() => {
                    navi("/verification", { replace: true });
                }}
            >
                {t("account.verification")}
            </Button>
        </Box>
    );
};

const Signup = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const [userType, setUserType] = useState<UserRole>(UserRole.GUEST);
    const [signupInfo, setSignupInfo] = useState({
        name: "",
        contact: "",
        email: "",
        pw: "",
        companyName: "",
    });
    const [pwCheck, setPwCheck] = useState("");
    const [policyAgreement, setPolicyAgreement] = useState(false);
    const [isTypingPwCheck, setIsTypingPwCheck] = useState(false);
    const [successToRegister, setSuccessToRegister] = useState(false);
    const [errorPart, setErrorPart] = useState("");

    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const isUserLoaded = useRecoilValue(isUserLoadedRecoil);
    const [userData, setUserData] = useRecoilState(userRecoil);

    const { t } = useTranslation();

    const isAllFilled =
        signupInfo.email &&
        signupInfo.name &&
        signupInfo.contact &&
        signupInfo.companyName &&
        signupInfo.pw &&
        pwCheck &&
        signupInfo.pw === pwCheck &&
        policyAgreement
            ? true
            : false;

    const validateInfo = async (): Promise<boolean> => {
        if (!signupInfo.email || !validateEmail(signupInfo.email)) {
            // signupInfo.email 중복검사
            window.alert(t("account.wrongEmailFormat"));
            setErrorPart("email");
            return false;
        }
        if (!signupInfo.pw || !validatePw(signupInfo.pw)) {
            window.alert(t("account.wrongPasswordFormat"));
            setErrorPart("pw");
            return false;
        }
        if (signupInfo.pw !== pwCheck) {
            window.alert(t("account.wrongPasswordMatching"));
            setErrorPart("pwCheck");
            return false;
        }
        if (!signupInfo.name) {
            window.alert(t("account.noNameInput"));
            setErrorPart("name");
            return false;
        }
        if (!signupInfo.contact) {
            window.alert(t("account.noContactInput"));
            setErrorPart("contact");
            return false;
        }
        if (!signupInfo.companyName) {
            window.alert(t("account.noCompanyNameInput"));
            setErrorPart("companyName");
            return false;
        }

        return true;
    };

    const register = async () => {
        setErrorPart("");
        if (await validateInfo()) {
            // POST register
            try {
                await signUpUser({
                    ...signupInfo,
                    role: userType,
                });
                const user = await fetchMe();
                setUserData(() => user);
                window.scrollTo({ top: 0 });
                window.alert(t("account.signUpComplete"));
                setSuccessToRegister(() => true);
            } catch (error: unknown) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const _err = error as any;
                if (_err.name === "HTTPError") {
                    const errorCode = _err.response.status;
                    if (errorCode === 409) {
                        alert(t("account.alreadyRegistered"));
                    }
                } else {
                    console.log(error);
                }

                return;
            }
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    if (!isUserLoaded) {
        return <Bingle />;
    }

    if (successToRegister || userData) {
        return <WelcomeAfterSignup nickname={userData?.name} />;
    }

    return (
        <Grid container flexDirection={"column"} alignItems={"center"}>
            <Grid
                container
                sx={{
                    flexDirection: "column",
                    maxWidth: "494px",
                    width: "100%",
                    flexWrap: "nowrap",
                    mt: 8,
                }}
            >
                <Grid>
                    <BackButton />
                </Grid>
                <Grid mb={5} mt={2}>
                    <Typography variant="f32h40EB">
                        {t("account.signup")}
                    </Typography>
                </Grid>
                <Grid mb={2}>
                    <Typography variant="f18h20B">
                        {t("account.role")}
                    </Typography>
                </Grid>
                <Grid container columnSpacing={1}>
                    <Grid item>
                        <Button
                            variant="outlined"
                            sx={{
                                width: "108px",
                                borderWidth: 1.5,
                                ...(userType === UserRole.GUEST
                                    ? {}
                                    : unselectedButtonSx),
                            }}
                            onClick={() => setUserType(UserRole.GUEST)}
                        >
                            {t(`userRole.${UserRole.COMPANY}`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            sx={{
                                width: "108px",
                                borderWidth: 1.5,
                                ...(userType === UserRole.UNAPPROVED_EXPERT
                                    ? {}
                                    : unselectedButtonSx),
                            }}
                            onClick={() =>
                                setUserType(UserRole.UNAPPROVED_EXPERT)
                            }
                        >
                            {t(`userRole.${UserRole.EXPERT}`)}
                        </Button>
                    </Grid>
                </Grid>
                <TextField
                    placeholder={t(`account.email`) ?? ""}
                    error={errorPart === "email"}
                    value={signupInfo.email}
                    onChange={(e) =>
                        setSignupInfo((prev) => {
                            return { ...prev, email: e.target.value };
                        })
                    }
                    sx={{ mt: 3 }}
                />
                <TextField
                    placeholder={t(`account.pw`) ?? ""}
                    value={signupInfo.pw}
                    error={errorPart === "pw"}
                    onChange={(e) =>
                        setSignupInfo((prev) => {
                            return { ...prev, pw: e.target.value };
                        })
                    }
                    sx={{ mt: 3 }}
                    InputProps={{
                        type: "password",
                        style: { color: "black", fontFamily: "sans-serif" },
                    }}
                />

                <Grid container mt={1} mb={-2}>
                    <Typography
                        variant="f12h20B"
                        color={errorPart === "pw" ? "error" : "mono.600"}
                    >
                        {t("account.passwordValidationLabel")}
                    </Typography>
                </Grid>

                <TextField
                    placeholder={t("account.pwCheck") ?? ""}
                    value={pwCheck}
                    type={"password"}
                    onChange={(e) => setPwCheck(e.currentTarget.value)}
                    sx={{ mt: 3 }}
                    onFocus={() => setIsTypingPwCheck(true)}
                    onBlur={() => setIsTypingPwCheck(false)}
                    error={
                        (isTypingPwCheck && pwCheck !== signupInfo.pw) ||
                        errorPart === "pwCheck"
                    }
                    helperText={
                        isTypingPwCheck && pwCheck !== signupInfo.pw
                            ? t("account.wrongPasswordMatching")
                            : ""
                    }
                    InputProps={{
                        type: "password",
                        style: { color: "black", fontFamily: "sans-serif" },
                    }}
                />
                <TextField
                    placeholder={t("account.name") ?? ""}
                    error={errorPart === "name"}
                    value={signupInfo.name}
                    onChange={(e) =>
                        setSignupInfo((prev) => {
                            return { ...prev, name: e.target.value };
                        })
                    }
                    sx={{ mt: 3 }}
                />

                <TextField
                    placeholder={`${t("account.phone")} (ex. ${t(
                        "account.phonePlaceholder"
                    )})`}
                    type={"tel"}
                    error={errorPart === "contact"}
                    value={signupInfo.contact}
                    onChange={(e) =>
                        setSignupInfo((prev) => {
                            return { ...prev, contact: e.target.value };
                        })
                    }
                    sx={{ mt: 3 }}
                />
                <TextField
                    placeholder={t("account.companyName") ?? ""}
                    value={signupInfo.companyName}
                    error={errorPart === "companyName"}
                    onChange={(e) =>
                        setSignupInfo((prev) => {
                            return { ...prev, companyName: e.target.value };
                        })
                    }
                    sx={{ mt: 3 }}
                />

                <Grid container mt={1} mb={-2}>
                    <Typography variant="f12h20B" color={"mono.600"}>
                        {t("account.companyNameExtraLabel")}
                    </Typography>
                </Grid>

                <Grid container alignItems={"center"} mt={5} mb={1}>
                    <img
                        src={`/images/icon/${
                            policyAgreement ? "checked" : "unchecked"
                        }.svg`}
                        style={{ cursor: "pointer" }}
                        alt={"checkbox"}
                        onClick={() => setPolicyAgreement((prev) => !prev)}
                    />

                    <Grid ml={1}>
                        <Typography variant="f18h20B">
                            {t("account.securityPolicyAgree")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        border: "solid 1px #AAA",
                        p: 3,
                        maxHeight: isMobile ? "443px" : "285px",
                        overflow: "scroll",
                    }}
                >
                    <Typography fontSize={14}>
                        <p>
                            <em>
                                &lt; 브릿지오버
                                &gt;('https://tradeforce.co.kr'이하
                                '트레이드포스')
                            </em>
                            은(는) 「개인정보 보호법」 제30조에 따라 정보주체의
                            개인정보를 보호하고 이와 관련한 고충을 신속하고
                            원활하게 처리할 수 있도록 하기 위하여 다음과 같이
                            개인정보 처리방침을 수립·공개합니다.
                        </p>
                        <p>
                            ○ 이 개인정보처리방침은 <em>2023</em>년 <em>1</em>월{" "}
                            <em>1</em>부터 적용됩니다.
                        </p>
                        <p>
                            <strong>
                                제1조(개인정보의 처리 목적)
                                <br />
                                <br />
                                <em>
                                    &lt; 브릿지오버
                                    &gt;('https://tradeforce.co.kr'이하
                                    '트레이드포스')
                                </em>
                                은(는) 다음의 목적을 위하여 개인정보를
                                처리합니다. 처리하고 있는 개인정보는 다음의 목적
                                이외의 용도로는 이용되지 않으며 이용 목적이
                                변경되는 경우에는 「개인정보 보호법」 제18조에
                                따라 별도의 동의를 받는 등 필요한 조치를 이행할
                                예정입니다.
                            </strong>
                        </p>

                        <ul>
                            <p>1. 홈페이지 회원가입 및 관리</p>
                            <p>
                                회원 가입의사 확인, 회원제 서비스 제공에 따른
                                본인 식별·인증, 회원자격 유지·관리 목적으로
                                개인정보를 처리합니다.
                            </p>
                            <p>2. 민원사무 처리</p>
                            <p>
                                민원인의 신원 확인, 민원사항 확인, 사실조사를
                                위한 연락·통지, 처리결과 통보 목적으로
                                개인정보를 처리합니다.
                            </p>
                            <p>3. 재화 또는 서비스 제공</p>
                            <p>
                                계약서·청구서 발송, 맞춤서비스 제공, 본인인증,
                                요금결제·정산을 목적으로 개인정보를 처리합니다.
                            </p>
                            <p>4. 마케팅 및 광고에의 활용</p>
                            <p>
                                신규 서비스(제품) 개발 및 맞춤 서비스 제공,
                                이벤트 및 광고성 정보 제공 및 참여기회 제공 ,
                                접속빈도 파악 또는 회원의 서비스 이용에 대한
                                통계 등을 목적으로 개인정보를 처리합니다.
                            </p>
                            <br />
                        </ul>
                        <p>
                            <strong>제2조(개인정보의 처리 및 보유 기간)</strong>
                            <br />
                            <br />① <em>&lt; 브릿지오버 &gt;</em>은(는) 법령에
                            따른 개인정보 보유·이용기간 또는 정보주체로부터
                            개인정보를 수집 시에 동의받은 개인정보 보유·이용기간
                            내에서 개인정보를 처리·보유합니다.
                            <br />
                            <br />② 각각의 개인정보 처리 및 보유 기간은 다음과
                            같습니다.
                        </p>
                        <ul>
                            <li>1.&lt;홈페이지 회원가입 및 관리&gt;</li>
                            <li>
                                &lt;홈페이지 회원가입 및 관리&gt;와 관련한
                                개인정보는 수집.이용에 관한
                                동의일로부터&lt;3년&gt;까지 위 이용목적을 위하여
                                보유.이용됩니다.
                            </li>
                            <li>보유근거 : 의뢰 계약 기록용</li>
                            <li>관련법령 : </li>
                            <li>예외사유 : </li>
                        </ul>
                        <br />
                        <p>
                            <strong>제3조(처리하는 개인정보의 항목) </strong>
                            <br />
                            <br /> ① <em>&lt; 브릿지오버 &gt;</em>은(는) 다음의
                            개인정보 항목을 처리하고 있습니다.
                        </p>
                        <ul>
                            <li>1&lt; 홈페이지 회원가입 및 관리 &gt;</li>
                            <li>
                                필수항목 : 이메일, 휴대전화번호, 로그인ID, 성별,
                                생년월일, 이름, 회사명, 서비스 이용 기록, 쿠키,
                                접속 IP 정보
                            </li>
                            <li>선택항목 : </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid container justifyContent={"center"}>
                    <Button
                        variant="contained"
                        disabled={!isAllFilled}
                        sx={{ width: "236px", mt: 5, mb: 5 }}
                        onClick={register}
                    >
                        {t("account.join")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Signup;
