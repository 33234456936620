import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../theme";

/**
 * Footer 컴포넌트
 */
const Footer = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    const _openInNewTab = (url: string) => {
        window?.open(url, "_blank")?.focus();
    };
    const { t } = useTranslation();
    return (
        <footer style={{ overflowX: "hidden" }}>
            <Box
                sx={{
                    mt: 0,
                    pb: 5,
                    pt: 5,
                    pl: isMobile ? 3 : 20,
                    pr: isMobile ? 3 : 20,
                }}
                bgcolor="black"
                color="white"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100vw",
                    overflowX: "hidden",
                }}
                flexDirection={"column"}
            >
                <Box
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                >
                    <Grid container item flexDirection={"column"}>
                        <Grid item display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"} sx={{ mb: 2 }}>
                                <Typography
                                    variant={isMobile ? "f16h20B" : "f20h28EB"}
                                >
                                    {t("footer.notice")}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>{t("footer.contact")}</Typography>
                                <Typography>{t("footer.FAX")}</Typography>
                                <Typography>
                                    support@tradeforce.co.kr
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        flexDirection={"column"}
                        pt={isMobile ? 10 : 0}
                    >
                        <Grid item display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"} sx={{ mb: 2 }}>
                                <Typography
                                    variant={isMobile ? "f16h20B" : "f20h28EB"}
                                >
                                    {t("footer.terms")}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography
                                    variant={isMobile ? "f16h20B" : "f20h28EB"}
                                >
                                    {t("footer.privacyPolicy")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box display={"flex"} flexDirection={"column"} pt={10}>
                    <Grid container flexDirection={"column"}>
                        <Grid item display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"}>
                                <Typography>{t("footer.company1")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>{t("footer.company2")}</Typography>
                                <Typography>{t("footer.company3")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </footer>
    );
};

export default Footer;
