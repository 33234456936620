import ky from "ky";

console.log("backendURL: " + process.env.REACT_APP_BACKEND_URL);

export const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || "http://localhost:3334";

const apiInstance = ky.extend({
    prefixUrl: BACKEND_URL,
    credentials: "include",
});

export default apiInstance;
