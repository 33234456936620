import React from "react";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import { tabletMaxWidthMediaQuery } from "../../../theme";

export interface ServiceThirdTileProps {
    imgSrc: string;
    title: string;
    desc: string;
}

const ServiceThirdTile: React.FC<ServiceThirdTileProps> = ({
    imgSrc,
    title,
    desc,
}) => {
    const isMobile = useMediaQuery(tabletMaxWidthMediaQuery);

    return (
        <Grid item xs={isMobile ? 12 : 6} pb={2}>
            <Box
                border={"solid 1px #AAAAAA"}
                height={260}
                p={5}
                pb={isMobile ? undefined : 3}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={isMobile ? "space-between" : "flex-start"}
            >
                <img src={imgSrc} height={100} width={100} />
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    pt={isMobile ? undefined : 3}
                >
                    <Typography
                        display={"flex"}
                        color={"#568FFC"}
                        fontWeight={800}
                    >
                        {title}
                    </Typography>
                    <Typography display={"flex"} pt={1}>
                        {desc}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default ServiceThirdTile;
