import React, { useState } from "react";

import { Box } from "@mui/material";

import { Tradefirm } from "../../api-interfaces/tradefirm";
import DetailModal from "../modals/DetailModal";

interface CompanyTileMainProps {
    isMobile: boolean;
    data: Tradefirm;
    width?: number;
    height?: number;
}

const CompanyTileMain: React.FC<CompanyTileMainProps> = ({
    data,
    isMobile,
    width,
    height,
}) => {
    // console.log(data, "Inside but not used?");
    const [openModal, setOpenModal] = useState<boolean>(false);
    return (
        <>
            <DetailModal
                data={data}
                isOpen={openModal}
                onClose={() => setOpenModal(!openModal)}
                isMobile={isMobile}
            />
            <Box
                display={"flex"}
                width={"100%"}
                position={"relative"}
                height={height ? height : 300}
                sx={{ border: "solid 1px #AAAAAA" }}
                justifyContent={"center"}
                p={3}
                onClick={() => setOpenModal(true)}
            >
                <img
                    style={{
                        width: width ? width : isMobile ? "70%" : "50%",
                        objectFit: "contain",
                        minWidth: 100,
                    }}
                    src={data.image || "/images/company/company_1.png"}
                />
            </Box>
        </>
    );
};

export default CompanyTileMain;
