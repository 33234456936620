import React from "react";

import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

import InquiryFAQSection from "../components/sections/inquiry/InquiryFAQSection";
import { mobileMaxWidthMediaQuery } from "../theme";

const Inquiry: React.FC = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    return (
        <Grid
            container
            sx={{
                p: isMobile ? 2 : 4,
                minHeight: "100vh",
                alignItems: "center",
                justifyContent: "cetner",
                flexDirection: "column",
                pt: isMobile ? 10 : 18, // 해당섹션의 nav 간 거리 + nav height, 모바일은 임의
                pb: 6.25,
            }}
        >
            <InquiryFAQSection />
        </Grid>
    );
};

export default Inquiry;
