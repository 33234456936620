import React from "react";

import { InfoOutlined } from "@mui/icons-material";
import {
    Grid,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../theme";

export interface RequestProcessMeterProps {
    level: number;
}

export const reqProcess = [
    "전문가 견적 대기 중",
    "전문가 견적 상담 및 비교 중",
    "의뢰 착수금 납부 대기 중",
    "의뢰 착수금 납부 확인 중",
    "의뢰 진행 중",
    "의뢰 완료",
    // "마감 및 중단 의뢰",
];

const RequestProcessMeter: React.FC<RequestProcessMeterProps> = ({ level }) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Grid container columnSpacing={isMobile ? 1 : 1.5} mb={2} mt={5}>
            {reqProcess.map((process, idx) => (
                <Grid item xs={2} key={`req-level-${idx + 1}`}>
                    <Grid
                        sx={{
                            width: "100%",
                            height: "5px",
                            backgroundColor:
                                idx === 0 && level === 0
                                    ? theme.palette.pRed?.main
                                    : idx + 1 === level
                                    ? theme.palette.pBlue.main
                                    : level > reqProcess.length
                                    ? idx + 1 === reqProcess.length
                                        ? theme.palette.pGreen.main
                                        : theme.palette.mono[500]
                                    : "black",
                            mb: 1,
                        }}
                    ></Grid>
                    <Grid container alignItems={"center"}>
                        <Typography
                            variant={isMobile ? "f12h20" : "f18h26B"}
                            color={
                                idx === 0 && level === 0
                                    ? "error"
                                    : idx + 1 === level
                                    ? "pBlue.main"
                                    : level > reqProcess.length
                                    ? idx + 1 === reqProcess.length
                                        ? "pGreen.main"
                                        : "mono.500"
                                    : "black"
                            }
                        >
                            {t("common.step1")}
                            {idx === 0 && level === 0 ? 0 : idx + 1}{" "}
                            {t("common.step2")}
                        </Typography>
                        {!isMobile && (
                            <Tooltip
                                title={
                                    idx === 0 && level === 0
                                        ? (t("status.aborted") as string)
                                        : (t(
                                              `questStatus.${
                                                  idx === 5 && level === 7
                                                      ? 7
                                                      : idx + 1
                                              }`
                                          ) as string)
                                }
                                arrow
                                placement={"bottom-start"}
                            >
                                <InfoOutlined
                                    fontSize="small"
                                    sx={{ ml: 1, cursor: "pointer" }}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default RequestProcessMeter;
