import React, { useEffect, useRef, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";

import { Add } from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { ChatParsed, ReqeustPatchChat } from "../../api-interfaces/chat";
import { UserRole } from "../../api-interfaces/user";
import { mobileMaxWidthMediaQuery } from "../../theme";
import ModalDialog from "../modals/ModalDialog";
import { currentChatMessageListRecoil, userRecoil } from "../states/recoil";

import ChatBox from "./ChatBox";

export interface ChatSectionProps {
    height?: string | number;
    readonly?: boolean;
    returnUploadHandler?: (
        data: ReqeustPatchChat,
        file?: File
    ) => () => Promise<void>;
    chatData: ChatParsed;
}

const ChatSection: React.FC<ChatSectionProps> = ({
    height = "500px",
    readonly = false,
    returnUploadHandler,
    chatData,
}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const userData = useRecoilValue(userRecoil);
    const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
    const [registeredLeftUserAtAdmin, setRegisteredLeftUserAtAdmin] =
        useState("");

    const chatListRef = useRef<HTMLDivElement>(null);

    const [currentChatMessageList, _setCurrentChatMessageList] = useRecoilState(
        currentChatMessageListRecoil
    );
    const [text, setText] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (chatListRef.current)
            chatListRef.current.scrollTop =
                chatListRef.current?.scrollHeight || 0;
    }, []);

    useEffect(() => {
        if (chatData.logs.length > 0) {
            if (chatListRef.current)
                chatListRef.current.scrollTop =
                    chatListRef.current?.scrollHeight || 0;
        }
    }, [currentChatMessageList]);

    useEffect(() => {
        if (
            userData?.role === UserRole.ADMIN &&
            currentChatMessageList.length > 0
        ) {
            // 어드민의 경우 제일 처음에 말한 사람이 오른쪽
            setRegisteredLeftUserAtAdmin(() => currentChatMessageList[0].from);
        }
    }, [currentChatMessageList, userData]);

    const handleUpload = returnUploadHandler
        ? returnUploadHandler(
              { content: text, from: userData?._id ?? "" },
              attachedFiles[0]
          )
        : undefined;
    return (
        <>
            <Grid>
                <Grid
                    sx={{
                        height: height,
                        overflow: "auto",
                        borderRadius: "10px",
                    }}
                    ref={chatListRef}
                >
                    {(currentChatMessageList.length > 0
                        ? currentChatMessageList
                        : chatData.logs
                    ).map((chat, i) => {
                        return (
                            <ChatBox
                                key={`chat-${i}`}
                                isMyChat={
                                    userData?.role === UserRole.ADMIN
                                        ? registeredLeftUserAtAdmin !==
                                          chat.from
                                        : chat.from == userData?._id
                                }
                                time={chat.time}
                                content={chat.content}
                                src={chat.src}
                                srcInfo={chat.srcInfo}
                            />
                        );
                    })}
                </Grid>
                {!readonly && (
                    <TextField
                        fullWidth
                        placeholder={
                            t("quest.chatTextFieldPlaceholder") as string
                        }
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                fontSize: isMobile ? "14px" : "18px",
                                p: 0,
                                pl: 1,
                                bgcolor: "#FFF",
                                "& fieldset": {
                                    borderColor: "#000",
                                },
                            },
                        }}
                        disabled={userData?.role === UserRole.ADMIN}
                        value={text}
                        onChange={(e) => setText(() => e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <>
                                    <IconButton component="label">
                                        <input
                                            type="file"
                                            multiple
                                            hidden
                                            onChange={(e) => {
                                                const inputFiles = Array.from(
                                                    e.target.files || []
                                                );
                                                setAttachedFiles((prev) => [
                                                    ...prev,
                                                    ...inputFiles,
                                                ]);
                                            }}
                                        />
                                        <Add
                                            color="primary"
                                            fontSize={
                                                isMobile ? "small" : "medium"
                                            }
                                        />
                                    </IconButton>
                                </>
                            ),
                            endAdornment: (
                                <Button
                                    sx={{
                                        px: 4,
                                        fontSize: isMobile ? "14px" : "18px",
                                    }}
                                    variant="text"
                                    onClick={async () => {
                                        if (handleUpload) {
                                            await handleUpload();
                                            setText(() => "");
                                        }
                                    }}
                                >
                                    {t("status.send")}
                                </Button>
                            ),
                        }}
                    />
                )}
            </Grid>
            <ModalDialog
                isMobile={isMobile}
                title={t("quest.sendFile")}
                open={attachedFiles.length > 0}
                onClose={() => setAttachedFiles([])}
            >
                <Typography variant="f16h20B">
                    {t("quest.sendFileAskMessage")}
                </Typography>
                <Grid container spacing={1} mt={2}>
                    {attachedFiles.map((file, idx) => (
                        <Grid
                            item
                            key={`attached-file-${idx}`}
                            maxWidth={"99%"}
                        >
                            <Chip
                                label={file.name}
                                variant="outlined"
                                onDelete={() => {
                                    setAttachedFiles((prev) =>
                                        prev.filter((f, i) => i !== idx)
                                    );
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid container justifyContent={"flex-end"} mt={3}>
                    <Button
                        fullWidth={isMobile}
                        variant="contained"
                        sx={{
                            height: "34px",
                        }}
                        onClick={async () => {
                            if (handleUpload) {
                                await handleUpload();
                                setText(() => "");
                            }
                            setAttachedFiles([]);
                        }}
                    >
                        <Typography variant="f16h20B">
                            {t("status.send")}
                        </Typography>
                    </Button>
                </Grid>
            </ModalDialog>
        </>
    );
};

export default ChatSection;
