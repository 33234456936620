export const DEFAULT_PROFILE_IMG_PATH =
    "/images/TRADEFORCE_files/default_profile.png";

export const REQUEST_DETAIL_SAMPLE_TEXT = `*제품명 : 
*산업군 : 제조업, 유통업, 중계업, 도매업 (상기 기능추가가 불가할 시 추가사항)
*희망 수출 국가 : (특정국가가 없을 시, 전문가에게 수출 가능 국가 시장분석 요청 기재)
*기술적 설명 필요 여부 : 유/무 (전문가에게 기술적 설명 요망)
*희망 수출 규모 및 MOQ :
*샘플 제공 가능 여부 : 무상 / 유상 제공
*기타 요청 사항 :
`;
