import React, { useEffect, useRef } from "react";

import { useSetRecoilState } from "recoil";

import useMediaQuery from "@mui/material/useMediaQuery";

import CommunitySection from "../components/sections/home/CommunitySection";
import Section1 from "../components/sections/home/Section1";
import Section2 from "../components/sections/home/Section2";
import StepSection from "../components/sections/home/StepSection";
import { navThemeRecoil } from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

import Inquiry from "./Inquiry";

// 관리하기가 힘들어서 컴포넌트화 시켰음
const Home: React.FC = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const setNavTheme = useSetRecoilState(navThemeRecoil);

    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);

    const changeNavbarColor = () => {
        const sh1 = ref1.current?.scrollHeight ?? (isMobile ? 770 : 850);
        const sh2 =
            sh1 + (ref2.current?.scrollHeight ?? 0) || (isMobile ? 1620 : 1800);

        if (window.scrollY >= sh2) {
            setNavTheme(navThemeValues.WHITE);
        } else if (window.scrollY >= sh1) {
            setNavTheme(navThemeValues.BLACK);
        } else {
            setNavTheme(navThemeValues.WHITE_TRANS);
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        changeNavbarColor();
        window.addEventListener("scroll", changeNavbarColor);
        return () => window.removeEventListener("scroll", changeNavbarColor);
    }, [isMobile]);

    return (
        <>
            <Section1 ref={ref1} />
            <Section2 ref={ref2} />
            <StepSection />
            <CommunitySection />
            {/* <BulletinBoard isLandingPage /> */}

            <Inquiry />
        </>
    );
};

export default Home;
