import React, { useState, useEffect } from "react";

import {
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Button, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";
import { Manager, Socket } from "socket.io-client";

import {
    ChatMessage,
    ChatParsed,
    ReqeustPatchChat,
} from "../api-interfaces/chat";
import {
    EstimateReportInfo,
    QuestFormTypes,
    QuestModel,
} from "../api-interfaces/quest";
import { UserRole } from "../api-interfaces/user";
import { getAllCrossChats } from "../apis/admin";
import { BACKEND_URL } from "../apis/base";
import { getChatroom, sendChat } from "../apis/chating";
import {
    abortQuest,
    getEstimatedChats,
    getQuestById,
    selectExpert,
} from "../apis/quest";
import { uploadFC } from "../apis/upload";
import BackButton from "../components/buttons/BackButton";
import Bingle from "../components/indicators/Bingle";
import EstimateModifySection from "../components/sections/allRequests/EstimateModifySection";
import RequestChatSection from "../components/sections/requestDetail/RequestChatSection";
import RequestInfoSection from "../components/sections/requestDetail/RequestInfoSection";
import RequestReportSelectSection from "../components/sections/requestDetail/RequestReportSelectSection";
import {
    currentAdminAllCrossChatsRecoil,
    currentChatMessageListRecoil,
    navThemeRecoil,
    userRecoil,
} from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";
import { sampleReqInfo } from "../utils/dummyData";
import { formatBytes } from "../utils/format";

const RequestDetail = () => {
    const { reqId } = useParams();
    const userData = useRecoilValue(userRecoil);
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const [searchParams] = useSearchParams();
    const [reqType, setReqType] = useState<QuestFormTypes>(
        QuestFormTypes.EXPORT
    );
    const [estimateReports, setEstimateReports] = useState<ChatParsed[]>([]);
    const [processLevel, setProcessLevel] = useState<number>(2);
    const chatId = searchParams.get("chat");
    const [reqCard, setReqCard] = useState<QuestModel>();
    const [chatRoom, setChatRoom] = useState<ChatParsed>();
    const navi = useNavigate();
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [modifyingReportInfo, setModifyingReportInfo] =
        useState<EstimateReportInfo>({
            beginDate: "",
            feeRate: "",
            detailInfo: "",
        });
    const [isShowSuggestedReportInfo, setIsShowSuggestedReportInfo] =
        useState(false);
    const [socket, setSocket] = useState<Socket>();
    const [manager, setManager] = useState<Manager>();
    const [_currentChatMessageList, setCurrentChatMessageList] = useRecoilState(
        currentChatMessageListRecoil
    );
    const [currentAdminAllCrossChats, setCurrentAdminAllCrossChats] =
        useRecoilState(currentAdminAllCrossChatsRecoil);
    const [crossChatIdx, setCrossChatIdx] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        // manager set
        const uri = BACKEND_URL ?? `http://localhost:3333`;
        console.log("uri: ", uri);

        if (!uri) {
            return;
        }
        const manager = new Manager(uri);
        setManager(manager);
    }, []);

    useEffect(() => {
        // namespace set
        let s: Socket | undefined = undefined;
        if (chatRoom && manager) {
            s = manager.socket("/chatting");
            setSocket(s);
            setCurrentChatMessageList(() => chatRoom.logs);
        }
        return () => {
            if (s) {
                s.disconnect();
            }
        };
    }, [chatRoom, manager]);

    useEffect(() => {
        if (!socket) return;
        if (!chatId) return;
        // 채팅 입장
        socket.emit("enter", chatId);
    }, [socket]);

    useEffect(() => {
        if (!socket) return;
        const handler = () => {
            console.log("채팅방 입장");
        };
        socket.on("entered", handler);
        return () => {
            socket.off("entered", handler);
        };
    }, [socket]);

    useEffect(() => {
        if (!socket) return;
        // 누군가 노크하면 보내주기 상태로 만들어서 add 카트로 가기
        const handler = (delta: ChatMessage) => {
            setCurrentChatMessageList((prev) => [...prev, delta]);
        };
        socket.on("receive-chat", handler);

        return () => {
            socket.off("receive-chat", handler);
        };
    }, [socket]);

    const returnSendChatHandle =
        (chat: ReqeustPatchChat, file?: File) => async () => {
            let src: string | undefined = undefined;
            let size: string | undefined = undefined;
            let name: string | undefined = undefined;
            if (!chatId) return;
            if (file && userData) {
                const fsrc = await uploadFC(file, chatId);
                if (!fsrc) {
                    return;
                }
                src = fsrc.src;
                size = formatBytes(file.size);
                name = file.name;
            }

            const mChat = {
                ...chat,
                src,
                srcInfo: size && name ? { size, name } : undefined,
            };
            const newChatInfo = await sendChat(mChat, chatId);
            setCurrentChatMessageList(newChatInfo.logs);
        };

    const fetchQuestData = async (id: string) => {
        try {
            const fetchedQuests = await getQuestById(id);
            setReqType(() => fetchedQuests.type);
            setProcessLevel(() => fetchedQuests.level || 0);
            setReqCard(() => fetchedQuests);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchEstimatedChatsData = async (questId: string) => {
        try {
            const fetchedEstimatedChats = await getEstimatedChats(questId);
            setEstimateReports(fetchedEstimatedChats);
        } catch (e) {
            console.log(e);
        }
    };

    const fetchEstimatedChatData = async (chatId: string) => {
        try {
            const targetChat = await getChatroom(chatId);
            setChatRoom(targetChat);
            setModifyingReportInfo(targetChat.reportInfo);
        } catch (e) {
            console.log(e);
        }
    };

    const cancelRequest = async () => {
        if (window.confirm(t("quest.abortAskMessage") as string)) {
            if (reqId) {
                setProcessLevel(0);
                await abortQuest(reqId);
                window.alert(t("quest.cancelAccess"));
                location.replace("/");
            } else {
                alert(t("quest.notSpecifiedQuestError"));
            }
        }
    };

    const onSelectExpert = async (expertID: string, chatID: string) => {
        if (confirm(t("quest.confirmQuest") as string)) {
            try {
                if (reqId) {
                    console.log({
                        expertID: expertID,
                        questID: reqId,
                        chatID: chatID,
                    });
                    await selectExpert({
                        expertID: expertID,
                        questID: reqId,
                        chatID: chatID,
                    });
                    alert(t("quest.confirmSuccess"));
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    useEffect(() => {
        (async () => {
            if (chatId) {
                if (chatId === "admin") {
                    // 어드민일 때는 올크로스챗불로엄
                    if (reqId) {
                        const achats = await getAllCrossChats(reqId);
                        setCurrentAdminAllCrossChats(() => achats);
                    }
                } else {
                    await fetchEstimatedChatData(chatId);
                }
            }
        })();
    }, [chatId]);

    useEffect(() => {
        // 어드민일경우는 어레이중 타겟챗을 넣기.
        console.log("불러온 챗: ", currentAdminAllCrossChats);
        if (currentAdminAllCrossChats.length > 0) {
            const targetChat = currentAdminAllCrossChats[crossChatIdx];
            setChatRoom(() => targetChat);
            setModifyingReportInfo(() => targetChat.reportInfo);
        }
    }, [currentAdminAllCrossChats, crossChatIdx]);

    useEffect(() => {
        (async () => {
            if (!userData) {
                setIsUserLoading(() => true);
            }
            if (userData && reqId) {
                await fetchQuestData(reqId); // 의뢰정보 불러오기
                if (userData.role === UserRole.COMPANY) {
                    // 기업일 경우, 해당 퀘스트에 해당하는 전체 챗데이터 불러오기
                    await fetchEstimatedChatsData(reqId);
                } else if (userData.role === UserRole.EXPERT && !chatId) {
                    // 전문가인데, 채팅 아이디가 설정되지 않았으면 채팅창으로 이동해줄것
                    // NOTE 견적서 수정 삭제화면도 같이 있어야함
                    const cr = await getChatroom(reqId, true);
                    navi(
                        {
                            search: `?${createSearchParams({
                                chat: cr._id,
                            })}`,
                        },
                        { replace: true }
                    );
                } else if (userData.role === UserRole.ADMIN) {
                    // 어드민이면 멀티플 채팅 불러올 것, 그리고 이걸 페이징 할것?
                    navi(
                        {
                            search: `?${createSearchParams({
                                chat: "admin",
                            })}`,
                        },
                        { replace: true }
                    );
                }
                setIsUserLoading(() => false);
            }
        })();
    }, [userData]);

    useEffect(() => {
        (async () => {
            // 3단계이상이면 채팅 무조건 불러오기
            // TODO 중복로직 제거하기
            if (reqId && reqCard && reqCard.level >= 3) {
                const cr = await getChatroom(reqId, true);
                navi(
                    {
                        search: `?${createSearchParams({
                            chat: cr._id,
                        })}`,
                    },
                    { replace: true }
                );
            }
        })();
    }, [reqCard, reqId]);

    if (isUserLoading) {
        return <Bingle />;
    }

    return (
        <Grid
            container
            flexDirection={"column"}
            alignItems={"center"}
            p={isMobile ? 2.5 : 3}
        >
            <Grid
                container
                sx={{
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "1600px",
                    flexWrap: "nowrap",
                    mt: 8,
                }}
            >
                <Grid mb={5} mt={2} container>
                    <BackButton />
                    <Typography variant="f32h40EB">
                        {t("mypage.quest")}
                    </Typography>
                </Grid>
                {/* Info Box */}
                <Grid container spacing={2.5}>
                    {/* 의뢰정보와 전문가 정보 */}
                    <Grid item xs={isMobile ? 12 : 3}>
                        {reqCard && (
                            <RequestInfoSection
                                isMobile={isMobile}
                                cancelRequest={cancelRequest}
                                reqInfo={sampleReqInfo(reqCard).map(
                                    (infoEle) => {
                                        return {
                                            ...infoEle,
                                            title: t(`quest.${infoEle.title}`),
                                        };
                                    }
                                )}
                                expertInfo={
                                    chatRoom
                                        ? {
                                              name: chatRoom.expertInfo
                                                  .nickname,
                                              email: chatRoom.expertInfo.email,
                                              contact:
                                                  chatRoom.expertInfo.contact,
                                              feeRate:
                                                  chatRoom.reportInfo.feeRate,
                                              beginDate:
                                                  chatRoom.reportInfo.beginDate,
                                          }
                                        : undefined
                                }
                                level={processLevel}
                            />
                        )}
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 9}>
                        <Grid position={"relative"} height={"100%"}>
                            <Grid
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    height: isMobile ? "auto" : "100%",
                                    minHeight: "312px",
                                    width: "100%",
                                }}
                            >
                                {currentAdminAllCrossChats.length > 0 && (
                                    <Pagination
                                        count={currentAdminAllCrossChats.length}
                                        page={crossChatIdx + 1}
                                        onChange={(_e, v) => {
                                            setCrossChatIdx(() => v - 1);
                                        }}
                                    />
                                )}
                                <>
                                    {/* 견적서(채팅)목록에서 선택 및 채팅할 수 있음 */}
                                    {((processLevel < 3 && chatId === null) ||
                                        processLevel < 1) && (
                                        <RequestReportSelectSection
                                            isMobile={isMobile}
                                            estimateReports={estimateReports}
                                            reqType={reqType}
                                            onSelectExpertAtList={(chat) =>
                                                () => {
                                                    onSelectExpert(
                                                        chat.expert || "",
                                                        chat._id || ""
                                                    );
                                                    location.reload();
                                                }}
                                            level={processLevel}
                                        />
                                    )}

                                    {/* 3단계 미만에서 채팅중인데 회사가 아닐경우 */}
                                    {chatId !== null &&
                                        processLevel < 3 &&
                                        userData?.role !== UserRole.COMPANY &&
                                        reqId &&
                                        chatRoom &&
                                        (isShowSuggestedReportInfo ? (
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        setIsShowSuggestedReportInfo(
                                                            (prev) => !prev
                                                        );
                                                    }}
                                                    fullWidth
                                                    variant="contained"
                                                >
                                                    {!isShowSuggestedReportInfo
                                                        ? t(
                                                              "quest.showEstimate"
                                                          )
                                                        : t(
                                                              "quest.hideEstimate"
                                                          )}
                                                </Button>
                                                <EstimateModifySection
                                                    isMobile={isMobile}
                                                    questID={reqId}
                                                    reportInfo={
                                                        modifyingReportInfo
                                                    }
                                                    setReportInfo={
                                                        setModifyingReportInfo
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    setIsShowSuggestedReportInfo(
                                                        (prev) => !prev
                                                    );
                                                }}
                                                fullWidth
                                                variant="outlined"
                                            >
                                                {!isShowSuggestedReportInfo
                                                    ? t("quest.showEstimate")
                                                    : t("quest.hideEstimate")}
                                            </Button>
                                        ))}
                                    {/* 3단계 이상이거나, 채팅중일경우(==채팅목록화면이 아닐경우), 0단계면 접근 불가*/}
                                    {(processLevel >= 3 || chatId !== null) &&
                                        processLevel > 0 &&
                                        chatRoom && (
                                            <RequestChatSection
                                                returnUploadHandler={
                                                    returnSendChatHandle
                                                }
                                                isMobile={isMobile}
                                                level={processLevel}
                                                onSelectExpert={() => {
                                                    onSelectExpert(
                                                        chatRoom.expert,
                                                        chatRoom._id
                                                    );
                                                    location.reload();
                                                }}
                                                reqId={reqId || ""}
                                                minus48={
                                                    chatRoom &&
                                                    userData?.role ===
                                                        UserRole.ADMIN &&
                                                    processLevel >= 3
                                                        ? 32
                                                        : chatId !== null &&
                                                          processLevel < 3 &&
                                                          reqId &&
                                                          chatRoom
                                                        ? userData?.role ===
                                                          UserRole.ADMIN
                                                            ? 80
                                                            : userData?.role !==
                                                              UserRole.COMPANY
                                                            ? 48
                                                            : 0
                                                        : 0
                                                }
                                                settlement={
                                                    reqCard?.settlement
                                                        ? JSON.parse(
                                                              reqCard.settlement
                                                          )
                                                        : undefined
                                                }
                                                chatData={chatRoom}
                                            />
                                        )}
                                </>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RequestDetail;
