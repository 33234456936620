import {
    ChatModel,
    ChatParsed,
    ReqeustPatchChat,
} from "../api-interfaces/chat";

import apiInstance from "./base";

/**
 * 채팅하기
 * @param chatMsg
 * @returns
 */
export const sendChat = async (chatMsg: ReqeustPatchChat, chatID: string) => {
    const query = `v1/chat/${chatID}`;

    const tc = await apiInstance
        .patch(query, { json: chatMsg })
        .json<ChatModel>();
    const targetChat: ChatParsed = {
        ...tc,
        logs: JSON.parse(tc.logs),
        reportInfo: JSON.parse(tc.reportInfo),
        expertInfo: JSON.parse(tc.expertInfo),
        announcements: JSON.parse(tc.announcements),
    };
    console.log("result of chatting", tc);
    return targetChat;
};

/**
 * 특정 채팅방 불러오기
 *
 * @param chatIDOrQuestID
 * @returns
 */
export const getChatroom = async (
    chatIDOrQuestID: string,
    byQuestID?: boolean
) => {
    const query = `v1/chat/${chatIDOrQuestID}${
        byQuestID ? "?byQuestID=1" : ""
    }`;

    const tc = await apiInstance.get(query).json<ChatModel>();

    const targetChat: ChatParsed = {
        ...tc,
        logs: JSON.parse(tc.logs),
        reportInfo: JSON.parse(tc.reportInfo),
        expertInfo: JSON.parse(tc.expertInfo),
        announcements: JSON.parse(tc.announcements),
    };
    return targetChat;
};
