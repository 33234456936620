import { MongoDBIncluding } from "./baseType";

export enum CommunityCategoryType {
    TRADEINFO = "무역정보",
    BLOG = "블로그",
    REVIEW = "리뷰",
}

export enum FreeBoardCategoryType {
    MACHINE = "기계",
    CAR_PART = "자동차부품",
    HEAVY_EQUIP = "중장비",
    ROBOT_AUTO = "로봇자동화",
}

export type CategoryType = CommunityCategoryType | FreeBoardCategoryType;

export interface Comment {
    /**
     * 유저닉네임
     */
    name: string;

    /**
     * 프로필 이미지 주소
     *
     * @remarks 없으면 default image
     */
    profileImage?: string;

    /**
     * @remarks
     * YYYY-MM-DD hh:mm:ss 형식
     */
    timestamp: string;

    /**
     * User 의 _id
     */
    userID: string;

    /**
     * 내용
     */
    contents: string;

    /**
     * 답글
     * @remarks
     * 없으면 Length 가 0 인 array
     */
    replies: Comment[];

    /**
     * 삭제된 댓글인지?
     */
    isDeleted?: boolean;
}

export interface PostBase extends MongoDBIncluding {
    /**
     * 제목
     */
    title: string;
    /**
     * 게시판 종류
     *
     * @remarks
     * undefined 는 사실 존재하지 않음.
     * Mongoose 사용 편리하게 하려고 넣어둠
     * front 에서는 ? 없애도됨
     */
    category?: CategoryType;
    /**
     * 좋아요 수
     *
     * @default 0
     */
    likes: number;
    /**
     * 코멘트 갯수
     *
     * @default 0
     */
    commentsNumber: number;
    /**
     * plain text
     *
     * @default ""
     */
    contents: string;

    /**
     * 내 포스트인지 아닌지 알기위한...
     */
    userID: string;

    /**
     * @remarks
     * YYYY-MM-DD hh:mm:ss 형식
     */
    timestamp: string;
}

export interface PostModel extends PostBase {
    comments: string;
    /**
     * 이전글 PostModel 의 _id
     * @default ""
     */
    prev?: string;
    /**
     * 다음글 PostModel 의 _id
     * @default ""
     */
    next?: string;
}

export interface PostSimple extends MongoDBIncluding {
    title: string;
    category: CategoryType;
    /**
     * plain text
     */
    contents: string;
    likes: number;
    commentsNumber: number;
}

export interface RequestPostPost {
    /**
     * 제목
     */
    title: string;
    /**
     * 게시판 종류
     */
    category: CategoryType;
    /**
     * plain text
     * @default "[]"
     */
    contents?: string;
}
export interface ResponseGetPost extends PostBase {
    /** `undefined` 가 없음 */
    category: CategoryType;

    comments: Comment[];
    /**
     * 이전글 PostModel 의 PostSimple 형태
     */
    prev?: PostSimple;
    /**
     * 다음글 PostModel 의 PostSimple 형태
     */
    next?: PostSimple;
}

export interface RequestPatchPost {
    title?: string;
    category?: CategoryType;
    contents?: string;
}

export interface TargetCommentInfo {
    /**
     * @example
     * ```js
     * // 만약 A에게 답글을 다는데, A 가 포스팅에서 두번째 댓글일 경우
     * [1]
     *
     * // 만약 B에게 답글을 다는데, A 의 댓글의 두번째 답글이 B인 경우
     * [1, 1]
     *
     * // 만약 C에게 답글을 다는데, B의 3번째 답글이 C인 경우
     * [1, 1, 2]
     * ```
     */
    attachingIdxPath: number[];
}

export interface RequestPostComment {
    /**
     * 내용
     * @default ""
     */
    contents?: string;

    /**
     * 타겟코멘트를 지정하면 거기에 댓글이 달림, 타겟코멘트의 userID 와 timestamp 값을 받아옴
     * 없으면 그냥 글에 달리는 댓글임
     * */
    targetComment?: TargetCommentInfo;
}

export interface RequestPatchComment {
    /**
     * 수정이 아니라 삭제하는 경우
     */
    isDeleting?: boolean;

    /**
     * 내용
     * @default ""
     */
    contents?: string;

    /**
     * 자기 자신의 idx path
     *
     * @example
     * ```js
     * // 만약 내가 A의 댓글(글에서 2번째 댓글)의 다섯번째 답글일 경우
     *
     * [1, 4]
     * ```
     */
    attachingIdxPath: number[];
}

export interface ResponsePatchComment {
    commentsNumber: number;
    comments: Comment[];
}

export interface ResponsePostLike {
    likes: number;
    liked: boolean;
}
