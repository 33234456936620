import { Doccount } from "../api-interfaces/doccount";
import {
    RequestPostAndPatchTradefirm,
    Tradefirm,
} from "../api-interfaces/tradefirm";

import apiInstance from "./base";

/**
 * 무역상사 목록 가져오기
 * @param sort - "companyName" | "exportItem" | "providingQuestService"
 */
export const getTradefirms = async (
    skip?: number,
    limit?: number,
    sort?: string
) => {
    const query = `v1/tradefirm${skip || limit || sort ? "?" : ""}${
        skip ? `skip=${skip}` : ""
    }${skip && limit ? "&" : ""}${limit ? `limit=${limit}` : ""}${
        limit && sort ? "&" : ""
    }${sort ? `sort=${sort}` : ""}`;
    const tfs = await apiInstance.get(query).json<Tradefirm[]>();
    return tfs;
};

/**
 * 무역상사 생성하기
 *
 * @param data - 필드정보들
 */
export const postTradefirm = async (data: RequestPostAndPatchTradefirm) => {
    const query = `v1/tradefirm`;
    const u = await apiInstance.post(query, { json: data }).json<Tradefirm>();
    return u;
};

/**
 * 무역상사 가져오기
 *
 * @param tfID - 무역상사 _id
 */
export const fetchTradefirm = async (tfID: string) => {
    const query = `v1/tradefirm/${tfID}`;
    const u = await apiInstance.get(query).json<Tradefirm>();
    return u;
};

/**
 * 무역상사 수정하기
 *
 * @param tfID - 무역상사 _id
 * @param data - 필드정보들
 */
export const patchTradefirm = async (
    tfID: string,
    data: RequestPostAndPatchTradefirm
) => {
    const query = `v1/tradefirm/${tfID}`;
    const u = await apiInstance.patch(query, { json: data }).json<Tradefirm>();
    return u;
};

/**
 * 무역상사 삭제하기
 *
 * @param tfID - 무역상사 _id
 */
export const deleteUserAdminControl = async (tfID: string) => {
    const query = `v1/tradefirm/${tfID}`;
    const u = await apiInstance.delete(query).json();
    return u;
};

/**
 * 무역상사 목록 가져오기
 */
export const getTradeFirmDoccount = async () => {
    const query = `v1/tradefirm/doccount`;
    const tfs = await apiInstance.get(query).json<Doccount>();
    return tfs;
};
