import React from "react";

import { Add } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { TextButtonProps } from "./button-interfaces";

const AddWithTextButton: React.FC<TextButtonProps> = ({ text, onClick }) => {
    return (
        <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Button onClick={onClick}>
                <Typography variant="f18h20B">{text}</Typography>
                <Grid ml={1} mt={0.2}>
                    <Add />
                </Grid>
            </Button>
        </Box>
    );
};

export default AddWithTextButton;
