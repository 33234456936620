import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

export interface UserpageCateSelectSectionProps {
    pageCate: "request" | "community";
    setPageCate: (cate: "request" | "community") => void;
}

const UserpageCateSelectSection: React.FC<UserpageCateSelectSectionProps> = ({
    pageCate,
    setPageCate,
}) => {
    const { t } = useTranslation();
    return (
        <Grid container mt={4}>
            <Button
                variant={pageCate === "request" ? "contained" : "text"}
                sx={{
                    height: "34px",
                    fontSize: 14,
                    fontWeight: 800,
                    color: pageCate === "request" ? undefined : "#AAAAAA",
                }}
                onClick={() => setPageCate("request")}
            >
                {t("mypage.quest")}
            </Button>
            {/* <Button
                variant={pageCate === "community" ? "contained" : "text"}
                sx={{
                    height: "34px",
                    ml: 1,
                    paddingX: 2,
                    fontSize: 14,
                    fontWeight: 800,
                    color: pageCate === "community" ? undefined : "#AAAAAA",
                }}
                onClick={() => setPageCate("community")}
            >
                커뮤니티
            </Button> */}
        </Grid>
    );
};

export default UserpageCateSelectSection;
