import React, { Ref, forwardRef } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../../theme";

const CompanyInfoFirst = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();

    return (
        <>
            <img
                src={"./images/company-info/company-info_bg.png"}
                style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    zIndex: -1,
                    objectFit: "cover",
                    top: 0,
                    imageResolution: "300dpi",
                }}
            />
            {/* 1 번쨰 THEME */}
            <Box
                ref={ref}
                sx={{
                    display: "flex",
                    height: "100vh",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    p: 4,
                    animation: "fadeIn 3s",
                }}
            >
                <Typography
                    variant={isMobile ? "f32h40EB" : "f64h84EB"}
                    sx={{ color: "white" }}
                    component={"div"}
                >
                    {t("companyInfo.t10")}
                </Typography>
                <Typography
                    variant={isMobile ? "f32h40EB" : "f64h84EB"}
                    sx={{ color: "white" }}
                    component={"div"}
                >
                    {t("companyInfo.t11")}
                </Typography>
            </Box>
        </>
    );
});
export default CompanyInfoFirst;
