import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSetRecoilState } from "recoil";

import { Button, Grid, Typography, useMediaQuery } from "@mui/material";

// import { ChatParsed } from "../api-interfaces/chat";
// import { QuestModel } from "../api-interfaces/quest";
// import { getAllQuests } from "../apis/admin";
// import { getEstimatedChats } from "../apis/quest";
// import AdminConsulting from "../components/frames/AdminConsulting";
import AdminCustomer from "../components/frames/AdminCustomer";
import AdminProfessional from "../components/frames/AdminProfessional";
import AdminTFT from "../components/frames/AdminTFT";
// import NoReportSection from "../components/sections/requestDetail/NoReportSection";
// import RequestChatSection from "../components/sections/requestDetail/RequestChatSection";
// import RequestInfoSection from "../components/sections/requestDetail/RequestInfoSection";
import { navThemeRecoil } from "../components/states/recoil";
import { tabletMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";
// import { sampleReqInfo } from "../utils/dummyData";

const Admin = () => {
    const isMobile = useMediaQuery(tabletMaxWidthMediaQuery);
    const [selectedTab, setSelectedTab] = useState(0);

    // 의뢰 필드
    // const [allQuests, setAllQuest] = useState<QuestModel[]>([]);
    // const [currentQuest, setCurrentQuest] = useState<QuestModel>();
    // const [selectedQuestChats, setSelectedQuestChats] = useState<ChatParsed[]>(
    //     []
    // );
    // const [selectedPage, _setSelectedPage] = useState(1);
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const navi = useNavigate();

    const tabs: string[] = [
        "고객관리",
        "컨설팅 의뢰 관리",
        "수/출입 의뢰 관리",
        "전문가 관리",
        "TC.com 관리",
    ];

    useEffect(() => {
        if (selectedTab === 2) {
            navi("/mypage");
        }
    }, [selectedTab]);

    // const cancelQuest = async () => {
    //     if (window.confirm("진행 중인 의뢰를 중단하시겠습니까?")) {
    //         if (currentQuest) {
    //             await abortQuest(currentQuest._id);
    //             window.alert("취소 신청이 완료되었습니다");
    //             location.reload();
    //         } else {
    //             alert("지정된 의뢰가 없습니다!");
    //         }
    //     }
    // };

    // const selectQuestExpert =
    //     (chatID: string, expertID: string) => async () => {
    //         if (currentQuest) {
    //             await selectExpert({
    //                 expertID,
    //                 chatID,
    //                 questID: currentQuest._id,
    //             });
    //         }
    //     };

    // useEffect(() => {
    //     (async () => {
    //         const allQ = await getAllQuests();
    //         setAllQuest(() => allQ);
    //     })();
    // }, []);

    // useEffect(() => {
    //     (async () => {
    //         console.log("allQ length: ", allQuests.length);
    //         if (allQuests.length > 0) {
    //             setCurrentQuest(() => allQuests[selectedPage - 1]);
    //         }
    //     })();
    // }, [allQuests, selectedPage]);

    // useEffect(() => {
    //     (async () => {
    //         if (currentQuest) {
    //             const chats = await getEstimatedChats(currentQuest._id);
    //             console.log("currentQ._id", currentQuest._id);
    //             setSelectedQuestChats(() => chats);
    //         }
    //     })();
    // }, [currentQuest]);

    // useEffect(() => {
    //     console.log("chats!!!: ", selectedQuestChats);
    // }, [selectedQuestChats]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    const changeTab = () => {
        if (selectedTab == 0) {
            return <AdminCustomer />;
        } else if (selectedTab == 1) {
            return (
                <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    height={"80vh"}
                    flexDirection="column"
                    item
                >
                    <img
                        src={`/images/icon/consulting_chat_grey.svg`}
                        width={isMobile ? 48 : 80}
                    />
                    <Typography sx={{ mt: 2 }}>준비 중...</Typography>
                </Grid>
            );
        } else if (selectedTab == 3) {
            return <AdminProfessional />;
        } else if (selectedTab == 4) {
            return <AdminTFT />;
        }
    };
    return (
        <>
            <Grid
                container
                flexDirection={"row"}
                alignItems={"flex-start"}
                p={isMobile ? 2.5 : 4}
                justifyContent={isMobile ? undefined : "center"}
                sx={{ overflowY: "auto" }}
            >
                {/* 왼쪽 탭 부분 */}
                <Grid
                    container
                    sx={{
                        maxWidth: "1600px",
                        width: "16%",
                        mt: 18,
                        flexDirection: "column",
                        minWidth: 130,
                    }}
                    display={isMobile ? "none" : undefined}
                >
                    {tabs.map((e, idx) => {
                        return (
                            <Button
                                key={`${e}-${idx}`}
                                sx={{
                                    display: "flex",
                                    p: 2,
                                }}
                                onClick={() => {
                                    setSelectedTab(idx);
                                }}
                            >
                                <Typography
                                    variant={
                                        selectedTab === idx
                                            ? "f18h20B"
                                            : undefined
                                    }
                                >
                                    {e}
                                </Typography>
                            </Button>
                        );
                    })}
                </Grid>
                {/* 내용 */}
                <Grid
                    container
                    sx={{
                        width: "80%",
                        maxWidth: "1300px",
                        flexWrap: "nowrap",
                        mt: 8,
                        flexDirection: "column",
                        overflowX: "hidden",
                    }}
                >
                    {changeTab()}
                </Grid>
            </Grid>
        </>
    );
};

export default Admin;
