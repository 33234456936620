import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { Box, Grid, Pagination, Typography } from "@mui/material";

import { ResponseGetUserAdminControl } from "../../api-interfaces/admin";
import { UserRole } from "../../api-interfaces/user";
import { getCompanies, getDoccount, getGuests } from "../../apis/admin";
import SolidTextButtonWithColor from "../buttons/SolidTextButtonWithColor";
import Bingle from "../indicators/Bingle";
import { renderUpdateComponentRecoil } from "../states/recoil";

import AdminCustomerTile from "./AdminCustomerTile";

const AdminCustomer = () => {
    //Pagination
    const [userDocLimit, setUserDocLimit] = useState(0);
    const [notUserDocLimit, setNotUserDocLimit] = useState(0);

    const [customersData, setCustomersData] =
        useState<ResponseGetUserAdminControl[]>();
    const [users, setUsers] = useState<ResponseGetUserAdminControl[]>();
    const [notUsers, setNotUsers] = useState<ResponseGetUserAdminControl[]>();

    const [docLimit, setDocLimit] = useState<number>(1);
    const [showUnexpertList, setShowUnexpertList] = useState<boolean>(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [reRenderData, _setReRenderData] = useRecoilState(
        renderUpdateComponentRecoil
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            //고객관리
            setIsLoading(true);
            const userlim = await getDoccount(UserRole.COMPANY);
            const notuserlim = await getDoccount(UserRole.GUEST);
            const user = await getCompanies(0, 5);
            const notuser = await getGuests(0, 5);
            setDocLimit(Math.ceil(userlim.count / 5));
            setUsers(user);
            setNotUsers(notuser);
            setCustomersData(user);
            setUserDocLimit(Math.ceil(userlim.count / 5));
            setNotUserDocLimit(Math.ceil(notuserlim.count / 5));
            setIsLoading(false);
        })();
    }, [reRenderData]);

    useEffect(() => {
        if (showUnexpertList) {
            setDocLimit(notUserDocLimit);
            setCustomersData(notUsers);
            setSelectedPage(1);
        } else {
            setDocLimit(userDocLimit);
            setCustomersData(users);
            setSelectedPage(1);
        }
    }, [showUnexpertList]);

    useEffect(() => {
        (async () => {
            try {
                if (showUnexpertList) {
                    const dt = await getGuests((selectedPage - 1) * 5, 5);
                    console.log(dt, "customer");
                    setCustomersData(() => dt);
                } else {
                    const dt = await getCompanies((selectedPage - 1) * 5, 5);
                    console.log(dt, "customer");
                    setCustomersData(() => dt);
                }
            } catch (e) {
                console.log(e, "업로드 문제");
            }
        })();
    }, [selectedPage, reRenderData]);

    return (
        <>
            <Grid container mb={3} flexDirection={"column"}>
                <Box
                    display={"flex"}
                    border={"solid 1px #AAAAAA"}
                    height={34}
                    width={237}
                    alignItems={"center"}
                    p={1}
                >
                    <img
                        src={"./images/icon/search.svg"}
                        width={20}
                        height={20}
                    />
                    <Box display={"flex"} pl={1}>
                        <Typography color={"#AAAAAA"}>검색</Typography>
                    </Box>
                </Box>
                <Box display={"flex"} mt={3}>
                    <SolidTextButtonWithColor
                        text={showUnexpertList ? "전문가목록" : "미승인목록"}
                        size={15}
                        sx={{ width: 100, height: 35 }}
                        onClick={() =>
                            showUnexpertList
                                ? setShowUnexpertList(false)
                                : setShowUnexpertList(true)
                        }
                    />
                </Box>
                <Box display={"flex"} mt={3} mb={3}>
                    <Pagination
                        count={docLimit}
                        page={selectedPage}
                        onChange={(
                            event: React.ChangeEvent<unknown>,
                            value: number
                        ) => setSelectedPage(value)}
                    />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                    {isLoading ? (
                        <Bingle height={300} />
                    ) : (
                        <>
                            {customersData
                                ? customersData.map((e, idx) => (
                                      <AdminCustomerTile
                                          key={idx}
                                          data={e}
                                          unexpert={showUnexpertList}
                                      />
                                  ))
                                : ""}
                        </>
                    )}
                </Box>
            </Grid>
        </>
    );
};

export default AdminCustomer;
