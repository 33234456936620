import React, { useEffect, useRef } from "react";

import { useSetRecoilState } from "recoil";

import { Box, useMediaQuery } from "@mui/material";

import CompanyInfoFirst from "../components/sections/company-info/CompanyInfoFirst";
import CompanyInfoFourth from "../components/sections/company-info/CompanyInfoFourth";
import CompanyInfoSecond from "../components/sections/company-info/CompanyInfoSecond";
import CompanyInfoThird from "../components/sections/company-info/CompanyInfoThird";
import { navThemeRecoil } from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

const CompanyInfo = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const setNavTheme = useSetRecoilState(navThemeRecoil);

    const ref1 = useRef<HTMLDivElement>(null);

    const changeNavbarColor = () => {
        const sh1 = ref1.current?.scrollHeight ?? (isMobile ? 770 : 850);
        if (window.scrollY >= sh1) {
            setNavTheme(navThemeValues.WHITE);
        } else {
            setNavTheme(navThemeValues.WHITE_TRANS);
        }
    };

    useEffect(() => {
        changeNavbarColor();
        window.addEventListener("scroll", changeNavbarColor);
        return () => window.removeEventListener("scroll", changeNavbarColor);
    }, [isMobile]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <CompanyInfoFirst ref={ref1} />
            <CompanyInfoSecond />
            <CompanyInfoThird />
            <CompanyInfoFourth />
        </Box>
    );
};

export default CompanyInfo;
