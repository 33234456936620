import React, { useState } from "react";

import { useRecoilValue } from "recoil";

import { Add } from "@mui/icons-material";
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { AnnouncementMessage } from "../../../api-interfaces/announcement";
import { ExpertSettlement } from "../../../api-interfaces/quest";
import { UserRole } from "../../../api-interfaces/user";
import { popAnnouncement, pushAnnouncement } from "../../../apis/announcement";
import {
    pleaseCheckPay,
    confirmPayment,
    inputSettlement,
    completeQuest,
    approveSettlement,
    denySettlement,
} from "../../../apis/quest";
import { uploadFC } from "../../../apis/upload";
import { computeFinalFee } from "../../../utils/format";
import AnouncementBlock from "../../cards/AnouncementBlock";
import ManagerAccInfoCard from "../../cards/ManagerAccInfoCard";
import FileUploader from "../../inputs/FileUploader";
import ModalDialog from "../../modals/ModalDialog";
import { userRecoil } from "../../states/recoil";

export interface AnouncementSectionProps {
    isMobile: boolean;
    onConfirm: () => void;
    reqId: string;
    level: number;
    annData: AnnouncementMessage[];
    chatID: string;
    /**
     * 5단계인 시점에서 정산보고서를 받았는가
     */
    hasSettlement?: boolean;
    defaultFeeRate?: string;
}

const AnouncementSection: React.FC<AnouncementSectionProps> = ({
    isMobile,
    onConfirm,
    reqId,
    level,
    annData,
    chatID,
    hasSettlement,
    defaultFeeRate,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [text, setText] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [openCostModal, setOpenCostModal] = useState(false);
    const [settlementInfo, setSettlementInfo] = useState<ExpertSettlement>({
        exportFee: "",
        commission: defaultFeeRate || "",
        finalFee: "",
        isNotPercentageCommission: false,
        proof: "",
    });
    const userData = useRecoilValue(userRecoil);
    const [proofFile, setProofFile] = useState<File>();

    const { t } = useTranslation();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onCloseModal = () => {
        setOpenModal(false);
        setText("");
    };

    const onPostAnouncement = async () => {
        await pushAnnouncement(chatID, text);
        location.reload();
    };

    const handlePopAnnouncement = async () => {
        if (annData.length < 1) {
            return;
        }
        handleClose();
        await popAnnouncement(chatID);
        location.reload();
    };

    const sendSettlement = async () => {
        try {
            if (!proofFile) {
                alert(t("quest.verifyingDocumentMessage"));
                return;
            }
            const pRes = await uploadFC(proofFile, chatID);
            if (!pRes) {
                alert(t("error.network"));
                return;
            }

            await inputSettlement(reqId, {
                ...settlementInfo,
                proof: pRes.src,
                commission: settlementInfo.isNotPercentageCommission
                    ? t("quest.seeVerifyingDocument")
                    : settlementInfo.commission,
                finalFee: settlementInfo.isNotPercentageCommission
                    ? settlementInfo.finalFee
                    : `${computeFinalFee(
                          settlementInfo.exportFee,
                          settlementInfo.commission
                      )}`,
            });

            alert(t("quest.transferSuccess"));
            location.reload();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <Grid
                container
                sx={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography variant="f20h28EB">
                        {t("quest.adminAnnoucement")}
                    </Typography>

                    {/* 어드민일 경우 공지 추가삭제가 가능함 */}
                    <>
                        {isMobile && userData?.role === UserRole.ADMIN && (
                            <IconButton size="small" onClick={handleClick}>
                                <Add htmlColor={"black"} />
                            </IconButton>
                        )}
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: 0,
                                    border: "solid 1px black",
                                },
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    setOpenModal(true);
                                }}
                                sx={{ m: 0 }}
                                dense={true}
                                disabled={level === 7}
                            >
                                <Typography variant="f14h20">
                                    공지추가
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={handlePopAnnouncement}
                                sx={{ m: 0 }}
                                dense={true}
                                disabled={level === 7}
                            >
                                <Typography variant="f14h20">
                                    공지삭제
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </>
                </Grid>

                <ManagerAccInfoCard showInfo={level >= 3} />
                <Divider />
                {/* 공지들 */}
                {isMobile ? (
                    <Grid
                        sx={{
                            mt: 2,
                            maxHeight: "500px",
                            overflow: "auto",
                        }}
                    >
                        {annData.map((l, i) => (
                            <AnouncementBlock
                                key={`anounce-${i}`}
                                isRead={false}
                                onRead={() => console.log("read!")}
                                date={l.time}
                                content={l.content}
                            />
                        ))}
                    </Grid>
                ) : (
                    <>
                        <Grid
                            sx={{
                                mt: 2,
                                position: "relative",
                                width: "100%",
                                flexGrow: 1,
                                height: isMobile ? "500px" : "auto",
                            }}
                        >
                            <Grid
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    width: "100%",
                                    height: "100%",
                                    overflow: "auto",
                                }}
                            >
                                {annData.map((l, i) => (
                                    <AnouncementBlock
                                        key={`anounce-${i}`}
                                        isRead={true}
                                        onRead={() => console.log("read!")}
                                        date={l.time}
                                        content={l.content}
                                    />
                                ))}
                            </Grid>
                        </Grid>

                        <Grid mt={2}>
                            {level < 3 && userData?.role === UserRole.COMPANY && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{ whiteSpace: "nowrap" }}
                                    onClick={onConfirm}
                                >
                                    {t("quest.decide")}
                                </Button>
                            )}

                            {level === 3 &&
                                userData?.role === UserRole.COMPANY && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ whiteSpace: "nowrap" }}
                                        onClick={() => {
                                            pleaseCheckPay(reqId);
                                            alert(t("quest.requestSuccess"));
                                            location.reload();
                                        }}
                                    >
                                        {t("quest.depositComplete")}
                                    </Button>
                                )}
                            {level === 3 && userData?.role === UserRole.EXPERT && (
                                <Button
                                    sx={{ whiteSpace: "nowrap" }}
                                    variant="contained"
                                    fullWidth
                                    disabled
                                >
                                    {t("quest.depositWaiting")}
                                </Button>
                            )}
                            {level === 4 &&
                                (userData?.role === UserRole.COMPANY ||
                                    userData?.role === UserRole.EXPERT) && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled
                                        sx={{ whiteSpace: "nowrap" }}
                                    >
                                        {t("quest.depositConfirming")}
                                    </Button>
                                )}

                            {level === 4 && userData?.role === UserRole.ADMIN && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                        confirmPayment(reqId);
                                        alert(t("quest.confirmSuccessRaw"));
                                        location.reload();
                                    }}
                                >
                                    {t("quest.confirmDeposit")}
                                </Button>
                            )}

                            {level === 5 &&
                                (userData?.role === UserRole.EXPERT ? (
                                    // 5단계
                                    hasSettlement ? (
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            disabled
                                        >
                                            {t("quest.checkingSettlement")}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                setOpenCostModal(true)
                                            }
                                        >
                                            {t("quest.inputSettlement")}
                                        </Button>
                                    )
                                ) : hasSettlement ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={async () => {
                                                if (
                                                    confirm(
                                                        t(
                                                            "quest.askCompleteSettlementMessage"
                                                        ) as string
                                                    )
                                                ) {
                                                    await approveSettlement(
                                                        reqId
                                                    );
                                                    alert(
                                                        t(
                                                            "quest.completeMessage"
                                                        )
                                                    );
                                                    location.reload();
                                                }
                                            }}
                                        >
                                            {t("quest.checkSettlement")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            sx={{ mt: 1 }}
                                            onClick={async () => {
                                                if (
                                                    confirm(
                                                        t(
                                                            "quest.askRejectSettlementMessage"
                                                        ) as string
                                                    )
                                                ) {
                                                    await denySettlement(reqId);
                                                    alert(
                                                        t(
                                                            "quest.completeMessage"
                                                        )
                                                    );
                                                    location.reload();
                                                }
                                            }}
                                        >
                                            {t("quest.askReapplySettlement")}
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled
                                    >
                                        {t("quest.writingSettlement")}
                                    </Button>
                                ))}
                            {level === 6 &&
                                (userData?.role === UserRole.ADMIN ? (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={async () => {
                                            if (
                                                confirm(
                                                    t(
                                                        "quest.askCompleteProjectMessage"
                                                    ) as string
                                                )
                                            ) {
                                                await completeQuest(reqId);
                                                alert(
                                                    t("quest.completeMessage")
                                                );
                                                location.reload();
                                            }
                                        }}
                                    >
                                        {t("quest.projectComplete")}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        disabled
                                    >
                                        {t("quest.finalChecking")}
                                    </Button>
                                ))}
                            {level >= 7 && (
                                <Button variant="contained" fullWidth disabled>
                                    {t("quest.projectComplete")}
                                </Button>
                            )}
                            {userData?.role === UserRole.ADMIN && !isMobile && (
                                <>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={() => {
                                            handleClose();
                                            setOpenModal(true);
                                        }}
                                        sx={{ mt: 1 }}
                                        disabled={level === 7}
                                    >
                                        공지추가
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={handlePopAnnouncement}
                                        sx={{ mt: 1 }}
                                        disabled={level === 7}
                                    >
                                        공지삭제
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>

            <ModalDialog
                isMobile={isMobile}
                title={t("quest.settlementTitle")}
                open={openCostModal}
                onClose={() => setOpenCostModal(false)}
            >
                <Button
                    onClick={() =>
                        setSettlementInfo((prev) => {
                            return {
                                ...prev,
                                isNotPercentageCommission:
                                    !prev.isNotPercentageCommission,
                            };
                        })
                    }
                    variant={
                        settlementInfo.isNotPercentageCommission
                            ? "contained"
                            : "outlined"
                    }
                >
                    {t("quest.inputCommissionFeeManually")}
                </Button>
                <Grid mt={3} mb={1}>
                    <Typography variant="f16h20B">
                        {t("quest.최종 수출 금액")}
                    </Typography>
                </Grid>
                <TextField
                    placeholder={t("quest.inputExportingFee") as string}
                    value={settlementInfo.exportFee}
                    onChange={(e) =>
                        setSettlementInfo((prev) => {
                            return { ...prev, exportFee: e.target.value };
                        })
                    }
                />
                <Grid mt={3} mb={1}>
                    <Typography variant="f16h20B">
                        {t("quest.commissionFee")}
                    </Typography>
                </Grid>
                <TextField
                    placeholder={
                        settlementInfo.isNotPercentageCommission
                            ? (t("quest.percentageWithPlaceholder") as string)
                            : ""
                    }
                    value={
                        settlementInfo.isNotPercentageCommission
                            ? (t("status.deactivate") as string)
                            : settlementInfo.commission
                    }
                    onChange={(e) =>
                        setSettlementInfo((prev) => {
                            return { ...prev, commission: e.target.value };
                        })
                    }
                    disabled={settlementInfo.isNotPercentageCommission}
                />
                <Grid mt={3} mb={1}>
                    <Typography variant="f16h20B">
                        {t("quest.finalFee")}
                    </Typography>
                </Grid>
                <TextField
                    disabled={!settlementInfo.isNotPercentageCommission}
                    placeholder={t("quest.inputFinalFee") as string}
                    value={`${
                        settlementInfo.isNotPercentageCommission
                            ? settlementInfo.finalFee
                            : computeFinalFee(
                                  settlementInfo.exportFee,
                                  settlementInfo.commission
                              )
                    }`}
                    onChange={(e) =>
                        setSettlementInfo((prev) => {
                            return { ...prev, finalFee: e.target.value };
                        })
                    }
                />
                <Grid mt={3} mb={1} container>
                    <Typography variant="f18h20B">
                        {t("quest.verifyingDocument")}
                    </Typography>
                    <Typography
                        variant="f12h20"
                        color={"mono.600"}
                        sx={{ ml: 1 }}
                    >
                        {t("quest.verifyingDocumentHelpText")}
                    </Typography>
                </Grid>
                <FileUploader onUpload={setProofFile} />

                <Grid container justifyContent={"flex-end"} mt={3}>
                    <Button
                        fullWidth={isMobile}
                        variant="contained"
                        sx={{
                            height: "34px",
                        }}
                        onClick={async () => {
                            if (
                                window.confirm(
                                    t("quest.askSettlementMessage") as string
                                )
                            ) {
                                await sendSettlement();
                                setOpenCostModal(false);
                            }
                        }}
                    >
                        <Typography variant="f16h20B">
                            {t("status.complete")}
                        </Typography>
                    </Button>
                </Grid>
            </ModalDialog>

            <ModalDialog
                isMobile={isMobile}
                title={"관리자 공지 추가"}
                open={openModal}
                onClose={onCloseModal}
            >
                <TextField
                    placeholder="공지를 입력해주세요"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    helperText={"공지는 게시 후 수정/삭제가 불가합니다"}
                />

                <Grid container justifyContent={"flex-end"} mt={3}>
                    <Button
                        fullWidth={isMobile}
                        variant="contained"
                        sx={{
                            height: "34px",
                        }}
                        onClick={async () => {
                            if (window.confirm("공지를 게시하시겠습니까?")) {
                                await onPostAnouncement();
                                onCloseModal();
                            }
                        }}
                    >
                        <Typography variant="f16h20B">게시</Typography>
                    </Button>
                </Grid>
            </ModalDialog>
        </>
    );
};

export default AnouncementSection;
