import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./en";
import kr from "./kr";

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: { kr, en },
        fallbackLng: ["kr", "en"],
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
