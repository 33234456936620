import React from "react";

import { Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { QuestFormTypes } from "../../../api-interfaces/quest";
import { FontWeightValues } from "../../../types/fontWeightTypes";

export interface NoReportSectionProps {
    isMobile: boolean;
    reqType: QuestFormTypes;
}

const NoReportSection: React.FC<NoReportSectionProps> = ({
    isMobile,
    reqType,
}) => {
    const { t } = useTranslation();
    return (
        <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            height={"100%"}
        >
            <img
                src={`/images/icon/${
                    reqType === QuestFormTypes.CONSULTING
                        ? "consulting_chat_grey.svg"
                        : "estimate_sheet.svg"
                }`}
                width={isMobile ? 48 : 80}
            />
            <Grid mt={4} sx={{ textAlign: "center" }}>
                <Typography
                    variant={isMobile ? "f18h26B" : "f48h64EB"}
                    fontWeight={FontWeightValues.REGULAR}
                    color="mono.600"
                >
                    {reqType === QuestFormTypes.CONSULTING
                        ? t("quest.waitingForConsulting")
                        : t("quest.watingForEstimate")}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default NoReportSection;
