import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { signoutUser } from "../../apis/user";

interface DropDownNavProps {
    contents?: { text: string; href: string }[];
    buttonComponent: (isOpen: boolean) => React.ReactNode;
    login?: boolean;
}

const DropDownNav: React.FC<DropDownNavProps> = ({
    contents = [],
    buttonComponent,
    login = true,
}) => {
    // const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const navigate = useNavigate();
    const { t } = useTranslation();
    const signout = () => {
        try {
            signoutUser();
            window.location.replace("/");
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <Box onClick={handleClick}>{buttonComponent(open)}</Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        "& .MuiAvatar-root": {
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            width: 32,
                        },
                        border: "solid 1px #000",
                        borderRadius: 0,
                        // boxShadow: "2px 4px 12px 0px rgba(0,0,0,0.09)",
                        mt: 1,
                    },
                }}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
                {contents.map((content) => (
                    <MenuItem
                        key={`dropdown-${content.href}`}
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            p: 3,
                            pb: 1.5,
                            pt: 1.5,
                        }}
                        onClick={() => navigate(content.href)}
                    >
                        <Typography variant="f14h20">{content.text}</Typography>
                    </MenuItem>
                ))}
                {/* Attach signout as the last item */}
                {login ? (
                    <MenuItem
                        onClick={signout}
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            p: 3,
                            pb: 1.5,
                            pt: 1.5,
                        }}
                    >
                        <Typography variant="f14h20">
                            {t("nav.logout")}
                        </Typography>
                    </MenuItem>
                ) : (
                    <></>
                )}
            </Menu>
        </>
    );
};

export default DropDownNav;
