import React from "react";

import { Theme, Box, Button } from "@mui/material";
import { SxProps } from "@mui/system";

import NanumL from "../typography/NanumL";

interface SolidTextButtonWithColorProps {
    text: string;
    image?: string;
    onClick?: () => void;
    sx?: SxProps<Theme>;
    color?: string;
    size?: number;
}

const SolidTextButtonWithColor: React.FC<SolidTextButtonWithColorProps> = ({
    text,
    image,
    onClick,
    sx,
    color,
    size = 18,
}) => {
    return (
        <Box
            display={"flex"}
            sx={{
                border: "solid 1px",
                height: 50,
                width: 215,
                ...sx,
            }}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Button onClick={onClick} sx={{ width: "100%", height: "100%" }}>
                <Box display={"flex"} pr={image ? 2 : 0}>
                    <NanumL
                        size={size}
                        color={color ? color : "black"}
                        text={text}
                    />
                </Box>
                {image ? (
                    <Box display={"flex"}>
                        <img src={image} />
                    </Box>
                ) : (
                    <></>
                )}
            </Button>
        </Box>
    );
};

export default SolidTextButtonWithColor;
