import { ChatMessage } from "../api-interfaces/chat";
import { QuestFormTypes, QuestModel } from "../api-interfaces/quest";
import { EstimateReport } from "../types/requestFormTypes";

import { formatDateDotToSerial } from "./format";

export const sampleReqInfo = (req: QuestModel) => [
    {
        title: "의뢰번호",
        content: `${formatDateDotToSerial(req.date)}-${req._id}`,
    },
    { title: "유형", content: req.type },
    { title: "산업군", content: req.proposal[0] },
    { title: "구분", content: req.proposal[1] },
    { title: "연매출", content: req.proposal[2] },
    { title: "직원수", content: req.proposal[3] },
    { title: "기업명", content: req.companyName },
    { title: "요청사항", content: req.proposal[7] },
    {
        title:
            req.type === QuestFormTypes.EXPORT
                ? "수출희망금액"
                : req.type === QuestFormTypes.IMPORT
                ? "수입희망금액"
                : "컨설팅희망금액",
        content: req.proposal[8],
    },
    { title: "요청 일자", content: req.date },
    // { title: "마감 일자", content: "22.11.23" },
];

export const sampleEstimateReports: EstimateReport[] = [
    {
        profile: {
            userId: 111,
            name: "Se Hyun Kim",
            image: "/images/ambassadors/headphoto/1.png",
            subtitle: "1인 무역 기업",
        },
        reportInfo: {
            beginDate: "2022년 7월 3일",
            feeRate: "10%",
            detailInfo: "상세정보",
        },
    },
    {
        profile: {
            userId: 222,
            name: "Chang Gyu Kim",
            image: "/images/ambassadors/headphoto/2.png",
            subtitle: "Personal Trade",
        },
        reportInfo: {
            beginDate: "2022년 7월 3일",
            feeRate: "10%",
            detailInfo: "상세정보",
        },
    },
    {
        profile: {
            userId: 333,
            name: "Se Hyun Kim",
            image: "/images/ambassadors/headphoto/1.png",
            subtitle: "1인 무역 기업",
        },
        reportInfo: {
            beginDate: "2022년 7월 3일",
            feeRate: "10%",
            detailInfo: "상세정보",
        },
    },
    {
        profile: {
            userId: 444,
            name: "Chang Gyu Kim",
            image: "/images/ambassadors/headphoto/2.png",
            subtitle: "Personal Trade",
        },
        reportInfo: {
            beginDate: "2022년 7월 3일",
            feeRate: "10%",
            detailInfo: "상세정보",
        },
    },
    {
        profile: {
            userId: 555,
            name: "Se Hyun Kim",
            image: "/images/ambassadors/headphoto/1.png",
            subtitle: "1인 무역 기업",
        },
        reportInfo: {
            beginDate: "2022년 7월 3일",
            feeRate: "10%",
            detailInfo: "상세정보",
        },
    },
    {
        profile: {
            userId: 666,
            name: "Chang Gyu Kim",
            image: "/images/ambassadors/headphoto/2.png",
            subtitle: "Personal Trade",
        },
        reportInfo: {
            beginDate: "2022년 7월 3일",
            feeRate: "10%",
            detailInfo: "상세정보",
        },
    },
];

export const sampleExpertInfo = [
    { title: "이름", content: "석상필" },
    { title: "연락처", content: "010-1234-5678" },
    { title: "이메일", content: "sangpil.suk@example.com" },
    { title: "착수일자", content: "2022.11.30" },
    { title: "수수료율", content: "10%" },
];

export const sampleMangerAnouncements = [
    { content: "대금 수령일 확인 필요", date: "22.08.24", isRead: false },
    { content: "계약서 초안 검토해주세요", date: "22.08.25", isRead: false },
    { content: "1차 샘플 전달 완료", date: "22.08.25", isRead: true },
];

export const sampleChatList: ChatMessage[] = [
    {
        from: "1",
        time: "10:20",
        content: "요청주신 카탈로그 영문버전 첨부합니다.",
    },
    {
        from: "1",
        time: "10:20",
        content: "",
        src: "/images/logo/logo.png",
    },
    {
        from: "222",
        time: "10:23",
        content:
            "확인되었습니다. 바이어 측에 카탈로그 전달 후, 다시 연락드리겠습니다.",
    },
    {
        from: "1",
        time: "10:24",
        content: "네 감사합니다",
    },

    {
        from: "222",
        time: "16:24",
        content:
            "카탈로그 영문 버전을 바이어 측에 보냈고, 무상 샘플 1개 요청 받았습니다",
    },
    {
        from: "1",
        time: "16:30",
        content:
            "무상 샘플 1개 제공 가능합니다.\n자세한 내용은 이메일을 참고해주세요.",
    },
    {
        from: "222",
        time: "16:42",
        content:
            "바이어 측에서 샘플 만족하고, 연간 물량 논의 및 계약 체결 희망하니 계약서 초안 검토해주세요",
    },
    {
        from: "222",
        time: "16:42",
        content: "로고 이미지.png",
        src: "/images/logo/logo.png",
    },
];

export const sampleReqCards: QuestModel[] = [
    {
        _id: "1",
        type: QuestFormTypes.EXPORT,
        level: 7,
        companyName: "(주)제로클래스랩",
        date: "2022.08.24",
        productName: "중고 농업용 트랙터",
        clientID: "1",
        proposal: ["", ""],
    },
    // {
    //     id: "2",
    //     type: "수출",
    //     level: 5,
    //     companyName: "(주)제로클래스랩",
    //     date: "2022.08.24",
    //     productName: "중고 농업용 트랙터",
    // },
    // {
    //     id: "3",
    //     type: "수출",
    //     level: 3,
    //     companyName: "(주)제로클래스랩",
    //     date: "2022.08.24",
    //     productName: "중고 농업용 트랙터",
    // },
    // {
    //     id: "4",
    //     type: "수입",
    //     level: 2,
    //     companyName: "(주)제로클래스랩",
    //     date: "2022.08.26",
    //     productName: "아스팔트 피니셔 XC-580",
    // },
    // {
    //     id: "5",
    //     type: "컨설팅",
    //     companyName: "(주)제로클래스랩",
    //     date: "2022.08.29",
    //     title: "안녕하세요. 로터리 조립을 위해 들어가는 볼트를 소싱하고 싶습니다.",
    // },
    // {
    //     id: "6",
    //     type: "수출",
    //     level: 2,
    //     companyName: "(주)제로클래스랩",
    //     date: "2022.08.26",
    //     productName: "크롤러 굴삭기 R3000LC-7",
    // },
];
