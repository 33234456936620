import { atom } from "recoil";

import { ChatMessage, ChatParsed } from "../../api-interfaces/chat";
import { QuestFormTypes } from "../../api-interfaces/quest";
import { ParsedUser } from "../../api-interfaces/user";
import { navThemeValues } from "../../types/navThemeTypes";

export const isUserLoadedRecoil = atom({
    default: false,
    key: "isUserLoadedRecoil",
});

export const userRecoil = atom<ParsedUser | undefined>({
    default: undefined,
    // default: {
    //     userID: 1,
    //     nickname: "김트포",
    //     profileImage:
    //         "https://fdopportunities.com/wp-content/uploads/2019/12/fdo-bsherman-480x480.jpg",
    //     role: "전문가",
    //     contact: "010-1234-5678",
    //     companyName: "트레이드포스",
    //     companyAddr: "종로구 부암동 창의문로 146",
    //     businessNum: "000-00-00000",
    // },
    key: "userRecoil",
});

export const navThemeRecoil = atom<navThemeValues>({
    default: navThemeValues.WHITE,
    key: "navThemeRecoil",
});

// Admin
export const renderUpdateComponentRecoil = atom<boolean>({
    default: false,
    key: "renderUpdateComponentRecoil",
});

export const currentChatMessageListRecoil = atom<ChatMessage[]>({
    default: [],
    key: "currentChatMessageList",
});

export const currentAdminAllCrossChatsRecoil = atom<ChatParsed[]>({
    default: [],
    key: "currentAdminAllCrossChatsRecoil",
});

// request type initialValue

export const currentQuestFormTypeRecoil = atom<QuestFormTypes>({
    default: QuestFormTypes.EXPORT,
    key: "currentQuestFormTypeRecoil",
});
