import React from "react";

import { useRecoilValue } from "recoil";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Avatar,
    Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { UserRole } from "../../api-interfaces/user";
import { signoutUser } from "../../apis/user";
import { FontWeightValues } from "../../types/fontWeightTypes";
import { DEFAULT_PROFILE_IMG_PATH } from "../../utils/consts";
import { userRecoil } from "../states/recoil";

import LangDropdown from "./LangDropdown";
import Logo from "./Logo";

export interface DrawerMenuProps {
    open: boolean;
    onClose: () => void;
}

const DrawerMenu: React.FC<DrawerMenuProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const userData = useRecoilValue(userRecoil);
    const navList = [
        {
            href:
                userData?.role === UserRole.EXPERT
                    ? "/requests"
                    : "/service-request",
            text:
                userData?.role === UserRole.EXPERT
                    ? t("nav.exploreRequests")
                    : t("nav.serviceRequest"),
        },
        {
            href: "/service",
            text: t("nav.serviceIntroduction"),
        },
        {
            href: "/company-info",
            text: t("nav.companyIntroduction"),
        },
        {
            href: "/ambassador",
            text: t("nav.ambassador"),
        },
        ...(userData &&
        (userData.role === UserRole.GUEST ||
            userData.role === UserRole.UNAPPROVED_EXPERT)
            ? [
                  {
                      href: "/verification",
                      text: t("nav.verification"),
                  },
              ]
            : []),
    ];
    const loginNavList = [
        {
            href: "/login",
            text: t("nav.login"),
        },
        {
            href: "/signup",
            text: t("nav.signup"),
        },
    ];

    const signout = () => {
        try {
            signoutUser();
            window.location.replace("/");
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Drawer anchor={"right"} open={open} onClose={onClose}>
            <Grid p={1} pl={3} onClick={onClose}>
                <Logo inverse />
            </Grid>
            <Grid
                container
                flexDirection={"column"}
                justifyContent={"space-between"}
                height={"100%"}
                onClick={onClose}
            >
                <List sx={{ width: 270 }}>
                    {navList.map((nav, idx) =>
                        userData?.role === UserRole.ADMIN && idx === 0 ? (
                            <></>
                        ) : (
                            <ListItem key={`drawer-nav-${nav.text}`}>
                                <ListItemButton
                                    href={
                                        userData?.role || idx !== 0
                                            ? nav.href
                                            : "/signup"
                                    }
                                >
                                    <Typography
                                        variant="f18h20B"
                                        sx={{
                                            fontWeight:
                                                FontWeightValues.REGULAR,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {nav.text}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                    <Accordion
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            pl: 2,
                            boxShadow: "none",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                variant="f18h20B"
                                sx={{
                                    fontWeight: FontWeightValues.REGULAR,
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {t("nav.community")}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItem>
                                <ListItemButton href={"/community"}>
                                    <Typography
                                        variant="f18h20B"
                                        sx={{
                                            fontWeight:
                                                FontWeightValues.REGULAR,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {t("nav.community")}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton href={"/bulletinboard"}>
                                    <Typography
                                        variant="f18h20B"
                                        sx={{
                                            fontWeight:
                                                FontWeightValues.REGULAR,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {t("nav.freeboard")}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton href={"/company"}>
                                    <Typography
                                        variant="f18h20B"
                                        sx={{
                                            fontWeight:
                                                FontWeightValues.REGULAR,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        TF.com
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        </AccordionDetails>
                    </Accordion>

                    {userData && (
                        <>
                            {userData.role === UserRole.ADMIN && (
                                <ListItem>
                                    <ListItemButton href="/admin">
                                        <Typography
                                            variant="f18h20B"
                                            sx={{
                                                fontWeight:
                                                    FontWeightValues.REGULAR,
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            관리페이지
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            )}
                            <Divider />
                            <ListItem>
                                <ListItemButton onClick={signout}>
                                    <Typography
                                        variant="f18h20B"
                                        sx={{
                                            fontWeight:
                                                FontWeightValues.REGULAR,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {t("nav.logout")}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        </>
                    )}
                </List>
                <List sx={{ width: 250 }}>
                    {userData ? (
                        <ListItem>
                            <ListItemButton href="/mypage">
                                <Avatar
                                    src={
                                        userData.image ||
                                        DEFAULT_PROFILE_IMG_PATH
                                    }
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        mr: 1,
                                    }}
                                />
                                <Typography
                                    variant="f18h20B"
                                    sx={{
                                        fontWeight: FontWeightValues.REGULAR,
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {userData.nickname}
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                    ) : (
                        loginNavList.map((nav) => (
                            <ListItem key={`drawer-nav-${nav.text}`}>
                                <ListItemButton href={nav.href}>
                                    <Typography
                                        variant="f18h20B"
                                        sx={{
                                            fontWeight:
                                                FontWeightValues.REGULAR,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {nav.text}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        ))
                    )}
                    <ListItem sx={{ height: 52 }}>
                        <LangDropdown color={"black"} list />
                    </ListItem>
                </List>
            </Grid>
        </Drawer>
    );
};

export default DrawerMenu;
