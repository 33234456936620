import React from "react";

import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { Tradefirm } from "../../api-interfaces/tradefirm";

import ForwardedSlideUpTransition from "./ForwardedSlideUpTransition";
import ModalDialog from "./ModalDialog";

interface DetailModalProps {
    isOpen: boolean;
    onClose: () => void;
    isMobile: boolean;
    data: Tradefirm;
}

const DetailModal: React.FC<DetailModalProps> = ({
    isOpen,
    onClose,
    isMobile,
    data,
}) => {
    const { t } = useTranslation();
    return (
        <ModalDialog
            isMobile={isMobile}
            TransitionComponent={ForwardedSlideUpTransition}
            open={isOpen}
            title={""}
            onClose={() => onClose()}
            themeMode={"Dark"}
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    p: 0,
                    position: "absolute",
                    top: 40,
                    pl: 2,
                }}
            >
                <Box display={"flex"}>
                    <Typography variant={"f24h36B"}>
                        {data.companyName}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent
                tabIndex={-1}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    outline: "none",
                    p: 0,
                    width: "100%",
                }}
            >
                <Grid
                    container
                    mb={3}
                    flexDirection={"column"}
                    ml={2}
                    width={"auto"}
                    justifyContent={"space-between"}
                >
                    <Grid container justifyContent={"space-between"}>
                        <Grid
                            display={"flex"}
                            sx={{ flexDirection: isMobile ? "column" : "row" }}
                            justifyContent={"flex-start"}
                        >
                            <Grid
                                display={"flex"}
                                flexDirection={"column"}
                                width={isMobile ? 150 : 250}
                                minWidth={150}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"flex-end"}
                                    pt={2}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("tradefirm.companyName")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.companyName}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("tradefirm.exportingItem")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.exportItem}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("tradefirm.bestExportingItem")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.proudExportItem}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t(
                                                "tradefirm.desirableSourcingItemSet"
                                            )}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.desirableSourcingItemSet}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                display={"flex"}
                                flexDirection={"column"}
                                pl={isMobile ? undefined : 5}
                                justifyContent={"flex-start"}
                                width={isMobile ? 150 : 250}
                                minWidth={150}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    pt={2}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("tradefirm.mainDealingItem")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.mainDealingItem}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("tradefirm.homepage")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.homepage}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("tradefirm.companyForm")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.companyForm}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        pt={2}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                fontColor: "#AAAAAA",
                                            }}
                                        >
                                            {t("tradefirm.exportingNations")}
                                        </Typography>
                                        <Typography variant={"f16h20B"}>
                                            {data.exportingNations}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"flex-end"}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            pt={2}
                            width={800}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontColor: "#AAAAAA",
                                }}
                            >
                                {t("tradefirm.companyIntroduction")}
                            </Typography>
                            <Typography variant={"f16h20B"}>
                                {data.companyIntroduction}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{ display: "flex", height: 60, padding: 0, pt: 3 }}
            ></DialogActions>
        </ModalDialog>
    );
};

export default DetailModal;
