import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { ChevronRight } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { ChatParsed } from "../../../api-interfaces/chat";
import { FontWeightValues } from "../../../types/fontWeightTypes";
import { DEFAULT_PROFILE_IMG_PATH } from "../../../utils/consts";
import ModalDialog from "../../modals/ModalDialog";
import InfoList from "../../tables/InfoList";

export interface RequestExpertCardProps {
    isMobile: boolean;
    report: ChatParsed;
    isSelected: boolean;
    onSelectCard: () => void;
}

const RequestExpertCard: React.FC<RequestExpertCardProps> = ({
    isMobile,
    report,
    isSelected = false,
    onSelectCard,
}) => {
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Grid container columnSpacing={2.5} mb={3}>
                <Grid item xs={isMobile ? 12 : 3}>
                    <Grid height={"100%"} sx={{ position: "relative" }}>
                        <img
                            src={
                                report.expertInfo.image ||
                                DEFAULT_PROFILE_IMG_PATH
                            }
                            width={"100%"}
                            height={"100%"}
                            style={{
                                objectFit: "cover",
                                aspectRatio: isMobile ? "4 / 3" : "auto",
                                cursor: "pointer",
                            }}
                            onClick={() => setOpenInfoModal(true)}
                        />
                        <Grid
                            sx={{
                                position: "absolute",
                                left: 30,
                                bottom: 36,
                            }}
                        >
                            <Typography variant="f20h28EB" color={"mono.50"}>
                                {report.expertInfo.nickname}
                            </Typography>
                            <Grid>
                                <Typography
                                    variant="f18h26B"
                                    color={"mono.50"}
                                    fontWeight={"regular"}
                                >
                                    {report.expertInfo.subtitle}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={isMobile ? 12 : 9}>
                    <Grid
                        sx={{
                            bgcolor: isMobile ? "#FAFAFA" : "#FFF",
                            border: "solid 1px #AAAAAA",
                            p: 3.5,
                            position: "relative",
                        }}
                    >
                        <Grid
                            sx={{
                                position: isMobile ? "static" : "absolute",
                                right: "28px",
                                top: "28px",
                            }}
                        >
                            <Grid
                                container
                                flexDirection={isMobile ? "row" : "column"}
                                alignItems={"flex-end"}
                                justifyContent={"space-between"}
                                mb={3.5}
                            >
                                <Button
                                    variant={
                                        isSelected ? "contained" : "outlined"
                                    }
                                    sx={{
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        height: "34px",
                                    }}
                                    onClick={onSelectCard}
                                >
                                    <Typography
                                        variant="f14h20"
                                        fontWeight={"bold"}
                                    >
                                        {t("status.select")}
                                    </Typography>
                                </Button>

                                <Button
                                    variant="outlined"
                                    sx={{
                                        mt: isMobile ? 0 : 1,
                                        borderColor: "#1D1D1D",
                                        height: "34px",
                                    }}
                                    onClick={() =>
                                        navigate(
                                            `${window.location.pathname}?chat=${report._id}`
                                        )
                                    }
                                >
                                    <Typography
                                        variant="f14h20"
                                        fontWeight={"bold"}
                                    >
                                        {t("status.chat")}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid mb={3}>
                            <Typography variant="f18h26B">
                                {t("quest.expectedEstimateInfo")}
                            </Typography>
                        </Grid>
                        <InfoList
                            data={[
                                {
                                    title: t("quest.proposedDate"),
                                    content: report.reportInfo.beginDate,
                                },
                                {
                                    title: t("quest.commissionFee"),
                                    content: `${report.reportInfo.feeRate}`,
                                },
                            ]}
                        />
                        <Grid>
                            <Grid mb={1}>
                                <Typography variant="f14h20" color={"mono.600"}>
                                    {t("quest.estimateDetail")}
                                </Typography>
                            </Grid>
                            <TextField
                                multiline
                                rows={5}
                                sx={{
                                    bgcolor: "#FFF",
                                    "& .MuiOutlinedInput-root": {
                                        height: "132px",
                                        fontSize: "14px",
                                        lineHeight: 1.43,
                                        fontWeight: 600,
                                    },
                                }}
                                value={report.reportInfo.detailInfo}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* NOTE why hard coded? */}
            <ModalDialog
                isMobile={isMobile}
                title={report.expertInfo.nickname}
                open={openInfoModal}
                onClose={() => setOpenInfoModal(false)}
                themeMode={"Dark"}
            >
                <Grid mb={3}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">경력</Typography>
                    </Grid>
                    <Typography
                        variant="f20h28EB"
                        sx={{
                            fontWeight: FontWeightValues.REGULAR,
                            wordBreak: "break-all",
                        }}
                    >
                        {"20년 이상"}
                    </Typography>
                </Grid>
                <Grid mb={3}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">컨설팅 부문</Typography>
                    </Grid>
                    <Typography
                        variant="f20h28EB"
                        sx={{
                            fontWeight: FontWeightValues.REGULAR,
                            wordBreak: "break-all",
                        }}
                    >
                        {"기계 산업 포함 B2B 산업용 전반"}
                    </Typography>
                </Grid>
                <Grid mb={3}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">전문 지역</Typography>
                    </Grid>
                    <Typography
                        variant="f20h28EB"
                        sx={{
                            fontWeight: FontWeightValues.REGULAR,
                            wordBreak: "break-all",
                        }}
                    >
                        {"북미,유럽 등"}
                    </Typography>
                </Grid>
                <Grid mb={3}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">
                            누적 거래 금액
                        </Typography>
                    </Grid>
                    <Typography
                        variant="f20h28EB"
                        sx={{
                            fontWeight: FontWeightValues.REGULAR,
                            wordBreak: "break-all",
                        }}
                    >
                        {"100 억 이상"}
                    </Typography>
                </Grid>
                <Button color="secondary">
                    자세히 보기 <ChevronRight />
                </Button>
            </ModalDialog>
        </>
    );
};

export default RequestExpertCard;
