import React from "react";

import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";

import { Color } from "@mui/material";
import { LinkProps } from "@mui/material/Link";
import { createTheme } from "@mui/material/styles";
import {
    ColorPartial,
    PaletteColor,
    PaletteColorOptions,
} from "@mui/material/styles/createPalette";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TypographyPropsVariantOverrides } from "@mui/material/Typography";

import { FontWeightValues } from "./types/fontWeightTypes";

/**
 * 스타일 타입 디클레어
 */
declare module "@mui/material/styles" {
    interface TypographyVariants {
        f80h100B: React.CSSProperties;
        f64h84EB: React.CSSProperties;
        f48h64EB: React.CSSProperties;
        f48h64R: React.CSSProperties;
        f40h50B: React.CSSProperties;
        f32h40R: React.CSSProperties;
        f32h40EB: React.CSSProperties;
        f24h36EB: React.CSSProperties;
        f24h36B: React.CSSProperties;
        f20h28EB: React.CSSProperties;
        f20h28B: React.CSSProperties;
        f20h28L: React.CSSProperties;
        f18h26EB: React.CSSProperties;
        f18h26B: React.CSSProperties;
        f18h26: React.CSSProperties;
        f18h26L: React.CSSProperties;
        /**
         * 인풋필드 안 텍스트
         */
        f18h20B: React.CSSProperties;
        f16h24: React.CSSProperties;
        f16h24B: React.CSSProperties;
        /**
         * 텍스트 파일첨부 채팅창
         */
        f16h20B: React.CSSProperties;
        f14h20EB: React.CSSProperties;
        f14h20B: React.CSSProperties;
        f14h20: React.CSSProperties;
        f14h20L: React.CSSProperties;
        f12h20EB: React.CSSProperties;
        f12h20B: React.CSSProperties;
        f12h20: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        f80h100B?: React.CSSProperties;
        f64h84EB?: React.CSSProperties;
        f48h64EB?: React.CSSProperties;
        f48h64R?: React.CSSProperties;
        f40h50B?: React.CSSProperties;
        f32h40R?: React.CSSProperties;
        f32h40EB?: React.CSSProperties;
        f24h36EB?: React.CSSProperties;
        f24h36B?: React.CSSProperties;
        f20h28EB?: React.CSSProperties;
        f20h28B?: React.CSSProperties;
        f20h28L?: React.CSSProperties;
        f18h26EB?: React.CSSProperties;
        f18h26B?: React.CSSProperties;
        f18h26?: React.CSSProperties;
        f18h26L?: React.CSSProperties;
        /**
         * 인풋필드 안 텍스트
         */
        f18h20B?: React.CSSProperties;
        f16h24?: React.CSSProperties;
        f16h24B?: React.CSSProperties;
        /**
         * 텍스트 파일첨부 채팅창
         */
        f16h20B?: React.CSSProperties;
        f14h20EB?: React.CSSProperties;
        f14h20B?: React.CSSProperties;
        f14h20?: React.CSSProperties;
        f14h20L?: React.CSSProperties;
        f12h20EB?: React.CSSProperties;
        f12h20B?: React.CSSProperties;
        f12h20?: React.CSSProperties;
    }

    interface Palette {
        mono: Color;
        pGreen: PaletteColor;
        pBlue: PaletteColor;
        pRed?: PaletteColor;
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        mono?: ColorPartial;
        pGreen?: PaletteColorOptions;
        pBlue?: PaletteColorOptions;
        pRed?: PaletteColorOptions;
    }
}

/**
 * 버튼 에서 허용
 */
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        mono: true;
        pGreen?: true;
        pBlue?: true;
        pRed?: true;
    }
}

/**
 * 텍스트 필드에서 허용
 */
declare module "@mui/material/TextField" {
    interface TextFieldPropsColorOverrides {
        mono: true;
        pGreen?: true;
        pBlue?: true;
        pRed?: true;
    }
}
/**
 * 타이포그래피에서 허용
 */
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        f80h100B: true;
        f64h84EB: true;
        f48h64EB: true;
        f48h64R: true;
        f32h40EB: true;
        f40h50B: true;
        f32h40R: true;
        f24h36EB: true;
        f24h36B: true;
        f20h28EB: true;
        f20h28B: true;
        f20h28L: true;
        f18h26EB: true;
        f18h26B: true;
        f18h26: true;
        f18h26L: true;
        /**
         * 인풋필드 안 텍스트
         */
        f18h20B: true;
        f16h24: true;
        f16h24B: true;
        /**
         * 텍스트 파일첨부 채팅창
         */
        f16h20B: true;
        f14h20EB: true;
        f14h20B: true;
        f14h20: true;
        f14h20L: true;
        f12h20EB: true;
        f12h20B: true;
        f12h20: true;

        mono: true;
        pGreen?: true;
        pBlue?: true;
        pRed?: true;
    }
    interface TypographyPropsColorOverrides {
        mono: true;
    }

    interface TypographyPropsFont {
        nanum: true;
        cocos: true;
    }
}

const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

export const mobileWidth = 700;
export const tabletWidth = 960;
export const tasktopWidth = 1040;
export const mobileMaxWidthMediaQuery = `@media (max-width:${mobileWidth}px)`;
export const tabletMaxWidthMediaQuery = `@media (max-width:${tabletWidth}px)`;
export const tasktopMaxWidthMediaQuery = `@media (max-width:${tabletWidth}px)`;

export const generateFontStyles = (
    fontSize: number,
    fontWeight: FontWeightValues = FontWeightValues.REGULAR
) => {
    return {
        fontSize,
        fontWeight,
    };
};

const theme = createTheme({
    palette: {
        primary: {
            main: "#1D1D1D",
        },
        secondary: {
            main: "#FFF",
        },
        mono: {
            800: "#1D1D1D",
            600: "#AAAAAA",
            500: "#DDDDDD",
            400: "#F0F0F0",
            300: "#F3F3F4",
            200: "#FAFAFA",
            50: "#FFFFFF",
        },
        pGreen: {
            main: "#2DB961",
            light: "#EAF8EF",
        },
        pBlue: {
            main: "#568FFC",
            light: "#EEF4FF",
        },
        pRed: {
            main: "#FF4242",
        },
    },

    typography: {
        fontFamily: ["나눔스퀘어", "NanumSquare", '"sans-serif"'].join(","),
        f80h100B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "80px",
            lineHeight: 1.2,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f64h84EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "64px",
            lineHeight: 1.3,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f48h64EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "48px",
            lineHeight: 1.33,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f48h64R: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "48px",
            lineHeight: 1.33,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f40h50B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "40px",
            lineHeight: 1.25,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f32h40R: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "32px",
            lineHeight: 1.25,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f32h40EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "32px",
            lineHeight: 1.25,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f24h36EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "24px",
            lineHeight: 1.33,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f24h36B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "24px",
            lineHeight: 1.33,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f20h28EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "20px",
            lineHeight: 1.4,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f20h28B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "20px",
            lineHeight: 1.4,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f20h28L: {
            fontWeight: FontWeightValues.LIGHT,
            fontSize: "20px",
            lineHeight: 1.4,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f18h26EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "18px",
            lineHeight: 1.45,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f18h26B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "18px",
            lineHeight: 1.45,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f18h26: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "18px",
            lineHeight: 1.45,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f18h20B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "18px",
            lineHeight: 1.1,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f16h24: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "16px",
            lineHeight: 1.5,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f16h24B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "16px",
            lineHeight: 1.5,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f16h20B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "16px",
            lineHeight: 1.25,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f14h20EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "14px",
            lineHeight: 1.43,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f14h20B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "14px",
            lineHeight: 1.43,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f14h20: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "14px",
            lineHeight: 1.43,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f14h20L: {
            fontWeight: FontWeightValues.LIGHT,
            fontSize: "14px",
            lineHeight: 1.43,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f12h20EB: {
            fontWeight: FontWeightValues.EXTRA_BOLD,
            fontSize: "12px",
            lineHeight: 1.66,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f12h20B: {
            fontWeight: FontWeightValues.BOLD,
            fontSize: "12px",
            lineHeight: 1.66,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
        f12h20: {
            fontWeight: FontWeightValues.REGULAR,
            fontSize: "12px",
            lineHeight: 1.66,
            letterSpacing: "-0.02em",
            wordBreak: "keep-all",
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: "48px",
                    borderRadius: 0,
                    boxShadow: "none",
                    textTransform: "none",
                    fontWeight: FontWeightValues.BOLD,
                    fontSize: "18px",
                    lineHeight: 1.1,
                    letterSpacing: "-0.02em",
                    wordBreak: "keep-all",
                    "&:hover": {
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                inputProps: { sx: { p: 1.5 } },
            },
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        backgroundColor: "#FFF",
                        height: "48px",
                        borderRadius: 0,
                        "& fieldset": {
                            borderColor: "#888888",
                        },
                        fontWeight: FontWeightValues.BOLD,
                        fontSize: "18px",
                        lineHeight: 1.1,
                        letterSpacing: "-0.02em",
                        wordBreak: "keep-all",
                        "& ::placeholder": {
                            color: "#888",
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "black",
                    borderRadius: 0,
                    padding: "15px",
                    "& .MuiTooltip-arrow": {
                        color: "black",
                    },
                },
            },
        },
    },
});

export default theme;
