import React from "react";

import { Box, Grid } from "@mui/material";

interface BlackBoxModalProps {
    children?: JSX.Element;
    onClose?: () => void;
}

const BlackBoxModal: React.FC<BlackBoxModalProps> = ({ children, onClose }) => {
    return (
        <>
            <Grid
                item
                position={"absolute"}
                sx={{
                    width: 600,
                    height: 384,
                    color: "black",
                    bottom: -400,
                    zIndex: 999,
                }}
            >
                <Box
                    display={"flex"}
                    position={"absolute"}
                    right={0}
                    p={2}
                    onClick={onClose}
                >
                    <img src={"./images/icon/x-close_white.svg"} />
                </Box>
                {children}
            </Grid>
        </>
    );
};

export default BlackBoxModal;
