import React from "react";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import {
    CommunityDataType,
    CommunityTileContainerType,
} from "../../interfaces/community/communityDataType";

import HoveringWhiteMask from "./HoveringWhiteMask";

interface NewsTileAllProps {
    data: CommunityDataType;
    isMobile: boolean;
    /**
     * `main`: 랜딩페이지 큰거
     * `sub`: 랜딩페이지 작은거
     * `sub2`: 랜딩페이지 작은거 두번째
     * `default`: 커뮤니티 페이지
     */
    containerType?: CommunityTileContainerType;
}

const MAIN_TILE_HEIHGT = 500;
const MAIN_TILE_HEIHGT_MO = 335;
const SUB_TILE_HEIHGT = 240;
const SUB_TILE_HEIHGT_MO = 188;
const DEFAULT_TILE_HEIGHT = 225;

/**
 * 커뮤니티 섹션에서 보이는 타일들
 */
const NewsTileAll: React.FC<NewsTileAllProps> = ({
    isMobile,
    data: { text, from, image, link },
    containerType = "default",
}) => {
    if (!image) {
        return (
            <Box
                position={"relative"}
                sx={
                    containerType === "default"
                        ? {
                              display: "flex",
                              height: isMobile
                                  ? SUB_TILE_HEIHGT_MO
                                  : DEFAULT_TILE_HEIGHT,
                          }
                        : containerType === "main"
                        ? {
                              cursor: link ? "pointer" : "auto",
                              width: isMobile ? "100%" : "50%",
                              height: isMobile
                                  ? MAIN_TILE_HEIHGT_MO
                                  : MAIN_TILE_HEIHGT,
                          }
                        : containerType === "sub"
                        ? {
                              pl: isMobile ? 0 : 2.5,
                              height: isMobile
                                  ? SUB_TILE_HEIHGT_MO
                                  : SUB_TILE_HEIHGT,
                              mt: isMobile ? 2 : 0,
                          }
                        : containerType === "sub2"
                        ? {
                              pl: isMobile ? 0 : 2.5,
                              height: isMobile
                                  ? SUB_TILE_HEIHGT_MO
                                  : SUB_TILE_HEIHGT,
                              mt: isMobile ? 2 : 2.5,
                          }
                        : undefined
                }
            >
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                />
            </Box>
        );
    }
    return (
        <Box
            sx={
                containerType === "default"
                    ? {
                          display: "flex",
                          height: isMobile
                              ? SUB_TILE_HEIHGT_MO
                              : DEFAULT_TILE_HEIGHT,
                          position: "relative",
                          cursor: link ? "pointer" : "auto",
                      }
                    : containerType === "main"
                    ? {
                          cursor: link ? "pointer" : "auto",
                          display: "flex",
                          width: isMobile ? "100%" : "50%",
                          height: isMobile
                              ? MAIN_TILE_HEIHGT_MO
                              : MAIN_TILE_HEIHGT,
                          position: "relative",
                      }
                    : containerType === "sub"
                    ? {
                          display: "flex",
                          ml: isMobile ? 0 : 2.5,
                          position: "relative",
                          height: isMobile
                              ? SUB_TILE_HEIHGT_MO
                              : SUB_TILE_HEIHGT,
                          cursor: link ? "pointer" : "auto",
                          mt: isMobile ? 2 : 0,
                      }
                    : containerType === "sub2"
                    ? {
                          display: "flex",
                          ml: isMobile ? 0 : 2.5,
                          mt: isMobile ? 2 : 2.5,
                          position: "relative",
                          height: isMobile
                              ? SUB_TILE_HEIHGT_MO
                              : SUB_TILE_HEIHGT,
                          cursor: link ? "pointer" : "auto",
                      }
                    : undefined
            }
            onClick={() => {
                if (link) {
                    window.open(link);
                }
            }}
        >
            {link && <HoveringWhiteMask />}
            <img
                height={
                    containerType === "main"
                        ? isMobile
                            ? MAIN_TILE_HEIHGT_MO
                            : MAIN_TILE_HEIHGT
                        : containerType === "sub" || containerType === "sub2"
                        ? isMobile
                            ? SUB_TILE_HEIHGT_MO
                            : SUB_TILE_HEIHGT
                        : isMobile
                        ? SUB_TILE_HEIHGT_MO
                        : DEFAULT_TILE_HEIGHT
                }
                style={{ width: "100%", objectFit: "cover" }}
                src={image}
            />
            <Box
                position="absolute"
                sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,0.3)",
                }}
            />
            <Box position={"absolute"} p={3}>
                {text.split("\n").map((line, idx) => (
                    <Typography
                        key={`${link}-${idx}`}
                        variant={isMobile ? "f20h28EB" : "f24h36EB"}
                        color={"white"}
                        component="div"
                    >
                        {line}
                    </Typography>
                ))}
                <Typography
                    color={"white"}
                    component="div"
                    variant={isMobile ? "f14h20" : "f16h24"}
                    sx={{ mt: 2 }}
                >
                    {from}
                </Typography>
            </Box>
        </Box>
    );
};

export default NewsTileAll;
