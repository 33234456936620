import React, { useState } from "react";

import { Grid } from "@mui/material";

import { QuestFormTypes, QuestModel } from "../../../api-interfaces/quest";
import RequestCard from "../../cards/RequestCard";
import ReqTypeSelectSection from "../mypage/ReqTypeSelectSection";

export interface AllRequestListsSectionProps {
    quests: QuestModel[];
    isMobile: boolean;
    onSelectReq: (q: QuestModel) => void;
}

const AllRequestListsSection: React.FC<AllRequestListsSectionProps> = ({
    quests,
    isMobile = false,
    onSelectReq,
}) => {
    const [reqType, setReqType] = useState<QuestFormTypes>(
        QuestFormTypes.EXPORT
    );

    return (
        <Grid
            sx={
                isMobile
                    ? undefined
                    : {
                          bgcolor: "#FAFAFA",
                          border: "solid 1px #AAAAAA",
                          p: 3,
                      }
            }
        >
            <ReqTypeSelectSection
                reqType={reqType}
                setReqType={setReqType}
                importCount={
                    quests.filter((q) => q.type === QuestFormTypes.IMPORT)
                        .length
                }
                exportCount={
                    quests.filter((q) => q.type === QuestFormTypes.EXPORT)
                        .length
                }
                consultingCount={
                    quests.filter((q) => q.type === QuestFormTypes.CONSULTING)
                        .length
                }
            />
            <Grid
                sx={{
                    height: isMobile ? "auto" : "100vh",
                    overflow: "scroll",
                    mt: 2,
                }}
            >
                {quests
                    .filter((q) => q.type === reqType)
                    .map((quest, idx) => (
                        <RequestCard
                            key={`${quest._id}-card`}
                            isMobile={isMobile}
                            reqId={quest._id}
                            idx={idx + 1}
                            reqType={quest.type}
                            companyName={quest.companyName}
                            date={quest.date}
                            productName={quest.productName}
                            title={quest.title}
                            onClick={() => {
                                onSelectReq(quest);
                            }}
                        />
                    ))}
            </Grid>
        </Grid>
    );
};

export default AllRequestListsSection;
