import React, { useState, useEffect } from "react";

import { useSetRecoilState } from "recoil";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { fetchMe, signInUser } from "../apis/user";
import { navThemeRecoil, userRecoil } from "../components/states/recoil";
import LinkWrapper from "../components/typography/LinkWrapper";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

const Login = () => {
    const [email, setEmail] = useState<string>("");
    const [pw, setPw] = useState<string>("");
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const setUserData = useSetRecoilState(userRecoil);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    const login = async () => {
        try {
            await signInUser({ email, pw });
            const user = await fetchMe();
            setUserData(() => user);
            window.location.replace("/");
        } catch (e) {
            window.alert(t("account.notMatchingAlert"));
            setPw("");
            return;
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                minHeight: "100vh",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    ...(isMobile ? { justifyContent: "center" } : { mt: 18 }),
                }}
            >
                <Typography
                    variant="f48h64EB"
                    component="div"
                    sx={{ textAlign: "center", mb: 6.25 }}
                >
                    {t("account.login")}
                </Typography>
                <Box sx={{ width: "100%", maxWidth: "236px" }}>
                    <TextField
                        placeholder={t("account.email") ?? ""}
                        id="email"
                        autoComplete={"on"}
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        sx={{ mb: 3 }}
                    />
                    <TextField
                        placeholder={t("account.pw") ?? ""}
                        type={showPassword ? "text" : "password"}
                        autoComplete={"on"}
                        value={pw}
                        onChange={(e) => setPw(e.target.value)}
                        sx={{ mb: 3 }}
                        InputProps={{
                            style: { color: "black", fontFamily: "sans-serif" },
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            ),
                        }}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        disabled={!email || !pw}
                        onClick={login}
                        sx={{ mb: 2.5 }}
                    >
                        {t("account.login")}
                    </Button>
                    <Box
                        sx={{
                            justifyContent: "space-between",
                            display: "flex",
                        }}
                    >
                        <Box sx={{ display: "flex" }}>
                            <LinkWrapper href="/findid" noLinkStyle>
                                <Typography variant="f14h20" sx={{ mr: 2.5 }}>
                                    {t("account.findID")}
                                </Typography>
                            </LinkWrapper>
                            <LinkWrapper href="/findpw" noLinkStyle>
                                <Typography variant="f14h20">
                                    {t("account.findPW")}
                                </Typography>
                            </LinkWrapper>
                        </Box>
                        <Box>
                            <LinkWrapper href="/signup" noLinkStyle>
                                <Typography variant="f14h20B">
                                    {t("account.signup")}
                                </Typography>
                            </LinkWrapper>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
