import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../../theme";
import CollapsibleTable from "../../tables/CollapsibleTable";

export const FAQ_TITLE = "자주 묻는 질문";

const InquiryFAQSection = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();
    return (
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
            <Typography
                variant={isMobile ? "f32h40EB" : "f48h64EB"}
                mb={6.25}
                component="div"
            >
                {t("FAQ.title")}
            </Typography>
            <CollapsibleTable
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                rows={[...new Array(8)].map((_ele, idx) => {
                    return { body: t(`FAQ.a${idx}`), title: t(`FAQ.q${idx}`) };
                })}
            />
        </Box>
    );
};

export default InquiryFAQSection;
