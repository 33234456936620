import React, { useEffect, forwardRef, useState, Ref } from "react";

import { useNavigate } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import AnimatedNumbers from "react-animated-numbers";
import { useTranslation } from "react-i18next";

import { QuestFormTypes } from "../../../api-interfaces/quest";
import { UserRole } from "../../../api-interfaces/user";
import {
    mobileMaxWidthMediaQuery,
    tasktopMaxWidthMediaQuery,
} from "../../../theme";
import { currentQuestFormTypeRecoil, userRecoil } from "../../states/recoil";

const Section1 = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const [key, setKey] = useState(0);
    const [revenue, setRevenue] = useState(37);
    const [aboutRevenue, setAboutRevenue] = useState(107);
    const [projCount, setProjCount] = useState(70);
    const [partnerCount, setPartnerCount] = useState(330);
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const isTablet = useMediaQuery(tasktopMaxWidthMediaQuery);

    const { t, i18n } = useTranslation();
    const navi = useNavigate();
    const currentUser = useRecoilValue(userRecoil);
    const setCurrentQuestFormType = useSetRecoilState(
        currentQuestFormTypeRecoil
    );

    useEffect(() => {
        setKey((prev) => prev + 1);
        setProjCount(70);
    }, [isMobile, isTablet]);

    useEffect(() => {
        setKey((prev) => prev + 1);
        setPartnerCount(330);
    }, [isMobile, isTablet]);

    useEffect(() => {
        if (i18n.language === "en") {
            setRevenue(288);
            setKey((prev) => prev + 1);
            setAboutRevenue(812);
        } else {
            setRevenue(37);
            setKey((prev) => prev + 1);
            setAboutRevenue(107);
        }
    }, [i18n.language]);

    return (
        <Grid
            ref={ref}
            container
            sx={{
                height: "100vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                overflowX: "hidden",
                position: "relative",
                overflowY: "hidden",
            }}
            p={4}
        >
            <img
                src={"./images/main/main_background_2.png"}
                style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    zIndex: -1,
                    objectFit: "cover",
                    top: 0,
                    imageResolution: "300dpi",
                    overflowX: "hidden",
                }}
            />
            <Grid
                item
                xs={!isMobile ? 6 : 12}
                lg={6}
                sx={{
                    animation: "fadeIn 3s",
                    textAlign: isMobile ? "cetner" : "left",
                    pl: isMobile ? 0 : 10,
                    pr: isMobile ? 0 : 10,
                    pt: isMobile ? 4 : 0,
                }}
            >
                <Typography
                    color={"white"}
                    variant={isTablet ? "f16h20B" : "f24h36B"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("home.section10")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f16h20B" : "f24h36B"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("home.section11")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f16h20B" : "f24h36B"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("home.section12")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f16h20B" : "f24h36B"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("home.section13")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f24h36EB" : "f40h50B"}
                    component="div"
                    sx={{ mt: isMobile ? 2 : 4, whiteSpace: "nowrap" }}
                >
                    {t("home.section10st")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f48h64EB" : "f80h100B"}
                    component="div"
                >
                    {t("home.section10t")}
                </Typography>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: isMobile ? "center" : "space-between",

                        mt: isMobile ? 0 : 8,
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: 488,
                            minWidth: isMobile
                                ? undefined
                                : isTablet
                                ? 290
                                : 488,
                            display: "flex",
                            justifyContent: isMobile ? "center" : "flex-start",
                            my: isMobile ? 2 : 0,
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                color: "white",
                                borderColor: "white",
                                borderRadius: 3,
                                mr: 2,
                                width: "40%",
                            }}
                            onClick={() => {
                                if (!currentUser) {
                                    navi("login");
                                } else if (
                                    currentUser.role === UserRole.GUEST ||
                                    currentUser.role ===
                                        UserRole.UNAPPROVED_EXPERT
                                ) {
                                    navi("/verification");
                                } else if (
                                    currentUser.role === UserRole.COMPANY
                                ) {
                                    setCurrentQuestFormType(
                                        () => QuestFormTypes.EXPORT
                                    );
                                    navi("/service-request");
                                } else {
                                    navi("mypage");
                                }
                            }}
                        >
                            <Typography
                                variant={
                                    isMobile
                                        ? "f16h24B"
                                        : isTablet
                                        ? "f16h24B"
                                        : "f18h20B"
                                }
                            >
                                {t("home.section10b")}
                            </Typography>
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                width: "40%",
                                color: "white",
                                borderColor: "white",
                                borderRadius: 3,
                            }}
                            onClick={() => {
                                if (!currentUser) {
                                    navi("login");
                                } else if (
                                    currentUser.role === UserRole.GUEST ||
                                    currentUser.role ===
                                        UserRole.UNAPPROVED_EXPERT
                                ) {
                                    navi("/verification");
                                } else if (
                                    currentUser.role === UserRole.COMPANY
                                ) {
                                    setCurrentQuestFormType(
                                        () => QuestFormTypes.IMPORT
                                    );
                                    navi("/service-request");
                                } else {
                                    navi("mypage");
                                }
                            }}
                        >
                            <Typography
                                variant={
                                    isMobile
                                        ? "f16h24B"
                                        : isTablet
                                        ? "f16h24B"
                                        : "f18h20B"
                                }
                            >
                                {t("home.section11b")}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                xs={!isMobile ? 6 : 12}
                lg={6}
                sx={{
                    animation: "fadeIn 3s",
                    textAlign: isMobile ? "cetner" : "left",
                    pl: isMobile ? 0 : 10,
                    pr: isMobile ? 0 : 10,
                }}
            >
                <Typography
                    color={"white"}
                    variant={isTablet ? "f16h20B" : "f24h36EB"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("home.section10l")}
                </Typography>
                <Typography
                    key={key}
                    color={"white"}
                    variant={isTablet ? "f24h36EB" : "f40h50B"}
                    component="div"
                    sx={{
                        mb: isMobile ? 2 : 4,
                        display: "flex",
                        justifyContent: isMobile ? "center" : "flex-start",
                        whiteSpace: "nowrap",
                    }}
                >
                    {i18n.language === "en" && t("common.mone")}
                    <AnimatedNumbers
                        animateToNumber={revenue}
                        configs={[
                            { mass: 1, tension: 220, friction: 100 },
                            { mass: 1, tension: 180, friction: 130 },
                            { mass: 1, tension: 280, friction: 90 },
                            { mass: 1, tension: 180, friction: 135 },
                            { mass: 1, tension: 260, friction: 100 },
                            { mass: 1, tension: 210, friction: 180 },
                        ]}
                    />
                    {t("common.mone1")}
                    {"  ("}
                    {t("common.about")}
                    {"  "}
                    {i18n.language === "en" && t("common.mone")}
                    <AnimatedNumbers
                        key={key}
                        animateToNumber={aboutRevenue}
                        configs={[
                            { mass: 1, tension: 220, friction: 100 },
                            { mass: 1, tension: 180, friction: 130 },
                            { mass: 1, tension: 280, friction: 90 },
                            { mass: 1, tension: 180, friction: 135 },
                            { mass: 1, tension: 260, friction: 100 },
                            { mass: 1, tension: 210, friction: 180 },
                        ]}
                    />
                    {t("common.mone1")}
                    {")"}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f16h20B" : "f24h36EB"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("home.section11l")}
                </Typography>
                <Typography
                    key={`${key}-1`}
                    color={"white"}
                    variant={isTablet ? "f24h36EB" : "f40h50B"}
                    component="div"
                    sx={{
                        mb: isMobile ? 2 : 4,
                        display: "flex",
                        justifyContent: isMobile ? "center" : "flex-start",
                    }}
                >
                    <AnimatedNumbers
                        animateToNumber={projCount}
                        configs={[
                            { mass: 1, tension: 220, friction: 100 },
                            { mass: 1, tension: 180, friction: 130 },
                            { mass: 1, tension: 280, friction: 90 },
                            { mass: 1, tension: 180, friction: 135 },
                            { mass: 1, tension: 260, friction: 100 },
                            { mass: 1, tension: 210, friction: 180 },
                        ]}
                    />
                    {t("common.count1")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f16h20B" : "f24h36EB"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("home.section13l")}
                </Typography>
                <Typography
                    key={`${key}-2`}
                    color={"white"}
                    variant={isTablet ? "f24h36EB" : "f40h50B"}
                    component="div"
                    sx={{
                        mb: isMobile ? 2 : 4,
                        display: "flex",
                        justifyContent: isMobile ? "center" : "flex-start",
                    }}
                >
                    <AnimatedNumbers
                        animateToNumber={partnerCount}
                        configs={[
                            { mass: 1, tension: 220, friction: 100 },
                            { mass: 1, tension: 180, friction: 130 },
                            { mass: 1, tension: 280, friction: 90 },
                            { mass: 1, tension: 180, friction: 135 },
                            { mass: 1, tension: 260, friction: 100 },
                            { mass: 1, tension: 210, friction: 180 },
                        ]}
                    />
                    {t("common.count2")}
                </Typography>
                <Typography
                    color={"white"}
                    variant={isTablet ? "f12h20" : "f16h20B"}
                    component="div"
                    sx={{ mt: isMobile ? 0 : 10, whiteSpace: "nowrap" }}
                >
                    {t("home.section12l")}
                </Typography>
            </Grid>
        </Grid>
    );
});

export default Section1;
