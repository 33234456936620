import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";

import {
    ChatOutlined,
    DeleteOutlined,
    EditOutlined,
    FavoriteBorderOutlined,
    FavoriteOutlined,
} from "@mui/icons-material";
import {
    Button,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";

import { ResponseGetPost } from "../api-interfaces/post";
import { deletePost, getOnePost, likePost, postComment } from "../apis/post";
import BackButton from "../components/buttons/BackButton";
import BulletinBoardCard from "../components/cards/BulletinBoardCard";
import CommentBlock from "../components/post/CommentBlock";
import { navThemeRecoil, userRecoil } from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { FontWeightValues } from "../types/fontWeightTypes";
import { navThemeValues } from "../types/navThemeTypes";

export interface PostDetailProps {}

const PostDetail: React.FC<PostDetailProps> = () => {
    const { postId } = useParams();
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const [post, setPost] = useState<ResponseGetPost>();
    const [commentText, setCommentText] = useState("");
    const userData = useRecoilValue(userRecoil);
    const [liked, setLiked] = useState(false);
    const [likeButtonDisabled, setLikeButtonDisabled] = useState(true);

    const onDelete = () => {
        if (postId)
            if (confirm("이 포스트를 삭제하시겠습니까?")) {
                deletePost(postId)
                    .then(() => {
                        alert("삭제되었습니다");
                        location.replace("/bulletinboard");
                    })
                    .catch((e) => {
                        alert("삭제에 실패했습니다");
                        console.log(e);
                    });
            }
    };

    const submitComment = () => {
        if (postId) {
            postComment(postId, { contents: commentText })
                .then((updatedPost) => {
                    console.log(updatedPost);
                    setPost((prev) => {
                        if (!prev) return prev;
                        else {
                            return {
                                ...prev,
                                comments: updatedPost.comments,
                                commentsNumber: updatedPost.commentsNumber,
                            };
                        }
                    });
                    setCommentText("");
                })
                .catch((e) => {
                    console.log(e);
                    alert("댓글 작성에 실패했습니다.");
                });
        }
    };

    const pressLike = async () => {
        if (postId) {
            try {
                setLiked((prev) => !prev);
                setLikeButtonDisabled(true);
                setPost((prev) => {
                    if (!prev) return prev;
                    else {
                        return {
                            ...prev,
                            likes: prev.likes + (liked ? -1 : 1),
                        };
                    }
                });
                await likePost(postId);
                setLikeButtonDisabled(false);
            } catch (e) {
                console.log(e);
                setLiked((prev) => !prev);
                setPost((prev) => {
                    if (!prev) return prev;
                    else {
                        return {
                            ...prev,
                            likes: prev.likes - (liked ? -1 : 1),
                        };
                    }
                });
                setLikeButtonDisabled(false);
            }
        }
    };

    useEffect(() => {
        if (postId && userData) {
            getOnePost(postId).then((post) => {
                console.log(post);
                setPost(post);
                setLikeButtonDisabled(userData?._id === post.userID);
            });
            console.log(userData?.likedPosts?.includes(postId));
            setLiked(userData?.likedPosts?.includes(postId) ?? false);
        }
    }, [postId, userData]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    return (
        <Grid container flexDirection={"column"} alignItems={"center"} p={2.5}>
            <Grid
                sx={{
                    maxWidth: "750px",
                    width: "100%",
                    // flexWrap: "nowrap",
                    mt: 15,
                }}
            >
                <BackButton />
                <Grid mt={2}>
                    <Typography variant="f14h20">{post?.category}</Typography>
                </Grid>
                <Grid mb={2}>
                    <Typography
                        variant="f24h36B"
                        fontWeight={FontWeightValues.EXTRA_BOLD}
                    >
                        {post?.title}
                    </Typography>
                </Grid>
                <Grid>
                    <pre style={{ fontFamily: "inherit", margin: 0 }}>
                        {post?.contents}
                    </pre>
                </Grid>

                {/* 좋아요 / 댓글 수 */}
                <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={2}
                >
                    <Grid>
                        <Grid container alignItems={"center"} flex={1}>
                            <IconButton
                                onClick={pressLike}
                                disabled={likeButtonDisabled}
                            >
                                {liked ? (
                                    <FavoriteOutlined color="primary" />
                                ) : (
                                    <FavoriteBorderOutlined color="primary" />
                                )}
                            </IconButton>
                            <Grid pr={3}>
                                <Typography variant="f16h24">
                                    {post?.likes}
                                </Typography>
                            </Grid>

                            <IconButton>
                                <ChatOutlined color="primary" />
                            </IconButton>
                            <Grid pr={3}>
                                <Typography variant="f16h24">
                                    {post?.commentsNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {userData && userData._id === post?.userID && (
                        <Grid>
                            <Grid container>
                                <IconButton
                                    href={`/bulletinboard/${postId}/edit`}
                                >
                                    <EditOutlined color="primary" />
                                </IconButton>
                                <IconButton onClick={onDelete}>
                                    <DeleteOutlined color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {/* 디바이더 */}
                <Divider sx={{ mt: 5, mb: 2 }} />
                {/* 댓글 입력창 */}
                <TextField
                    fullWidth
                    placeholder="댓글을 입력해주세요"
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                            p: 0,
                            pl: 1,
                            bgcolor: "#FFF",
                            "& fieldset": {
                                borderColor: "#000",
                            },
                        },
                    }}
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <Button
                                sx={{
                                    px: 4,
                                    fontSize: "14px",
                                }}
                                variant="text"
                                onClick={submitComment}
                            >
                                댓글쓰기
                            </Button>
                        ),
                    }}
                />
                {/* 댓글 리스트 */}
                {post?.comments.map((comment, i) => (
                    <CommentBlock
                        key={`comment-${i}`}
                        commentIdx={i}
                        comment={comment}
                        postId={postId || ""}
                        setPost={setPost}
                    />
                ))}
                {(post?.next || post?.prev) && (
                    <Grid mt={5}>
                        <Typography
                            variant="f24h36B"
                            fontWeight={FontWeightValues.EXTRA_BOLD}
                        >
                            다른 글
                        </Typography>
                    </Grid>
                )}
                {[post?.next, post?.prev].map((data, idx) => {
                    return (
                        data && (
                            <Grid item key={`ambassador_data_${idx}`} mt={2}>
                                <BulletinBoardCard
                                    isMobile={isMobile}
                                    data={data}
                                />
                            </Grid>
                        )
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default PostDetail;
