import React, { useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import {
    CommunityDataType,
    communityFilterContents,
    communityFilterKeys,
    LandingPageDefaultCommunity,
} from "../../../interfaces/community/communityDataType";
import { mobileMaxWidthMediaQuery } from "../../../theme";
import FilterButton from "../../buttons/FilterButton";
import ViewMoreButton from "../../buttons/ViewMoreButton";
import NewsTileAll from "../../frames/NewsTileAll";

const preparationNewsTileData: CommunityDataType = {
    text: "",
    image: "",
    from: "",
    type: "BLOG",
}; // type 은 그닥 상관없음

/**
 * 랜딩페이지 커뮤니티 부분
 */
const CommunitySection = () => {
    const [communityData, setCommunityData] = useState<CommunityDataType[]>(
        LandingPageDefaultCommunity
    );
    const [selected, setSelected] = useState<number>(0);

    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();

    const handleFilter = (id: number) => {
        setSelected(id);
        const filteredDoc = LandingPageDefaultCommunity.filter(
            (data) => id === 0 || data.type === communityFilterKeys[id]
        );
        setCommunityData(() => filteredDoc);
    };
    return (
        <Grid
            container
            sx={{
                width: "100%",
                backgroundColor: "white",
                alignItems: "center",
                flexDirection: "column",
                overflowX: "hidden",
                minHeight: "100vh",
            }}
            p={isMobile ? 2 : 4}
            pt={isMobile ? 2 : 18}
            pb={2}
        >
            <Box
                display={"flex"}
                justifyContent={"flex-start"}
                textAlign={"start"}
                width={"100%"}
            >
                <Typography
                    className="cocosharp"
                    color={"black"}
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    sx={{ textAlign: "left" }}
                >
                    Community
                </Typography>
            </Box>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2.5 }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    width={"100%"}
                    alignItems={"center"}
                    pt={isMobile ? 3.5 : 5}
                    sx={{ overflowX: "auto" }}
                >
                    {communityFilterContents.map((data, idx) => {
                        return (
                            <FilterButton
                                key={idx}
                                selected={selected === idx ? true : false}
                                sx={
                                    idx === communityFilterContents.length - 1
                                        ? { mr: 0 }
                                        : undefined
                                }
                                text={t(`community.${data}`)}
                                onClick={() => handleFilter(idx)}
                            />
                        );
                    })}
                </Box>
                {!isMobile && <ViewMoreButton text={"View More"} />}
            </Box>
            <Box
                display={"flex"}
                width={"100%"}
                height={"100%"}
                mt={isMobile ? 0 : 2}
                flexDirection={isMobile ? "column" : "row"}
                position={"relative"}
                justifyContent={"space-around"}
            >
                {/* 커다란 커뮤니티 카드 컴포넌트 */}

                <NewsTileAll
                    data={
                        communityData.length > 0
                            ? {
                                  ...communityData[0],
                              }
                            : { ...preparationNewsTileData }
                    }
                    isMobile={isMobile}
                    containerType="main"
                />

                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    width={isMobile ? "100%" : "50%"}
                    height={"100%"}
                    position={"relative"}
                >
                    <NewsTileAll
                        containerType="sub"
                        data={
                            communityData.length > 1
                                ? {
                                      ...communityData[1],
                                  }
                                : { ...preparationNewsTileData }
                        }
                        isMobile={isMobile}
                    />
                    <NewsTileAll
                        containerType="sub2"
                        data={
                            communityData.length > 2
                                ? {
                                      ...communityData[2],
                                  }
                                : { ...preparationNewsTileData }
                        }
                        isMobile={isMobile}
                    />
                </Box>

                <Box display={"flex"}>
                    {isMobile && <ViewMoreButton pt={2.5} text={"View more"} />}
                </Box>
            </Box>
        </Grid>
    );
};

export default CommunitySection;
