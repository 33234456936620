import React from "react";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../../theme";

interface OneTileC4Props {
    title: string;
    subtitle: string;
    desc: string;
    imgSrc: string;
}

const OneTileC4: React.FC<OneTileC4Props> = ({
    title,
    subtitle,
    desc,
    imgSrc,
}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    return (
        <Grid
            item
            display={"flex"}
            xs={isMobile ? 12 : 2}
            sm={4}
            md={4}
            height={360}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    backgroundColor: "#1D1D1D",
                    height: "100%",
                    p: 5,
                }}
            >
                <Typography color={"#568FFC"} variant="f18h26">
                    {title}
                </Typography>
                <Typography color={"#568FFC"} variant="f18h26EB">
                    {subtitle}
                </Typography>
                <Typography color={"white"} sx={{ mt: 1 }}>
                    {desc}
                </Typography>
                <img
                    src={imgSrc}
                    style={{ position: "absolute", bottom: 25, right: 25 }}
                />
            </Box>
        </Grid>
    );
};

const COMPANY_INFO_TABLE_IMG_PREFIX =
    "./images/company-info/company_info_table_";

const CompanyInfoFourth = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 1264,
                width: "100%",
                pb: 10,
                px: 3,
            }}
        >
            {/* title */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    animation: "fadeIn 3s",
                }}
            >
                <Typography
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    component={"div"}
                >
                    {t("companyInfo.t401")}
                </Typography>
                <Typography
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    component={"div"}
                >
                    {t("companyInfo.t402")}
                </Typography>
            </Box>
            {/* Table Info */}
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                pt={5}
            >
                {[
                    "Bridge",
                    "Certifier",
                    "Enforcer",
                    "Insulator",
                    "Concierge",
                    "Risk Bearer",
                ].map((title, idx) => (
                    <OneTileC4
                        title={title}
                        subtitle={t(`companyInfo.t4${idx + 1}1`)}
                        desc={t(`companyInfo.t4${idx + 1}2`)}
                        imgSrc={`${COMPANY_INFO_TABLE_IMG_PREFIX}${
                            idx + 1
                        }.png`}
                    />
                ))}
            </Grid>
        </Box>
    );
};

export default CompanyInfoFourth;
