import { MongoDBIncluding } from "./baseType";

/**
 * 0. 취소된 의뢰
 * 1. 의뢰요청
 * 2. 전문가 상담 및 비교 중
 * 3. 의뢰비 납부 대기
 * 4. 의뢰비 납부 확인
 * 5. 진행 중
 * 6. 마지막 금액 확인
 * 7. 완료
 */
export enum QuestStatus {
    CANCELED = 0,
    PROPOSAL = 1,
    ESTIMATED = 2,
    WAITING_PAYMENT = 3,
    CONFIRMING_PAYMENT = 4,
    PROCESSING = 5,
    CONFIRMED_FINAL_FEE = 6,
    COMPLETE = 7,
}

export enum QuestFormTypes {
    EXPORT = "수출",
    IMPORT = "수입",
    CONSULTING = "컨설팅",
}

export interface QuestField {
    title: string;
    options: string[];
    hasEtc?: boolean;
}

/**
 * 전문가가 보낸 정보
 */
export interface EstimateReportInfo {
    /**
     * 착수가능일
     */
    beginDate: string;

    /**
     * 수수료
     */
    feeRate: string;

    /**
     * 상세정보
     */
    detailInfo: string;
}

export interface QuestBase extends MongoDBIncluding {
    /**
     * 처음에 만들 때 셋해줌
     */
    clientID: string;

    /**
     * 대기중인 전문가들. 2단계로 넘어갈 때 생성 및 추가 해줌
     */
    preparedExperts?: string[];

    /**
     * 3단계로 넘어갈 때 생성해줌
     */
    expertID?: string;

    /**
     * 채팅아이디
     * NOTE: 3단계로 넘어갈 때 생성해줌
     */
    chat?: string;

    /**
     * 프로포절의 selected 퀘스트필드 값
     * 처음에 무조건 받는 값
     *
     * TODO 나중에 스키마 정리후 밸리데이션 하기
     */
    proposal: string[];

    /**
     * 수출 수입 컨설팅?
     */
    type: QuestFormTypes;

    /**
     * 숫자로 된 지금 단계 {@link QuestStatus}
     */
    level: QuestStatus;

    /**
     * 회사소개서
     */
    companyIntroduction?: string;
    /**
     * 상품카탈로그
     */
    productCatalog?: string;
}

export interface QuestModel extends QuestBase {
    /**
     * 기업명
     * get from user정보
     * 제로클래스랩
     */
    companyName: string;

    /**
     * 등록일자
     * 2022.09.11 // auto generate
     */
    date: string;

    /**
     * 중고 농업용 트랙터
     * 처음 신청할 때 상품명을 할 수 있음
     */
    productName?: string;

    /**
     * This is only for consulting // TODO later
     */
    title?: string;

    /**
     * NOTE stringified settlement
     * see {@link ExpertSettlement}
     */
    settlement?: string;
}

/**
 * 정산서 인터페이스
 */
export interface ExpertSettlement {
    /**
     * 최종수출금액
     */
    exportFee: string;
    /**
     * 수수료 @default - 자동불러오기
     */
    commission: string;
    /**
     * 최종정산액 => computed value
     */
    finalFee: string;

    /**
     * `true` 시 수수료 비율이 아니라 금액으로 입력하기
     */
    isNotPercentageCommission?: boolean;

    /**
     * 증빙파일 (계약서, 선적서류, 신고필증 중 필수 첨부) 다운로드 주소
     */
    proof: string;
}

export interface RequestPostQuest {
    /**
     * 상품명
     */
    productName: string;

    /**
     * 기업 아이디 (company's _id);
     *
     * @remarks
     * 관리자만 필요. 나머지는 cookie 에서 값 가져옴.
     */
    clientID?: string;

    /**
     * 필드 값들
     */
    proposal: string[];

    /**
     * 수출 수입?
     */
    type: QuestFormTypes;
    /**
     * 회사소개서
     */
    companyIntroduction?: string;
    /**
     * 상품카탈로그
     */
    productCatalog?: string;
}

export interface RequestSelectExpertPatch {
    expertID: string;
    questID: string;
    chatID: string;
}
