import React from "react";

import Link from "@mui/material/Link";

export interface LinkWrapperProps {
    href: string;
    noLinkStyle?: boolean;
    children?: React.ReactNode;
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({
    href = "#",
    noLinkStyle,
    children,
}) => {
    return (
        <Link href={href} sx={noLinkStyle ? { textDecoration: "none" } : {}}>
            {children}
        </Link>
    );
};

export default LinkWrapper;
