import React, { useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, Collapse, Grid, TextField, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { EstimateReportInfo, QuestModel } from "../../../api-interfaces/quest";
import { suggestQuestEstimation } from "../../../apis/quest";
import { sampleReqInfo } from "../../../utils/dummyData";
import BlueOutlinedTextButton from "../../buttons/BlueOutlinedTextButton";
import DownloadButton from "../../buttons/DownloadButton";
import InfoList from "../../tables/InfoList";

export interface EstimateWriteSectionProps {
    quest: QuestModel;
    isMobile: boolean;
}

const EstimateWriteSection: React.FC<EstimateWriteSectionProps> = ({
    quest,
    isMobile,
}) => {
    const [openInfoBox, setOpenInfoBox] = useState<boolean>(false);
    const [reqInfo, setReqInfo] = useState<QuestModel>();
    const [reportInfo, setReportInfo] = useState<EstimateReportInfo>({
        beginDate: "",
        feeRate: "",
        detailInfo: "",
    });

    const { t } = useTranslation();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setReqInfo(quest);
    }, [quest]);

    const submit = async () => {
        try {
            const promotedQuest = await suggestQuestEstimation(
                quest._id,
                reportInfo
            );
            console.log(promotedQuest);
            window.alert(t("quest.transferSuccess"));
            location.href = "/mypage";
        } catch (e) {
            window.alert(t("error.transferFailed"));
            return;
        }
    };

    return (
        <Grid container width={"100%"} flexDirection={"column"}>
            {/* 요청서 섹션 */}
            <Grid
                item
                sx={
                    isMobile
                        ? { width: "100%" }
                        : {
                              bgcolor: "#FAFAFA",
                              border: "solid 1px #AAAAAA",
                          }
                }
            >
                <Collapse
                    in={openInfoBox}
                    collapsedSize={isMobile ? "180px" : "200px"}
                    sx={{ p: isMobile ? 0 : 3 }}
                >
                    <Typography component="div" variant="f20h28EB">
                        {t("quest.et1")}
                    </Typography>
                    <BlueOutlinedTextButton
                        sx={{ mt: 4, mb: 2 }}
                        text={t("mypage.quest")}
                    />
                    {reqInfo && (
                        <InfoList
                            data={sampleReqInfo(reqInfo).map((infoEle) => {
                                return {
                                    ...infoEle,
                                    title: t(`quest.${infoEle.title}`),
                                };
                            })}
                        />
                    )}
                    {reqInfo?.companyIntroduction && (
                        <DownloadButton
                            label={t("quest.et21")}
                            href={reqInfo.companyIntroduction}
                        />
                    )}
                    {reqInfo?.productCatalog && (
                        <DownloadButton
                            label={t("quest.et22")}
                            href={reqInfo.productCatalog}
                        />
                    )}
                </Collapse>
                <Button
                    fullWidth
                    onClick={() => setOpenInfoBox((prev) => !prev)}
                    sx={isMobile ? { bgcolor: "mono.300" } : undefined}
                >
                    {openInfoBox ? (
                        <ExpandLess fontSize="large" htmlColor={"#AAAAAA"} />
                    ) : (
                        <ExpandMore fontSize="large" htmlColor={"#AAAAAA"} />
                    )}
                </Button>
            </Grid>

            {/* 견적서 작성 섹션 */}
            <Grid
                item
                sx={
                    isMobile
                        ? { mt: 4 }
                        : {
                              bgcolor: "#FAFAFA",
                              border: "solid 1px #AAAAAA",
                              p: 3,
                              mt: 2.5,
                          }
                }
            >
                {/* <Typography variant="f20h28EB" component="div">
                    {reqInfo?.type !== "컨설팅"
                        ? t("quest.answerConsulting")
                        : t("quest.writeEstimate")}
                </Typography> */}
                {reqInfo?.type === "컨설팅" && (
                    <>
                        {/* 착수 가능일 */}
                        <Grid mt={3} mb={1}>
                            <Typography variant="f16h20B">
                                {t("quest.proposedDate")}
                            </Typography>
                        </Grid>
                        <TextField
                            placeholder={
                                t("quest.dateWithPlaceholder") as string
                            }
                            value={reportInfo.beginDate}
                            onChange={(e) =>
                                setReportInfo((prev) => {
                                    return {
                                        ...prev,
                                        beginDate: e.target.value,
                                    };
                                })
                            }
                        />
                        {/* 수수료율 */}
                        <Typography
                            variant="f16h20B"
                            component="div"
                            sx={{ mt: 3, mb: 1 }}
                        >
                            {t("quest.consultingFee")}
                        </Typography>
                        <TextField
                            placeholder={
                                t(
                                    "quest.consultingFeeWithPlaceholder"
                                ) as string
                            }
                            value={reportInfo.feeRate}
                            onChange={(e) =>
                                setReportInfo((prev) => {
                                    return {
                                        ...prev,
                                        feeRate: e.target.value,
                                    };
                                })
                            }
                        />
                    </>
                )}

                {reqInfo?.type !== "컨설팅" && (
                    <>
                        {/* 착수 가능일 */}
                        <Grid mt={3} mb={1}>
                            <Typography variant="f16h20B">
                                {t("quest.proposedDate")}
                            </Typography>
                        </Grid>
                        <TextField
                            placeholder={
                                t("quest.dateWithPlaceholder") as string
                            }
                            value={reportInfo.beginDate}
                            onChange={(e) =>
                                setReportInfo((prev) => {
                                    return {
                                        ...prev,
                                        beginDate: e.target.value,
                                    };
                                })
                            }
                        />
                        {/* 수수료율 */}
                        <Typography
                            variant="f16h20B"
                            component="div"
                            sx={{ mt: 3, mb: 1 }}
                        >
                            {t("quest.commissionFee")}
                        </Typography>
                        <TextField
                            placeholder={
                                t("quest.percentageWithPlaceholder") as string
                            }
                            value={reportInfo.feeRate}
                            onChange={(e) =>
                                setReportInfo((prev) => {
                                    return {
                                        ...prev,
                                        feeRate: e.target.value,
                                    };
                                })
                            }
                        />
                    </>
                )}
                {/* 디테일 */}
                <Typography
                    variant="f16h20B"
                    component="div"
                    sx={{ mt: 3, mb: 1 }}
                >
                    {reqInfo?.type === "컨설팅"
                        ? t("quest.consultingAnswer")
                        : t("quest.estimateDetail")}
                </Typography>
                <TextField
                    multiline
                    rows={6}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            height: "150px",
                            fontSize: "14px",
                            lineHeight: 1.43,
                            fontWeight: 600,
                        },
                    }}
                    placeholder={t("quest.detailsPlaceholder") as string}
                    value={reportInfo.detailInfo}
                    onChange={(e) =>
                        setReportInfo((prev) => {
                            return {
                                ...prev,
                                detailInfo: e.target.value,
                            };
                        })
                    }
                />
                {/* 버튼 */}
                <Grid container mt={3} justifyContent={"flex-end"}>
                    <Button
                        variant="outlined"
                        sx={{
                            height: "34px",
                        }}
                        color="primary"
                        onClick={submit}
                    >
                        <Typography variant="f16h20B">
                            {t("status.submit")}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EstimateWriteSection;
