import React from "react";

const NotFound = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                flexDirection: "column",
            }}
        >
            <div>404 NotFound 🥺</div>
            <button onClick={() => window.location.replace("/")}>Home</button>
        </div>
    );
};

export default NotFound;
