import React from "react";

import { Button, Typography } from "@mui/material";

import { TextButtonProps } from "./button-interfaces";

const BlackOutlinedTextButton: React.FC<TextButtonProps> = ({
    text,
    sx,
    onClick,
}) => {
    return (
        <Button
            variant="outlined"
            sx={{
                height: "34px",
                whiteSpace: "nowrap",
                ...sx,
            }}
            onClick={onClick}
        >
            <Typography variant="f16h20B">{text}</Typography>
        </Button>
    );
};

export default BlackOutlinedTextButton;
