import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

// import { AmbassadorDataType } from "../../interfaces/ambassador/ambassadorDataType";
import { useTranslation } from "react-i18next";

import { AmbassadorDataType } from "../../interfaces/ambassador/ambassadorDataType";
import AmbassadorModal from "../modals/AmbassadorModal";

interface AmbassadorTileMainProps {
    data: AmbassadorDataType;
    isMobile: boolean;

    width?: number;
    height?: number;
}

const StaticAmbassadorTileMain: React.FC<AmbassadorTileMainProps> = ({
    isMobile,
    data,
    width,
    height,
}) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            <Box
                display={"flex"}
                width={"100%"}
                position={"relative"}
                onClick={() => setOpenModal(true)}
            >
                <img
                    style={{
                        width: width ? width : "100%",
                        objectFit: "cover",
                        height: height ? height : isMobile ? 480 : 650,
                    }}
                    src={data.image}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "rgba(33,33,33,0.3)",
                        px: 3,
                        pt: 3,
                        pb: 6,
                        textAlign: "start",
                    }}
                >
                    <Typography
                        sx={{ color: "white", whiteSpace: "nowrap" }}
                        variant={isMobile ? "f20h28EB" : "f24h36EB"}
                    >
                        {t(`ambassador.${data.grade}`)}
                    </Typography>
                    <Typography
                        sx={{ color: "white" }}
                        variant={isMobile ? "f14h20L" : "f20h28L"}
                    >
                        {t(`ambassador.${data.field}`)}
                    </Typography>
                </Box>
            </Box>
            <AmbassadorModal
                data={data}
                isOpen={openModal}
                onClose={() => setOpenModal(!openModal)}
                isMobile={isMobile}
            />
        </>
    );
};

export default StaticAmbassadorTileMain;
