import React, { useEffect } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useRecoilState } from "recoil";

import { Grid, LinearProgress } from "@mui/material";

import { UserRole } from "./api-interfaces/user";
import { fetchMe } from "./apis/user";
import ChannelService from "./components/external/ChannelService";
import { isUserLoadedRecoil, userRecoil } from "./components/states/recoil";
import Admin from "./pages/Admin";
import AllRequests from "./pages/AllRequests";
import Ambassador from "./pages/Ambassador";
import BulletinBoard from "./pages/BulletinBoard";
import Community from "./pages/Community";
import Company from "./pages/Company";
import CompanyInfo from "./pages/CompanyInfo";
import FindID from "./pages/FindID";
import FindPW from "./pages/FindPW";
import Home from "./pages/Home";
import Inquiry from "./pages/Inquiry";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import MyPage from "./pages/MyPage";
import NotFound from "./pages/NotFound";
import PostDetail from "./pages/PostDetail";
import PostEdit from "./pages/PostEdit";
import RequestDetail from "./pages/RequestDetail";
import Service from "./pages/Service";
import ServiceRequestForm from "./pages/ServiceRequestForm";
import Signup from "./pages/Signup";
import Verification from "./pages/Verification";
import RouteChangeTracker from "./RouteChangeTracker";

const App = () => {
    const [isUserLoaded, setIsUserLoaded] = useRecoilState(isUserLoadedRecoil);
    const [userData, setUserData] = useRecoilState(userRecoil);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        (async () => {
            if (!userData) {
                try {
                    const fetchedUser = await fetchMe();
                    setUserData(() => fetchedUser);
                } catch (e) {
                    console.log("not logeed in");
                    setUserData(() => undefined);
                }
            }
            setIsUserLoaded(() => true);
        })();
    }, []);

    useEffect(() => {
        if (isUserLoaded) {
            const channelTalk = new ChannelService();
            if (userData) {
                const {
                    _id,
                    nickname: name,
                    contact: mobileNumber,
                    email,
                    image: avatarUrl,
                } = userData;

                channelTalk.boot({
                    memberId: _id,
                    pluginKey: process.env.REACT_APP_PUBLIC_CHANNEL_IO_KEY,
                    profile: {
                        avatarUrl,
                        email,
                        mobileNumber,
                        name,
                    },
                });
            } else {
                channelTalk.boot({
                    pluginKey: process.env.REACT_APP_PUBLIC_CHANNEL_IO_KEY,
                });
            }
            return () => {
                channelTalk.shutdown();
            };
        }
    }, [userData, isUserLoaded]);

    return (
        <BrowserRouter>
            <RouteChangeTracker />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="community" element={<Community />} />
                    <Route path="community/:postId" element={<PostDetail />} />
                    <Route path="ambassador" element={<Ambassador />} />
                    <Route path="inquiry" element={<Inquiry />} />
                    <Route path="service" element={<Service />} />
                    <Route path="company" element={<Company />} />
                    <Route path="company-info" element={<CompanyInfo />} />
                    <Route path="bulletinboard" element={<BulletinBoard />} />
                    <Route path="bulletinboard/edit" element={<PostEdit />} />
                    <Route
                        path="bulletinboard/:postId"
                        element={<PostDetail />}
                    />
                    <Route
                        path="bulletinboard/:postId/edit"
                        element={<PostEdit />}
                    />
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<Signup />} />
                    {userData &&
                        (userData?.role === UserRole.GUEST ||
                            userData?.role === UserRole.UNAPPROVED_EXPERT) && (
                            <Route
                                path="verification"
                                element={<Verification />}
                            />
                        )}

                    {userData && <Route path="mypage" element={<MyPage />} />}
                    {userData && userData?.role === UserRole.ADMIN && (
                        <Route path="admin" element={<Admin />} />
                    )}
                    {userData && userData?.role === UserRole.COMPANY && (
                        <Route
                            path="service-request"
                            element={<ServiceRequestForm />}
                        />
                    )}

                    <Route path="/request/:reqId" element={<RequestDetail />} />
                    {userData &&
                        (userData?.role === UserRole.ADMIN ||
                            userData?.role === UserRole.EXPERT) && (
                            <Route path="/requests" element={<AllRequests />} />
                        )}
                    <Route path="/findid" element={<FindID />} />
                    <Route path="/findpw" element={<FindPW />} />

                    {/* <Route path="/test" element={<Test />} /> */}
                    {isUserLoaded ? (
                        <Route path="*" element={<NotFound />} />
                    ) : (
                        <Route
                            path="*"
                            element={
                                <Grid
                                    sx={{
                                        width: "100%",
                                        height: "90vh",
                                        pt: "45vh",
                                    }}
                                >
                                    <LinearProgress />
                                </Grid>
                            }
                        />
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
