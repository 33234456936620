import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { RequestFindingPostForgotUserData } from "../api-interfaces/user";
import { findEmailOrPrepareResetPW } from "../apis/user";
import { mobileMaxWidthMediaQuery } from "../theme";

const FindID: React.FC = () => {
    const [fetchedEmail, setFetchedEmail] = useState("");
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const navi = useNavigate();
    const [indexer, setIndexer] = useState<RequestFindingPostForgotUserData>({
        name: "",
        contact: "",
    });
    const { t } = useTranslation();

    const submit = async () => {
        try {
            const modifiedIndexer = {
                ...indexer,
                contact: indexer.contact.replace("+82", "0"),
            };
            const fEmail = await findEmailOrPrepareResetPW(modifiedIndexer);
            setFetchedEmail(() => fEmail);
        } catch (e) {
            alert(t("account.notExistingUser"));
            console.error(e);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                minHeight: "100vh",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 236,
                    width: "100%",
                    alignItems: "center",
                    ...(isMobile ? { justifyContent: "center" } : { mt: 18 }),
                }}
            >
                <Typography variant="f48h64EB" sx={{ mb: 6.25 }}>
                    {t("account.findID")}
                </Typography>

                {fetchedEmail ? (
                    <>
                        {/* ID 를 찾았을 때 화면 */}
                        <Typography
                            variant="f18h20B"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            {`${indexer.name}${t(
                                "account.findIDFlavorText1"
                            )} ${fetchedEmail} ${t("common.is2")}`}
                        </Typography>
                        <TextField
                            value={fetchedEmail}
                            disabled
                            sx={{ mt: 3 }}
                            fullWidth
                        />

                        <Button
                            variant="contained"
                            sx={{ mt: 2.5 }}
                            onClick={() => {
                                navi("/login");
                            }}
                            fullWidth
                        >
                            {t("account.login")}
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="f18h20B" sx={{ width: "100%" }}>
                            {t("account.name")}
                        </Typography>
                        <TextField
                            placeholder={t("account.namePlaceholder") ?? ""}
                            value={indexer.name}
                            onChange={(e) =>
                                setIndexer((prev) => {
                                    return { ...prev, name: e.target.value };
                                })
                            }
                            sx={{ mt: 1 }}
                            autoComplete="name"
                        />

                        <Typography
                            variant="f18h20B"
                            sx={{ width: "100%", mt: 2.5 }}
                            component="div"
                        >
                            {t("account.phone")}
                        </Typography>
                        <TextField
                            placeholder={t("account.phonePlaceholder") ?? ""}
                            value={indexer.contact}
                            fullWidth
                            onChange={(e) =>
                                setIndexer((prev) => {
                                    return { ...prev, contact: e.target.value };
                                })
                            }
                            autoComplete="contact"
                            sx={{ mt: 1 }}
                        />

                        <Button
                            disabled={!indexer.contact || !indexer.name}
                            variant="contained"
                            fullWidth
                            onClick={submit}
                            sx={{ mt: 2.5 }}
                        >
                            {t("common.complete")}
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default FindID;
