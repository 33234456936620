import React, { useEffect, useState } from "react";

import { useSetRecoilState } from "recoil";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { Tradefirm } from "../api-interfaces/tradefirm";
import { getTradefirms, getTradeFirmDoccount } from "../apis/tradefirm";
import AddWithTextButton from "../components/buttons/AddWithTextButton";
import FilterButton from "../components/buttons/FilterButton";
import CompanyTileMain from "../components/frames/CompanyTileMain";
import { navThemeRecoil } from "../components/states/recoil";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

const DEFAULT_LIMIT = 96;
const INITIAL_DEFAULT_LIMIT = 6;

/**
 * TFcom, 무역상사들 뷰
 */
const Company: React.FC = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const companyFilterContents = ["회사명", "전문영역", "의뢰서비스"];
    const [selected, setSelected] = useState<number>(0);
    const setNavTheme = useSetRecoilState(navThemeRecoil);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const [companies, setCompanies] = useState<Tradefirm[]>([]);
    const [currentSkip, setCurrentSkip] = useState(0);
    const [tfLength, setTFLength] = useState<number>();
    const [isSortClicked, setIsSortClicked] = useState(true);
    const { t } = useTranslation();

    /**
     * 탭에 따른 소트필트 값 찾기
     * @param s - 화면에 있는 버튼의 인덱스 (현재: 기업명, 전문영역, 의뢰서비스)
     * @returns - Tradefirm interface 의 필드명
     */
    const getSortField = (s: number) => {
        if (s === 0) {
            return "companyName";
        }
        if (s === 1) {
            return "exportItem";
        }
        if (s === 2) {
            return "providingQuestService";
        }
    };

    // 카테고리별로 소트했을 때
    useEffect(() => {
        (async () => {
            if (isSortClicked) {
                setCurrentSkip(() => 0);
                const comp = await getTradefirms(
                    0,
                    INITIAL_DEFAULT_LIMIT,
                    getSortField(selected)
                );
                setCompanies(() => comp);
            }
        })();
    }, [isSortClicked, selected]);

    // 더보기를 눌렀을 때
    useEffect(() => {
        (async () => {
            if (!isSortClicked) {
                // NOTE 더보기하면 96개 씩 더 불러오기.
                const comp = await getTradefirms(
                    currentSkip,
                    currentSkip === 0 ? INITIAL_DEFAULT_LIMIT : DEFAULT_LIMIT,
                    getSortField(selected)
                );

                setCompanies((prev) => [...prev, ...comp]);
            }
        })();
    }, [currentSkip, selected, isSortClicked]);

    // 몇개인지 미리 받아오기
    useEffect(() => {
        (async () => {
            // 페이지네이션 끝 길이
            const cobj = await getTradeFirmDoccount();
            setTFLength(() => cobj.count);
            console.log("무역상사 전체갯수", cobj.count);
        })();
    }, []);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    const isEnd = tfLength && tfLength <= currentSkip + DEFAULT_LIMIT;

    const showMoreTF = () => {
        if (isEnd) {
            return;
        }
        setCurrentSkip((prev) =>
            currentSkip === 0
                ? prev + INITIAL_DEFAULT_LIMIT
                : prev + DEFAULT_LIMIT
        );
        setIsSortClicked(() => false);
    };

    const handleSort = (idx: number) => {
        setIsSortClicked(() => true);
        setSelected(() => idx);
    };

    return (
        <Grid
            container
            sx={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                p: isMobile ? 2 : 4,
                pt: 15,
            }}
        >
            <Grid
                item
                display={"flex"}
                justifyContent={"center"}
                textAlign={"center"}
                alignItems={"center"}
                width={"100%"}
                pl={1}
                sx={{
                    wordBreak: "break-word",
                }}
            >
                <Typography
                    color="mono.800"
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                >
                    {t("tradefirm.t")}
                </Typography>
            </Grid>
            <Grid item display={"flex"} justifyContent={"center"} pt={5}>
                {companyFilterContents.map((data, idx) => {
                    return (
                        <FilterButton
                            key={idx}
                            selected={selected === idx ? true : false}
                            text={t(`tradefirm.${data}`)}
                            onClick={() => handleSort(idx)}
                        />
                    );
                })}
            </Grid>
            <Grid
                container
                display={"flex"}
                width={"100%"}
                pt={5}
                flexDirection={isMobile ? "column" : "row"}
                spacing={2}
                pb={5}
            >
                {companies
                    ? companies.map((data, idx) => {
                          return (
                              <Grid
                                  item
                                  display={"flex"}
                                  xs={isMobile ? undefined : 4}
                                  key={`company_${idx}`}
                                  position={"relative"}
                                  onClick={() => setOpenModal(!openModal)}
                              >
                                  <CompanyTileMain
                                      data={data}
                                      isMobile={isMobile}
                                  />
                              </Grid>
                          );
                      })
                    : ""}
            </Grid>
            <Grid container>
                {!isEnd && (
                    <AddWithTextButton text={"더보기"} onClick={showMoreTF} />
                )}
            </Grid>
        </Grid>
    );
};

export default Company;
