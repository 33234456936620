import React from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../../theme";

interface OneTileC3Props {
    title: string;
    subtitle: string;
    desc1: string;
    desc2: string;
}

const OneTileC3: React.FC<OneTileC3Props> = ({
    title,
    desc1,
    desc2,
    subtitle,
}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                pr: 10,
                mb: 10,
                mt: isMobile && !title ? -5 : 0,
                width: 330,
            }}
        >
            {title ? (
                <Typography
                    sx={{ mb: 2, whiteSpace: "nowrap" }}
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                >
                    {title}
                </Typography>
            ) : (
                <Box sx={{ mb: 2, height: isMobile ? 0 : 64 }} />
            )}
            {subtitle && (
                <Typography
                    variant={isMobile ? "f20h28B" : "f24h36B"}
                    component="div"
                    sx={{ whiteSpace: "nowrap", mt: 1, mb: 2 }}
                >
                    {subtitle}
                </Typography>
            )}

            <Box>
                <Typography
                    variant={isMobile ? "f16h24B" : "f20h28B"}
                    component="div"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {desc1}
                </Typography>
                {desc2 && (
                    <Typography
                        variant={isMobile ? "f16h24B" : "f20h28B"}
                        component="div"
                        sx={{ whiteSpace: "nowrap" }}
                    >
                        {desc2}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

const CompanyInfoThird = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                maxWidth: 1264,
                width: "100%",
                flexDirection: "column",
                px: 3,
                mb: isMobile ? 5 : 20,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                }}
            >
                <OneTileC3
                    title="Vision"
                    subtitle=""
                    desc1={t("companyInfo.t301")}
                    desc2={t("companyInfo.t302")}
                />
                <OneTileC3
                    title="Mission"
                    subtitle=""
                    desc1={t("companyInfo.t311")}
                    desc2={t("companyInfo.t312")}
                />
            </Box>

            <Box display={"flex"} flexDirection={isMobile ? "column" : "row"}>
                <OneTileC3
                    title="Core Value"
                    subtitle={t("companyInfo.t321")}
                    desc1={t("companyInfo.t322")}
                    desc2=""
                />
                <OneTileC3
                    title=""
                    subtitle={t("companyInfo.t323")}
                    desc1={t("companyInfo.t324")}
                    desc2=""
                />
                <OneTileC3
                    title=""
                    subtitle={t("companyInfo.t325")}
                    desc1={t("companyInfo.t326")}
                    desc2=""
                />
            </Box>
        </Box>
    );
};

export default CompanyInfoThird;
