import React, { useState } from "react";

import { useRecoilValue } from "recoil";

import { ArrowDropDown } from "@mui/icons-material";
import { Avatar, Button, Grid, TextField, Typography } from "@mui/material";

import { Comment, ResponseGetPost } from "../../api-interfaces/post";
import { deleteOrEditComment, postComment } from "../../apis/post";
import ModalDialog from "../modals/ModalDialog";
import { userRecoil } from "../states/recoil";

export interface CommentBlockProps {
    commentIdx: number;
    comment: Comment;
    postId: string;
    setPost: React.Dispatch<React.SetStateAction<ResponseGetPost | undefined>>;
}

const CommentBlock: React.FC<CommentBlockProps> = ({
    commentIdx,
    comment,
    postId,
    setPost,
}) => {
    const [viewMore, setViewMore] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [targetReplyIdx, setTargetReplyIdx] = useState<number>();
    const [modalInput, setModalInput] = useState("");
    const [modalMode, setModalMode] = useState<"reply" | "edit">("reply");
    const userData = useRecoilValue(userRecoil);

    const submitCommentReply = () => {
        if (postId) {
            postComment(postId, {
                contents: modalInput,
                targetComment: { attachingIdxPath: [commentIdx] },
            })
                .then((updatedPost) => {
                    setModalInput("");
                    setPost((prev) => {
                        if (!prev) return prev;
                        else {
                            return {
                                ...prev,
                                comments: updatedPost.comments,
                                commentsNumber: updatedPost.commentsNumber,
                            };
                        }
                    });
                    alert("답글이 등록되었습니다");
                    setShowModal(false);
                })
                .catch((e) => {
                    console.log(e);
                    alert("댓글 작성에 실패했습니다.");
                });
        }
    };

    const onDelete = (replyIndex?: number) => {
        deleteOrEditComment(postId, {
            isDeleting: true,
            attachingIdxPath: [
                commentIdx,
                ...(replyIndex !== undefined ? [replyIndex] : []),
            ],
        })
            .then((updatedPost) => {
                setPost((prev) => {
                    if (!prev) return prev;
                    else {
                        return {
                            ...prev,
                            comments: updatedPost.comments,
                            commentsNumber: updatedPost.commentsNumber,
                        };
                    }
                });
                alert("삭제 되었습니다");
            })
            .catch((e) => {
                console.log(e);
                alert("댓글 삭제에 실패했습니다.");
            });
    };

    const onEdit = (replyIndex?: number) => {
        deleteOrEditComment(postId, {
            contents: modalInput,
            attachingIdxPath: [
                commentIdx,
                ...(replyIndex !== undefined ? [replyIndex] : []),
            ],
        })
            .then((updatedPost) => {
                setModalInput("");
                setPost((prev) => {
                    if (!prev) return prev;
                    else {
                        return {
                            ...prev,
                            comments: updatedPost.comments,
                            commentsNumber: updatedPost.commentsNumber,
                        };
                    }
                });
                alert("수정 되었습니다");
                setShowModal(false);
            })
            .catch((e) => {
                console.log(e);
                alert("댓글 수정에 실패했습니다.");
            });
    };

    const getDateText = (date: string) => {
        const today = new Date();
        const timeValue = new Date(date);

        const betweenTime = Math.floor(
            (today.getTime() - timeValue.getTime()) / 1000 / 60
        );
        if (betweenTime < 1) return "방금전";
        if (betweenTime < 60) {
            return `${betweenTime}분전`;
        }

        const betweenTimeHour = Math.floor(betweenTime / 60);
        if (betweenTimeHour < 24) {
            return `${betweenTimeHour}시간전`;
        }

        const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
        if (betweenTimeDay < 365) {
            return `${betweenTimeDay}일전`;
        }

        return `${Math.floor(betweenTimeDay / 365)}년전`;
    };

    return (
        <>
            <Grid mt={2}>
                {/* 프로필, 닉네임 */}
                <Grid container alignItems={"center"}>
                    <Avatar
                        sx={{ width: "24px", height: "24px" }}
                        src={comment.profileImage}
                    />
                    <Grid ml={1}>
                        <Typography variant="f12h20">{comment.name}</Typography>
                    </Grid>
                </Grid>
                <Grid mt={1}>
                    <Typography
                        variant="f14h20"
                        color={comment.isDeleted ? "mono.600" : "inherit"}
                    >
                        {comment.contents}
                    </Typography>
                </Grid>
                <Grid mt={1} container alignItems={"center"}>
                    <Typography variant="f14h20" color={"mono.600"}>
                        {getDateText(comment.timestamp)}
                    </Typography>
                    {userData?._id === comment.userID && !comment.isDeleted && (
                        <Grid
                            ml={2}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                setModalMode("edit");
                                setTargetReplyIdx(undefined);
                                setModalInput(comment.contents);
                                setShowModal(true);
                            }}
                        >
                            <Typography variant="f14h20" color={"mono.600"}>
                                수정
                            </Typography>
                        </Grid>
                    )}
                    {!comment.isDeleted && (
                        <Grid
                            ml={2}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                setModalMode("reply");
                                setShowModal(true);
                            }}
                        >
                            <Typography variant="f14h20" color={"mono.600"}>
                                답글달기
                            </Typography>
                        </Grid>
                    )}
                    {userData?._id === comment.userID && !comment.isDeleted && (
                        <Grid
                            ml={2}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                onDelete();
                            }}
                        >
                            <Typography variant="f14h20" color={"mono.600"}>
                                삭제
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {comment.replies
                ?.slice(0, viewMore ? undefined : 1)
                .map((reply, i) => (
                    <Grid mt={2} ml={3} key={`reply-${i}`}>
                        {/* 프로필, 닉네임 */}
                        <Grid container alignItems={"center"}>
                            <Avatar
                                sx={{ width: "24px", height: "24px" }}
                                src={reply.profileImage}
                            />
                            <Grid ml={1}>
                                <Typography variant="f12h20">
                                    {reply.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid mt={1}>
                            <Typography
                                variant="f14h20"
                                color={reply.isDeleted ? "mono.600" : "inherit"}
                            >
                                {reply.contents}
                            </Typography>
                        </Grid>
                        <Grid mt={1} container alignItems={"center"}>
                            <Typography variant="f14h20" color={"mono.600"}>
                                {getDateText(reply.timestamp)}
                            </Typography>
                            {userData?._id === reply.userID &&
                                !reply.isDeleted && (
                                    <>
                                        <Grid
                                            ml={2}
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setModalMode("edit");
                                                setTargetReplyIdx(i);
                                                setModalInput(reply.contents);
                                                setShowModal(true);
                                            }}
                                        >
                                            <Typography
                                                variant="f14h20"
                                                color={"mono.600"}
                                            >
                                                수정
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            ml={2}
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                onDelete(i);
                                            }}
                                        >
                                            <Typography
                                                variant="f14h20"
                                                color={"mono.600"}
                                            >
                                                삭제
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            {/* <Grid ml={2} sx={{ cursor: "pointer" }}>
                            <Typography variant="f14h20" color={"mono.600"}>
                                답글달기
                            </Typography>
                        </Grid> */}
                        </Grid>
                    </Grid>
                ))}
            {!viewMore && comment.replies && comment.replies?.length > 1 && (
                <Button onClick={() => setViewMore(true)} sx={{ ml: 2 }}>
                    <Typography variant="f14h20" color={"mono.600"}>
                        대댓글 더보기
                    </Typography>
                    <ArrowDropDown sx={{ color: "mono.600" }} />
                </Button>
            )}
            <ModalDialog
                isMobile={false}
                title={modalMode === "edit" ? "댓글 수정" : "답글달기"}
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                    setModalInput("");
                }}
                fullScreen
                PaperProps={{
                    sx: {
                        height: "auto",
                        mt: "5vh",
                        borderRadius: "0px 0px 0px 0px",
                    },
                }}
            >
                <Grid container flexDirection={"column"}>
                    <Grid mt={1} mb={2}>
                        <TextField
                            placeholder="댓글을 입력해주세요"
                            value={modalInput}
                            onChange={(e) => setModalInput(e.target.value)}
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        onClick={
                            modalMode === "reply"
                                ? submitCommentReply
                                : () => onEdit(targetReplyIdx)
                        }
                    >
                        완료
                    </Button>
                </Grid>
            </ModalDialog>
        </>
    );
};

export default CommentBlock;
