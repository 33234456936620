/* eslint-disable prettier/prettier */
export type CommunityTileContainerType = "main" | "sub" | "default" | "sub2";
export interface CommunityDataType {
    /**
     * escape 문자 `\n`으로 강제개행 구별
     */
    text: string;

    /**
     * 작성자
     */
    from: string;
    /**
     * 카테고리 종류 ex. BLOG
     */
    type: CommunityCategory;

    /**
     * 이미지 src uri
     */
    image: string;

    /**
     * 클릭했을 때 넘어갈 외부 링크
     */
    link?: string;
}

/**
 * 커뮤니티 카테고리 타입
 *
 * @remarks
 * {@link CommunityDataType.type} 의 타입
 */
export type CommunityCategory = "INFO" | "REVIEW" | "BLOG" | "TRADEINFO";

/**
 * {@link CommunityCategory} 에 해당하는 레이블들
 */
export const CommunityCategoryLabelMap = {
    ALL: "전체",
    INFO: "소식",
    REVIEW: "리뷰",
    BLOG: "블로그",
    TRADEINFO: "무역정보",
};

/**
 * 비교를 위한 어레이
 */
export const communityFilterKeys = Object.keys(CommunityCategoryLabelMap);
/**
 * 필터에 들어갈 array
 */
export const communityFilterContents = Object.values(CommunityCategoryLabelMap);

/**
 * 랜딩페이지에 디폴트로 들어가는 커뮤니티 데이터
 */
export const LandingPageDefaultCommunity: CommunityDataType[] = [
    {
        text: "브릿지오버, 일본 아스카인덱스와 전략적 파트너십 체결",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_116.jpg",
        link: "https://blog.naver.com/bridgeover01/223344731734",
    },
    {
        text: "브릿지오버, 아람코 연계사 EROG와 K-산소발생기 거래 성사로 중동 진출",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_115.jpg",
        link: "https://blog.naver.com/bridgeover01/223352735970",
    },
    {
        text: "[브릿지오버 뉴스] 임팩트 테마별 엑셀러레이팅(인구위기극복, 정보격차해소 분야) 데모데이 성공리 마쳐",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_117.png",
        link: "https://blog.naver.com/bridgeover01/223286768492",
    },
    {
        text: "사단법인 글로벌비지니스컨설팅협회(GBC협회) 창립총회",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_114.jpg",
        link: "https://blog.naver.com/bridgeover01/223289691800",
    },
    {
        text: "한국과 영국의 무역 교두보 김태호 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_113.jpg",
        link: "https://blog.naver.com/bridgeover01/223279103958",
    },
    {
        text: "[무역정보] 글로벌셀러가 알아야할 전자상거래 수출통관 TIP",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_112.jpg",
        link: "https://blog.naver.com/bridgeover01/223279829154",
    },
    {
        text: "[관세 & 무역뉴스] 수출통관 사무처리에 관한 고시 개정에 따른 전자 문서 변경 (전자상거래 수출, 12.15(금) 20:00)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_111.jpg",
        link: "https://blog.naver.com/bridgeover01/223279776476",
    },
    {
        text: "[관세정보] 한-인도 CEPA C/O 관련 협정세율 적용 시 '인도 발행 C/O번호' 작성 (23.11.30 최종 고지 분)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_110.jpg",
        link: "https://blog.naver.com/bridgeover01/223279158055",
    },
    {
        text: "[관세정보] 수출신고서 포장종류부호 기재관련 업무처리",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_109.png",
        link: "https://blog.naver.com/bridgeover01/223276876714",
    },
    {
        text: "[관세 & 무역뉴스] 영문수출신고필증 발급",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_108.png",
        link: "https://blog.naver.com/bridgeover01/223270804161",
    },
    {
        text: "[관세 & 무역뉴스] 통관고유부호 신청 후 반려, 재신청 방법",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_107.png",
        link: "https://blog.naver.com/bridgeover01/223270799115",
    },
    {
        text: "[관세 & 무역뉴스] 통관고유부호 정보수정",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_106.png",
        link: "https://blog.naver.com/bridgeover01/223270795697",
    },
    {
        text: "[관세 & 무역뉴스] 사업자 통관고유부호 발급",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_105.png",
        link: "https://blog.naver.com/bridgeover01/223270787731",
    },
    {
        text: "[관세뉴스] 할당관세 규정 일부개정에 따른 변경사항",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_104.jpg",
        link: "https://blog.naver.com/bridgeover01/223270773320",
    },
    {
        text: "[무역뉴스] 미국 물류 자동화 시장동향 (23' 4분기)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_103.jpg",
        link: "https://blog.naver.com/bridgeover01/223266655850",
    },
    {
        text: "[브릿지오버 스토리] 매경: (주)브릿지오버, (주)혜인 독일 융하인리히 전동 핸드 파레트트럭 AME15 판매 대행 협약 체결",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_102.jpg",
        link: "https://blog.naver.com/bridgeover01/223269719123",
    },
    {
        text: "[관세 & 무역뉴스] 수출통관 사무처리에 관한 고시 개정에 따른 전자 문서 변경 안내(전자상거래 수출)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_101.jpg",
        link: "https://blog.naver.com/bridgeover01/223255294367",
    },
    {
        text: "[관세뉴스] 모바일 관세청 / 개인납세자 관세 납부 서비스 런칭",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_100.png",
        link: "https://blog.naver.com/bridgeover01/223255299680",
    },
    {
        text: "[관세정보] 한-인도 원산지증명서 전자시스템(EODES)에 따른 '원산지증명서 발급'시 목적항 코드 추가(rev)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_99.jpg",
        link: "https://blog.naver.com/bridgeover01/223258242189",
    },
    {
        text: "[무역정보] 원산지 전자교환 시스템 (EODES/Electronic Origin Data Exchange System: 원산지 정보를 전자적으로 실시간 교환하는 시스템)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_98.jpg",
        link: "https://blog.naver.com/bridgeover01/223259280429",
    },
    {
        text: "[관세뉴스] 협정관세 적용 신청서서식 변경 & 한-인도 CEPA 협정관세 적용",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_97.jpg",
        link: "https://blog.naver.com/bridgeover01/223259294423",
    },
    {
        text: "스마트팜, 스마트농업 솔루션에서 축산 가공 식품까지 : 유럽/중남미/B2B수출입 컨설팅 전문가, 오지영 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_96.jpg",
        link: "https://blog.naver.com/bridgeover01/223261088831",
    },
    {
        text: "2023.10 브릿지오버 뉴스: 안산 기계/장비 물류센터 확장",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_95.png",
        link: "https://blog.naver.com/bridgeover01/223252152859",
    },
    {
        text: "2023.09 브릿지오버 뉴스: 안산 3PL창고 오프닝 세리머니",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_94.jpg",
        link: "https://blog.naver.com/bridgeover01/223220733376",
    },
    {
        text: "제 1회 한국 - 우크라이나 뉴빌딩협회 포럼 성료",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_93.jpg",
        link: "https://blog.naver.com/bridgeover01/223253654039",
    },
    {
        text: "2023.10.31 호남수출포럼 by. GBC총동문회 & GBC협회 추진위",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_92.jpg",
        link: "https://blog.naver.com/bridgeover01/223253779218",
    },
    {
        text: "[무역정보] 컨테이너 이동 중 발행하는 지연료? DEM & DET",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_91.jpg",
        link: "https://blog.naver.com/bridgeover01/223234730766",
    },
    {
        text: "[관세&무역소식] 보세공장 특례 강화 & 규제완화",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_90.png",
        link: "https://blog.naver.com/bridgeover01/223247392188",
    },
    {
        text: "[관세뉴스] 특허보세구역운영에 관한 고시",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223234761706",
    },
    {
        text: "[관세뉴스] 보세공장 운영에 관한 고시",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223234767202",
    },
    {
        text: "[관세뉴스] House AWB 추가 정정신청 시, 신고내역 자동생성",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223235530460",
    },
    {
        text: "[관세뉴스] 화물 전자신고 및 관련 출력서식 용어 변경",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223235545559",
    },
    {
        text: "[기업소개] 포엑스 무역관 협동조합",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_89.png",
        link: "https://blog.naver.com/bridgeover01/223254024912",
    },
    {
        text: "떠오르는 신사업: ESG 녹생경영 Master, 장병일 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_88.jpg",
        link: "https://blog.naver.com/bridgeover01/223203620674",
    },
    {
        text: "[브릿지오버] 2023 스타트업 코리아 전략회의 with BRIDGEOVER",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_87.png",
        link: "https://blog.naver.com/bridgeover01/223202093056",
    },
    {
        text: "[GBC] GBC 총동문회 14기 환영식 & 간담회 성료",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_86.jpg",
        link: "https://blog.naver.com/bridgeover01/223194797875",
    },
    {
        text: "아마존/이베이 해외 유통채널 전문가 : 김태경 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_85.jpg",
        link: "https://blog.naver.com/bridgeover01/223189848870",
    },
    {
        text: "한국 기계산업 온라인 무역 NO.1 : 코머신 박은철 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_84.jpg",
        link: "https://blog.naver.com/bridgeover01/223189281930",
    },
    {
        text: "[기업탐방] 우리나라 모터계의 새바람, DKM MOTOR",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_83.jpg",
        link: "https://blog.naver.com/bridgeover01/223183412641",
    },
    {
        text: "중미 경제의 중심, 코스타리카 홍성용 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_80.jpeg",
        link: "https://blog.naver.com/bridgeover01/223177836383",
    },
    {
        text: "사단법인 글로벌 비지니스컨설턴트협회 창립총회",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_82.jpeg",
        link: "https://blog.naver.com/bridgeover01/223171711878",
    },
    {
        text: "GMA Partners 정회원 정기모임",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_81.jpeg",
        link: "https://blog.naver.com/bridgeover01/223171701856",
    },
    {
        text: "한,중 관세 1인자, 임창환 관세사님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_79.png",
        link: "https://blog.naver.com/bridgeover01/223161566454",
    },
    {
        text: "이베이 셀러가이드 : CS 관리",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_78.png",
        link: "https://blog.naver.com/bridgeover01/223161864199",
    },
    {
        text: "이베이 셀러가이드 : 퍼포먼스 관리",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_77.png",
        link: "https://blog.naver.com/bridgeover01/223161860453",
    },
    {
        text: "2023.07 브릿지오버: 기업부설연구소 설립",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223167555548",
    },
    {
        text: "[서비스의뢰] 정밀 금형 가공 기술 대표회사, (주)제일엠엔에스",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_76.png",
        link: "https://blog.naver.com/bridgeover01/223154814019",
    },
    {
        text: "지나온 30년의 신사업 여정을 돌아보며, 앞으로 30년을 도전하시는 글로벌 비즈니스 전문가 GMA 전용하 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_75.jpeg",
        link: "https://blog.naver.com/bridgeover01/223158331222",
    },
    {
        text: "23년 하반기 물류동향(해상&상공)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155050459",
    },
    {
        text: "23년 물류 현재 상황",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155026328",
    },
    {
        text: "23'상반기 글로벌 경제/물류 동향",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155011632",
    },
    {
        text: "베트남, 아프리카, 중국 특송 전문 : KORTOP GLS",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_74.jpeg",
        link: "https://blog.naver.com/bridgeover01/223154210331",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기: FCL VS LCL VS 콘솔(feat.훈증)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_73.png",
        link: "https://blog.naver.com/bridgeover01/223152966145",
    },
    {
        text: "[서비스의뢰]K-모터 대표회사, (주)DKM",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_72.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155207681",
    },
    {
        text: "[무역정보] 구매확인서 : 간접수출",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_71.jpeg",
        link: "https://blog.naver.com/bridgeover01/223143444412",
    },
    {
        text: "[무역정보] 구매확인서 : INTRO",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_71.jpeg",
        link: "https://blog.naver.com/bridgeover01/223143339039",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : EXW는 누구를 위한걸까?(Feat.코로나 마스크 2020년 파동이야기)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_70.jpeg",
        link: "https://blog.naver.com/bridgeover01/223142511004",
    },
    {
        text: "융하인리히 : 2023.07월 프로모션",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_69.jpg",
        link: "https://blog.naver.com/bridgeover01/223134447220",
    },
    {
        text: "6월 카드뉴스 배정홍 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_68.jpeg",
        link: "https://blog.naver.com/bridgeover01/223132644994",
    },
    {
        text: "5월 카드뉴스 남종석 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_67.jpeg",
        link: "https://blog.naver.com/bridgeover01/223132643461",
    },
    {
        text: "5월 카드뉴스 박기홍 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_66.jpeg",
        link: "https://blog.naver.com/bridgeover01/223132640107",
    },
    {
        text: "[모집] 브릿지오버 x 하나금융그룹: 경력보유여성 & 장애인",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_58.jpeg",
        link: "https://blog.naver.com/bridgeover01/223133392396",
    },
    {
        text: "[브릿지오버] ISO9001인증 획득",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_57.png",
        link: "https://blog.naver.com/bridgeover01/223141302561",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : DDP with Amazon",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_65.jpeg",
        link: "https://blog.naver.com/bridgeover01/223136085920",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : FOB VS CIF",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_64.png",
        link: "https://blog.naver.com/bridgeover01/223135085788",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : 내국 신용장(Local L/C) vs 구매확인서 with 벤더회사",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223124934390",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 7: Story of B/L -2",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_62.png",
        link: "https://blog.naver.com/bridgeover01/223120950494",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 6: Story of B/L -1",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_61.png",
        link: "https://blog.naver.com/bridgeover01/223118696136",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 5: All about 'HS CODE' 국제통일 상품분류체계",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_60.png",
        link: "https://blog.naver.com/bridgeover01/223116678519",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 4: MSDS와 컨테이너 위험물 검사",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_59.png",
        link: "https://blog.naver.com/bridgeover01/223114004683",
    },
    {
        text: "인도 홈쇼핑계의 황태자, '코끼리를 올라타라' 저자 '신시열' 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_56.jpeg",
        link: "https://blog.naver.com/bridgeover01/223129483188",
    },
    {
        text: "국내외를 아우르며 왕성한 활동을 펼치고 계시는\n(주)국제컨설팅의 '남경복' 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_55.jpeg",
        link: "https://blog.naver.com/bridgeover01/223110471478",
    },
    {
        text: "[트레이드포스] 벤처기업협회 추천 스타트업 30곳 선정!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_54.png",
        link: "http://www.startupdaily.kr/news/articleView.html?idxno=3399",
    },
    {
        text: "[EXPO] 2023 AMAZON PRIME EXPO in SBA서울경제진흥원: INTRO",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_53.jpeg",
        link: "https://blog.naver.com/bridgeover01/223109052302",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 3: EDI & VGM & TAREWEIGHT",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223105861391",
    },
    {
        text: "GMA연합 워크샵 및 총회 참석 [Global Marketing Association",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_52.jpeg",
        link: "https://blog.naver.com/bridgeover01/223100601336",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 2: 화물선적 & 스케줄 & 항편",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223103836702",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 1: 화주 & 포워딩",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223103109644",
    },
    {
        text: "[무역정보] Everything of Amazon step.2: 아마존 물류?",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_51.jpeg",
        link: "https://blog.naver.com/bridgeover01/223098207260",
    },
    {
        text: "[무역정보] Everything of Amazon step.1: 아마존 용어 정리 & 최근 아마존 셀러 모집 동향",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_51.jpeg",
        link: "https://blog.naver.com/bridgeover01/223098196424",
    },
    {
        text: "[무역정보] Everything of Container for 2023: 컨테이너의 모든 것.",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223093468099",
    },
    {
        text: "월드옥타(OKTA) 제24차 세계대표자대회 및 수출상담회",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_48.jpeg",
        link: "https://blog.naver.com/bridgeover01/223083547835",
    },
    {
        text: "GBC 협회 추진위원회 워크샵",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_47.jpeg",
        link: "https://blog.naver.com/bridgeover01/223083629862",
    },
    {
        text: "안틸벤처스 CEO 방문",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_46.jpeg",
        link: "https://blog.naver.com/bridgeover01/223083561395",
    },
    {
        text: "중남미, 중동 전문가, 신약 해외 인허가부터 해외 수출 독점판매 계약 체결 등 해외 무역계의 만능 영업 전문가! Samuel SW Baik",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_45.jpeg",
        link: "https://blog.naver.com/bridgeover01/223079132870",
    },
    {
        text: "GBC-GMA-OKTA 글로벌 컨설팅 협력 논의",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_44.jpeg",
        link: "https://blog.naver.com/bridgeover01/223078786832",
    },
    {
        text: "해외발전소, 해외 공장 설비에 특화된 기술자문부터 해외B2B 바이어 연결까지! 설비산업의 전문가, 홍지훈 전문가님!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_43-2.jpeg",
        link: "https://blog.naver.com/bridgeover01/223070263480",
    },
    {
        text: "동남아시아 온라인 전자상거래의 허브, 이커머스계 문성호 전문가님!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_42-2.jpeg",
        link: "https://blog.naver.com/bridgeover01/223070223652",
    },
    {
        text: "GMA 파트너스 미국 및 베트남 수출 협력 미팅",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_41.jpeg",
        link: "https://blog.naver.com/bridgeover01/223066798019",
    },
    {
        text: "동료를 모집하고 있습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_40.png",
        link: "https://blog.naver.com/bridgeover01/223062867335",
    },
    {
        text: "융하인리히 APAC 총괄 디렉터의 브릿지오버 판교 본사 방문",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_39.jpeg",
        link: "https://blog.naver.com/bridgeover01/223062909435",
    },
    {
        text: "GBC 총동문회 간담회 및 13기 GBC 원우 환영식",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_38.jpeg",
        link: "https://blog.naver.com/bridgeover01/223062884132",
    },
    {
        text: "대만 바이어 방문 - 라라이프 대만 수입 건",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_37.jpeg",
        link: "https://blog.naver.com/bridgeover01/223062930401",
    },
    {
        text: "[트레이드포스] 인바운드 마케팅 & 무역 사무 포지션을 채용합니다!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_30.png",
        link: "https://blog.naver.com/bridgeover01/223059903852",
    },
    {
        text: "[트레이드포스] 플랫폼 아웃바운드 영업 채용 공고!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_30.png",
        link: "https://blog.naver.com/bridgeover01/223059794052",
    },
    {
        text: "KIMES 2023을 방문하였습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_36.jpeg",
        link: "https://blog.naver.com/bridgeover01/223056363133",
    },
    {
        text: "옥서스 해외 시장 진출 협의 및 공장 투어",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_35.jpeg",
        link: "https://blog.naver.com/bridgeover01/223054220787",
    },
    {
        text: "아마존 호미사장님 - Leading Trust 김태경 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_34.png",
        link: "https://blog.naver.com/bridgeover01/223049823668",
    },
    {
        text: "(주)인앤아웃코퍼레이션 - 층간소음 없는 무음의 시작 '뭄뭄실내화' 수출의뢰",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_33.jpeg",
        link: "https://blog.naver.com/bridgeover01/223047386045",
    },
    {
        text: "GBC - GMA 북미 시장 공동 진출 방안 협의",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_31.png",
        link: "https://blog.naver.com/bridgeover01/223046909034",
    },
    {
        text: "[무역사기] 필리핀 수출 무역 사기가 기승을 부리고 있습니다.",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_32.jpeg",
        link: "https://blog.naver.com/bridgeover01/223043864923",
    },
    {
        text: "[이슈] 증가하는 글로벌 해외 직구 시장, 한국에서는 왜 어려울까?",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_32.jpeg",
        link: "https://blog.naver.com/bridgeover01/223043874064",
    },
    {
        text: "(주)브릿지오버 - 트레이드포스 1년 6개월간의 성적표",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_30.png",
        link: "https://blog.naver.com/bridgeover01/223040685621",
    },
    {
        text: "(주)파워플레이어 - 유기농 클린 뷰티 브랜드 온그리디언츠 'ONGREDIENTS'",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_29.png",
        link: "https://blog.naver.com/bridgeover01/223043714902",
    },
    {
        text: "(주)휴젝트 - 세계 최대의 에너지 하베스팅 원천 기술 보유 기업",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_28.png",
        link: "https://blog.naver.com/bridgeover01/223043707722",
    },
    {
        text: "농축산물부터 에너지 및 스마트팜 솔루션의 소재까지 유럽/중남미의 B2B 수출입 전문가!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_27.jpeg",
        link: "https://blog.naver.com/bridgeover01/223038230663",
    },
    {
        text: "GBC 총동문회 튀르기예 지진 성금 전달식",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_26.png",
        link: "https://blog.naver.com/bridgeover01/223037729877",
    },
    {
        text: "(주)브릿지오버 소셜 벤처 판별",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_25.png",
        link: "https://blog.naver.com/bridgeover01/223036887163",
    },
    {
        text: "무역의 A-Z! 기본 제반 사항에 대한 준비를 타파할 명재호 관세사님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_24.jpeg",
        link: "https://blog.naver.com/bridgeover01/223036074902",
    },
    {
        text: "브릿지오버 - 옥서스 해외 수출 협약 MOU 체결식",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_23.png",
        link: "https://blog.naver.com/bridgeover01/223025806064",
    },
    {
        text: "코리아빌드위크를 방문하였습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_22.jpeg",
        link: "https://blog.naver.com/bridgeover01/223018685634",
    },
    {
        text: "글로벌마케팅협회 GMA에서 (주)브릿지오버 방문해주셨습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_21.jpeg",
        link: "https://blog.naver.com/bridgeover01/223018674030",
    },
    {
        text: "(주)입셀론바이오 - 카이스트 피부 침투 기술과 의학 전문의 창업가와의 만남",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_20.jpeg",
        link: "https://blog.naver.com/bridgeover01/223007152150",
    },
    {
        text: "중국 수출입 전문가! 해외박람회부터 중국유통망을 확보하신 최승이 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_19.jpeg",
        link: "https://blog.naver.com/bridgeover01/222996084655",
    },
    {
        text: "아시아 12개국의 시장 섭렵! B2B 핵심 산업군의 베테랑, 조삼현 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_18.jpeg",
        link: "https://blog.naver.com/bridgeover01/222998989878",
    },
    {
        text: "KAIST Alumni News - (주)브릿지오버 곽인철 대표",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_17.png",
        link: "https://blog.naver.com/bridgeover01/222995630109",
    },
    {
        text: "준형텍스(주) - 대구 소재의 섬유 전문 기업",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_16.jpg",
        link: "https://blog.naver.com/bridgeover01/222994233272",
    },
    {
        text: "브리스텍 - CES 2회 연속 혁신상에 빛나는 스마트 욕창매트 기업",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_15.jpeg",
        link: "https://blog.naver.com/bridgeover01/222994222331",
    },
    {
        text: "[공지]융하인리히 공식 온라인 판매점! 전동 핸드파레트 트럭 판매 게시!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_14.jpeg",
        link: "https://blog.naver.com/bridgeover01/222993677969",
    },
    {
        text: "(주)옥서스에서 방문해주셨습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_13.png",
        link: "https://blog.naver.com/bridgeover01/222988416823",
    },
    {
        text: "SK초대 바르샤바 지사장, 폴란드 및 유럽 최고의 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_12.jpg",
        link: "https://blog.naver.com/bridgeover01/222988426523",
    },
    {
        text: "아마존/이베이 해외 유통채널! 이제는 전문가와 함께~",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_11.jpg",
        link: "https://blog.naver.com/bridgeover01/222985790611",
    },
    {
        text: "B2B 디지털 무역 플랫폼 트레이드포스,\n한국산업대전 참가",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_2.jpeg",
        link: "https://blog.naver.com/bridgeover01/222911596071",
    },
    {
        text: "트레이드포스,\n제조업 해외 무역 책임지는 디지털 수출입 플랫폼",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_5.png",
        link: "https://www.psnews.co.kr/news/articleView.html?idxno=2015050",
    },
    {
        text: "클로즈업 기업현장\n 프로그램에 방영된 트레이드포스\n운영사 (주)브릿지오버",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_7.png",
        link: "https://blog.naver.com/bridgeover01/222911616558",
    },
    {
        text: "(주)브릿지오버 사회적기업 성장지원센터\n'소셜캠퍼스 온'기업선정",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_3.png",
        link: "https://blog.naver.com/bridgeover01/222695384575",
    },
    {
        text: "글로벌 비즈니트 컨설턴트(GBC),\n총동문회 부회장 취임",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_4.png",
        link: "https://blog.naver.com/bridgeover01/222806434699",
    },
    {
        text: "트레이드포스 서비스 소개\n[GBC 정례포럼]",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_6.jpeg",
        link: "https://blog.naver.com/bridgeover01/222911606491",
    },
    {
        text: "KOTRA GBC 총 동문회\n회장단의 원우사 브릿지오버 방문",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_8.jpeg",
        link: "https://blog.naver.com/bridgeover01/222977325002",
    },
    {
        text: "트레드링스에서 브릿지오버를 방문해주셨습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_9.jpeg",
        link: "https://blog.naver.com/bridgeover01/222977326420",
    },
    {
        text: "GBC 글로벌 컨설팅 위원회\nKICK OFF 미팅_230109",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_10.png",
        link: "https://blog.naver.com/bridgeover01/222980802776",
    },
    {
        text: "(주)브릿지오버, 넥스트라이즈 참가 통한 수출입 전문가 모집 박차",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_1.jpeg",
        link: "https://blog.naver.com/bridgeover01/222806426228",
    },
];

/**
 * 커뮤니티 페이지에 스태틱으로 들어가는 디폴트데이터
 */
export const CommunityPageDefaultData: CommunityDataType[] = [
    {
        text: "브릿지오버, 일본 아스카인덱스와 전략적 파트너십 체결",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_116.jpg",
        link: "https://blog.naver.com/bridgeover01/223344731734",
    },
    {
        text: "브릿지오버, 아람코 연계사 EROG와 K-산소발생기 거래 성사로 중동 진출",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_115.jpg",
        link: "https://blog.naver.com/bridgeover01/223352735970",
    },
    {
        text: "[브릿지오버 뉴스] 임팩트 테마별 엑셀러레이팅(인구위기극복, 정보격차해소 분야) 데모데이 성공리 마쳐",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_117.png",
        link: "https://blog.naver.com/bridgeover01/223286768492",
    },
    {
        text: "사단법인 글로벌비지니스컨설팅협회(GBC협회) 창립총회",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_114.jpg",
        link: "https://blog.naver.com/bridgeover01/223289691800",
    },
    {
        text: "한국과 영국의 무역 교두보 김태호 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_113.jpg",
        link: "https://blog.naver.com/bridgeover01/223279103958",
    },
    {
        text: "[무역정보] 글로벌셀러가 알아야할 전자상거래 수출통관 TIP",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_112.jpg",
        link: "https://blog.naver.com/bridgeover01/223279829154",
    },
    {
        text: "[관세 & 무역뉴스] 수출통관 사무처리에 관한 고시 개정에 따른 전자 문서 변경 (전자상거래 수출, 12.15(금) 20:00)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_111.jpg",
        link: "https://blog.naver.com/bridgeover01/223279776476",
    },
    {
        text: "[관세정보] 한-인도 CEPA C/O 관련 협정세율 적용 시 '인도 발행 C/O번호' 작성 (23.11.30 최종 고지 분)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_110.jpg",
        link: "https://blog.naver.com/bridgeover01/223279158055",
    },
    {
        text: "[관세정보] 수출신고서 포장종류부호 기재관련 업무처리",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_109.png",
        link: "https://blog.naver.com/bridgeover01/223276876714",
    },
    {
        text: "[관세 & 무역뉴스] 영문수출신고필증 발급",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_108.png",
        link: "https://blog.naver.com/bridgeover01/223270804161",
    },
    {
        text: "[관세 & 무역뉴스] 통관고유부호 신청 후 반려, 재신청 방법",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_107.png",
        link: "https://blog.naver.com/bridgeover01/223270799115",
    },
    {
        text: "[관세 & 무역뉴스] 통관고유부호 정보수정",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_106.png",
        link: "https://blog.naver.com/bridgeover01/223270795697",
    },
    {
        text: "[관세 & 무역뉴스] 사업자 통관고유부호 발급",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_105.png",
        link: "https://blog.naver.com/bridgeover01/223270787731",
    },
    {
        text: "[관세뉴스] 할당관세 규정 일부개정에 따른 변경사항",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_104.jpg",
        link: "https://blog.naver.com/bridgeover01/223270773320",
    },
    {
        text: "[무역뉴스] 미국 물류 자동화 시장동향 (23' 4분기)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_103.jpg",
        link: "https://blog.naver.com/bridgeover01/223266655850",
    },
    {
        text: "[브릿지오버 스토리] 매경: (주)브릿지오버, (주)혜인 독일 융하인리히 전동 핸드 파레트트럭 AME15 판매 대행 협약 체결",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_102.jpg",
        link: "https://blog.naver.com/bridgeover01/223269719123",
    },
    {
        text: "[관세 & 무역뉴스] 수출통관 사무처리에 관한 고시 개정에 따른 전자 문서 변경 안내(전자상거래 수출)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_101.jpg",
        link: "https://blog.naver.com/bridgeover01/223255294367",
    },
    {
        text: "[관세뉴스] 모바일 관세청 / 개인납세자 관세 납부 서비스 런칭",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_100.png",
        link: "https://blog.naver.com/bridgeover01/223255299680",
    },
    {
        text: "[관세정보] 한-인도 원산지증명서 전자시스템(EODES)에 따른 '원산지증명서 발급'시 목적항 코드 추가(rev)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_99.jpg",
        link: "https://blog.naver.com/bridgeover01/223258242189",
    },
    {
        text: "[무역정보] 원산지 전자교환 시스템 (EODES/Electronic Origin Data Exchange System: 원산지 정보를 전자적으로 실시간 교환하는 시스템)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_98.jpg",
        link: "https://blog.naver.com/bridgeover01/223259280429",
    },
    {
        text: "[관세뉴스] 협정관세 적용 신청서서식 변경 & 한-인도 CEPA 협정관세 적용",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_97.jpg",
        link: "https://blog.naver.com/bridgeover01/223259294423",
    },
    {
        text: "스마트팜, 스마트농업 솔루션에서 축산 가공 식품까지 : 유럽/중남미/B2B수출입 컨설팅 전문가, 오지영 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_96.jpg",
        link: "https://blog.naver.com/bridgeover01/223261088831",
    },
    {
        text: "2023.10 브릿지오버 뉴스: 안산 기계/장비 물류센터 확장",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_95.png",
        link: "https://blog.naver.com/bridgeover01/223252152859",
    },
    {
        text: "2023.09 브릿지오버 뉴스: 안산 3PL창고 오프닝 세리머니",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_94.jpg",
        link: "https://blog.naver.com/bridgeover01/223220733376",
    },
    {
        text: "제 1회 한국 - 우크라이나 뉴빌딩협회 포럼 성료",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_93.jpg",
        link: "https://blog.naver.com/bridgeover01/223253654039",
    },
    {
        text: "2023.10.31 호남수출포럼 by. GBC총동문회 & GBC협회 추진위",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_92.jpg",
        link: "https://blog.naver.com/bridgeover01/223253779218",
    },
    {
        text: "[무역정보] 컨테이너 이동 중 발행하는 지연료? DEM & DET",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_91.jpg",
        link: "https://blog.naver.com/bridgeover01/223234730766",
    },
    {
        text: "[관세&무역소식] 보세공장 특례 강화 & 규제완화",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_90.png",
        link: "https://blog.naver.com/bridgeover01/223247392188",
    },
    {
        text: "[관세뉴스] 특허보세구역운영에 관한 고시",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223234761706",
    },
    {
        text: "[관세뉴스] 보세공장 운영에 관한 고시",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223234767202",
    },
    {
        text: "[관세뉴스] House AWB 추가 정정신청 시, 신고내역 자동생성",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223235530460",
    },
    {
        text: "[관세뉴스] 화물 전자신고 및 관련 출력서식 용어 변경",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223235545559",
    },
    {
        text: "[기업소개] 포엑스 무역관 협동조합",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_89.png",
        link: "https://blog.naver.com/bridgeover01/223254024912",
    },
    {
        text: "떠오르는 신사업: ESG 녹생경영 Master, 장병일 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_88.jpg",
        link: "https://blog.naver.com/bridgeover01/223203620674",
    },
    {
        text: "[브릿지오버] 2023 스타트업 코리아 전략회의 with BRIDGEOVER",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_87.png",
        link: "https://blog.naver.com/bridgeover01/223202093056",
    },
    {
        text: "[GBC] GBC 총동문회 14기 환영식 & 간담회 성료",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_86.jpg",
        link: "https://blog.naver.com/bridgeover01/223194797875",
    },
    {
        text: "아마존/이베이 해외 유통채널 전문가 : 김태경 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_85.jpg",
        link: "https://blog.naver.com/bridgeover01/223189848870",
    },
    {
        text: "한국 기계산업 온라인 무역 NO.1 : 코머신 박은철 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_84.jpg",
        link: "https://blog.naver.com/bridgeover01/223189281930",
    },
    {
        text: "[기업탐방] 우리나라 모터계의 새바람, DKM MOTOR",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_83.jpg",
        link: "https://blog.naver.com/bridgeover01/223183412641",
    },
    {
        text: "중미 경제의 중심, 코스타리카 홍성용 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_80.jpeg",
        link: "https://blog.naver.com/bridgeover01/223177836383",
    },
    {
        text: "사단법인 글로벌 비지니스컨설턴트협회 창립총회",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_82.jpeg",
        link: "https://blog.naver.com/bridgeover01/223171711878",
    },
    {
        text: "GMA Partners 정회원 정기모임",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_81.jpeg",
        link: "https://blog.naver.com/bridgeover01/223171701856",
    },
    {
        text: "한,중 관세 1인자, 임창환 관세사님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_79.png",
        link: "https://blog.naver.com/bridgeover01/223161566454",
    },
    {
        text: "이베이 셀러가이드 : CS 관리",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_78.png",
        link: "https://blog.naver.com/bridgeover01/223161864199",
    },
    {
        text: "이베이 셀러가이드 : 퍼포먼스 관리",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_77.png",
        link: "https://blog.naver.com/bridgeover01/223161860453",
    },
    {
        text: "2023.07 브릿지오버: 기업부설연구소 설립",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223167555548",
    },
    {
        text: "[서비스의뢰] 정밀 금형 가공 기술 대표회사, (주)제일엠엔에스",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_76.png",
        link: "https://blog.naver.com/bridgeover01/223154814019",
    },
    {
        text: "지나온 30년의 신사업 여정을 돌아보며, 앞으로 30년을 도전하시는 글로벌 비즈니스 전문가 GMA 전용하 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_75.jpeg",
        link: "https://blog.naver.com/bridgeover01/223158331222",
    },
    {
        text: "23년 하반기 물류동향(해상&상공)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155050459",
    },
    {
        text: "23년 물류 현재 상황",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155026328",
    },
    {
        text: "23'상반기 글로벌 경제/물류 동향",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155011632",
    },
    {
        text: "베트남, 아프리카, 중국 특송 전문 : KORTOP GLS",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_74.jpeg",
        link: "https://blog.naver.com/bridgeover01/223154210331",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기: FCL VS LCL VS 콘솔(feat.훈증)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_73.png",
        link: "https://blog.naver.com/bridgeover01/223152966145",
    },
    {
        text: "[서비스의뢰]K-모터 대표회사, (주)DKM",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_72.jpeg",
        link: "https://blog.naver.com/bridgeover01/223155207681",
    },
    {
        text: "[무역정보] 구매확인서 : 간접수출",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_71.jpeg",
        link: "https://blog.naver.com/bridgeover01/223143444412",
    },
    {
        text: "[무역정보] 구매확인서 : INTRO",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_71.jpeg",
        link: "https://blog.naver.com/bridgeover01/223143339039",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : EXW는 누구를 위한걸까?(Feat.코로나 마스크 2020년 파동이야기)",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_70.jpeg",
        link: "https://blog.naver.com/bridgeover01/223142511004",
    },
    {
        text: "융하인리히 : 2023.07월 프로모션",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_69.jpg",
        link: "https://blog.naver.com/bridgeover01/223134447220",
    },
    {
        text: "6월 카드뉴스 배정홍 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_68.jpeg",
        link: "https://blog.naver.com/bridgeover01/223132644994",
    },
    {
        text: "5월 카드뉴스 남종석 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_67.jpeg",
        link: "https://blog.naver.com/bridgeover01/223132643461",
    },
    {
        text: "5월 카드뉴스 박기홍 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_66.jpeg",
        link: "https://blog.naver.com/bridgeover01/223132640107",
    },
    {
        text: "[모집] 브릿지오버 x 하나금융그룹: 경력보유여성 & 장애인",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_58.jpeg",
        link: "https://blog.naver.com/bridgeover01/223133392396",
    },
    {
        text: "[브릿지오버] ISO9001인증 획득",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_57.png",
        link: "https://blog.naver.com/bridgeover01/223141302561",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : DDP with Amazon",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_65.jpeg",
        link: "https://blog.naver.com/bridgeover01/223136085920",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : FOB VS CIF",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_64.png",
        link: "https://blog.naver.com/bridgeover01/223135085788",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 : 내국 신용장(Local L/C) vs 구매확인서 with 벤더회사",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_63.jpeg",
        link: "https://blog.naver.com/bridgeover01/223124934390",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 7: Story of B/L -2",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_62.png",
        link: "https://blog.naver.com/bridgeover01/223120950494",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 6: Story of B/L -1",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_61.png",
        link: "https://blog.naver.com/bridgeover01/223118696136",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 5: All about 'HS CODE' 국제통일 상품분류체계",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_60.png",
        link: "https://blog.naver.com/bridgeover01/223116678519",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 4: MSDS와 컨테이너 위험물 검사",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_59.png",
        link: "https://blog.naver.com/bridgeover01/223114004683",
    },
    {
        text: "인도 홈쇼핑계의 황태자, '코끼리를 올라타라' 저자 '신시열' 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_56.jpeg",
        link: "https://blog.naver.com/bridgeover01/223129483188",
    },
    {
        text: "국내외를 아우르며 왕성한 활동을 펼치고 계시는\n(주)국제컨설팅의 '남경복' 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_55.jpeg",
        link: "https://blog.naver.com/bridgeover01/223110471478",
    },
    {
        text: "[트레이드포스] 벤처기업협회 추천 스타트업 30곳 선정!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_54.png",
        link: "http://www.startupdaily.kr/news/articleView.html?idxno=3399",
    },
    {
        text: "[EXPO] 2023 AMAZON PRIME EXPO in SBA서울경제진흥원: INTRO",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_53.jpeg",
        link: "https://blog.naver.com/bridgeover01/223109052302",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 3: EDI & VGM & TAREWEIGHT",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223105861391",
    },
    {
        text: "GMA연합 워크샵 및 총회 참석 [Global Marketing Association",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_52.jpeg",
        link: "https://blog.naver.com/bridgeover01/223100601336",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 2: 화물선적 & 스케줄 & 항편",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223103836702",
    },
    {
        text: "[무역정보] 화물 BOOKING 이야기 1: 화주 & 포워딩",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223103109644",
    },
    {
        text: "[무역정보] Everything of Amazon step.2: 아마존 물류?",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_51.jpeg",
        link: "https://blog.naver.com/bridgeover01/223098207260",
    },
    {
        text: "[무역정보] Everything of Amazon step.1: 아마존 용어 정리 & 최근 아마존 셀러 모집 동향",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_51.jpeg",
        link: "https://blog.naver.com/bridgeover01/223098196424",
    },
    {
        text: "[무역정보] Everything of Container for 2023: 컨테이너의 모든 것.",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_49.png",
        link: "https://blog.naver.com/bridgeover01/223093468099",
    },
    {
        text: "월드옥타(OKTA) 제24차 세계대표자대회 및 수출상담회",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_48.jpeg",
        link: "https://blog.naver.com/bridgeover01/223083547835",
    },
    {
        text: "GBC 협회 추진위원회 워크샵",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_47.jpeg",
        link: "https://blog.naver.com/bridgeover01/223083629862",
    },
    {
        text: "안틸벤처스 CEO 방문",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_46.jpeg",
        link: "https://blog.naver.com/bridgeover01/223083561395",
    },
    {
        text: "중남미, 중동 전문가, 신약 해외 인허가부터 해외 수출 독점판매 계약 체결 등 해외 무역계의 만능 영업 전문가! Samuel SW Baik",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_45.jpeg",
        link: "https://blog.naver.com/bridgeover01/223079132870",
    },
    {
        text: "GBC-GMA-OKTA 글로벌 컨설팅 협력 논의",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_44.jpeg",
        link: "https://blog.naver.com/bridgeover01/223078786832",
    },
    {
        text: "해외발전소, 해외 공장 설비에 특화된 기술자문부터 해외B2B 바이어 연결까지! 설비산업의 전문가, 홍지훈 전문가님!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_43-2.jpeg",
        link: "https://blog.naver.com/bridgeover01/223070263480",
    },
    {
        text: "동남아시아 온라인 전자상거래의 허브, 이커머스계 문성호 전문가님!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_42-2.jpeg",
        link: "https://blog.naver.com/bridgeover01/223070223652",
    },
    {
        text: "GMA 파트너스 미국 및 베트남 수출 협력 미팅",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_41.jpeg",
        link: "https://blog.naver.com/bridgeover01/223066798019",
    },
    {
        text: "동료를 모집하고 있습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_40.png",
        link: "https://blog.naver.com/bridgeover01/223062867335",
    },
    {
        text: "융하인리히 APAC 총괄 디렉터의 브릿지오버 판교 본사 방문",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_39.jpeg",
        link: "https://blog.naver.com/bridgeover01/223062909435",
    },
    {
        text: "GBC 총동문회 간담회 및 13기 GBC 원우 환영식",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_38.jpeg",
        link: "https://blog.naver.com/bridgeover01/223062884132",
    },
    {
        text: "대만 바이어 방문 - 라라이프 대만 수입 건",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_37.jpeg",
        link: "https://blog.naver.com/bridgeover01/223062930401",
    },
    {
        text: "[트레이드포스] 인바운드 마케팅 & 무역 사무 포지션을 채용합니다!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_30.png",
        link: "https://blog.naver.com/bridgeover01/223059903852",
    },
    {
        text: "[트레이드포스] 플랫폼 아웃바운드 영업 채용 공고!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_30.png",
        link: "https://blog.naver.com/bridgeover01/223059794052",
    },
    {
        text: "KIMES 2023을 방문하였습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_36.jpeg",
        link: "https://blog.naver.com/bridgeover01/223056363133",
    },
    {
        text: "옥서스 해외 시장 진출 협의 및 공장 투어",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_35.jpeg",
        link: "https://blog.naver.com/bridgeover01/223054220787",
    },
    {
        text: "아마존 호미사장님 - Leading Trust 김태경 대표님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_34.png",
        link: "https://blog.naver.com/bridgeover01/223049823668",
    },
    {
        text: "(주)인앤아웃코퍼레이션 - 층간소음 없는 무음의 시작 '뭄뭄실내화' 수출의뢰",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_33.jpeg",
        link: "https://blog.naver.com/bridgeover01/223047386045",
    },
    {
        text: "GBC - GMA 북미 시장 공동 진출 방안 협의",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_31.png",
        link: "https://blog.naver.com/bridgeover01/223046909034",
    },
    {
        text: "[무역사기] 필리핀 수출 무역 사기가 기승을 부리고 있습니다.",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_32.jpeg",
        link: "https://blog.naver.com/bridgeover01/223043864923",
    },
    {
        text: "[이슈] 증가하는 글로벌 해외 직구 시장, 한국에서는 왜 어려울까?",
        from: "브릿지오버",
        type: "TRADEINFO",
        image: "./images/main/community/community_32.jpeg",
        link: "https://blog.naver.com/bridgeover01/223043874064",
    },
    {
        text: "(주)브릿지오버 - 트레이드포스 1년 6개월간의 성적표",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_30.png",
        link: "https://blog.naver.com/bridgeover01/223040685621",
    },
    {
        text: "(주)파워플레이어 - 유기농 클린 뷰티 브랜드 온그리디언츠 'ONGREDIENTS'",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_29.png",
        link: "https://blog.naver.com/bridgeover01/223043714902",
    },
    {
        text: "(주)휴젝트 - 세계 최대의 에너지 하베스팅 원천 기술 보유 기업",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_28.png",
        link: "https://blog.naver.com/bridgeover01/223043707722",
    },
    {
        text: "농축산물부터 에너지 및 스마트팜 솔루션의 소재까지 유럽/중남미의 B2B 수출입 전문가!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_27.jpeg",
        link: "https://blog.naver.com/bridgeover01/223038230663",
    },
    {
        text: "GBC 총동문회 튀르기예 지진 성금 전달식",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_26.png",
        link: "https://blog.naver.com/bridgeover01/223037729877",
    },
    {
        text: "(주)브릿지오버 소셜 벤처 판별",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_25.png",
        link: "https://blog.naver.com/bridgeover01/223036887163",
    },
    {
        text: "무역의 A-Z! 기본 제반 사항에 대한 준비를 타파할 명재호 관세사님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_24.jpeg",
        link: "https://blog.naver.com/bridgeover01/223036074902",
    },
    {
        text: "브릿지오버 - 옥서스 해외 수출 협약 MOU 체결식",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_23.png",
        link: "https://blog.naver.com/bridgeover01/223025806064",
    },
    {
        text: "코리아빌드위크를 방문하였습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_22.jpeg",
        link: "https://blog.naver.com/bridgeover01/223018685634",
    },
    {
        text: "글로벌마케팅협회 GMA에서 (주)브릿지오버 방문해주셨습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_21.jpeg",
        link: "https://blog.naver.com/bridgeover01/223018674030",
    },
    {
        text: "(주)입셀론바이오 - 카이스트 피부 침투 기술과 의학 전문의 창업가와의 만남",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_20.jpeg",
        link: "https://blog.naver.com/bridgeover01/223007152150",
    },
    {
        text: "중국 수출입 전문가! 해외박람회부터 중국유통망을 확보하신 최승이 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_19.jpeg",
        link: "https://blog.naver.com/bridgeover01/222996084655",
    },
    {
        text: "아시아 12개국의 시장 섭렵! B2B 핵심 산업군의 베테랑, 조삼현 전문가님",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_18.jpeg",
        link: "https://blog.naver.com/bridgeover01/222998989878",
    },
    {
        text: "KAIST Alumni News - (주)브릿지오버 곽인철 대표",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_17.png",
        link: "https://blog.naver.com/bridgeover01/222995630109",
    },
    {
        text: "준형텍스(주) - 대구 소재의 섬유 전문 기업",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_16.jpg",
        link: "https://blog.naver.com/bridgeover01/222994233272",
    },
    {
        text: "브리스텍 - CES 2회 연속 혁신상에 빛나는 스마트 욕창매트 기업",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_15.jpeg",
        link: "https://blog.naver.com/bridgeover01/222994222331",
    },
    {
        text: "[공지]융하인리히 공식 온라인 판매점! 전동 핸드파레트 트럭 판매 게시!",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_14.jpeg",
        link: "https://blog.naver.com/bridgeover01/222993677969",
    },
    {
        text: "(주)옥서스에서 방문해주셨습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_13.png",
        link: "https://blog.naver.com/bridgeover01/222988416823",
    },
    {
        text: "SK초대 바르샤바 지사장, 폴란드 및 유럽 최고의 전문가",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_12.jpg",
        link: "https://blog.naver.com/bridgeover01/222988426523",
    },
    {
        text: "아마존/이베이 해외 유통채널! 이제는 전문가와 함께~",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_11.jpg",
        link: "https://blog.naver.com/bridgeover01/222985790611",
    },
    {
        text: "B2B 디지털 무역 플랫폼 트레이드포스,\n한국산업대전 참가",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_2.jpeg",
        link: "https://blog.naver.com/bridgeover01/222911596071",
    },
    {
        text: "트레이드포스,\n제조업 해외 무역 책임지는 디지털 수출입 플랫폼",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_5.png",
        link: "https://www.psnews.co.kr/news/articleView.html?idxno=2015050",
    },
    {
        text: "클로즈업 기업현장\n 프로그램에 방영된 트레이드포스\n운영사 (주)브릿지오버",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_7.png",
        link: "https://blog.naver.com/bridgeover01/222911616558",
    },
    {
        text: "(주)브릿지오버 사회적기업 성장지원센터\n'소셜캠퍼스 온'기업선정",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_3.png",
        link: "https://blog.naver.com/bridgeover01/222695384575",
    },
    {
        text: "글로벌 비즈니트 컨설턴트(GBC),\n총동문회 부회장 취임",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_4.png",
        link: "https://blog.naver.com/bridgeover01/222806434699",
    },
    {
        text: "트레이드포스 서비스 소개\n[GBC 정례포럼]",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_6.jpeg",
        link: "https://blog.naver.com/bridgeover01/222911606491",
    },
    {
        text: "KOTRA GBC 총 동문회\n회장단의 원우사 브릿지오버 방문",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_8.jpeg",
        link: "https://blog.naver.com/bridgeover01/222977325002",
    },
    {
        text: "트레드링스에서 브릿지오버를 방문해주셨습니다.",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_9.jpeg",
        link: "https://blog.naver.com/bridgeover01/222977326420",
    },
    {
        text: "GBC 글로벌 컨설팅 위원회\nKICK OFF 미팅_230109",
        from: "브릿지오버",
        type: "BLOG",
        image: "./images/main/community/community_10.png",
        link: "https://blog.naver.com/bridgeover01/222980802776",
    },
    {
        text: "(주)브릿지오버, 넥스트라이즈 참가 통한 수출입 전문가 모집 박차",
        from: "브릿지오버",
        type: "INFO",
        image: "./images/main/community/community_1.jpeg",
        link: "https://blog.naver.com/bridgeover01/222806426228",
    },
];
