import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { Box, Grid, Pagination, Typography } from "@mui/material";

import { ResponseGetUserAdminControl } from "../../api-interfaces/admin";
import { UserRole } from "../../api-interfaces/user";
import { getDoccount, getExperts, getUnexperts } from "../../apis/admin";
import SolidTextButtonWithColor from "../buttons/SolidTextButtonWithColor";
import Bingle from "../indicators/Bingle";
import { renderUpdateComponentRecoil } from "../states/recoil";

import AdminProfessionalTile from "./AdminProfessionalTile";

const AdminProfessional = () => {
    const [expDocLimit, setExpDocLimit] = useState(0);
    const [unExpDocLimit, setUnExpDocLimit] = useState(0);

    const [data, setData] = useState<ResponseGetUserAdminControl[]>();
    const [experts, setExperts] = useState<ResponseGetUserAdminControl[]>();
    const [unexperts, setUnexperts] = useState<ResponseGetUserAdminControl[]>();

    const [docLimit, setDocLimit] = useState<number>(1);
    const [showUnexpertList, setShowUnexpertList] = useState<boolean>(false);
    const [selectedPage, setSelectedPage] = useState(1);
    const [reRenderData, _setReRenderData] = useRecoilState(
        renderUpdateComponentRecoil
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            //전문가
            setIsLoading(true);
            const lim = await getDoccount(UserRole.UNAPPROVED_EXPERT);
            const explim = await getDoccount(UserRole.EXPERT);
            const exp = await getExperts(0, 3);
            const unexp = await getUnexperts(0, 3);
            setDocLimit(Math.ceil(explim.count / 3));
            setExperts(exp);
            setUnexperts(unexp);
            setData(exp);
            setExpDocLimit(Math.ceil(explim.count / 3));
            setUnExpDocLimit(Math.ceil(lim.count / 3));

            console.log(explim.count, "problem");
            setIsLoading(false);
        })();
    }, [reRenderData]);

    useEffect(() => {
        console.log("hey", showUnexpertList);
        if (showUnexpertList) {
            setData(unexperts);
            setDocLimit(unExpDocLimit);
            setSelectedPage(1);
        } else {
            setData(experts);
            setDocLimit(expDocLimit);
            setSelectedPage(1);
        }
    }, [showUnexpertList]);

    useEffect(() => {
        (async () => {
            let dt;
            if (showUnexpertList) {
                dt = await getUnexperts((selectedPage - 1) * 3, 3);
                console.log(dt, "professional");
            } else {
                dt = await getExperts((selectedPage - 1) * 3, 3);
                console.log(dt, "professional");
            }
            setData(dt);
        })();
    }, [selectedPage, reRenderData]);

    return (
        <>
            <Grid container mb={3} flexDirection={"column"}>
                <Box
                    display={"flex"}
                    border={"solid 1px #AAAAAA"}
                    height={34}
                    width={237}
                    alignItems={"center"}
                    p={1}
                >
                    <img
                        src={"./images/icon/search.svg"}
                        width={20}
                        height={20}
                    />
                    <Box display={"flex"} pl={1}>
                        <Typography color={"#AAAAAA"}>검색</Typography>
                    </Box>
                </Box>
                <Box display={"flex"} mt={3}>
                    <SolidTextButtonWithColor
                        text={showUnexpertList ? "전문가목록" : "미승인목록"}
                        size={15}
                        sx={{ width: 100, height: 35 }}
                        onClick={() =>
                            showUnexpertList
                                ? setShowUnexpertList(false)
                                : setShowUnexpertList(true)
                        }
                    />
                </Box>
                <Box display={"flex"} mt={3}>
                    <Pagination
                        count={docLimit}
                        page={selectedPage}
                        onChange={(
                            event: React.ChangeEvent<unknown>,
                            value: number
                        ) => setSelectedPage(value)}
                    />
                </Box>
            </Grid>
            <Box display={"flex"} flexDirection={"column"}>
                {isLoading ? (
                    <Bingle height={300} />
                ) : (
                    <>
                        {data
                            ? data.map((e, idx) => (
                                  <AdminProfessionalTile
                                      key={idx}
                                      data={e}
                                      unexpert={showUnexpertList}
                                  />
                              ))
                            : ""}
                    </>
                )}
            </Box>
        </>
    );
};

export default AdminProfessional;
