import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import {
    RequestFindingPostForgotUserData,
    RequestPostResetPassword,
} from "../api-interfaces/user";
import { findEmailOrPrepareResetPW, resetPassword } from "../apis/user";
import { mobileMaxWidthMediaQuery } from "../theme";
import { validatePw } from "../utils/validation";

const FindPW: React.FC = () => {
    const [indexer, setIndexer] = useState<RequestFindingPostForgotUserData>({
        name: "",
        contact: "",
        email: "",
    });
    const [ps, setPs] = useState<RequestPostResetPassword>({ rsp: "" });
    const [pwCheck, setPwCheck] = useState("");
    const [errorPart, setErrorPart] = useState("");
    const [isTypingPwCheck, setIsTypingPwCheck] = useState(false);
    const [fetchedEmail, setFetchedEmail] = useState("");
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const navi = useNavigate();
    const { t } = useTranslation();

    const submit = async () => {
        try {
            const modifiedIndexer = {
                ...indexer,
                contact: indexer.contact.replace("+82", "0"),
            };
            const fEmail = await findEmailOrPrepareResetPW(modifiedIndexer);
            setFetchedEmail(() => fEmail);
        } catch (e) {
            alert(t("account.notExistingUser"));
            console.error(e);
        }
    };

    const resetPW = async () => {
        try {
            if (!ps.rsp || !validatePw(ps.rsp)) {
                window.alert(t("account.wrongPasswordFormat"));
                setErrorPart("pw");
                return false;
            }
            if (ps.rsp !== pwCheck) {
                window.alert(t("account.wrongPasswordMatching"));
                setErrorPart("pwCheck");
                return false;
            }
            await resetPassword(ps);
            alert(t("account.passwordChanged"));
            navi("/login");
        } catch (e) {
            alert(t("error.internal"));
            console.error(e);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                minHeight: "100vh",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 236,
                    width: "100%",
                    alignItems: "center",
                    ...(isMobile ? { justifyContent: "center" } : { mt: 18 }),
                }}
            >
                {fetchedEmail ? (
                    <>
                        <Box mb={5} mt={2}>
                            <Typography
                                sx={{ textAlign: "center" }}
                                variant="f48h64EB"
                            >
                                {t("account.pwReset")}
                            </Typography>
                        </Box>
                        {/* ID 안내 화면 */}
                        <Typography
                            variant="f18h20B"
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            {`${indexer.name}${t(
                                "account.findIDFlavorText1"
                            )} ${fetchedEmail} ${t("common.is2")}`}
                        </Typography>
                        <TextField
                            value={fetchedEmail}
                            disabled
                            sx={{ mt: 2.5 }}
                            fullWidth
                        />
                        {/* 새로운비밀번호 */}
                        <TextField
                            placeholder={t("account.pw") ?? ""}
                            value={ps.rsp}
                            error={errorPart === "pw"}
                            onChange={(e) =>
                                setPs((prev) => {
                                    return { ...prev, rsp: e.target.value };
                                })
                            }
                            sx={{ mt: 2.5 }}
                            InputProps={{
                                style: {
                                    color: "black",
                                    fontFamily: "sans-serif",
                                },
                                type: "password",
                            }}
                        />
                        <Typography
                            variant="f12h20B"
                            color={errorPart === "pw" ? "error" : "mono.600"}
                            sx={{ mt: 1 }}
                        >
                            {t("account.passwordValidationLabel")}
                        </Typography>
                        {/* 비밀번호 체크 */}
                        <TextField
                            placeholder={t("account.pwCheck") ?? ""}
                            value={pwCheck}
                            type={"password"}
                            onChange={(e) => setPwCheck(e.currentTarget.value)}
                            sx={{ mt: 2.5 }}
                            onFocus={() => setIsTypingPwCheck(true)}
                            onBlur={() => setIsTypingPwCheck(false)}
                            error={
                                (isTypingPwCheck && pwCheck !== ps.rsp) ||
                                errorPart === "pwCheck"
                            }
                            helperText={
                                isTypingPwCheck && pwCheck !== ps.rsp
                                    ? t("account.wrongPasswordMatching")
                                    : ""
                            }
                            InputProps={{
                                style: {
                                    color: "black",
                                    fontFamily: "sans-serif",
                                },
                                type: "password",
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{ mt: 2.5 }}
                            onClick={resetPW}
                            fullWidth
                        >
                            {t("account.resetPassword")}
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="f48h64EB" sx={{ mb: 6.25 }}>
                            {t("account.findPW")}
                        </Typography>
                        {/* 이름 */}
                        <Typography variant="f18h20B" sx={{ width: "100%" }}>
                            {t("account.name")}
                        </Typography>
                        <TextField
                            placeholder={t("account.namePlaceholder") ?? ""}
                            value={indexer.name}
                            onChange={(e) =>
                                setIndexer((prev) => {
                                    return { ...prev, name: e.target.value };
                                })
                            }
                            sx={{ mt: 1 }}
                            autoComplete="name"
                        />
                        {/* 연락처 */}
                        <Typography
                            variant="f18h20B"
                            sx={{ width: "100%", mt: 2.5 }}
                            component="div"
                        >
                            {t("account.phone")}
                        </Typography>
                        <TextField
                            placeholder={t("account.phonePlaceholder") ?? ""}
                            value={indexer.contact}
                            onChange={(e) =>
                                setIndexer((prev) => {
                                    return { ...prev, contact: e.target.value };
                                })
                            }
                            autoComplete="contact"
                            sx={{ mt: 1 }}
                        />
                        {/* 이메일 */}
                        <Typography
                            variant="f18h20B"
                            sx={{ width: "100%", mt: 2.5 }}
                        >
                            {t("account.email")}
                        </Typography>
                        <TextField
                            placeholder="aaa@tradeforce.com"
                            value={indexer.email}
                            onChange={(e) =>
                                setIndexer((prev) => {
                                    return { ...prev, email: e.target.value };
                                })
                            }
                            sx={{ mt: 1 }}
                            autoComplete="email"
                        />
                        <Button
                            disabled={
                                !indexer.contact ||
                                !indexer.name ||
                                !indexer.email
                            }
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2.5 }}
                            onClick={submit}
                        >
                            {t("common.complete")}
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default FindPW;
