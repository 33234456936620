import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSetRecoilState } from "recoil";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import BackButton from "../components/buttons/BackButton";
import FilterButton from "../components/buttons/FilterButton";
import NewsTileAll from "../components/frames/NewsTileAll";
import { navThemeRecoil } from "../components/states/recoil";
import {
    CommunityDataType,
    communityFilterContents,
    communityFilterKeys,
    CommunityPageDefaultData,
} from "../interfaces/community/communityDataType";
import { tabletMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

/**
 * 커뮤니티 페이지 컴포넌트
 */
const Community: React.FC = () => {
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const [selected, setSelected] = useState<number>(0);
    const [communityData, setCommunityData] = useState<CommunityDataType[]>(
        CommunityPageDefaultData
    );

    const navi = useNavigate();

    const isMobile = useMediaQuery(tabletMaxWidthMediaQuery);
    const handleFilter = (idx: number) => {
        setSelected(() => idx);
        const filteredDoc = CommunityPageDefaultData.filter(
            (datum) => idx === 0 || datum.type === communityFilterKeys[idx]
        );
        setCommunityData(() => filteredDoc);
    };

    const changeNavbarColor = () => {
        if (window.scrollY >= 1820) {
            setNavTheme(navThemeValues.WHITE);
        } else if (window.scrollY >= 900) {
            setNavTheme(navThemeValues.BLACK);
        } else {
            setNavTheme(navThemeValues.WHITE);
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        changeNavbarColor();
        window.addEventListener("scroll", changeNavbarColor);
        return () => window.removeEventListener("scroll", changeNavbarColor);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Grid
            container
            sx={{
                width: "100%",
                backgroundColor: "white",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                pt: 12,
            }}
            p={isMobile ? 3 : 5}
        >
            {/* 뒤로버튼 */}
            {!isMobile && (
                <Grid item mb={1} width={"100%"}>
                    <BackButton
                        onClick={() => {
                            navi(-1);
                        }}
                    />
                </Grid>
            )}

            {/* 제목 */}
            <Grid item width={"100%"}>
                <Typography
                    textAlign={"left"}
                    className="cocosharp"
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    color="mono.800"
                >
                    Community
                </Typography>
            </Grid>

            {/* 필터 섹션*/}
            <Grid
                item
                mt={6}
                display={"flex"}
                justifyContent={"flex-start"}
                width={"100%"}
            >
                {communityFilterContents.map((data, idx) => {
                    return (
                        <FilterButton
                            key={`filter-tab-${idx}`}
                            selected={selected === idx ? true : false}
                            text={data}
                            onClick={() => handleFilter(idx)}
                        />
                    );
                })}
            </Grid>

            {/* 커뮤니티 타일 섹션 */}
            <Box
                display={"grid"}
                gridTemplateColumns={!isMobile ? "1fr 1fr 1fr" : ""}
                gap={2}
                width={"100%"}
                pt={isMobile ? 5 : 6}
                flexDirection={isMobile ? "column" : "row"}
            >
                {communityData.map((data, idx) => {
                    return (
                        <NewsTileAll
                            key={`community_data_${idx}`}
                            data={data}
                            isMobile={isMobile}
                        />
                    );
                })}
            </Box>
        </Grid>
    );
};

export default Community;
