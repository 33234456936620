import { ResponseUploadFile } from "../api-interfaces/file";

import apiInstance from "./base";

/**
 *
 * @param id - user 관련은 userID, chat 관련은 chatID
 * @param formData
 * @returns
 */
export const uploadImage = async (id: string, formData: FormData) => {
    const query = `v1/upload-image/${id}`;

    const uploaded = await apiInstance
        .post(query, { body: formData })
        .json<ResponseUploadFile>();

    return uploaded;
};

export const uploadFile = async (id: string, formData: FormData) => {
    const query = `v1/upload-file/${id}`;

    const uploaded = await apiInstance
        .post(query, { body: formData })
        .json<ResponseUploadFile>();

    return uploaded;
};

/**
 * 예시) 실제 이미지 업로드 하는 함수
 *
 * @remarks
 * 사용처: verification 이랑 신청 => userID
 * @param file
 * @param fileName
 * @param userID
 * @returns
 */
export const uploadImg = async (
    file: File | null,
    fileName: string,
    userID: string
) => {
    if (!file || !userID || !fileName) {
        return false;
    }
    const splitted = file.name.split(".");
    const ext = splitted[splitted.length - 1];
    const formData = new FormData();
    formData.append("image", file, `${fileName}.${ext}`);
    const res = await uploadImage(userID, formData);

    console.log(res); // 로그용

    return res;
};

/**
 * 예시) 실제 파일 업로드 하는 함수
 *
 * @remarks
 * 사용처: verification 이랑 신청 => userID
 *
 * @param file
 * @param fileName
 * @param id
 * @returns
 */
export const uploadF = async (
    file: File | null,
    fileName: string,
    id: string
) => {
    if (!file || !id || !fileName) {
        return false;
    }
    const splitted = file.name.split(".");
    const ext = splitted[splitted.length - 1];
    const formData = new FormData();
    formData.append("userfile", file, `${fileName}.${ext}`);
    const res = await uploadFile(id, formData);

    console.log(res); // 로그용

    return res;
};

/**
 * 업로드 함수 래퍼
 * @remarks
 * 현사용처: 정산서, 채팅
 *
 * @param file
 * @param userID
 * @returns
 */
export const uploadFC = async (file: File | null, id: string) => {
    if (!file || !id) {
        return false;
    }
    const formData = new FormData();
    formData.append("userfile", file);
    const res = await uploadFile(id, formData);
    return res;
};
