import {
    RequestPatchUserAdminControl,
    ResponseGetUserAdminControl,
} from "../api-interfaces/admin";
import { ChatModel, ChatParsed } from "../api-interfaces/chat";
import { Doccount, DoccountType } from "../api-interfaces/doccount";
import { QuestFormTypes, QuestModel } from "../api-interfaces/quest";

import apiInstance from "./base";

// NOTE 유저정보 불러오기는 user.ts의 getUser를 사용해주세요.

/**
 * 승인 안 된 회사 가져오기
 */
export const getGuests = async (skip?: number, limit?: number) => {
    const query = `v1/admin-control/guests${skip || limit ? "?" : ""}${
        skip ? `skip=${skip}` : ""
    }${skip && limit ? "&" : ""}${limit ? `limit=${limit}` : ""}`;

    const ueus = await apiInstance
        .get(query)
        .json<ResponseGetUserAdminControl[]>();

    return ueus;
};

/**
 * 승인된 회사 가져오기
 */
export const getCompanies = async (skip?: number, limit?: number) => {
    const query = `v1/admin-control/companies${skip || limit ? "?" : ""}${
        skip ? `skip=${skip}` : ""
    }${skip && limit ? "&" : ""}${limit ? `limit=${limit}` : ""}`;

    const ueus = await apiInstance
        .get(query)
        .json<ResponseGetUserAdminControl[]>();

    return ueus;
};

/**
 * 승인 안 된 전문가 가져오기
 */
export const getUnexperts = async (skip?: number, limit?: number) => {
    const query = `v1/admin-control/unexperts${skip || limit ? "?" : ""}${
        skip ? `skip=${skip}` : ""
    }${skip && limit ? "&" : ""}${limit ? `limit=${limit}` : ""}`;

    const ueus = await apiInstance
        .get(query)
        .json<ResponseGetUserAdminControl[]>();

    return ueus;
};

/**
 * 승인된 전문가 가져오기
 */
export const getExperts = async (skip?: number, limit?: number) => {
    const query = `v1/admin-control/experts${skip || limit ? "?" : ""}${
        skip ? `skip=${skip}` : ""
    }${skip && limit ? "&" : ""}${limit ? `limit=${limit}` : ""}`;

    const ueus = await apiInstance
        .get(query)
        .json<ResponseGetUserAdminControl[]>();

    return ueus;
};

/**
 * 유저 승인시키기
 *
 * @param userID
 * @returns
 */
export const approveUser = async (userID: string) => {
    const query = `v1/admin-control/approve/${userID}`;
    const u = await apiInstance.post(query).json<ResponseGetUserAdminControl>();
    return u;
};

/**
 * 유저 반려시키기 === 활동신청에 필요한 서류들 필드 비우기
 *
 * @param userID
 * @returns
 */
export const declineUser = async (userID: string) => {
    const query = `v1/admin-control/decline/${userID}`;
    const u = await apiInstance
        .post(query, { timeout: 60000 })
        .json<ResponseGetUserAdminControl>();
    return u;
};

/**
 * 유저 수정하기
 *
 * @param userID - 수정하고자 하는 User._id
 * @param modifying - 수정하고자 하는 필드값들의 오브젝트
 */
export const patchUserAdminControl = async (
    userID: string,
    modifying: RequestPatchUserAdminControl
) => {
    const query = `v1/admin-control/user/${userID}`;
    const u = await apiInstance
        .patch(query, { json: modifying })
        .json<ResponseGetUserAdminControl>();
    return u;
};

/**
 * 유저 삭제하기
 *
 * @param userID - 수정하고자 하는 User._id
 * @param modifying - 수정하고자 하는 필드값들의 오브젝트
 */
export const deleteUserAdminControl = async (userID: string) => {
    const query = `v1/admin-control/user/${userID}`;
    const u = await apiInstance.delete(query).json();
    return u;
};

/**
 * 특정 컬렉션에 있는 도큐먼트의 수
 *
 * @param docType - 종류
 * @returns
 */
export const getDoccount = async (
    docType: DoccountType,
    questType?: QuestFormTypes
) => {
    const query = `v1/admin-control/doccount/${docType} ${
        questType ? `?type=${questType}` : ""
    }`;
    const dc = await apiInstance.get(query).json<Doccount>();
    return dc;
};

/**
 * 모든 의뢰목록 불러오기
 */
export const getAllQuests = async (
    skip?: number,
    limit?: number,
    type?: QuestFormTypes
) => {
    const query = `v1/admin-control/quest/all${
        skip || limit || type ? "?" : ""
    }${skip ? `skip=${skip}` : ""}${skip && limit ? "&" : ""}${
        limit ? `limit=${limit}` : ""
    }${limit && type ? "&" : ""}${type ? `type=${type}` : ""}`;
    return (
        await apiInstance.get(query).json<{
            quests: QuestModel[];
        }>()
    ).quests;
};

/**
 * 모든 의뢰목록 불러오기 v2
 *
 * @param skip
 * @param limit
 * @param type
 * @param filter - 필터링 (-1: 전체, 0~ 단계, 6은 6,7단계 전부)
 * @returns
 */
export const getAllQuestsV2 = async (
    skip?: number,
    limit?: number,
    type?: QuestFormTypes,
    level?: number
) => {
    const query = `v1/admin-control/quest/all${
        skip || limit || type || level ? "?" : ""
    }${skip ? `skip=${skip}` : ""}${skip && limit ? "&" : ""}${
        limit ? `limit=${limit}` : ""
    }${limit && type ? "&" : ""}${type ? `type=${type}` : ""}${
        type && level !== undefined ? "&" : ""
    }${level !== undefined ? `level=${level}` : ""}`;
    const res = await apiInstance.get(query).json<{
        quests: QuestModel[];
        cobj: { iCount: number; eCount: number };
    }>();
    console.log("res: ", res);

    return res;
};

/**
 * 해당하는 의뢰의 모든 채팅 불러오기
 * @param questID
 */
export const getAllCrossChats = async (questID: string) => {
    const query = `v1/admin-control/${questID}/chats/all`;
    const cs = await apiInstance.get(query).json<ChatModel[]>();
    const parsed: ChatParsed[] = cs.map((c) => {
        return {
            ...c,
            logs: JSON.parse(c.logs),
            reportInfo: JSON.parse(c.reportInfo),
            expertInfo: JSON.parse(c.expertInfo),
            announcements: JSON.parse(c.announcements),
        };
    });
    return parsed;
};

// export const getQuestCountbyType = async () => {
//     const query = `v1/admin-control/quest-count`;
//     const qc = await apiInstance
//         .get(query)
//         .json<{ iCount: number; eCount: number }>();

//     return qc;
// };
