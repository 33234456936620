import React from "react";

import { Box } from "@mui/material";

import { ResponseGetUserAdminControl } from "../../api-interfaces/admin";
// import { AmbassadorDataType } from "../../interfaces/ambassador/ambassadorDataType";
import BlackBoxModal from "../modals/BlackBoxModal";
import NanumEB from "../typography/NanumEB";
import NanumL from "../typography/NanumL";

interface AmbassadorTileMainProps {
    data: ResponseGetUserAdminControl;
    isMobile: boolean;
    isClicked: boolean;
    width?: number;
    height?: number;
}

const AmbassadorTileMain: React.FC<AmbassadorTileMainProps> = ({
    isMobile,
    data: { name, subtitle, image },
    isClicked,
    width,
    height,
}) => {
    return (
        <>
            <Box display={"flex"} width={"100%"} position={"relative"}>
                <img
                    style={{
                        width: width ? width : "100%",
                        objectFit: "cover",
                        height: height ? height : isMobile ? 480 : 650,
                    }}
                    src={image}
                />
                <Box
                    display={"flex"}
                    position={"absolute"}
                    p={3}
                    flexDirection={"column"}
                    bottom={0}
                    textAlign={"start"}
                    mb={3}
                >
                    <NanumEB size={isMobile ? 20 : 24} text={name} />
                    <NanumL size={isMobile ? 14 : 20} text={subtitle} />
                </Box>
            </Box>
            {isClicked ? <BlackBoxModal /> : <></>}
        </>
    );
};

export default AmbassadorTileMain;
