import React from "react";

import { Link } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { PostSimple } from "../../api-interfaces/post";

interface BulletinBoardCardProps {
    data: PostSimple;
    isMobile: boolean;
}
const BulletinBoardCard: React.FC<BulletinBoardCardProps> = ({
    isMobile,
    data: { _id, category, title, contents, likes, commentsNumber },
}) => {
    return (
        <>
            <Link
                to={`/bulletinboard/${_id}`}
                style={{ textDecoration: "none", color: "inherit" }}
            >
                <Grid
                    height={205}
                    sx={{
                        width: "100%",
                        border: "solid 1px #AAAAAA",
                        cursor: "pointer",
                    }}
                    p={4}
                >
                    <Grid>
                        <Typography variant="f14h20">{category}</Typography>
                    </Grid>
                    <Grid
                        sx={{
                            mt: 0.5,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <Typography variant={isMobile ? "f20h28EB" : "f24h36B"}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid
                        pt={1}
                        sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <Typography variant="f14h20">{contents}</Typography>
                    </Grid>
                    <Grid container mt={2}>
                        <Grid pr={0.5}>
                            <img src={"/images/icon/empty_heart.svg"} />
                        </Grid>
                        <Grid pr={3}>
                            <Typography variant="f16h24">{likes}</Typography>
                        </Grid>

                        <Grid pr={0.5}>
                            <img
                                src={"/images/icon/message-text-square-01.svg"}
                            />
                        </Grid>
                        <Grid pr={3}>
                            <Typography variant="f16h24">
                                {commentsNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Link>
        </>
    );
};

export default BulletinBoardCard;
