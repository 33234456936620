import React from "react";

import ReactDOM from "react-dom/client";

import { RecoilRoot } from "recoil";

import "./i18n/lang-set";

import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import App from "./App";
import theme from "./theme";

import "./index.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <RecoilRoot>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </RecoilRoot>
);
