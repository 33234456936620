import React from "react";

import { Box, Grid, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";

import { RequestFormTypes } from "../../types/requestFormTypes";
import BlackOutlinedTextButton from "../buttons/BlackOutlinedTextButton";
import BlueOutlinedTextButton from "../buttons/BlueOutlinedTextButton";
import GrayContainedTextButton from "../buttons/GrayContainedTextButton";
import GreenContainedTextButton from "../buttons/GreenContainedTextButton";
import CancelledChip from "../chips/CancelledChip";
import RequestProcessMeter from "../indicators/RequestProcessMeter";
import InfoList from "../tables/InfoList";

export interface RequestCardProps {
    isMobile: boolean;
    reqId: string;
    idx: number;
    reqType: RequestFormTypes;
    level?: number;
    companyName: string;
    date: string;
    productName?: string;
    title?: string;
    showMoreButton?: boolean;
    onClick?: () => void;
}

const RequestCard: React.FC<RequestCardProps> = ({
    isMobile,
    reqId,
    idx,
    reqType,
    level,
    companyName,
    title,
    date,
    productName,
    showMoreButton = false,
    onClick,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Grid
            sx={{
                border: "solid 1px #AAAAAA",
                bgcolor: "#FFF",
                p: 3.5,
                mb: 2,
                ...(onClick
                    ? {
                          ":hover": { borderColor: theme.palette.pBlue.main },
                          cursor: "pointer",
                      }
                    : {}),
            }}
            onClick={onClick}
        >
            <Box
                sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}
            >
                {/* tabs */}
                <Box sx={{ display: "flex", width: "100%", overflowX: "auto" }}>
                    <BlueOutlinedTextButton
                        text={`${t("mypage.quest")} ${idx}`}
                    />
                    <GrayContainedTextButton text={t(`mypage.${reqType}`)} />
                    {level === 0 && <CancelledChip sx={{ ml: 1 }} />}
                    {level !== undefined && level > 6 && (
                        <GreenContainedTextButton text={t("status.complete")} />
                    )}
                </Box>
                {/* showmore */}
                {!isMobile && showMoreButton && (
                    <BlackOutlinedTextButton
                        text={t("mypage.showMore")}
                        onClick={() =>
                            window.location.assign(`/request/${reqId}`)
                        }
                    />
                )}
            </Box>

            <InfoList
                data={[
                    { title: t("account.companyName"), content: companyName },
                    { title: t("mypage.registeredDate"), content: date },
                    productName
                        ? {
                              title: t("mypage.productName"),
                              content: productName,
                          }
                        : { title: t("mypage.question"), content: title || "" },
                ]}
            />

            {level !== undefined && (
                <Grid sx={{ maxWidth: "630px" }}>
                    <RequestProcessMeter level={level} />
                </Grid>
            )}
            {isMobile && showMoreButton && (
                <BlackOutlinedTextButton
                    sx={{ mt: 1 }}
                    text={t("mypage.showMore")}
                    onClick={() => window.location.assign(`/request/${reqId}`)}
                />
            )}
        </Grid>
    );
};

export default RequestCard;
