import React, { useEffect, useState } from "react";

// import Box from "@mui/material/Box";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { ResponseUploadFile } from "../api-interfaces/file";
import { QuestFormTypes } from "../api-interfaces/quest";
import { registerQuest } from "../apis/quest";
import { uploadF } from "../apis/upload";
import BackButton from "../components/buttons/BackButton";
import ButtonSelector from "../components/buttons/ButtonSelector";
import FileUploader from "../components/inputs/FileUploader";
import {
    currentQuestFormTypeRecoil,
    navThemeRecoil,
    userRecoil,
} from "../components/states/recoil";
import { tabletMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";
import {
    RequestFormField,
    RequestFormFieldByType,
} from "../types/requestFormTypes";
import { REQUEST_DETAIL_SAMPLE_TEXT } from "../utils/consts";

const commonFields: RequestFormField = [
    {
        title: "귀사의 산업군",
        options: ["제조업", "유통업", "도매업", "중계업"],
    },
    {
        title: "귀사의 업종",
        options: ["기계", "중장비", "자동차 부품", "로봇 자동화"],
        hasEtc: true,
    },
    {
        title: "귀사의 전년도 연 매출",
        options: ["1~10억", "10~50억", "50~100억", "100~500억", "500억 이상"],
    },
    {
        title: "귀사의 직원 수",
        options: ["1~10명", "10~50명", "50~100명", "100~500명", "500명 이상"],
    },
];

const fields: RequestFormFieldByType = {
    수출: [
        {
            title: "수출 전담 직원",
            options: ["없음", "있음"],
        },
        {
            title: "수출 경험 여부",
            options: ["없음", "5개국 미만", "10개국 이상"],
        },
        {
            title: "전년도 수출액",
            options: ["10만불 이상", "100만불 이상", "1000만불 이상"],
        },
    ],
    수입: [
        {
            title: "수입 전담 직원",
            options: ["없음", "있음"],
        },
    ],
    컨설팅: [
        {
            title: "컨설팅 분야",
            options: [
                "전시회/행사/해외영업지원",
                "특허/지재권/시험",
                "브랜드개발/관리",
                "서류대행/현지등록/환보험",
                "조사/일반 컨설팅",
                "해외규격인증",
                "디자인 개발",
                "역량강화교육",
                "국제 운송",
                "법무/세무/회계 컨설팅",
                "홍보/광고",
                "홍보동영상",
                "통번역",
            ],
        },
        {
            title: "바우처 여부",
            options: ["바우처 해당", "바우처 미 해당"],
        },
    ],
};

const ServiceRequestForm = () => {
    const isMobile = useMediaQuery(tabletMaxWidthMediaQuery);
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const [formType, setFormType] = useRecoilState(currentQuestFormTypeRecoil);
    const [responses, setResponses] = useState<string[]>([]);
    const [requestDetail, setRequestDetail] = useState("");
    const [expectedPrice, setExpectedPrice] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [companyIntroFile, setCompanyIntroFile] = useState<File>();
    const [productCatalog, setProductCatalog] = useState<File>();

    const userData = useRecoilValue(userRecoil);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
        setResponses(
            [...commonFields, ...fields[formType]].map(
                (field) => field.options[0]
            )
        );
    }, []);

    useEffect(() => {
        if (formType === QuestFormTypes.EXPORT) {
            setRequestDetail((prev) => prev || REQUEST_DETAIL_SAMPLE_TEXT);
        } else {
            setRequestDetail((prev) =>
                prev === REQUEST_DETAIL_SAMPLE_TEXT ? "" : prev
            );
        }
    }, [formType]);

    const submit = async () => {
        console.log(responses);
        setIsSubmitting(() => true);
        try {
            if (userData) {
                // 회사소개서
                let cif: ResponseUploadFile | false | undefined = undefined;
                if (companyIntroFile) {
                    cif = await uploadF(
                        companyIntroFile,
                        "companyIntro",
                        userData._id
                    );

                    if (!cif) {
                        alert(t("error.network"));
                        return;
                    }
                }
                // 제품카탈로그
                let pdc: ResponseUploadFile | false | undefined = undefined;
                if (productCatalog) {
                    pdc = await uploadF(
                        productCatalog,
                        "productCatalog",
                        userData._id
                    );
                    if (!pdc) {
                        alert(t("error.network"));
                        return;
                    }
                }
                console.log("pdc: ", pdc?.src);
                await registerQuest({
                    clientID: userData._id,
                    type: formType,
                    proposal: [...responses, requestDetail, expectedPrice],
                    productName,
                    productCatalog: pdc ? pdc.src : undefined,
                    companyIntroduction: cif ? cif.src : undefined,
                });
                alert(t("quest.applySuccess"));
                location.replace("/");
            }
            setIsSubmitting(() => false);
        } catch (e) {
            setIsSubmitting(() => false);
        }
    };

    return (
        <Grid container flexDirection={"column"} alignItems={"center"} p={2.5}>
            <Grid
                container
                sx={{
                    flexDirection: "column",
                    maxWidth: "650px",
                    width: "100%",
                    flexWrap: "nowrap",
                    mt: 8,
                }}
            >
                <Grid>
                    <BackButton />
                </Grid>
                <Grid mt={2} mb={5}>
                    <Typography variant="f32h40EB">
                        {t("nav.serviceRequest")}
                    </Typography>
                </Grid>
                <Grid mb={2}>
                    <Typography variant="f18h20B">
                        {t("quest.questType")}
                    </Typography>
                </Grid>
                <ButtonSelector
                    options={[
                        t("mypage.export"),
                        t("mypage.import"),
                        t("mypage.consulting"),
                    ]}
                    onSelect={(idx, value) => {
                        if (value) {
                            switch (value) {
                                case "수출":
                                    setFormType(QuestFormTypes.EXPORT);
                                    break;
                                case "수입":
                                    setFormType(QuestFormTypes.IMPORT);
                                    break;
                                case "컨설팅":
                                    setFormType(QuestFormTypes.CONSULTING);
                                    break;
                                case t("mypage.export"):
                                    setFormType(QuestFormTypes.EXPORT);
                                    break;
                                case t("mypage.import"):
                                    setFormType(QuestFormTypes.IMPORT);
                                    break;
                                case t("mypage.consulting"):
                                    setFormType(QuestFormTypes.CONSULTING);
                                    break;
                            }
                        }
                    }}
                    initialSelectIdx={["수출", "수입", "컨설팅"].indexOf(
                        formType
                    )}
                />
                {[...commonFields, ...fields[formType]].map((value, index) => (
                    <Grid key={`req-form-field-${index}`} mt={3}>
                        <Grid mb={2}>
                            <Typography variant="f18h20B">
                                {t(`quest.${value.title}`)}
                            </Typography>
                        </Grid>
                        <ButtonSelector
                            options={value.options.map((opt) => {
                                return t(`quest.${opt}`);
                            })}
                            onSelect={(idx, val) => {
                                console.log(idx, val);
                                setResponses((res) =>
                                    res.map((r, i) =>
                                        i === index ? value.options[idx] : r
                                    )
                                );
                            }}
                            hasEtc={value.hasEtc || false}
                        />
                    </Grid>
                ))}
                <Grid mt={3}>
                    <Grid container mb={2} alignItems={"center"}>
                        <Typography variant="f18h20B">
                            {t("quest.askingDetail")}
                        </Typography>

                        <Grid ml={isMobile ? 0 : 1} mt={isMobile ? 1 : 0}>
                            <Typography fontSize={12} color={"mono.600"}>
                                {t("quest.askingDetailHelpText")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <TextField
                        multiline
                        rows={12}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                height: "270px",
                                fontSize: "14px",
                                lineHeight: 1.43,
                                fontWeight: 600,
                            },
                        }}
                        value={requestDetail}
                        onChange={(e) => setRequestDetail(e.target.value)}
                    />
                </Grid>
                {formType !== "컨설팅" && (
                    <Grid mt={3}>
                        <Grid mb={2}>
                            <Typography variant="f18h20B">
                                {t(`quest.${formType}`)} {t("quest.상품명")}
                            </Typography>
                        </Grid>
                        <TextField
                            placeholder={t("quest.상품명") as string}
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                    </Grid>
                )}

                <Grid mt={3}>
                    <Grid mb={2}>
                        <Typography variant="f18h20B">
                            {t(`quest.${formType}`)} {t("quest.희망 금액")}
                        </Typography>
                    </Grid>
                    <TextField
                        placeholder={
                            t("quest.expectedPricePlaceholder") as string
                        }
                        value={expectedPrice}
                        onChange={(e) => setExpectedPrice(e.target.value)}
                    />
                </Grid>

                <Grid mt={4}>
                    <Grid mb={2} container>
                        <Typography variant="f18h20B">
                            {t("quest.et21")}
                        </Typography>
                        <Typography
                            variant="f12h20"
                            color={"mono.600"}
                            sx={{ ml: 1 }}
                        >
                            {t("quest.optional")}
                        </Typography>
                    </Grid>
                    <FileUploader onUpload={setCompanyIntroFile} />
                </Grid>
                {formType === "수출" && (
                    <Grid mt={4}>
                        <Grid mb={2} container>
                            <Typography variant="f18h20B">
                                {t("quest.et22")}
                            </Typography>
                            <Typography
                                variant="f12h20"
                                color={"mono.600"}
                                sx={{ ml: 1 }}
                            >
                                {t("quest.optional")}
                            </Typography>
                        </Grid>
                        <FileUploader onUpload={setProductCatalog} />
                    </Grid>
                )}
                <Grid container justifyContent={"center"}>
                    <Button
                        variant="contained"
                        sx={{ width: "236px", mt: 5, mb: 5 }}
                        onClick={submit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <CircularProgress />
                        ) : (
                            t("status.application")
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ServiceRequestForm;
