import React from "react";

import { Close } from "@mui/icons-material";
import {
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { FontWeightValues } from "../../types/fontWeightTypes";

const Transtion = (
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) => {
    return <Slide direction="up" ref={ref} {...props} />;
};

const ForwardedSlideUpTransition = React.forwardRef(Transtion);

interface ModalDialogProps extends DialogProps {
    isMobile: boolean;
    title: string;
    onClose: () => void;
    themeMode?: "Dark" | "Light";
}

const ModalDialog: React.FC<ModalDialogProps> = ({
    isMobile,
    title,
    children,
    onClose,
    themeMode = "Light",
    ...others
}) => {
    return (
        <Dialog
            // fullScreen={isMobile}
            TransitionComponent={ForwardedSlideUpTransition}
            maxWidth={"md"}
            scroll={"paper"}
            {...others}
            PaperProps={{
                ...others?.PaperProps,
                elevation: 1,
                sx: {
                    m: 2.5,
                    borderRadius: 0,
                    p: 4,
                    width: isMobile ? "100%" : "680px",
                    bgcolor: themeMode === "Dark" ? "mono.800" : "mono.50",
                    color: themeMode === "Dark" ? "mono.50" : "mono.800",
                    ...others?.PaperProps?.sx,
                },
            }}
        >
            <DialogTitle sx={{ p: 0, pb: 2 }}>
                <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant="f24h36B"
                        fontWeight={FontWeightValues.EXTRA_BOLD}
                    >
                        {title}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Close
                            color={
                                themeMode === "Dark" ? "secondary" : "primary"
                            }
                        />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent tabIndex={-1} sx={{ p: 0 }}>
                {/* 컨텐츠 */}
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default ModalDialog;
