import { ExpertSettlement } from "../api-interfaces/quest";
import { QuestLeftSideExpertInfo } from "../api-interfaces/user";

export const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatDateDotToSerial = (date: string) => {
    return date.replace(/\./g, "");
};

export const leftsideExperInfoMapToArray = (
    mapData: QuestLeftSideExpertInfo
) => {
    const res: { title: string; content: string }[] = [];
    res.push({ title: "이름", content: mapData.name });
    res.push({ title: "연락처", content: mapData.contact });
    res.push({ title: "이메일", content: mapData.email });
    res.push({ title: "착수일자", content: mapData.beginDate });
    res.push({ title: "수수료율", content: mapData.feeRate });
    return res;
};

export const settlementMapToArray = (mapData: ExpertSettlement) => {
    const res: { title: string; content: string }[] = [];
    res.push({ title: "최종 수출 금액", content: mapData.exportFee });
    res.push({ title: "수수료", content: mapData.commission });
    res.push({ title: "최종금액", content: mapData.finalFee });
    return res;
};

export const computeFinalFee = (exportFee: string, feeRate: string) => {
    let finalExportFeeNum = 1;

    const exportFeePerUnit = exportFee.split("/")[0];
    const exportFeeOnlyNumberStr = exportFeePerUnit.replace(/[^0-9]/g, "");
    const exportFeeOnlyNumber = Number(exportFeeOnlyNumberStr);
    const feeRateOnlyNumber = Number(feeRate.replace(/[^0-9]/g, ""));
    if (!exportFeeOnlyNumberStr) {
        return "0";
    }
    if (exportFeePerUnit.includes("천")) {
        finalExportFeeNum = finalExportFeeNum * 1000;
    } else if (exportFeePerUnit.includes("백")) {
        finalExportFeeNum = finalExportFeeNum * 100;
    } else if (exportFeePerUnit.includes("십")) {
        finalExportFeeNum = finalExportFeeNum * 10;
    }
    if (exportFeePerUnit.includes("만")) {
        finalExportFeeNum = finalExportFeeNum * 10000;
    }

    finalExportFeeNum = finalExportFeeNum * (exportFeeOnlyNumber || 1);

    finalExportFeeNum = (finalExportFeeNum * feeRateOnlyNumber) / 100;

    return finalExportFeeNum;
};
