import React from "react";

import { FileDownloadOutlined } from "@mui/icons-material";
import { Grid, IconButton, Typography, useTheme } from "@mui/material";

export interface ChatBoxProps {
    isMyChat?: boolean;
    content?: string;
    time: string;
    src?: string;
    srcInfo?: { size: string; name: string };
}

const ChatBox: React.FC<ChatBoxProps> = ({
    isMyChat = false,
    content = "",
    time,
    src,
    srcInfo,
}) => {
    const theme = useTheme();
    return (
        <Grid
            container
            justifyContent={isMyChat ? "flex-end" : "flex-start"}
            mb={1.5}
        >
            <Grid>
                <Grid
                    container
                    sx={{
                        maxWidth: "450px",
                        bgcolor: isMyChat
                            ? theme.palette.pBlue.main
                            : theme.palette.mono[400],
                        borderRadius: "10px",
                        p: 2,
                        width: "fit-content",
                    }}
                >
                    {src ? (
                        <>
                            <Grid container alignItems={"center"}>
                                <a href={src} download>
                                    <IconButton sx={{ bgcolor: "white" }}>
                                        <FileDownloadOutlined
                                            htmlColor={theme.palette.pBlue.main}
                                        />
                                    </IconButton>
                                </a>
                                <Grid ml={2}>
                                    <Typography
                                        variant="f16h24B"
                                        color={
                                            isMyChat ? "mono.200" : "pBlue.main"
                                        }
                                    >
                                        {srcInfo?.name ?? ""}
                                    </Typography>
                                    <br />
                                    <Typography
                                        variant="f14h20"
                                        color={
                                            isMyChat ? "mono.200" : "mono.600"
                                        }
                                    >
                                        {srcInfo?.size ?? ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid flex={1} mr={2}>
                                <Typography
                                    variant="f16h24B"
                                    color={isMyChat ? "mono.200" : "mono.800"}
                                >
                                    {content}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography
                                    variant="f14h20"
                                    color={isMyChat ? "mono.200" : "mono.600"}
                                >
                                    {time}
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
                {/* {isRead && isMyChat && (
                    <Grid container mt={1}>
                        <Check
                            fontSize="small"
                            htmlColor={theme.palette.mono[600]}
                            sx={{ mr: 0.5 }}
                        />
                        <Typography variant="f14h20" color={"mono.600"}>
                            읽음
                        </Typography>
                    </Grid>
                )} */}
            </Grid>
        </Grid>
    );
};

export default ChatBox;
