import React from "react";

import { Button, Typography, useTheme } from "@mui/material";

import { TextButtonProps } from "./button-interfaces";

const GrayContainedTextButton: React.FC<TextButtonProps> = ({
    text,
    onClick,
}) => {
    const theme = useTheme();

    return (
        <Button
            variant="contained"
            sx={{
                height: "34px",
                ml: 1,
                bgcolor: theme.palette.mono[200],
                "&:hover": {
                    bgcolor: theme.palette.mono[200],
                },
            }}
            onClick={onClick}
        >
            <Typography variant="f16h20B" color={"mono.600"}>
                {text}
            </Typography>
        </Button>
    );
};

export default GrayContainedTextButton;
