const account = {
    login: "로그인",
    findID: "ID 찾기",
    findPW: "PW 찾기",
    signup: "회원가입",
    email: "이메일",
    pw: "비밀번호",
    notMatchingAlert: "회원정보가 일치하지 않습니다.",

    name: "이름",
    phone: "전화번호",
    notExistingUser: "존재하지 않는 유저입니다.",
    findIDFlavorText1: " 님의 아이디는",
    namePlaceholder: "김트포",
    phonePlaceholder: "01012345678",

    pwCheck: "비밀번호 확인",
    pwReset: "PW 리셋",
    wrongPasswordFormat: "비밀번호 형식이 올바르지 않습니다.",
    wrongPasswordMatching: "비밀번호 확인이 일치하지 않습니다.",
    passwordChanged: "패스워드가 변경되었습니다.",
    passwordValidationLabel: "영문, 숫자 조합 6자 이상",
    resetPassword: "리셋",

    nonamed: "(이름없음)",
    welcome1: "트레이드포스 회원가입을 축하드립니다!",
    welcome2: "서비스를 이용하시려면 아래 버튼을 클릭하여",
    welcome3: "상세정보를 입력해주세요",
    verification: "활동신청",
    wrongEmailFormat: "이메일의 형식이 올바르지 않습니다",
    noNameInput: "이름을 입력해주세요",
    noContactInput: "전화번호를 입력해주세요",
    noCompanyNameInput: "기업명을 입력해주세요",
    signUpComplete: "회원가입 성공",
    alreadyRegistered: "이미 등록된 유저입니다!",
    role: "역할",
    companyName: "기업명",
    companyNameExtraLabel: "개인일 경우 '개인 사업자' 기재",
    securityPolicyAgree: "개인정보 취급방침 동의",
    join: "가입",
};

const userRole = {
    0: "기업 (인증필요)",
    1: "기업",
    2: "전문가 (인증필요)",
    3: "전문가",
    8: "관리자",
};

const error = {
    internal: "내부적인 오류가 발생했습니다. 트레이드포스 측에 문의를 주세요.",
    network: "네트워크 오류! 연결상태를 확인해주세요!",
    imageUpload:
        "이미지 업로드 과정 중에 문제가 발생했습니다. 지속해서 문제가 발생하면 네트워크를 점검하시거나 재로그인을 해주세요.",
    fileUpload:
        "파일 업로드 과정 중에 문제가 발생했습니다. 지속해서 문제가 발생하면 네트워크를 점검하시거나 재로그인을 해주세요.",
    editFailed:
        "수정 중에 문제가 발생하였습니다. 지속해서 문제가 발생하면 네트워크를 점검하시거나 재로그인을 해주세요.",
    transferFailed:
        "전송 중에 문제가 발생하였습니다. 지속해서 문제가 발생하면 네트워크를 점검하시거나 재로그인을 해주세요.",
};

const common = {
    about: "약",
    complete: "완료",
    count1: "건",
    count2: "명",
    step1: "",
    step2: "단계",
    is2: "입니다.",
    mone: "\\",
    mone1: "억 원",
    님: "님",
    hi: "",
    download: "다운로드",
};

const community = {
    전체: "전체",
    소식: "소식",
    리뷰: "리뷰",
    블로그: "블로그",
    무역정보: "무역정보",
};

const nav = {
    exploreRequests: "의뢰찾기",
    serviceRequest: "서비스 의뢰",
    serviceIntroduction: "서비스 소개",
    companyIntroduction: "회사 소개",
    ambassador: "전문가 소개",
    verification: "활동신청",
    login: "로그인",
    signup: "회원가입",
    community: "커뮤니티",
    freeboard: "자유게시판",
    logout: "로그아웃",
    mypage: "마이페이지",
};

const footer = {
    terms: "이용약관",
    privacyPolicy: "개인정보처리방침",
    notice: "문의 사항은 우측 하단의 채널톡으로 문의해주세요.",
    contact: "전화번호: +82-02-6013-0901",
    FAX: "팩스번호: +82-0303-3448-0901",
    company1: "(주)브릿지오버",
    company2: `사업자등록번호 347-86-02181 | 소셜 벤처 인증
기업 제2021-01-0255호 | 무역협회 (KITA)
회원사`,
    company3: `경기도 성남시 수정구 대왕판교로 815 판교
제2테크노밸리 기업지원허브 833호`,
};

const home = {
    section10: "실력 있는 제조 기업,",
    section11: "능력있는 해외무역 전문가,",
    section12: "글로벌 무대에서 그들의 가장 강력한",
    section13: "경쟁력을 연결합니다.",
    section10st: "B2B 무역 솔루션 플랫폼",
    section10b: "수출 의뢰하기",
    section11b: "수입 의뢰하기",
    section10t: "TRADEFORCE",
    section10l: "누적매출 (거래액)",
    section11l: "누적 프로젝트 건수 (수출&수입)",
    section12l: "※ 21년 9월 ~ 23년 2월 누적 (1년 6개월 합산 기준)",
    section13l: "누적 파트너",

    section20t: "수출 전문가 매칭 플랫폼",
    section21t: "TRADEFORCE",
    section20st: "딱! 맞는 베테랑 해외 영업 전문가를 연결해",
    section21st: "수출 첫 걸음부터 무역 거래까지 성사 해드립니다",
    section20: "수입",
    section21: "수출",
    section22: "컨설팅",
    section20d: "수입 구매대행과 해외판권확보 영업을 전문가에게 의뢰합니다",
    section21d: "수출을 위한 해외영업 대행을 전문가에게 의뢰합니다",
    section22d:
        "해외시장정보 및 전반적인 무역과 영업의 노하우를 전문가를 통해 컨설팅을 받으실수 있습니다",

    section30: "해외 진출의 시작부터 끝까지,",
    section31: "트레이드포스 프로세스를 살펴보세요",
    section30d: "견적서 요청하기",
    section31d: "견적서 비교 하기",
    section32d: "직접 상담하기",
    section33d: "매칭 완료",

    community1: "동료를 모집하고 있습니다.",
    community2: "융하인리히 APAC 총괄 디렉터의 브릿지오버 판교 본사 방문",
    community0: "GMA 파트너스 미국 및 베트남 수출 협력 미팅",
    communityfrom0: "브릿지오버",
};

const FAQ = {
    title: "자주 묻는 질문",
    q0: "전문가 활동신청에 필요한 서류는 어떠한 것들이 있을까요?",
    q1: "기업 수출입 의뢰에 필요한 서류는 어떠한 것들이 있을까요?",
    q2: "선적서류 작업과 수출입 통관 업무도 대행하시나요?",
    q3: "물건이 오가는 수출입 이외에 컨설팅 업무도 매칭이 가능한가요?",
    q4: "파트너와 어떻게 연결되나요?",
    q5: "파트너와 분쟁 발생 시 어떻게 해야 하나요?",
    q6: "영업 정보 보안에 대해서 궁금해요.",
    q7: "정산 방식에 대해서 궁금해요.",
    a0: "활동신청시 경력사항에 들어가는 양식을 다운받아 작성하시면 됩니다. 또한 정산 시 받을 통장사본을 함께 첨부 해주시면 됩니다. *(법인 또는 사업자로 송금될 시, 별도로 사업자등록증을 요청 드릴 수 있습니다.)",
    a1: "전문가분들이 바이어들과 신속하게 거래에 대한 논의를 하기 위해서는 기업의 회사소개서와 제품 카다로그를 첨부 해 주시면 신뢰성 확보와 신속한 프로젝트 진행이 이뤄질 수 있습니다.",
    a2: "전문가와 기업의 1:1 대화창에 무역업무 대행을 트레이드포스팀에게 요청 한다는 메세지를 전달하시면, 관리자가 확인 후 무역 업무를 요청하신 사항으로 대행 해 드립니다.",
    a3: "트레이드포스는 무역협회의 컨설팅 자격증을 이수한 전문가분들이 다수 활동중이기 때문에 컨설팅이 필요 하실 경우, 해당 내용의 전문가를 매칭 해 드립니다. 현재 컨설팅의뢰는 준비사항으로 수출/수입의뢰를 통해 컨설팅 부분을 의뢰하시면 됩니다.",
    a4: "각 전문가분들의 견적 제안 이후, 거래 제안을 한 각 전문가분들과 1:1 대화를 통해 기업에게 적합한 전문가분을 선택하실 수 있습니다. 모든 프로젝트는 착수금 입금 이후 진행이 됩니다.",
    a5: "기업은 전문가와 1:1 협의하에 모든 계약진행과 대행 업무 진행이 이뤄지게 됩니다. 거래진행 시, 계약서 및 합의서 등 트레이드포스와 3자 협약으로 보증 되지 않은 거래의 경우, 분쟁 발생 부분을 트레이드포스에서는 책임지지 않습니다.",
    a6: "프로젝트 진행시, 영업 기밀 보안사항에 대해 전문가와 기업은 각 동의에 대한 비밀유지계약서를 작성하게 됩니다.",
    a7: "- 프로젝트 시작을 위해 에스크로계좌로 희망수출/수입금액에 대한 수수료 일부를 착수금으로 입금 하게 됩니다. 이 금액은 관리자 공지란에서 확인 하실 수 있습니다. - 최종 거래가 발생 후, 최종 수출/수입 금액으로 다시 수수료가 환산되며, 이때 에스크로 계좌로 입금 된 금액을 기준으로 차액과 증액은 반환 또는 송금 요청 될 수 있습니다. - 모든 정산은 상업송장 또는 수출/수입 신고금액의 실제 발생한 거래 금액에 의해 정산 되며, 거래 중단, 프로젝트 취소 시, 송금 된 착수금은 모두 반환 됩니다.",
};

const verify = {
    success: "성공적으로 신청되었습니다!",
    failed: "인증 중에 문제가 발생하였습니다.",
    alreadyVerified: "이미 활동신청을 했습니다.",
    verify: "활동 신청",
    resume: "경력정보 이력서",
    formDownload: "양식 다운로드",
    profileImage: "프로필 사진",
    copyAccount: "통장사본",
    businessLicense: "사업자 등록증",
    confirm: "완료",
};

const buttons = {
    fileSelect: "파일선택",
    noSelectedFile: "선택된 파일 없음",
};

const serviceIntroduction = {
    t10: "수출의 시작과 끝을 함께합니다.",
    t20: "브릿지오버는 경험에 연결을 더하여, 딱! 맞는 베테랑 해외영업 전문가를 연결해 수출 및 수입 첫 걸음부터 무역거래까지 성사해 드립니다.",
    t21: "지금까지 대한민국 수출 발전을 이끌어왔던 은퇴한 해외영업 및 무역 전문가들의 커리어 사각지대를 해결하고 무역의 어려움을 겪고 있는 기업들에게 해외 수출의 가능성을 열어주고자 카이스트 경영대학원의 소・부・장 전문가들이 뜻을 모아 (주)브릿지오버를 설립하였습니다.",
    t22: '디지털 B2B 무역 플랫폼 “트레이드포스" 서비스는 무역 전문가들과 함께 기업들의 해외 진출의 꿈을 실현합니다.',
    t23: "다양한 층의 전문가 확보",
    t24: "경험과 역량이 여전히 살아 있는 현직 은퇴한 전문가와 현 프리랜서 전문가들을 모집합니다. 대한민국 경제의 핵심 동력이었던 전문가, 은퇴와 동시에 경험과 역량의 활용방안이 사라져 사회적인 손실을 맞이하고 있은 이 시점에 브릿지오버는 은퇴한 전문가분들이 기술, 사업자문 및 영업력 등 축적된 경험과 노하우를 펼칠 수 있는 플랫폼 오피스가 되어 드립니다.",
    t25: "글로벌 진출을 원하는 기업들의 탄탄한 브릿지 역할",
    t26: "전세계로 수출을 원하는 수만개의 중소기업들과 1인기업들에게 수십년간 전세계 수출 경험과 노하우를 보유한 최고의 수출 전문가를 연결합니다. 국내 시장만 바라보다 사업 확장을 위해 진출을 검토하시나요? 무역의 전문성과 인적지원이 부족한 중소기업과 개인사업가에도 브릿지오버 플랫폼을 통해 각 분야의 컨설팅과 전문가의 제안을 받아 보실 수 있습니다.",
    t31: "매칭 서비스",
    t32: "국가별 산업별 검증된 해외무역 전문가와의 매칭",
    t33: "전문무역 업무대행",
    t34: "고객의 요청에 의한 물류, 통관, FTA컨설팅 대행",
    t35: "안전거래 시스템",
    t36: "확실한 거래완료 기반으로 지급되는 수수료 ",
    t365: "에스크로 계좌를 통한 안전 거래 시스템",
    t37: "고객맞춤 솔루션",
    t38: "기업 요청에 의한 트레이드포스 TF팀의 Buy & Sell 대행",
    t40: "국내 특허 출원",
    t41: "수출입 전문가 그룹핑 및 수출입 업무수행 시스템",
    t42: "수출입 전문가 유형 분석 시스템",
    t51: "에스크로 계좌를 통해 결제되어 거래 완료 시까지 결제 대금을 안전하게 보호받을 수 있습니다. 프로젝트 진행을 위해 전문가의 수수료 일부가 착수금으로 결제되며, 실제 거래 및 판매가 이뤄지지 않을 시, 전액 환불됩니다. 최종 거래 완료는 전문가의 프로젝트 완료 증빙과 고객의 확인 절차를 통해 수수료 지불이 이뤄집니다.",
    t52: "계약서 작성부터 무역 프로세스의 전반적인 지원",
    t53: "신뢰를 쌓아가야 하는 단계에서 믿을 만한 3자 중계가 필요한 경우, 계약서 작성과 검토 등 브릿지오버가 중계를 통해 파트너가 되어드립니다. 기업의 특성상 무역부분의 인적자원이 부족한 경우, 브릿지오버가 복잡한 무역 및 물류 업무를 무역 전문가를 통해 대행해 드립니다.",
    t54: "해외 바이어 의전 서비스, 전시회, 세미나 개최 대행 지원",
    t55: "해외 바이어 방문 시, 공장 투어 및 차량, 호텔, 숙소 예약 등 출장 스케줄 의전 업무를 지원 해 드립니다. 또한 세미나 또한 세미나 , 전시회 개최 시 필요한 제반 사항에 대한 전문가를 연결 해 드립니다.",
    t60: "의뢰고객",
    t611: "수출, 수입에 대한",
    t6112: "의뢰신청",
    t612: "전문가들의",
    t6122: "제안 및 견적",
    t613: "전문가 선택",
    t6132: "및 1:1 컨설팅",
    t614: "프로젝트 진행을",
    t6142: "위한 착수금 입금",
    t615: "거래성사 증빙 확인",
    t6152: "및 수수료 지불",
    t71: "회원가입 / 기업정보 등록",
    t72: "프로젝트 진행",
    t73: "프로젝트 완료",
    t74: "프로젝트 진행 시, 희망수출액 대비 실제 거래 성사 금액에는 차이가 있을 수 있으며, 최종 거래 금액에 의해 수수료가 산정됩니다.",
    t75: "프로젝트 진행시, 고객 요청에 의하여 (주)브릿지오버에서 무역 업무를 대행해 드립니다.",
    t80: "전문가",
    t811: "전문가 자격의 사전",
    t8112: "인터뷰 및 전문가 등재",
    t812: "전문가 활동",
    t8122: "- 거래제안",
    t813: "전문가 선택 시,",
    t8132: "1:1 컨설팅",
    t814: "제안 된 수수료율에",
    t8142: "의한 착수금 확인",
    t815: "최종 거래 금액 또는",
    t8152: "거래에 대한 증빙 제출",
    t91: "회원가입 / 전문가 이력 등록",
    t93: "프로젝트 완료에 대한 수수료 입금",
};

const companyInfo = {
    t10: "트레이드 포스의",
    t11: "역할과 가치",
    t21: "멘토링부터 컨설팅, 수출입 총괄, 프로젝트PM, ",
    t22: "무역실무까지 트레이드 포스가 함께합니다.",
    t301: "모두가 신뢰 안에서 수출이",
    t302: "가능한 세상을 만듭니다.",
    t311: "우리는 축적된 경험을",
    t312: "가치롭게 만듭니다.",
    t321: "신뢰 (Fidelity)",
    t322: "축적된 견고한 믿음",
    t323: "연결 (Bridge)",
    t324: "땀과 경험을 연결하는 기술",
    t325: "용기 (Fortitude)",
    t326: "새롭게 시작하는 결연한 용기",
    t401: "트레이드포스는",
    t402: "경험에 연결을 더하여 가치를 창출합니다.",
    t411: "교량자",
    t412: "물리적, 사회적, 시간적 거리를 좁혀 거래를 활성화합니다.",
    t421: "인증자",
    t422: "가치를 확인하고, 구매자에게 판매자가 파는 제품의 품질에 불안감을 갖지 않도록 정보를 제공합니다.",
    t431: "집행자",
    t432: "구매자와 판매자가 전력을 다하고, 협력하고, 정직하게 만듭니다.",
    t441: "보호자",
    t442: "한쪽이 지나치게 마진을 추구하거나 대립이 발생하지 않고 원하는 것을 달성하도록 돕습니다.",
    t451: "안내자",
    t452: "성가신 일을 줄여주고, 정보의 홍수 속에서 고객이 올바른 결정을 내릴 수 있게 돕습니다.",
    t461: "위험 감수자",
    t462: "위험 회피 성향이 강한 거래 상대를 위해 거래 변수나 형태의 불확실성을 줄여줍니다.",
};

const ambassador = {
    t1: "트레이드포스의 엠버서더를 소개합니다",
    엠버서더: "엠버서더",
    field: "담당분야",
    industryGroup: "산업군",
    coutryInCharge: "주요 담당국가",
    highlightingCareer: "주요 경력사항",
    해외소싱전문: "해외소싱전문",
    중국진출전문: "중국진출전문",
    "중소기업 수출입 컨설팅": "중소기업 수출입 컨설팅",
    "폴란드 해외영업 컨설팅": "폴란드 해외영업 컨설팅",
    "온라인 유통 및 마케팅, 컨설팅": "온라인 유통 및 마케팅, 컨설팅",
    해외영업전문: "해외영업전문",
    "해외영업, 컨설팅, 사업자문, 소싱": "해외영업, 컨설팅, 사업자문, 소싱",
    "해외 시장 컨설팅": "해외 시장 컨설팅",
    "컨설팅, 사업자문": "컨설팅, 사업자문",
    "배트남 해외영업, 온라인마케팅": "배트남 해외영업, 온라인마케팅",
    "수출입제반 컨설팅": "수출입제반 컨설팅",
    "플랜트, 연료전지, 반도체 기술고문, 턴키장비 수출, 바이어 연결":
        "플랜트, 연료전지, 반도체 기술고문, 턴키장비 수출, 바이어 연결",
    "해외 이커머스, 물류 풀필먼트, 해외진출인허가, 사업자문 및 컨설팅":
        "해외 이커머스, 물류 풀필먼트, 해외진출인허가, 사업자문 및 컨설팅",
    "해외 바이어 영업, 기술이전 계약, 식약품 인허가, 구매계약 협상, 자문 및 컨설팅":
        "해외 바이어 영업, 기술이전 계약, 식약품 인허가, 구매계약 협상, 자문 및 컨설팅",
    "해외 컨설팅(시장조사, 기술 자문, 신사업개발)":
        "해외 컨설팅(시장조사, 기술 자문, 신사업개발)",
    "해외영업, 컨설팅, 사업자문": "해외영업, 컨설팅, 사업자문",
    "해외수출, 마케팅, 경영기획": "해외수출, 마케팅, 경영기획",
    "한국관세, 중국관세, 대외무역법": "한국관세, 중국관세, 대외무역법",
};

const tradefirm = {
    t: "함께 있어 든든한 무역상사.",
    회사명: "회사명",
    전문영역: "전문영역",
    의뢰서비스: "의뢰서비스",
    companyName: "기업명",
    exportingItem: "수출 품목",
    bestExportingItem: "수출에 자신 있는 품목",
    desirableSourcingItemSet: "소싱 희망 품목",
    mainDealingItem: "주요 취급 품목군",
    homepage: "홈페이지",
    companyForm: "전문무역상사 기업 형태",
    exportingNations: "수출 중인 국가",
    companyIntroduction: "기업소개",
};

const mypage = {
    // 프로필섹션
    companyName: "회사명", // NOTE account 의 companyName 은 기업명이다.
    verification: "활동신청",
    verified: "승인",
    notVerified: "미승인",
    notRegistered: "미등록",
    profileEdit: "프로필 수정",
    address: "주소",
    profilePicChange: "프로필 이미지 변경",
    businessLicense: "사업자등록증",

    editSuccess: "수정되었습니다",
    // 유저카테고리 셀렉트 섹션
    quest: "의뢰",
    // 의뢰섹션
    import: "수입",
    export: "수출",
    consulting: "컨설팅",
    noQuestPrompt: "의뢰가 없습니다",
    수입: "수입의뢰", // NOTE reqType 을 바로넣었을 때 결과
    수출: "수출의뢰",
    컨설팅: "컨설팅의뢰",
    showMore: "더 보기",
    registeredDate: "등록 일자",
    productName: "제품명",
    question: "질문",
};

const status = {
    confirm: "확인",
    saving: "저장중...",
    apply: "적용",
    cancelled: "중단",
    complete: "완료",
    aborted: "중단됨",
    submit: "제출",
    select: "선택",
    chat: "채팅하기",
    modify: "수정",
    delete: "삭제",
    cancel: "취소",
    send: "보내기",
    deactivate: "비활성화",
    application: "신청",
};

const questStatus = {
    0: "마감 및 중단 의뢰",
    1: "전문가 견적 대기 중",
    2: "전문가 견적 상담 및 비교 중",
    3: "의뢰 착수금 납부 대기 중",
    4: "의뢰 착수금 납부 확인 중",
    5: "의뢰 진행 중",
    6: "마지막 금액 확인",
    7: "의뢰 완료",
};

const quest = {
    // all Requests sections
    aqt0: "모든 의뢰",
    aqt1: "견적서 작성",
    et1: "의뢰 정보",
    의뢰번호: "의뢰번호",
    유형: "유형",
    산업군: "산업군",
    구분: "구분",
    연매출: "연매출",
    직원수: "직원수",
    기업명: "기업명",
    요청사항: "요청사항",
    수입희망금액: "수입희망금액",
    수출희망금액: "수출희망금액",
    컨설팅희망금액: "컨설팅희망금액",
    "요청 일자": "요청 일자",
    et21: "회사소개서",
    et22: "제품 카탈로그",

    writeEstimate: "견적서 작성",
    answerConsulting: "컨설팅 답변 작성",
    proposedDate: "착수 가능일",
    dateWithPlaceholder: "날짜 (ex 2022.08.24)",
    commissionFee: "수수료율",
    consultingFee: "컨설팅 제안 금액",
    consultingFeeWithPlaceholder: "컨설팅 제안 금액",
    percentageWithPlaceholder: "백분율 (ex 10%)",
    consultingAnswer: "견적 설명",
    estimateDetail: "견적 설명",
    detailsPlaceholder: "세부 사항",

    transferSuccess: "성공적으로 전송되었습니다.",
    // Request Detail Section
    abortAskMessage: "진행 중인 의뢰를 중단하시겠습니까?",
    cancelAccess: "취소 신청이 완료되었습니다",
    notSpecifiedQuestError: "지정된 의뢰가 없습니다!",
    confirmQuest: "의뢰를 확정하시겠습니까?",
    confirmSuccess: "의뢰를 확정하였습니다.",
    expertInfo: "전문가",
    questCancel: "의뢰취소",
    이름: "이름",
    연락처: "연락처",
    이메일: "이메일",
    착수일자: "착수일자",
    수수료율: "수수료율",

    abortRequestMessage: "중단된 의뢰입니다.",
    watingForEstimate: "견적서를 기다리고 있습니다",
    waitingForConsulting: "답변을 기다리고 있습니다",
    expectedEstimateInfo: "예상 견적 정보",
    showEstimate: "견적서 보기",
    hideEstimate: "견적서 숨기기",

    proposedEstimate: "제안된 견적서",
    deleteAskMessage: "정말로 삭제하시겠습니까?",
    // chat section
    verifyingDocumentMessage: "증빙서류를 반드시 첨부해주세요.",
    seeVerifyingDocument: "증빙서류 참조",
    adminAnnoucement: "관리자 공지",
    accountNumber: "계좌번호",
    bank: "은행",
    adminContact: "관리자 연락처",
    adminEmail: "관리자 이메일",
    questProcessStatus: "의뢰현황",
    chatScreen: "채팅창",
    settlement: "정산서",
    "최종 수출 금액": "최종 수출 금액",
    "최종 수입 금액": "최종 수입 금액",
    수수료: "수수료",
    "최종 금액": "최종 금액",
    최종금액: "최종금액",
    verifyingDocument: "증빙서류",
    chatTextFieldPlaceholder: "내용을 입력해주세요",
    sendFile: "파일 전송",
    sendFileAskMessage: "다음 파일들을 전송하시겠습니까?",
    decide: "확정하기",
    depositWaiting: "착수금 입금 대기",
    depositComplete: "착수금 입금 완료",
    depositConfirming: "입금 확인중",
    confirmDeposit: "착수금 입금 확인",
    requestSuccess: "요청되었습니다.",
    confirmSuccessRaw: "확인 처리되었습니다.",
    checkingSettlement: "정산서 검토중",
    inputSettlement: "정산정보 입력",
    checkSettlement: "정산정보 확인완료",
    askCompleteSettlementMessage: "정산서 검토를 완료하시겠습니까?",
    completeMessage: "완료되었습니다.",
    askRejectSettlementMessage: "정산서를 반려하시겠습니까?",
    askReapplySettlement: "정산정보 재요청",
    writingSettlement: "정산서 작성 중",
    askCompleteProjectMessage: "프로젝트를 완료하시겠습니까?",
    projectComplete: "프로젝트 완료",
    finalChecking: "최종검토 중",
    settlementTitle: "정산 정보",
    inputCommissionFeeManually: "수수료 직접 입력",
    inputExportingFee: "수출액 입력",
    finalFee: "최종 정산액",
    inputFinalFee: "최종 정산액 입력",
    verifyingDocumentHelpText:
        "*필수 (계약서, 선적서류, 신고필증 중 필수 첨부)",
    askSettlementMessage: "정산을 요청하시겠습니까?",
    // 신청화면
    questType: "의뢰",
    applySuccess: "신청이 완료되었습니다.",
    etcInput: "기타 입력...",
    "귀사의 산업군": "귀사의 산업군",
    "귀사의 업종": "귀사의 업종",
    "귀사의 전년도 연 매출": "귀사의 전연도 연매출",
    "귀사의 직원 수": "귀사의 직원 수",
    "수출 전담 직원": "수출 전담 직원",
    "수입 전담 직원": "수입 전담 직원",
    "수출 경험 여부": "수출 경험 여부",
    "전년도 수출액": "전년도 수출액",
    "컨설팅 분야": "컨설팅 분야",
    "바우처 여부": "바우처 여부",
    "바우처 해당": "바우처 해당",
    "바우처 미 해당": "바우처 미 해당",
    "전시회/행사/해외영업지원": "전시회/행사/해외영업지원",
    "특허/지재권/시험": "특허/지재권/시험",
    "브랜드개발/관리": "브랜드개발/관리",
    "서류대행/현지등록/환보험": "서류대행/현지등록/환보험",
    "조사/일반 컨설팅": "조사/일반 컨설팅",
    해외규격인증: "해외규격인증",
    "디자인 개발": "디자인 개발",
    역량강화교육: "역량강화교육",
    "국제 운송": "국제 운송",
    "법무/세무/회계 컨설팅": "법무/세무/회계 컨설팅",
    "홍보/광고": "홍보/광고",
    홍보동영상: "홍보동영상",
    통번역: "통번역",
    제조업: "제조업",
    유통업: "유통업",
    도매업: "도매업",
    중계업: "중계업",
    기계: "기계",
    중장비: "중장비",
    "자동차 부품": "자동차 부품",
    "로봇 자동화": "로봇 자동화",
    자동차부품: "자동차부품",
    로봇자동화: "로봇자동화",
    "1~10억": "1~10억",
    "10~50억": "10~50억",
    "50~100억": "50~100억",
    "100~500억": "100~500억",
    "500억 이상": "500억 이상",
    "1~10명": "1~10명",
    "10~50명": "10~50명",
    "50~100명": "50~100명",
    "100~500명": "100~500명",
    "500명 이상": "500명 이상",
    있음: "있음",
    없음: "없음",
    "5개국 미만": "5개국 미만",
    "10개국 이상": "10개국 이상",
    "10만불 이상": "10만불 이상",
    "100만불 이상": "100만불 이상",
    "1000만불 이상": "1000만불 이상",
    askingDetail: "요청사항",
    askingDetailHelpText:
        "* 상세한 정보 전달을 위해 하기 내용을 모두 기재하시기를 제안드립니다.",
    상품명: "상품명",
    "희망 금액": "희망 금액",
    수출: "수출",
    수입: "수입",
    expectedPricePlaceholder: "금액/단위 (ex. 3000만원/1 unit)",
    optional: "선택",
    // 자유게시판
    전체: "전체",
    showMore: "더보기",
};

const forum = {
    title: "자유게시판",
    write: "글쓰기",
    noPostMessage: "작성된 글이 없습니다.",
};

const kr = {
    translation: {
        account,
        common,
        error,
        home,
        community,
        FAQ,
        nav,
        footer,
        userRole,
        verify,
        buttons,
        serviceIntroduction,
        companyInfo,
        ambassador,
        mypage,
        status,
        questStatus,
        quest,
        tradefirm,
        forum,
    },
};

export default kr;
