import React, { forwardRef, Ref } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { mobileMaxWidthMediaQuery } from "../../../theme";
import StepButton from "../../buttons/StepButton";

/**
 * Step 1, 2, 3, 4 있는 거
 */
const StepSection = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { t } = useTranslation();
    return (
        <Grid
            ref={ref}
            container
            sx={{
                minHeight: "100vh",
                width: "100%",
                backgroundColor: "white",
                alignItems: "center",
                overflowX: "hidden",
            }}
            p={isMobile ? 1 : 4}
            mb={isMobile ? 5 : 0}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"flex-start"}
                textAlign={"start"}
                width={isMobile ? "auto" : "100%"}
                mt={isMobile ? 10 : 0}
                mb={isMobile ? 10 : 0}
                sx={{ wordBreak: "keep-all" }}
                p={2}
            >
                <Typography
                    color={"black"}
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    sx={{ textAlign: "left" }}
                >
                    {t("home.section30")}
                </Typography>
                <Typography
                    color={"black"}
                    variant={isMobile ? "f32h40EB" : "f48h64EB"}
                    sx={{ textAlign: "left" }}
                >
                    {t("home.section31")}
                </Typography>
            </Box>
            <Box
                display={"flex"}
                width={"100%"}
                flexDirection={isMobile ? "column" : "row"}
                alignItems={"center"}
                minWidth={isMobile ? undefined : 900}
            >
                <StepButton
                    title={"STEP 1"}
                    subtitle={t("home.section30d")}
                    icon={"./images/icon/file-plus-02.svg"}
                    isMobile={isMobile}
                />
                <StepButton
                    title={"STEP 2"}
                    subtitle={t("home.section31d")}
                    icon={"./images/icon/file-search-02.svg"}
                    isMobile={isMobile}
                />
                <StepButton
                    title={"STEP 3"}
                    subtitle={t("home.section32d")}
                    icon={"./images/icon/user-right-01.svg"}
                    isMobile={isMobile}
                />
                <StepButton
                    title={"STEP 4"}
                    subtitle={t("home.section33d")}
                    icon={"./images/icon/file-check-03.svg"}
                    isMobile={isMobile}
                />
            </Box>
        </Grid>
    );
});

export default StepSection;
