import React, { useEffect, useState } from "react";

import { useRecoilValue, useSetRecoilState } from "recoil";

import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { ResponseUploadFile } from "../api-interfaces/file";
import { UserRole } from "../api-interfaces/user";
import { uploadF, uploadImg } from "../apis/upload";
import { patchMe } from "../apis/user";
import BackButton from "../components/buttons/BackButton";
import Bingle from "../components/indicators/Bingle";
import FileUploader from "../components/inputs/FileUploader";
import { navThemeRecoil, userRecoil } from "../components/states/recoil";
import { navThemeValues } from "../types/navThemeTypes";

const Verification = () => {
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const [businessLicenseFile, setBusinessLicenseFile] = useState<File>();
    const [resumeFile, setResumeFile] = useState<File>();
    const [profilePicFile, setProfilePicFile] = useState<File>();
    const [accountCopy, setAccountCopy] = useState<File>();
    const { t } = useTranslation();
    const userData = useRecoilValue(userRecoil);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = async () => {
        try {
            if (userData) {
                if (userData.role === UserRole.GUEST) {
                    if (!businessLicenseFile) {
                        return;
                    }
                    setIsSubmitting(() => true);
                    const bl = await uploadF(
                        businessLicenseFile,
                        "businessLicense",
                        userData._id
                    );
                    if (!bl) {
                        alert(t("error.network"));
                        return;
                    }
                    await patchMe({ businessLicense: bl.src }, true);

                    alert(t("verify.success"));
                } else if (userData.role === UserRole.UNAPPROVED_EXPERT) {
                    let acc: ResponseUploadFile | undefined = undefined;
                    let re: ResponseUploadFile | undefined = undefined;
                    let img: ResponseUploadFile | undefined = undefined;
                    setIsSubmitting(() => true);
                    if (accountCopy) {
                        acc = (await uploadF(
                            accountCopy,
                            "accountCopy",
                            userData._id
                        )) || { src: "", key: "" };
                    }
                    if (resumeFile) {
                        re = (await uploadF(
                            resumeFile,
                            "resume",
                            userData._id
                        )) || { src: "", key: "" };
                    }
                    if (profilePicFile) {
                        img = (await uploadImg(
                            profilePicFile,
                            "profilePic",
                            userData._id
                        )) || { src: "", key: "" };
                    }

                    await patchMe(
                        {
                            copyBankAccount: acc?.src ?? "",
                            careerCertification: re?.src ?? "",
                            image: img?.src ?? "",
                            wasApplied: true,
                        },
                        true
                    );
                    alert(t("verify.success"));
                }
            }
            setIsSubmitting(() => false);
            window.location.replace("/");
        } catch (e) {
            alert(t("verify.failed"));
            window.location.reload();
            return;
        }
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    if (
        (userData?.role === UserRole.UNAPPROVED_EXPERT &&
            userData.wasApplied) ||
        (userData?.role === UserRole.GUEST && userData.businessLicense)
    ) {
        return (
            <Grid
                container
                flexDirection={"column"}
                alignItems={"center"}
                p={2.5}
            >
                <Grid
                    container
                    sx={{
                        flexDirection: "column",
                        maxWidth: "494px",
                        width: "100%",
                        flexWrap: "nowrap",
                        mt: 8,
                        alignItems: "center",
                        height: "80vh",
                        justifyContent: "center",
                    }}
                >
                    <Grid mb={5} mt={2}>
                        <img
                            src="/images/icon/check_animation.gif"
                            width={60}
                            height={60}
                        />
                    </Grid>
                    <Grid mb={5} mt={2}>
                        <Typography variant="f32h40EB">
                            {t("verify.alreadyVerified")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container flexDirection={"column"} alignItems={"center"} p={2.5}>
            <Grid
                container
                sx={{
                    flexDirection: "column",
                    maxWidth: "494px",
                    width: "100%",
                    flexWrap: "nowrap",
                    mt: 8,
                }}
            >
                <Grid>
                    <BackButton />
                </Grid>
                <Grid mb={5} mt={2}>
                    <Typography variant="f32h40EB">
                        {t("verify.verify")}
                    </Typography>
                </Grid>

                {userData?.role === UserRole.UNAPPROVED_EXPERT ? (
                    <>
                        <Grid
                            mb={2}
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography variant="f18h20B">
                                {t("verify.resume")}
                            </Typography>
                            <a
                                href="/images/service/전문가_지원양식.xlsx"
                                download
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    color="pBlue"
                                    sx={{ fontSize: 16, height: "24px" }}
                                >
                                    <FileDownloadOutlined />
                                    <Typography variant="f16h20B">
                                        {t("verify.formDownload")}
                                    </Typography>
                                </Button>
                            </a>
                        </Grid>
                        <FileUploader onUpload={setResumeFile} />
                        <Grid mb={2} mt={5}>
                            <Typography variant="f18h20B">
                                {t("verify.profileImage")}
                            </Typography>
                        </Grid>
                        <FileUploader onUpload={setProfilePicFile} />
                        <Grid mb={2} mt={5}>
                            <Typography variant="f18h20B">
                                {t("verify.copyAccount")}
                            </Typography>
                        </Grid>
                        <FileUploader onUpload={setAccountCopy} />
                    </>
                ) : (
                    <>
                        <Grid mb={2}>
                            <Typography variant="f18h20B">
                                {t("verify.businessLicense")}
                            </Typography>
                        </Grid>
                        <FileUploader onUpload={setBusinessLicenseFile} />
                    </>
                )}
                <Grid container justifyContent={"center"}>
                    <Button
                        disabled={
                            (userData?.role === UserRole.GUEST &&
                                !businessLicenseFile) ||
                            isSubmitting
                        }
                        variant="contained"
                        sx={{ width: "236px", mt: 5, mb: 5 }}
                        onClick={submit}
                    >
                        {isSubmitting ? (
                            <Bingle height={48} />
                        ) : (
                            t("verify.confirm")
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Verification;
