import React from "react";

import { Chip, ChipProps } from "@mui/material";

import { useTranslation } from "react-i18next";

const CancelledChip: React.FC<ChipProps> = (props) => {
    const { t } = useTranslation();
    return (
        <Chip
            label={t("status.cancelled")}
            color="error"
            variant="outlined"
            clickable
            {...props}
        />
    );
};

export default CancelledChip;
