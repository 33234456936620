const account = {
    login: "Sign In",
    findID: "Find ID",
    findPW: "Find PW",
    signup: "Sign Up",
    email: "Email",
    pw: "Password",
    notMatchingAlert: "No Matching User",

    name: "Name",
    phone: "Phone",
    notExistingUser: "The user does not exist.",
    findIDFlavorText1: "'s ID: ",
    namePlaceholder: "John Doe",
    phonePlaceholder: "+821012345678",

    pwCheck: "Check PW",
    pwReset: "Reset PW",
    wrongPasswordFormat: "Wrong Format Password",
    wrongPasswordMatching: "Password Check not matched",
    passwordChanged: "The password was succesfully changed.",
    passwordValidationLabel: "6 or more / alphanumeric",
    resetPassword: "Reset",

    nonamed: "(No Named)",
    welcome1: "Welcome to TradeForce!",
    welcome2: "Press the button to verify who you are.",
    welcome3: "(Required to proceed to use the service)",
    verification: "Verify",
    wrongEmailFormat: "Wrong Format Email",
    noNameInput: "Fill the name field",
    noContactInput: "Fill the contact field",
    noCompanyNameInput: "Fill the company field",
    signUpComplete: "You are successfully registered!",
    alreadyRegistered: "Already Registered User",
    role: "Role",
    companyName: "Company Name",
    companyNameExtraLabel: "'Sole Proprietorship' can be allowed",
    securityPolicyAgree: "Agree with Security Policies",
    join: "Join",
};

const userRole = {
    0: "Client (Not verified)",
    1: "Client",
    2: "Expert (Not verified)",
    3: "Expert",
    8: "Admin",
};

const common = {
    about: "About",
    count1: "\u00A0Projects",
    count2: "\u00A0People",
    step1: "Step\u00A0",
    step2: "",
    complete: "Done",
    is2: "",
    mone1: "M",
    mone: "$",
    님: "",
    hi: "Hi, ",
    download: "Download",
};

const community = {
    전체: "ALL",
    소식: "STORY",
    리뷰: "REVIEW",
    블로그: "BLOG",
    무역정보: "TRADEINFO",
};

const error = {
    internal: "An unexpected error occured. Contact us via email.",
    network: "Network Connect Error! Please check your internet connection.",
    imageUpload:
        "An error occured while uploading the image. If you continuously face this message, login again.",
    fileUpload:
        "An error occured while uploading the file. If you continuously face this message, login again.",
    editFailed:
        "An error occured while editting. If you continuously face this message, login again.",
    transferFailed:
        "An error occured while networking. If you continuously face this message, login again.",
};

const home = {
    section10: "Qualified Manufacturing Companies,",
    section11: "Skillful International Specialists,",
    section12: "We are connecting their power",
    section13: "in the global world",
    section10st: "B2B Trade Solution",
    section10b: "Request for Export",
    section11b: "Request for Import",
    section10t: "TRADEFORCE",
    section10l: "Cum. Revenue (turnover)",
    section11l: "Cum. Project Count (Export & Import)",
    section12l: "※ Sep 2021 ~ Feb 2023 (during 1 year 6 months)",
    section13l: "Cum. Partners",

    section20t: "Export Specialist Matching Platform",
    section21t: "TRADEFORCE",
    section20st: "Meet with overseas sales experts",
    section21st: "Accompanying from the beginning to the end",
    section20: "Import",
    section21: "Export",
    section22: "Consulting",
    section20d:
        "Ask an expert for import buying agent and international copyright registration",
    section21d: "Ask an expert for overseas sales for export",
    section22d:
        "Consult overseas market information or overall trade and tips with experts",

    section30: "Explore the TRADEFORCE process—",
    section31: "Overseas Expansion A to Z",
    section30d: "Ask an Estimate",
    section31d: "Compare the Estimates",
    section32d: "Consult In Realtime",
    section33d: "Match the Specialist",

    community1: "We are looking for colleuges",
    community2:
        "Jungheinrich APAC General Director visits Bridgeover Pangyo headquarters",
    community0: "GMA Partners USA and Vietnam Export Cooperation Meeting",
    communityfrom0: "Bridgeover",
};

const FAQ = {
    title: "FAQ",
    q0: "What documents are required to apply for professional activities?",
    q1: "What kind of documents do you need to request the import and export of a company?",
    q2: "Do you also work on shipping documents and import and export customs?",
    q3: "Is it possible to match consulting work other than import and export of goods?",
    q4: "How do you connect with your partner?",
    q5: "What should I do in the event of a dispute with my partner?",
    q6: "I'm curious about sales information security.",
    q7: "I'm curious about the settlement method.",
    a0: "When applying for activities, you can download and fill out the form that goes into your career. Also, you can attach a copy of the bankbook that you will receive at the time of payment. *(If it is remitted to a corporation or a business operator, we may request a business registration card separately.",
    a1: "In order for professionals to quickly discuss transactions with buyers, please attach a company introduction and product catalog to ensure reliability and expedited project progress.",
    a2: "If you send a message to the trade force team in the 1:1 conversation between experts and companies, the manager will confirm and act on behalf of the trade team.",
    a3: "Tradeforce has a number of experts who have completed the Korea International Trade Association's consulting certificates, so if you need consulting, we will match them with experts. Currently, you can request the consulting part through export/import requests as a preparation.",
    a4: "After each expert's quote offer, you can have a one-on-one conversation with each expert who made the deal to choose the right expert for your business. All projects will be carried out after deposit.",
    a5: "All contracts and agency work will be carried out in 1:1 consultation with experts. In the event of a transaction, if a transaction is not guaranteed by a three-way agreement with Tradeforce, such as a contract or agreement, Tradeforce shall not be responsible for any dispute arising out of it.",
    a6: "In the course of the project, the professional and the company will write confidentiality agreements for their respective agreements on confidential business matters.",
    a7: "- Part of the fee for the desired export/import amount will be deposited into the escrow account to start the project as a deposit. You can check this amount in the manager's notice box. - After the final transaction takes place, the fee is converted back to the final export/import amount, and the difference and increase based on the amount deposited into the escrow account may be requested for return or remittance. - All settlements will be settled by the actual transaction amount of the commercial invoice or export/import declaration amount, and upon suspension of the transaction, cancellation of the project, any deposit remitted will be returned.",
};

const nav = {
    exploreRequests: "Explore",
    serviceRequest: "Request",
    serviceIntroduction: "Service",
    companyIntroduction: "Company",
    ambassador: "Ambassador",
    verification: "Verify",
    login: "Sign-In",
    signup: "Sign-Up",
    community: "Community",
    freeboard: "Forum",
    logout: "Sign-Out",
    mypage: "My Page",
};

const footer = {
    terms: "Terms of Use",
    privacyPolicy: "Privacy & Policy",
    notice: "For Inquiry, use ChannelTalk at the bottom-right corner",
    contact: "Contact: +82-02-6013-0901",
    FAX: "FAX: +82-0303-3448-0901",
    company1: "Bridgeover Inc.",
    company2: `Company Registration Number 347-86-02181 | Social Venture Certificated
| Company No. 2021-01-0255 | member of KITA`,
    company3: `815, Daewangpangyo-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do |
Pangyo 2nd Techno Valley - Enterprise Support Hub No.833`,
};

const verify = {
    success: "Successfully Registered!",
    failed: "Unexpected problems occurred during the verification",
    alreadyVerified: "Already Submitted.",
    resume: "Resume",
    verify: "Verify",
    formDownload: "Download the template (in Korean)",
    profileImage: "Profile Image",
    copyAccount: "Copy of Bank Account",
    businessLicense: "Business License",
    confirm: "Apply",
};

const buttons = {
    fileSelect: "Select",
    noSelectedFile: "Not Selected...",
};

const serviceIntroduction = {
    t10: "We are together while you exporting.",
    t20: "Bridging over adds connectivity to the experience. We will connect the veteran overseas sales experts to complete the trade from the first step of export and import.",
    t21: "The material, parts, and equipment expertise from KAIST Business School established Bridgeover, Inc. We resolve the career blind spots of retired overseas sales and trade experts, who have led Korea's export development. Simultaneously, we clear the obstacle of overseas export for the companies who suffering from trade.",
    t22: 'The digital B2B trade platform "TradeForce" service, along with trade experts, realizes companies\' dreams of going abroad.',
    t23: "Recruiting Various Experts",
    t24: "Now, with the retirement of experts, who were the key drivers of the Korean economy, the use of experience and capabilities has disappeared, facing social losses. Bridgeover is a platform office for retired professionals to develop their accumulated experience and know-how, including technical, business advice and sales skills.",
    t25: "The Robust bridge for companies looking to enter global market",
    t26: "With decades of global export experience and know-how, we connects thousands of small and medium-sized enterprises or solo entrepreneur that want to export globally. Are you considering entering the global market to expand your business? Small and medium-sized enterprises and solo entrepreneurs who lack trade expertise and human support can also receive consulting and expert suggestions in various field through the Bridgeover platform.",
    t31: "Matching Service",
    t32: "Matching with verified experts by country and industry",
    t33: "Professional Trade Agency",
    t34: "Logistics, Customs Clearance, FTA Consulting Agency",
    t35: "Secure Trade System",
    t36: "Fees paid on a solid transaction completion basis. ",
    t365: "Secure transaction system through escrow account.",
    t37: "Customized Solution",
    t38: "TradeForce TF Team's Buy & Sell agents by request of each company.",
    t40: "Domestic Patent",
    t41: "Import/Export Expert Grouping and Import/Export Business Performance System",
    t42: "Import/Export Expert Type Analysis System",
    t51: "Payments are made through escrow accounts to ensure that payments are secured until the transaction is completed. Some of the expert's fees will be paid as a deposit to proceed with the project, and if there is no actual transaction or sale, a full refund will be made. The final transaction will be completed through proof of project completion and customer verification procedures.",
    t52: "Overall support for the trade process from contract writing",
    t53: "If you need a reliable 3rd party relaying at the stage of building trust, Bridgeover will be your partner through relaying, such as writing and reviewing contracts. If the human resources in the trade sector are insufficient due to the nature of the company, the Bridgeover will act as a trade expert for complex trade and logistics tasks.",
    t54: "Support for overseas buyer's protocol service, exhibition, and seminar hosting agency",
    t55: "When visiting overseas buyers, we will support all business trips such as factory tours and reservations for vehicles, hotels, and accommodations. In addition, we connect experts on all necessary matters when holding seminars and exhibitions.",
    t60: "Client",
    t611: "Request for",
    t6112: "Export and Import",
    t612: "Expert Suggestions",
    t6122: "and Estimates",
    t613: "Expert Selection",
    t6132: "and 1:1 Consulting",
    t614: "Deposit deposit",
    t6142: "for project progress",
    t615: "Confirm completion",
    t6152: "and Payment of Fees",
    t71: "Sign up / Company info registration",
    t72: "Project Progress",
    t73: "Project Completion",
    t74: "During the project, there may be a difference in the actual transaction amount compared to the desired export amount, and the fee is calculated based on the final transaction amount.",
    t75: "Bridgeover Co., Ltd. will be a trade agent at the request of the customer.",
    t80: "Expert",
    t811: "Pre-interview of professional qualifications",
    t8112: "and expert registration",
    t812: "Expert Activity",
    t8122: "- Deal Proposal",
    t813: "Choose an expert,",
    t8132: "1:1 Consulting",
    t814: "Confirmation of deposit",
    t8142: "by proposed commission rate",
    t815: "Final Fee or Evidence of",
    t8152: "Transaction Submission",
    t91: "Sign up / Expert Resume Registration",
    t93: "Deposit commission for project completion",
};

const companyInfo = {
    t10: "TradeForce's",
    t11: "Mission & Vision",
    t21: "From mentoring, consulting, project managing,",
    t22: "To overall trade process, TradeForce be with you.",
    t301: "Make the World to",
    t302: "Import and Export in Trust.",
    t311: "Add the Value to",
    t312: "Accumulated Experiences.",
    t321: "Fidelity",
    t322: "Solid Faith",
    t323: "Bridge",
    t324: "Effort and Experience",
    t325: "Fortitude",
    t326: "Courage to Start anew",
    t401: "TradeForce creates Values",
    t402: "by Adding Connectness to Experience",
    t411: "교량자",
    t412: "Shorten physical, social, temporal distance to promote the trade.",
    t421: "인증자",
    t422: "Check the quality of products and provide buyer information.",
    t431: "집행자",
    t432: "Enforce them to make an effort, cooperate, and be honest.",
    t441: "보호자",
    t442: "Help achieve what you want without onesides' greedy or confrontation.",
    t451: "안내자",
    t452: "Get rid of anonyance and lead customers to the right decisions.",
    t461: "위험 감수자",
    t462: "Reduce the uncertainty of trading variables or forms for counterparties.",
};

const ambassador = {
    t1: "Let me introduce TradeForce Ambassadors",
    엠버서더: "Ambassador",
    field: "Field",
    industryGroup: "Industry Group",
    coutryInCharge: "Main Country",
    highlightingCareer: "Career Highlight",
    해외소싱전문: "International Sourcing Specialist",
    중국진출전문: "Chinese Market Specialist",
    "중소기업 수출입 컨설팅": "SME Import/Export Consultant",
    "폴란드 해외영업 컨설팅": "Polish Market Specialist",
    "온라인 유통 및 마케팅, 컨설팅":
        "Online Distribution, Marketing, Consulting",
    해외영업전문: "Overseas Sales Specialist",
    "해외영업, 컨설팅, 사업자문, 소싱":
        "International Sourcing, Sales, Business Advisory, and Consulting",
    "해외 시장 컨설팅": "Global Market Consultant",
    "컨설팅, 사업자문": "Business Advisory and Consulting",
    "배트남 해외영업, 온라인마케팅":
        "Vietnamese Market Sales, Online Marketing",
    "수출입제반 컨설팅": "Export/Import Base Consulting",
    "플랜트, 연료전지, 반도체 기술고문, 턴키장비 수출, 바이어 연결":
        "Plant/Fuel cell/Semiconductor Technology Advisor, Turnkey Equipment Export, Buyer Connecting",
    "해외 이커머스, 물류 풀필먼트, 해외진출인허가, 사업자문 및 컨설팅":
        "Global E-commerce / Item Fullfillment / Permission of Overseas Market Expansion Specialist, Business Advisor, Consultant",
    "해외 바이어 영업, 기술이전 계약, 식약품 인허가, 구매계약 협상, 자문 및 컨설팅":
        "Overseas Buyer Sales, Technology Transfer Contracts, Ministry of Food and Drug Safety Licensing, Purchase Contract Negotiations, Advisory and Consulting",
    "해외 컨설팅(시장조사, 기술 자문, 신사업개발)":
        "overseas consulting(Market Research, Technical Advice, Development of a New Business)",
    "해외영업, 컨설팅, 사업자문":
        "Overseas Sales, Consulting, Business Advisory",
    "해외수출, 마케팅, 경영기획":
        "Overseas export, marketing, management planning",
    "한국관세, 중국관세, 대외무역법":
        "Korean Tariffs, Chinese Tariffs, Foreign Trade Law",
};

const tradefirm = {
    t: "Reliable Trading Companies with You.",
    회사명: "By Company Name",
    전문영역: "By Specialized Field",
    의뢰서비스: "By Service",
    companyName: "Company Name",
    exportingItem: "Exporting Item Set",
    bestExportingItem: "Best Exporting Item Set",
    desirableSourcingItemSet: "Desirable Sourcing Item Set",
    mainDealingItem: "Main Dealing Item Set",
    homepage: "Homepage",
    companyForm: "Company Form",
    exportingNations: "Exporting Nations",
    companyIntroduction: "Company Introduction",
};

const mypage = {
    companyName: "Company",
    verification: "Verification",
    verified: "Verified",
    notVerified: "Not Verified",
    notRegistered: "Not Registered",
    profileEdit: "Edit Profile",
    address: "Address",
    profilePicChange: "Change Profile Image",
    businessLicense: "Certificate of Business Registration",

    editSuccess: "Successfully Editted.",

    quest: "Quest",

    import: "Import",
    export: "Export",
    consulting: "Consulting",
    noQuestPrompt: "There is no quest",
    수입: "Import",
    수출: "Export",
    컨설팅: "Consulting",
    showMore: "Show More",
    registeredDate: "Registered",
    productName: "Product Name",
    question: "Question",
};

const status = {
    confirm: "Confirm",
    saving: "Saving...",
    apply: "Apply",
    cancelled: "Cancelled",
    complete: "Done",
    aborted: "Cancelled",
    submit: "Submit",
    select: "Select",
    chat: "Chat",
    modify: "Modify",
    delete: "Delete",
    cancel: "Cancel",
    send: "Send",
    deactivate: "...",
    application: "Publish",
};

const questStatus = {
    0: "Cancelled/Aborted",
    1: "Proposal",
    2: "Comparing Estimations",
    3: "Waiting Payment",
    4: "Confirming Payment",
    5: "Work In Progress",
    6: "Confirming Final Fee",
    7: "Complete",
};

const quest = {
    // estimation
    aqt0: "All Requests",
    aqt1: "Write Estimate",
    et1: "Details",
    의뢰번호: "Quest No.",
    유형: "Type",
    산업군: "Group",
    구분: "Division",
    연매출: "Annual Sales",
    직원수: "Number of Employees",
    기업명: "Company Name",
    요청사항: "Request Details",
    수입희망금액: "Wished Import Cost",
    수출희망금액: "Wished Export Cost",
    "요청 일자": "Requested Date",
    et21: "Company Profile",
    et22: "Product Catalog",

    writeEstimate: "Estimate",
    answerConsulting: "Consulting",
    proposedDate: "Proposed Start Date",
    dateWithPlaceholder: "ex) 2022.08.24",
    commissionFee: "Commission Fee",
    percentageWithPlaceholder: "ex) 10%",
    consultingAnswer: "Comment",
    estimateDetail: "Suggestion",
    detailsPlaceholder: "Free-styled format",

    transferSuccess: "Successfully requested.",
    // Request Detail Section
    abortAskMessage: "Would you abort the quest?",
    cancelAccess: "Cancellation request were submitted.",
    notSpecifiedQuestError: "No specifed request!",
    confirmQuest: "Would you confirm the quest?",
    confirmSuccess: "Successfully confirmed.",
    expertInfo: "Expert Profile",
    questCancel: "Cancel Request",
    이름: "Name",
    연락처: "Contact",
    이메일: "Email",
    착수일자: "Proposed Start Date",
    수수료율: "Commission Fee",

    abortRequestMessage: "It's cancelled quest.",
    watingForEstimate: "Waiting For Estimates",
    waitingForConsulting: "Waiting For Comments",
    expectedEstimateInfo: "Proposed Estimate",

    showEstimate: "Show Estimate",
    hideEstimate: "Hide Estimate",

    proposedEstimate: "Suggestion",
    deleteAskMessage: "Would you really delete this?",
    // chat section
    verifyingDocumentMessage: "You must attach the document.",
    seeVerifyingDocument: "See the Verifying Document",
    adminAnnoucement: "Announcement",
    accountNumber: "Bank Account Number",
    bank: "Bank Name",
    adminContact: "Administrator Contact",
    adminEmail: "Administrator Email",
    questProcessStatus: "Quest Process",
    chatScreen: "Chat",
    settlement: "Settlement",
    "최종 수출 금액": "Final Sales",
    "최종 수입 금액": "Final Sales",
    수수료: "Commission Fee",
    "최종 금액": "Final Fee",
    최종금액: "Final Fee",
    verifyingDocument: "Verifying Document",
    chatTextFieldPlaceholder: "Type...",
    sendFile: "Send File",
    sendFileAskMessage: "Would you send following files?",
    decide: "Final Select",
    depositWaiting: "Deposit Waiting",
    depositComplete: "Request Checking Deposit",
    depositConfirming: "Deposit Confirming",
    confirmDeposit: "착수금 입금 확인",
    requestSuccess: "Successfully Requested.",
    confirmSuccessRaw: "Successfully Confirmed.",
    checkingSettlement: "Reviewing Settlement",
    inputSettlement: "Input Settlement",
    checkSettlement: "Confirm Settlement",
    askCompleteSettlementMessage: "Would you confirm the settlement",
    completeMessage: "Successfully Complete",
    askRejectSettlementMessage: "Would you reject the settlement",
    askReapplySettlement: "Reject and Re-ask",
    writingSettlement: "Writing Settlement",
    askCompleteProjectMessage: "Would you complete the project?",
    projectComplete: "Complete Project",
    finalChecking: "Final Reviewing",
    settlementTitle: "Settlement Details",
    inputCommissionFeeManually: "Input Manually",
    inputExportingFee: "Input Exporting Sales",
    finalFee: "Fianl Fee",
    inputFinalFee: "type in number...",
    verifyingDocumentHelpText:
        "*required (one of Contract, Shipping Documents, and Certificate of Importation)",
    askSettlementMessage:
        "Would you ask for the client to reivew the settlement?",

    applySuccess: "Successfully Published.",
    etcInput: "Type...",

    questType: "Quest Type",
    "귀사의 산업군": "Your Industry Group",
    "귀사의 업종": "Your Industry Type",
    "귀사의 전년도 연 매출": "Last Year Annual Sales",
    "귀사의 직원 수": "Number of Employees",
    "수출 전담 직원": "The Person in Charge",
    "수입 전담 직원": "The Person in Charge",
    "수출 경험 여부": "How many times have you exported?",
    "전년도 수출액": "Last Year Exporting Sales",
    제조업: "Manufacturing",
    유통업: "Distribution",
    도매업: "Wholesale",
    중계업: "Brokerage",
    기계: "Machine",
    중장비: "Heavy Equipment",
    "자동차 부품": "Automotive Parts",
    "로봇 자동화": "Robot Automation",
    자동차부품: "Automotive Parts",
    로봇자동화: "Robot Automation",
    "1~10억": "0.1~1B won",
    "10~50억": "1~5B won",
    "50~100억": "5~10B won",
    "100~500억": "10~50B won",
    "500억 이상": "over 50B won",
    "1~10명": "1~10",
    "10~50명": "10~50",
    "50~100명": "50~100",
    "100~500명": "100~500",
    "500명 이상": "over 500",
    있음: "Exist",
    없음: "Not Exist",
    "5개국 미만": "under 5",
    "10개국 이상": "over 10",
    "10만불 이상": "over $ 0.1M",
    "100만불 이상": "over $ 1M",
    "1000만불 이상": "over $ 10M",
    askingDetail: "What are you going to ask?",
    askingDetailHelpText: "* Please write all factors or elements of below",
    수출: "Exporting",
    수입: "Importing",
    상품명: "Product Name",
    "희망 금액": "Expected Price",
    expectedPricePlaceholder: "won/unit (ex. 30000000/1 unit)",
    optional: "Optional",

    전체: "All",
    showMore: "Show More",
};

const forum = {
    title: "Forum",
    write: "Write",
    noPostMessage: "There is no post",
};

const en = {
    translation: {
        common,
        home,
        error,
        community,
        FAQ,
        nav,
        footer,
        account,
        userRole,
        verify,
        buttons,
        serviceIntroduction,
        companyInfo,
        ambassador,
        mypage,
        status,
        questStatus,
        quest,
        tradefirm,
        forum,
    },
};

export default en;
