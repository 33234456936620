import React, { useState } from "react";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import ModalDialog from "./ModalDialog";

export interface QuestLevelFilterModalProps {
    handleClose: () => void;
    open: boolean;
    isMobile: boolean;
    onChangeFilter?: (filterTabIdx: number) => void;
}

/**
 * 의뢰단계 필터를 띄워주는 모달
 */
const QuestLevelFilterModal: React.FC<QuestLevelFilterModalProps> = ({
    handleClose,
    open,
    isMobile,
    onChangeFilter,
}) => {
    const [levelFilterOnModal, setLevelFilterOnModal] = useState<number>(-1);
    const { t } = useTranslation();

    return (
        <ModalDialog
            isMobile={isMobile}
            title={"의뢰 단계"}
            open={open}
            onClose={handleClose}
        >
            <Grid container spacing={1}>
                {[
                    "마감 및 중단 의뢰",
                    "전문가 견적 대기 중",
                    "전문가 견적 상담 및 비교 중",
                    "의뢰 착수금 납부 대기 중",
                    "의뢰 착수금 납부 확인 중",
                    "의뢰 진행 중",
                    "의뢰 완료",
                ].map((process, idx) => (
                    <Grid
                        item
                        xs={isMobile ? 6 : undefined}
                        key={`process-${process}`}
                    >
                        <Button
                            fullWidth={isMobile}
                            variant={
                                idx === levelFilterOnModal
                                    ? "contained"
                                    : "outlined"
                            }
                            sx={{
                                height: "34px",
                                mr: isMobile ? 0 : 1,
                            }}
                            onClick={() => {
                                setLevelFilterOnModal((prev) =>
                                    idx === prev ? -1 : idx
                                );
                            }}
                        >
                            <Typography variant="f16h20B">
                                {`${t("common.step1")}${idx}${t(
                                    "common.step2"
                                )}`}
                            </Typography>
                            <Tooltip
                                title={
                                    idx === 6
                                        ? t("questStatus.7") ?? ""
                                        : t(`questStatus.${idx}`) ?? ""
                                }
                                arrow
                                placement={"bottom-start"}
                            >
                                <InfoOutlined
                                    fontSize="small"
                                    sx={{ ml: 1, cursor: "pointer" }}
                                />
                            </Tooltip>
                        </Button>
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent={"flex-end"} mt={5}>
                <Button
                    fullWidth={isMobile}
                    variant="contained"
                    sx={{
                        height: "34px",
                    }}
                    onClick={() => {
                        if (onChangeFilter) {
                            onChangeFilter(levelFilterOnModal);
                        }
                        handleClose();
                    }}
                >
                    <Typography variant="f16h20B">
                        {t("status.apply")}
                    </Typography>
                </Button>
            </Grid>
        </ModalDialog>
    );
};

export default QuestLevelFilterModal;
