import React, { useState, useEffect } from "react";

import { useRecoilValue, useSetRecoilState } from "recoil";

import Grid from "@mui/material/Grid";

import { UserRole } from "../api-interfaces/user";
import MyRequestsSectionAdmin from "../components/sections/admin/MyRequestsSectionAdmin";
import MyRequestsSection from "../components/sections/mypage/MyRequestsSection";
import UserpageCateSelectSection from "../components/sections/mypage/UserpageCateSelectSection";
import UserProfileSection from "../components/sections/mypage/UserProfileSection";
import { navThemeRecoil, userRecoil } from "../components/states/recoil";
import { navThemeValues } from "../types/navThemeTypes";

const MyPage = () => {
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const [pageCate, setPageCate] = useState<"request" | "community">(
        "request"
    );
    const userData = useRecoilValue(userRecoil);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    return (
        <Grid container flexDirection={"column"} alignItems={"center"} p={2.5}>
            <Grid
                container
                sx={{
                    flexDirection: "column",
                    maxWidth: "1006px",
                    width: "100%",
                    flexWrap: "nowrap",
                    mt: 12,
                }}
            >
                <UserProfileSection />
                <UserpageCateSelectSection
                    pageCate={pageCate}
                    setPageCate={setPageCate}
                />
                {pageCate === "request" &&
                    (userData?.role === UserRole.ADMIN ? (
                        <MyRequestsSectionAdmin />
                    ) : (
                        <MyRequestsSection />
                    ))}
            </Grid>
        </Grid>
    );
};

export default MyPage;
