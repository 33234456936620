import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { RequestPatchUser, UserRole } from "../../../api-interfaces/user";
import { uploadFile, uploadImage } from "../../../apis/upload";
import { patchMe } from "../../../apis/user";
import {
    mobileMaxWidthMediaQuery,
    tabletMaxWidthMediaQuery,
} from "../../../theme";
import { FontWeightValues } from "../../../types/fontWeightTypes";
import { DEFAULT_PROFILE_IMG_PATH } from "../../../utils/consts";
import FileUploader from "../../inputs/FileUploader";
import ModalDialog from "../../modals/ModalDialog";
import { userRecoil } from "../../states/recoil";

export interface UserProfileSectionProps {}

const UserProfileSection: React.FC<UserProfileSectionProps> = ({}) => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const isTablet = useMediaQuery(tabletMaxWidthMediaQuery);
    const [userData, setUserData] = useRecoilState(userRecoil);
    const [userPatchData, setUserPatchData] = useState<RequestPatchUser>({});
    const [profileImageFile, setProfileImageFile] = useState<File>();
    const [businessLicenseFile, setBusinessLicenseFile] = useState<File>();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [approved, setApproved] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (userData) {
            if (
                userData.role == UserRole.GUEST ||
                userData.role == UserRole.UNAPPROVED_EXPERT ||
                userData.role == undefined
            ) {
                return;
            } else {
                setApproved(true);
                console.log("done");
            }
        }
    }, [userData]);
    const getUserRole = (role: UserRole): string => {
        return t(`userRole.${role}`);
    };

    const onUploadPicture = async (image: File) => {
        console.log(image.name);
        try {
            if (userData) {
                const formData = new FormData();
                formData.append("image", image);
                const res = await uploadImage(userData?._id, formData);
                console.log(res);
                setUserPatchData((prev) => {
                    return { ...prev, image: res.src };
                });
                return res;
            }
        } catch (e) {
            window.alert(t("error.imageUpload"));
        }
    };

    const onUploadFile = async (file: File) => {
        try {
            if (userData) {
                const formData = new FormData();
                formData.append("userfile", file);
                const res = await uploadFile(userData?._id, formData);

                console.log(res); // 로그용

                return res;
            }
        } catch (e) {
            window.alert(t("error.fileUpload"));
        }
    };

    const onPatchUser = async () => {
        try {
            setIsUploading(true);
            let imgSrc;
            let busLicSrc;
            if (profileImageFile)
                imgSrc = await onUploadPicture(profileImageFile);
            if (businessLicenseFile)
                busLicSrc = await onUploadFile(businessLicenseFile);
            const res = await patchMe({
                ...userPatchData,
                ...(imgSrc ? { image: imgSrc.src } : {}),
                ...(busLicSrc ? { businessLicense: busLicSrc.src } : {}),
            } as RequestPatchUser);
            console.log(res);
            setUserData(res);
            setIsUploading(false);
            alert(t("mypage.editSuccess"));
        } catch (e) {
            alert(t("error.editFailed"));
            console.log(e);
        }
    };

    useEffect(() => {
        if (userData) {
            setUserPatchData({
                name: userData.name,
                nickname: userData.nickname,
                companyName: userData.companyName,
                contact: userData.contact,
                companyAddr: userData.companyAddr,
                businessNum: userData.businessNum,
            });
            setProfileImageFile(undefined);
        }
    }, [openEditModal]);

    return (
        <>
            <Grid
                container
                sx={{
                    border: "solid 1px #AAAAAA",
                    p: 3.5,
                }}
                flexDirection={isMobile ? "column" : "row"}
            >
                <Grid
                    container
                    item
                    xs={isTablet ? (isMobile ? undefined : 6) : 5}
                    mb={isMobile ? 2 : 0}
                    pr={isMobile ? 0 : 2}
                >
                    <img
                        src={userData?.image || DEFAULT_PROFILE_IMG_PATH}
                        width={isMobile ? 80 : 150}
                        height={isMobile ? 80 : 150}
                        style={{ objectFit: "cover" }}
                    />
                    <Grid
                        ml={3}
                        container
                        flexDirection={isMobile ? "row" : "column"}
                        flex={1}
                        justifyContent={"space-between"}
                        maxHeight={"150px"}
                    >
                        <Grid>
                            <Grid>
                                <Typography
                                    variant="f24h36B"
                                    fontWeight={FontWeightValues.EXTRA_BOLD}
                                >
                                    {userData?.nickname}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="f12h20" color="mono.600">
                                    {getUserRole(
                                        userData?.role || UserRole.GUEST
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Button
                                variant="outlined"
                                sx={{ p: 1, height: "26px" }}
                                onClick={() => setOpenEditModal(true)}
                            >
                                <Typography variant="f12h20">
                                    {t("mypage.profileEdit")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    {!isMobile && <Divider flexItem orientation={"vertical"} />}
                </Grid>

                <Grid item xs={isMobile ? undefined : 3}>
                    <Grid>
                        <Typography
                            variant="f12h20"
                            color="mono.600"
                            sx={{
                                backgroundColor: "#FAFAFA",
                                width: "fit-content",
                                p: 1,
                                pt: 0.5,
                                pb: 0.5,
                                mb: 1,
                            }}
                        >
                            {t("mypage.companyName")}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            mt: 1,
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant="f18h20B"
                            fontWeight={FontWeightValues.REGULAR}
                        >
                            {userData?.companyName || t("mypage.notRegistered")}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography
                            variant="f12h20"
                            color="mono.600"
                            sx={{
                                backgroundColor: "#FAFAFA",
                                width: "fit-content",
                                p: 1,
                                pt: 0.5,
                                pb: 0.5,
                                mb: 1,
                            }}
                        >
                            {t("mypage.verification")}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            mt: 1,
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant="f18h20B"
                            fontWeight={FontWeightValues.REGULAR}
                        >
                            {approved
                                ? t("mypage.verified")
                                : t("mypage.notVerified")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={isTablet ? (isMobile ? undefined : 3) : 4}>
                    <Grid>
                        <Typography
                            variant="f12h20"
                            color="mono.600"
                            sx={{
                                backgroundColor: "#FAFAFA",
                                width: "fit-content",
                                p: 1,
                                pt: 0.5,
                                pb: 0.5,
                                mb: 1,
                            }}
                        >
                            {t("account.phone")}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            mt: 1,
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant="f18h20B"
                            fontWeight={FontWeightValues.REGULAR}
                        >
                            {userData?.contact || t("mypage.notRegistered")}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography
                            variant="f12h20"
                            color="mono.600"
                            sx={{
                                backgroundColor: "#FAFAFA",
                                width: "fit-content",
                                p: 1,
                                pt: 0.5,
                                pb: 0.5,
                                mb: 1,
                            }}
                        >
                            {t("mypage.address")}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            mt: 1,
                            mb: 2,
                        }}
                    >
                        <Typography
                            variant="f18h20B"
                            fontWeight={FontWeightValues.REGULAR}
                        >
                            {userData?.companyAddr || t("mypage.notRegistered")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <ModalDialog
                isMobile={isMobile}
                title={t("mypage.profileEdit")}
                open={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <Grid container mb={isMobile ? 2 : 0}>
                    <img
                        src={
                            profileImageFile
                                ? URL.createObjectURL(profileImageFile)
                                : userData?.image
                        }
                        width={isMobile ? 80 : 150}
                        height={isMobile ? 80 : 150}
                        style={{ objectFit: "cover" }}
                    />
                    <Grid
                        ml={3}
                        container
                        flexDirection={isMobile ? "row" : "column"}
                        flex={1}
                        justifyContent={"space-between"}
                        maxHeight={"150px"}
                    >
                        {!isMobile && (
                            <Grid>
                                <Grid>
                                    <Typography
                                        variant="f24h36B"
                                        fontWeight={FontWeightValues.EXTRA_BOLD}
                                    >
                                        {userData?.nickname}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        variant="f12h20"
                                        color="mono.600"
                                    >
                                        {getUserRole(
                                            userData?.role || UserRole.GUEST
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            {...(isMobile
                                ? {
                                      container: true,
                                      alignItems: "center",
                                      justifyContent: "center",
                                  }
                                : {})}
                        >
                            <Button
                                variant="outlined"
                                sx={{ p: 1, height: "26px" }}
                                // onClick={}
                                component="label"
                            >
                                <input
                                    type="file"
                                    accept="image/jpg, image/jpeg, image/png"
                                    hidden
                                    onChange={(e) => {
                                        if (e.target.files)
                                            setProfileImageFile(
                                                e.target.files[0]
                                            );
                                    }}
                                />
                                <Typography variant="f12h20">
                                    {t("mypage.profilePicChange")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={5}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">
                            {t("account.name")}
                        </Typography>
                    </Grid>
                    <TextField
                        value={userPatchData.name}
                        onChange={(e) =>
                            setUserPatchData((prev) => {
                                return {
                                    ...prev,
                                    name: e.target.value,
                                    nickname: e.target.value,
                                };
                            })
                        }
                    />
                </Grid>
                <Grid mt={2}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">
                            {t("mypage.companyName")}
                        </Typography>
                    </Grid>
                    <TextField
                        value={userPatchData.companyName}
                        onChange={(e) =>
                            setUserPatchData((prev) => {
                                return { ...prev, companyName: e.target.value };
                            })
                        }
                    />
                </Grid>
                <Grid mt={2}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">
                            {t("account.phone")}
                        </Typography>
                    </Grid>
                    <TextField
                        value={userPatchData.contact}
                        onChange={(e) =>
                            setUserPatchData((prev) => {
                                return { ...prev, contact: e.target.value };
                            })
                        }
                    />
                </Grid>
                <Grid mt={2}>
                    <Grid mb={1}>
                        <Typography variant="f20h28EB">
                            {t("mypage.address")}
                        </Typography>
                    </Grid>
                    <TextField
                        value={userPatchData.companyAddr}
                        onChange={(e) =>
                            setUserPatchData((prev) => {
                                return { ...prev, companyAddr: e.target.value };
                            })
                        }
                    />
                </Grid>
                {userData?.role === UserRole.COMPANY ||
                    (userData?.role === UserRole.GUEST && (
                        <Grid mt={2}>
                            <Grid mb={1}>
                                <Typography variant="f20h28EB">
                                    {t("mypage.businessLicense")}
                                </Typography>
                            </Grid>
                            <FileUploader onUpload={setBusinessLicenseFile} />
                        </Grid>
                    ))}
                <Grid container justifyContent={"flex-end"} mt={3}>
                    <Button
                        fullWidth={isMobile}
                        variant="contained"
                        sx={{
                            height: "34px",
                        }}
                        onClick={onPatchUser}
                        disabled={isUploading}
                    >
                        <Typography variant="f16h20B">
                            {isUploading
                                ? t("status.saving")
                                : t("status.confirm")}
                        </Typography>
                    </Button>
                </Grid>
            </ModalDialog>
        </>
    );
};

export default UserProfileSection;
