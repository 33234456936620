import React from "react";

import { Grid } from "@mui/material";

import { ChatParsed } from "../../../api-interfaces/chat";
import { QuestFormTypes } from "../../../api-interfaces/quest";

import NoReportSection from "./NoReportSection";
import RequestcancelledSection from "./RequestCancelledSection";
import RequestExpertCard from "./RequestExpertCard";

export interface RequestReportSelectSectionProps {
    isMobile: boolean;
    estimateReports: ChatParsed[];
    reqType: QuestFormTypes;
    onSelectExpertAtList: (chat: ChatParsed) => () => void;
    level: number;
}

const RequestReportSelectSection: React.FC<RequestReportSelectSectionProps> = ({
    isMobile,
    estimateReports,
    reqType,
    onSelectExpertAtList,
    level,
}) => {
    return (
        <Grid
            sx={{
                height: isMobile ? "auto" : "100%",
                overflow: "auto",
                ...(isMobile
                    ? {}
                    : {
                          bgcolor: "#FAFAFA",
                          border: "solid 1px #AAAAAA",
                          p: 3,
                      }),
            }}
        >
            {level < 1 ? (
                <RequestcancelledSection isMobile={isMobile} /> // 취소된 경우
            ) : estimateReports.length > 0 ? (
                estimateReports.map((report, idx) => (
                    // 뭐가 들어온 경우
                    <RequestExpertCard
                        key={`estimate-report-${idx}`}
                        isMobile={isMobile}
                        report={report}
                        isSelected={false}
                        onSelectCard={() => {
                            onSelectExpertAtList(report)();
                        }}
                    />
                ))
            ) : (
                <NoReportSection isMobile={isMobile} reqType={reqType} /> // 아직 안들어온 경우
            )}
        </Grid>
    );
};

export default RequestReportSelectSection;
