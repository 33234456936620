import React, { useState } from "react";

import { useRecoilValue } from "recoil";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, Collapse, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import {
    QuestLeftSideExpertInfo,
    UserRole,
} from "../../../api-interfaces/user";
import { FontWeightValues } from "../../../types/fontWeightTypes";
import { leftsideExperInfoMapToArray } from "../../../utils/format";
import { userRecoil } from "../../states/recoil";
import InfoList from "../../tables/InfoList";

export interface RequestInfoSectionProps {
    isMobile: boolean;
    cancelRequest: () => void;
    reqInfo: { title: string; content: string }[];
    expertInfo?: QuestLeftSideExpertInfo;
    level: number;
}

const RequestInfoSection: React.FC<RequestInfoSectionProps> = ({
    isMobile = false,
    cancelRequest,
    reqInfo,
    expertInfo,
    level,
}) => {
    const [openInfoBox, setOpenInfoBox] = useState<boolean>(false);
    const userData = useRecoilValue(userRecoil);
    const { t } = useTranslation();

    return (
        <Grid
            sx={{
                bgcolor: "#FAFAFA",
                border: "solid 1px #AAAAAA",
                maxHeight: "98vh",
                overflow: "auto",
                position: "relative",
            }}
        >
            <Collapse
                in={openInfoBox || !isMobile}
                collapsedSize={"80px"}
                sx={{ p: 3 }}
            >
                <InfoList data={reqInfo} />
                {expertInfo && (
                    <>
                        <Grid mt={5} mb={3}>
                            <Typography
                                variant="f24h36B"
                                fontWeight={FontWeightValues.EXTRA_BOLD}
                            >
                                {t("quest.expertInfo")}
                            </Typography>
                        </Grid>
                        <InfoList
                            data={leftsideExperInfoMapToArray(expertInfo).map(
                                (eInfo) => {
                                    return {
                                        ...eInfo,
                                        title: t(`quest.${eInfo.title}`),
                                    };
                                }
                            )}
                        />
                    </>
                )}

                {isMobile &&
                    ((userData?.role === UserRole.COMPANY &&
                        level > 0 &&
                        level < 3) || // 의뢰확정전 고객이 취소하거나 어드민이 아무때나 취소하거나
                        (userData?.role === UserRole.ADMIN && level > 0)) && (
                        <Grid mt={5}>
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{ bgcolor: "#FFF", whiteSpace: "nowrap" }}
                                onClick={cancelRequest}
                                disabled={level === 7}
                            >
                                {t("quest.questCancel")}
                            </Button>
                        </Grid>
                    )}
            </Collapse>

            {!isMobile &&
                level > 0 &&
                ((userData?.role === UserRole.COMPANY &&
                    level > 0 &&
                    level < 3) ||
                    (userData?.role === UserRole.ADMIN && level > 0)) && (
                    <Grid
                        p={3}
                        sx={{
                            position: "sticky",
                            bottom: 0,
                        }}
                    >
                        <Button
                            fullWidth
                            variant="outlined"
                            sx={{ bgcolor: "#FFF" }}
                            onClick={cancelRequest}
                            disabled={level === 7}
                        >
                            {t("quest.questCancel")}
                        </Button>
                    </Grid>
                )}

            {isMobile && (
                <Button
                    fullWidth
                    onClick={() => setOpenInfoBox((prev) => !prev)}
                >
                    {openInfoBox ? (
                        <ExpandLess fontSize="large" htmlColor={"#AAAAAA"} />
                    ) : (
                        <ExpandMore fontSize="large" htmlColor={"#AAAAAA"} />
                    )}
                </Button>
            )}
        </Grid>
    );
};

export default RequestInfoSection;
