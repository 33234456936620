import {
    ParsedUser,
    RequestFindingPostForgotUserData,
    RequestPatchUser,
    RequestPostResetPassword,
    RequestPostUser,
    ResponseLogIn,
    UserModel,
} from "../api-interfaces/user";

import apiInstance from "./base";

/**
 * 회원가입
 * @param userInfo - 회원가입에 필요한 필수 필드들
 */
export const signUpUser = async (userInfo: RequestPostUser) => {
    const query = `v1/user`;
    const nu = await apiInstance
        .post(query, { json: userInfo })
        .json<ResponseLogIn>();
    return nu;
};

/**
 * 로그인
 * @param userInfo - 이메일과 패스워드
 */
export const signInUser = async (userInfo: { email: string; pw: string }) => {
    const query = `v1/user/signin`;
    const nu = await apiInstance
        .post(query, { json: userInfo })
        .json<ResponseLogIn>();
    return nu;
};

/**
 * 로그아웃
 */
export const signoutUser = async () => {
    const query = `v1/user/signout`;
    const nu = await apiInstance.post(query).json<ResponseLogIn>();
    return nu;
};

/**
 * 내 정보 가져오기
 */
export const fetchMe = async () => {
    const query = `v1/user/me`;
    const u = await apiInstance.get(query).json<UserModel>();
    console.log(u);
    const fetchedUser: ParsedUser = {
        ...u,
    };
    return fetchedUser;
};

/**
 * 유저 정보를 가져오기
 *
 * @param userId
 * @returns
 */
export const getUser = async (userId: string) => {
    const query = `v1/user/${userId}`;
    const nu = await apiInstance.get(query).json<UserModel>();

    const fetchedUser: ParsedUser = {
        ...nu,
    };

    return fetchedUser;
};

/**
 * 자기 자신을 회원정보 수정하기
 *
 * @param userInfo - 수정할 자신의 유저정보
 * @param isApplicationSubmitting -  활동신청하는 경우
 */
export const patchMe = async (
    userInfo: RequestPatchUser,
    isApplicationSubmitting = false
) => {
    const query = `v1/user${isApplicationSubmitting ? "?application=1" : ""}`;
    const p = await apiInstance
        .patch(query, {
            json: userInfo,
            ...(isApplicationSubmitting ? { timeout: 60000 } : {}),
            // 이메일보내는데 오래걸릴경우를 대비
        })
        .json<UserModel>();

    const patched: ParsedUser = {
        ...p,
    };
    return patched;
};

/**
 * 이메일 찾거나 패스워드 리셋시키기 위한 쿠키 발급하기
 *
 * @param indexer - 이름과 전화번호, 그리고 비밀번호를 찾을거면 email 도 첨부
 * @returns
 */
export const findEmailOrPrepareResetPW = async (
    indexer: RequestFindingPostForgotUserData
) => {
    const query = `v1/user/find`;
    const fe = await apiInstance.post(query, { json: indexer }).json<{
        email: string;
    }>();
    return fe.email;
};

/**
 * 비밀번호 리셋시키기
 *
 * @remarks
 * 쿠키로 인증
 *
 * @param data - 비밀번호
 */
export const resetPassword = async (data: RequestPostResetPassword) => {
    const query = `v1/user/reset-password`;
    await apiInstance.post(query, { json: data }).json();
};
