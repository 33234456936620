import React from "react";

import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { ChatParsed, ReqeustPatchChat } from "../../../api-interfaces/chat";
import { ExpertSettlement } from "../../../api-interfaces/quest";
import { settlementMapToArray } from "../../../utils/format";
import ChatSection from "../../chat/ChatSection";
import RequestProcessMeter from "../../indicators/RequestProcessMeter";
import InfoList from "../../tables/InfoList";

import AnouncementSection from "./AnouncementSection";

export interface RequestChatSectionProps {
    isMobile: boolean;
    level: number;
    onSelectExpert: () => void;
    reqId: string;

    /**
     * 도저히 뷰 구조가 눈에 안들어와서 만듦
     *
     * @remarks
     * 견적서 버튼 만한 자리
     */
    minus48?: number;

    /**
     * 5단계서 정산정보입력했는지 여부
     */
    settlement?: ExpertSettlement;

    /**
     * 채팅파일 업로드할 때사용 (어드민은 필요없음)
     * @param data
     * @param file
     * @returns
     */
    returnUploadHandler?: (
        data: ReqeustPatchChat,
        file?: File
    ) => () => Promise<void>;

    /**
     * 챗 데이터
     */
    chatData: ChatParsed;
}

const RequestChatSection: React.FC<RequestChatSectionProps> = ({
    isMobile,
    level,
    onSelectExpert,
    reqId,
    minus48,
    settlement,
    returnUploadHandler,
    chatData,
}) => {
    const hasSettlement = settlement !== undefined;
    const { t } = useTranslation();
    return (
        <>
            <Grid
                sx={{
                    overflow: "auto",
                    maxHeight: minus48 ? `calc(100% - ${minus48}px)` : "100%",
                    bgcolor: "#FAFAFA",
                    border: "solid 1px #AAAAAA",
                }}
            >
                <Grid
                    container
                    flexDirection={isMobile ? "column" : "row"}
                    p={isMobile ? 3 : 0}
                >
                    {isMobile && (
                        <>
                            <Grid container>
                                <AnouncementSection
                                    onConfirm={onSelectExpert}
                                    isMobile={isMobile}
                                    level={level}
                                    reqId={reqId}
                                    hasSettlement={hasSettlement}
                                    defaultFeeRate={chatData.reportInfo.feeRate}
                                    chatID={chatData?._id}
                                    annData={chatData.announcements}
                                />
                            </Grid>
                            <Divider flexItem sx={{ mt: 3, mb: 3 }} />
                        </>
                    )}

                    <Grid item xs={isMobile ? 12 : 9} p={isMobile ? 0 : 3}>
                        <Typography variant="f20h28EB">
                            {t("quest.questProcessStatus")}
                        </Typography>
                        <RequestProcessMeter level={level} />
                        <Grid mt={8} mb={3}>
                            <Typography variant="f20h28EB">
                                {settlement
                                    ? t("quest.settlement")
                                    : t("quest.chatScreen")}
                            </Typography>
                        </Grid>
                        {settlement ? (
                            <>
                                <InfoList
                                    data={[
                                        {
                                            title: t("quest.의뢰번호"),
                                            content: `${reqId}`,
                                        },
                                        ...settlementMapToArray(settlement).map(
                                            (sEle) => {
                                                return {
                                                    ...sEle,
                                                    title: t(
                                                        `quest.${sEle.title}`
                                                    ),
                                                };
                                            }
                                        ),
                                    ]}
                                />
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    sx={{
                                        display:
                                            settlement.proof == ""
                                                ? "none"
                                                : undefined,
                                        mt: 7,
                                    }}
                                >
                                    <Typography variant="f18h20B">
                                        {t("quest.verifyingDocument")}
                                    </Typography>
                                    <a
                                        href={settlement.proof}
                                        download
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button
                                            color="pBlue"
                                            sx={{
                                                fontSize: 16,
                                                height: "24px",
                                            }}
                                        >
                                            <FileDownloadOutlined />
                                            <Typography variant="f16h20B">
                                                {t("common.download")}
                                            </Typography>
                                        </Button>
                                    </a>
                                </Box>
                            </>
                        ) : (
                            <ChatSection
                                returnUploadHandler={returnUploadHandler}
                                chatData={chatData}
                            />
                        )}
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    {!isMobile && (
                        <Grid
                            item
                            container
                            xs={3}
                            sx={{
                                p: 3,
                                ml: "-1px",
                            }}
                        >
                            <AnouncementSection
                                onConfirm={onSelectExpert}
                                isMobile={isMobile}
                                level={level}
                                reqId={reqId}
                                hasSettlement={hasSettlement}
                                defaultFeeRate={chatData.reportInfo.feeRate}
                                chatID={chatData._id}
                                annData={chatData.announcements}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid mt={2} mb={2}>
                {isMobile && level < 3 && (
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={onSelectExpert}
                    >
                        {t("quest.decide")}
                    </Button>
                )}
            </Grid>
        </>
    );
};

export default RequestChatSection;
