import React, { useEffect, useState } from "react";

import { useSetRecoilState } from "recoil";

import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import StaticAmbassadorTileMain from "../components/frames/StaticAmbassadorTile";
import { navThemeRecoil } from "../components/states/recoil";
import { AmbassadorDataType } from "../interfaces/ambassador/ambassadorDataType";
import { mobileMaxWidthMediaQuery } from "../theme";
import { navThemeValues } from "../types/navThemeTypes";

const Ambassador: React.FC = () => {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const setNavTheme = useSetRecoilState(navThemeRecoil);
    const { t } = useTranslation();

    const [ambassadorData, _setAmbassadorData] = useState<AmbassadorDataType[]>(
        [
            {
                k_name: "임창환",
                grade: "엠버서더",
                field: "한국관세, 중국관세, 대외무역법",
                part: "관세, FTA, 중국세관",
                industry: "관세, FTA, 중국세관",
                proCountry: "중국",
                career: "전) 대전세관장, 한중FTA 차이나 협력관, 부산 국제우편세관장, 부산본부세관 심사총괄과장, 광주본부세관 세관운영과장, 광주본부세관 납세심사과장, 관세청 원산지 지원담당관실, 관세청 세원심사과, 관세청 국제 협력과, 관세청 중국 관세 무역연구회 간사장, 국세 공무원교육원 관세학과 전임교수, 중국 인민대학 재세 연구소 개원연구원, 복단대학 상해 물류연구원 초빙학자, 배재대학교 무역학과 겸임교수, GBC자격시험 출제위원, 원산지 관리사 자격시험 출제 및 선정위원, 보세사 자격시험 출제 및 선정위원, 라사 국서 세무컨설팅 주식회사 파트너, 상해 국서 세무사 사무소 고문, 관세법인 뮤츄얼 스탠다드 고문관세사, 현) 주 중 한국대사관 통관물류협의회 고문, 북경 정균환 세무컨설팅 유한공사 부사장, 한국 중소기업 수출협동조합(KOMEX) 이사, 한중(SINOKOREA)관세사무소 대표 관세사",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/임창환.jpg",
            },
            {
                k_name: "전용하",
                grade: "엠버서더",
                field: "해외수출, 마케팅, 경영기획",
                part: "해외수출, 마케팅, 경영기획",
                industry: "해외수출, 마케팅, 경영기획",
                proCountry: "미국, 중국",
                career: "한양대학교 영문학과 졸업, 한양교육대학원 영어교육학과 졸업, 가산전자 경영기획팀장, (주)아이마스 미국법인장, CMKC/EMKC 해외건설 철도 계약팀 부사장, 현대아이비티(주) 대표이사, (주)씨앤팜 대표이사, BNB KOREA 중국지사장, 현)GMA협회 대표",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/전용하.jpg",
            },
            {
                k_name: "신시열",
                grade: "엠버서더",
                field: "해외영업, 컨설팅, 사업자문",
                part: "인도, 미국등 수출",
                industry: "소비재",
                proCountry: "인도",
                career: "동서리서치 연구원(1991~1992), 미국 코닥 본사 마케팅 스페셜리스트(1995~1996), 한솔PCS지점장/한솔아이글로브 기획팀장(1996~2002), CJ오쇼핑TV사업부장 상무 및 인도법인장(2002~2018), 오코멕스 대표이사(2018~2019), 경방네이처 사업부문 사장(2020~2021), 현)CNS네이쳐 공동대표, 연합뉴스 경제 TV 안유화쇼 출연(인도경제와 비즈니스 2023.06), KBS라디오 홍사훈의 경제쇼 및 최경영의 경제쇼 4회 출연(인도 경제 주제), MTN인도특집 총 8편(2019.10~2019.12), 한국 마케팅협회, 한국외대 인도 연구소 인도지역 전문가 과정, 서울대학교 아시아연구소 남아시아 센터 콜로키움, HLB생활건강(엘리샤코이)/뉴트리원/테라젠이텍스 등 인도 진출 자문, 전)인도포럼 운영위원장, 전)한-인도 산업포럼 상임위언(산자부)",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/신시열.jpeg",
            },
            {
                k_name: "남경복",
                grade: "엠버서더",
                field: "해외 컨설팅(시장조사, 기술 자문, 신사업개발)",
                part: "수출입, 컨설팅",
                industry: "식품",
                proCountry:
                    "아시아(몽골, 방글라데시, 베트남), 남미(페루, 멕시코), 중동(이집트)",
                career: "현)(주)국제컨설팅 회장/ 오리온 그룹 수출 고문, 신세계 이마트, 몽골 알타이 그룹 고문, 프란델그룹(방글라데시), 연세대학교 상경대학 경제학과 졸업(66학번)",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/남경복.jpg",
            },
            {
                k_name: "김태경 전문가",
                grade: "엠버서더",
                field: "온라인 유통 및 마케팅, 컨설팅",
                part: "온라인 유통 및 마케팅(Amazon, ebay, 해외 인플루언서 마케팅), 컨설팅(예비 창업자, 정부지원사업, 수출바우처사업)",
                industry: "소비재, 식품, B2C",
                proCountry: "미국, 동남아시아",
                career: "NYFASHOINCITY 대표 이셀러스 해외사업부 이사 이베이코리아 이베이양성 전문강사 한국무역협회 온라인 무역전문가 서울산업진흥원, 소상공인진흥재단, 서울경제지주, 인천테크노파크, 중진공, 한국임업진흥원 수행 및 대행사",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/김태경.jpg",
            },
            {
                k_name: "김세현 전문가",
                grade: "엠버서더",
                field: "해외소싱전문",
                part: "해외소싱, 해외영업",
                industry: "대형 중장비",
                proCountry: "북중미",
                career: "현) 무역회사 대표, 목재파쇄기 및 농업관련 장비 마케팅, 수출입업무,해외소싱, 국내B2B영업, 상하수도 가스관 진단, 기술영업",
                location_title: "대륙",
                location_text: "북중미",
                image: "./images/ambassadors/headphoto/1.png",
            },
            {
                k_name: "오병운 전문가",
                grade: "엠버서더",
                field: "중국진출전문",
                part: "해외영업, 사업자문, 컨설팅",
                industry: "반도체, 의료 및 미용 산업",
                proCountry: "중국",
                career: "현) 중국 컨설팅회사 대표 / 중국진출전략자문(법인설립, 인허가, 판로발굴, 투자유치, M&A)",
                location_title: "대륙",
                location_text: "중국",
                image: "./images/ambassadors/headphoto/2.png",
            },
            {
                k_name: "김성택 전문가",
                grade: "엠버서더",
                field: "중소기업 수출입 컨설팅",
                part: "해외영업, 사업자문, 컨설팅",
                industry: "산업/건설 장비, 배터리",
                proCountry: "",
                career: "바이어 발굴, 시장 조사, 신뢰 형성 및 무역 플로우 수행을 위한 커뮤니케이션과 장기적 관계영업",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/3.png",
            },
            {
                k_name: "남종석 전문가",
                grade: "엠버서더",
                field: "폴란드 해외영업 컨설팅",
                part: "해외영업, 사업자문, 컨설팅",
                industry: "섬유, 소비재",
                proCountry: "폴란드",
                career: "고려대졸업, 바르샤바 경제대학 경영학 석사, SK초대 바르샤바 지사장, 섬유원단 무역 연간 2천만불, 바르샤바 한인 회장, 폴란드 한인연합회장, 월드 옥타(OKTA) 세계한인무역협회 통상위원회 및 지사화사업 관할 부회장",
                location_title: "대륙",
                location_text: "폴란드",
                image: "./images/ambassadors/headphoto/남종석.jpg",
            },
            {
                k_name: "박기홍 전문가",
                grade: "엠버서더",
                field: "해외영업전문",
                part: "해외영업, 사업자문, 컨설팅",
                industry: "면방, 방직, 섬유",
                proCountry: "우즈베키스탄",
                career: "해외 법인 운영(우즈베키스탄 대우 면방 법인장), CIS 지역 진출 컨설팅",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/박기홍2.jpeg",
            },
            {
                k_name: "최승이 전문가",
                grade: "엠버서더",
                field: "해외영업, 컨설팅, 사업자문, 소싱",
                part: "해외수입, 바이어발굴, 컨설팅(사업자문, 법인설립, 식품인증)",
                industry: "식품, 농산품, 소비재",
                proCountry: "중국",
                career: "한국롯데그룹 공채 1기 (롯데제과, 롯데햄,우유) / 유통식품제조가공업 연합회 합작 & 인천광역시 수출 지원으로 중국 청도 미추홀 식품무역 유한공사 설립 (전문경영인), 중국 유통망 확보, 한국식품  플랙샵 운영 / 중국 수출 전시회 기업 컨설팅 / 중국기업 계약서 검토(번역)",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/최승이2.png",
            },
            {
                k_name: "조삼현 전문가",
                grade: "엠버서더",
                field: "해외 시장 컨설팅",
                part: "컨설팅(해외시장조사, 기술자문, 신사업개척), 해외영업",
                industry: "자동차부품, 반도체, 산업재",
                proCountry: "아시아(12개국)",
                career: "현) 3M아시아 총괄 리더, 해외 M&A 확장 운영관리, 기업 컨설팅(해외시장분석, 바이어연결), 기술자문",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/조삼현.jpeg",
            },
            {
                k_name: "권혁준 전문가",
                grade: "엠버서더",
                field: "컨설팅, 사업자문",
                part: "컨설팅 (자동화설비, IT도입기반), 사업자문(스마트팩토리산업)",
                industry: "IT컨설팅, 생산성",
                proCountry: "국내",
                career: "ITCEN Group/Goodcen Co.Ltd – CEO(ERP), Oracle Korea – Senior Director/GM(ERP), Siebel Systems – Country Manager/ President(Sales), WEBMETHODS Korea - Country Manager/ President(marketing), SAP Korea CO. Ltd - Director (Sales)",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/권혁준.jpg",
            },
            {
                k_name: "배정홍 전문가",
                grade: "엠버서더",
                field: "배트남 해외영업, 온라인마케팅",
                part: "컨설팅(해외시장조사, 기술자문, 신사업개척), 해외영업",
                industry: "소비재",
                proCountry: "베트남, 필리핀, 중국",
                career: "Gaxago 대표(합작법인), 이지웹피아 대표, 유니콘전자통신 개발팀장",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/배정홍.jpg",
            },
            {
                k_name: "명재호 전문가",
                grade: "엠버서더",
                field: "수출입제반 컨설팅",
                part: "수출입통관, 외국환거래, FTA 및 국내외 수출입 요건 가이드, 전략물자, 해외통관 컨설팅",
                industry: "관세, FTA, 외환",
                proCountry: "(주요)미국, 일본 및 전 세계 국가",
                career: "현) 대홍합동관세사무소 대표관세사/ 롯데글로벌로지스 통관팀장/ 현대모비스 Trade Compliance 총괄매니저/ 방위사업청 수출심의 위원/ 중소벤처기업청 비즈니스 지원단/ 아마존코리아 자문관세사",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/명재호2.jpeg",
            },
            {
                k_name: "오지영 전문가",
                grade: "엠버서더",
                field: "해외영업, 컨설팅, 사업자문, 소싱",
                part: "해외수입, 바이어발굴 및 연결, 컨설팅(사업자문, 법인 및 에이전시 설림), 해외기술협력컨설팅(국제공동R&D)",
                industry:
                    "스마트팜 솔루션, 농축산품, 식품(가공포함), 열전발전소자 부품, 화장품",
                proCountry:
                    "스페인,중남미(멕시코, 칠레, 우루과이, 아르헨티나, 페루), 기타(베트남, 아제르바이잔, 스위스, 프랑스, 모로코)",
                career: "현)글로벌기업 QAMPO(Spain소재) 아시아-태평양 총괄 Distributor 및 해외 비즈니스 전문 컨설팅 CHARLIE Works 대표, 국제무역전문가 과정수료, GBC 1급 자격보유",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/오지영.jpeg",
            },
            {
                k_name: "홍지훈 전문가",
                grade: "엠버서더",
                field: "플랜트, 연료전지, 반도체 기술고문, 턴키장비 수출, 바이어 연결",
                part: "반도체, 발전소 & 공장설비, 수소연료전지, 스마트윈도우 수출 및 기술 고문",
                industry:
                    "반도체, 발전소 & 공장 설비, 수소연료전지, 스마트윈도우 수출 및 기술 고문",
                proCountry: "주요)유럽,미국, 이외 전세계",
                career: "현)C-Motion 대표, 삼성 반도체 연구원, LG디스플레이 공정엔지니어, 미래컴퍼니 해외영업팁장",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/홍지훈.jpg",
            },
            {
                k_name: "문성호 전문가",
                grade: "엠버서더",
                field: "해외 이커머스, 물류 풀필먼트, 해외진출인허가, 사업자문 및 컨설팅",
                part: "해외 이커머스, 물류 풀필먼트, 해외진출인허가, 사업자문 및 컨설팅",
                industry: "동남아시아 이커머스 풀필먼트",
                proCountry: "동남아시아, 미국",
                career: "큐텐 - 싱가포르 이커머스 / 싱가폴 유통사업 - 온라인 오프라인 판매(대표)/ 쇼피 - 이커머스(물류팀장)/ 위클 - 아마존 운영대행사/ 현)리브 풀필먼트 - 이커머스(물류이사)",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/문성호.jpg",
            },
            {
                k_name: "사무엘 백 전문가",
                grade: "엠버서더",
                field: "해외 바이어 영업, 기술이전 계약, 식약품 인허가, 구매계약 협상, 자문 및 컨설팅",
                part: "해외 바이어 영업, 기술이전 계약, 식약품 인허가, 구매계약 협상, 자문 및 컨설팅",
                industry: "제약, 농/수산업 기술이전, 화장품, 소비재, B2B",
                proCountry: "주요)브라질, 멕시코, 이외 중남미 및 미국, 사우디",
                career: "현)Belobela LLC 대표/ Director, Latin-Asia Biz Dev, Meizler-UCB Biopharma S.A./ Diretor Comercial & Partner, GHP Produtos Eletromecanicos S/A / Co-founder, Tablethotels.jp/ Principal, Lobo I.E. Corp.",
                location_title: "대륙",
                location_text: "",
                image: "./images/ambassadors/headphoto/사무엘백.jpg",
            },
        ]
    );

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setNavTheme(navThemeValues.WHITE);
    }, []);

    return (
        <Grid
            container
            sx={{
                height: "auto",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                p: isMobile ? 2 : 4,
                pt: 15,
                overflowY: "scroll",
            }}
        >
            <Grid
                display={"flex"}
                justifyContent={"space-between"}
                textAlign={"start"}
                alignItems={"center"}
                width={"100%"}
                pl={1}
                sx={{ wordBreak: "break-word" }}
            >
                <Typography variant={isMobile ? "f32h40EB" : "f48h64EB"}>
                    {t("ambassador.t1")}
                </Typography>
            </Grid>
            <Grid
                container
                width={"100%"}
                pt={5}
                flexDirection={isMobile ? "column" : "row"}
                spacing={2}
            >
                {ambassadorData.map((data, idx) => {
                    return (
                        <Grid
                            item
                            display={"flex"}
                            xs={isMobile ? undefined : 4}
                            key={`ambassador_data_${idx}`}
                            position={"relative"}
                        >
                            <StaticAmbassadorTileMain
                                data={data}
                                isMobile={isMobile}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default Ambassador;
