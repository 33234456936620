import React from "react";

import { Button, Typography } from "@mui/material";

import { TextButtonProps } from "./button-interfaces";

const BlueOutlinedTextButton: React.FC<TextButtonProps> = ({
    text,
    onClick,
    sx,
}) => {
    return (
        <Button
            variant="outlined"
            sx={{
                height: "34px",
                whiteSpace: "nowrap",
                ...sx,
            }}
            color="pBlue"
            onClick={onClick}
        >
            <Typography variant="f16h20B">{text}</Typography>
        </Button>
    );
};

export default BlueOutlinedTextButton;
