import React from "react";

import { Box, CircularProgress } from "@mui/material";

export interface BingleProps {
    height?: number | string;
}

const Bingle: React.FC<BingleProps> = ({ height = "100vh" }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: height,
                width: "100%",
            }}
        >
            <CircularProgress size={50} />
        </Box>
    );
};

export default Bingle;
