import React from "react";

import IconButton from "@mui/material/IconButton";

interface BackButtonProps {
    onClick?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({
    onClick = () => {
        if (document.referrer) {
            window.history.back();
        } else {
            window.location.replace("/");
        }
    },
}) => {
    return (
        <IconButton size="small" onClick={onClick}>
            <img src={"/images/icon/arrow-narrow-left.svg"} />
        </IconButton>
    );
};

export default BackButton;
