import React from "react";

import Typography from "@mui/material/Typography";

interface NanumLProps {
    text: string;
    size?: number;
    color?: string;
}

const NanumL: React.FC<NanumLProps> = ({ text, color, size }) => {
    return (
        <Typography
            fontFamily={"nanumsquare"}
            fontWeight={"400"}
            color={color ? color : "white"}
            fontSize={size ? size : 48}
        >
            {text}
        </Typography>
    );
};

export default NanumL;
