import React from "react";

import { Outlet } from "react-router-dom";

import Footer from "../components/navigation/Footer";
import Navigation from "../components/navigation/Navigation";
const Layout = () => {
    return (
        <div style={{ height: "100vh" }}>
            <Navigation />

            <main
                style={{
                    minHeight: "100vh",
                    overflow: "auto",
                }}
            >
                <Outlet />
            </main>
            <Footer />
        </div>
    );
};
export default Layout;
