import React from "react";

import { Box, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";

import { useTranslation } from "react-i18next";

import { QuestFormTypes } from "../../../api-interfaces/quest";

export interface ReqTypeButtonProps extends ButtonProps {
    label: string;
    parentVar: string;
    count?: number;
    isSelect?: boolean;
}

const ReqTypeButton: React.FC<ReqTypeButtonProps> = ({
    label,
    parentVar,
    count = 0,
    isSelect = false,
    onClick,
    sx,
}) => {
    const { i18n } = useTranslation();

    return (
        <Button
            variant={"contained"}
            sx={{
                height: "34px",
                color: isSelect ? undefined : "#AAAAAA",
                borderRadius: parentVar === "round" ? "16px" : 0,
                minWidth: i18n.language === "en" ? 100 : 70,
                ...sx,
            }}
            color={isSelect ? "primary" : "secondary"}
            onClick={onClick}
        >
            <Typography variant="f14h20EB">{label}</Typography>
            <Typography
                variant="f14h20EB"
                color={isSelect ? "pBlue.main" : "inherit"}
                sx={{ ml: 0.5 }}
            >
                {count}
            </Typography>
        </Button>
    );
};

export interface ReqTypeSelectSectionProps {
    reqType: QuestFormTypes;
    setReqType?: (cate: QuestFormTypes) => void;
    /**
     * 어드민 때문에 생성
     * @remarks
     * - setReqType 보다 우선시 됨.
     * 권장패턴
     */
    onChangeTypeFilterHOF?: (cate: QuestFormTypes) => () => void;
    importCount?: number;
    exportCount?: number;
    consultingCount?: number;
    variant?: "round" | "square";
}

/**
 * 의뢰의 종류 필터 (카테고리필터) 선택 UI
 */
const ReqTypeSelectSection: React.FC<ReqTypeSelectSectionProps> = ({
    reqType,
    setReqType = (box) => {
        console.log(box);
    }, // TODO 추후 패턴 선택후 후 제거 혹은 enhance
    onChangeTypeFilterHOF,
    importCount = 0,
    exportCount = 0,
    consultingCount = 0,
    variant = "square",
}) => {
    const isImport = reqType === QuestFormTypes.IMPORT;
    const isExport = reqType === QuestFormTypes.EXPORT;
    const isConsulting = reqType === QuestFormTypes.CONSULTING;

    const { t } = useTranslation();
    return (
        <Box sx={{ overflowX: "auto", width: "100%", display: "flex" }}>
            <ReqTypeButton
                label={t("mypage.export")}
                parentVar={variant}
                isSelect={isExport}
                onClick={
                    onChangeTypeFilterHOF
                        ? onChangeTypeFilterHOF(QuestFormTypes.EXPORT)
                        : () => setReqType(QuestFormTypes.EXPORT)
                }
                count={exportCount}
            />
            <ReqTypeButton
                label={t("mypage.import")}
                parentVar={variant}
                isSelect={isImport}
                onClick={
                    onChangeTypeFilterHOF
                        ? onChangeTypeFilterHOF(QuestFormTypes.IMPORT)
                        : () => setReqType(QuestFormTypes.IMPORT)
                }
                count={importCount}
                sx={{ ml: 1 }}
            />
            <ReqTypeButton
                label={t("mypage.consulting")}
                parentVar={variant}
                isSelect={isConsulting}
                onClick={
                    onChangeTypeFilterHOF
                        ? onChangeTypeFilterHOF(QuestFormTypes.CONSULTING)
                        : () => setReqType(QuestFormTypes.CONSULTING)
                }
                count={consultingCount}
                sx={{ ml: 1 }}
            />
        </Box>
    );
};

export default ReqTypeSelectSection;
