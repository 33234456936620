import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";

import { Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { UserRole } from "../../api-interfaces/user";
import { generateFontStyles, tabletMaxWidthMediaQuery } from "../../theme";
import { DEFAULT_PROFILE_IMG_PATH } from "../../utils/consts";
import {
    isUserLoadedRecoil,
    navThemeRecoil,
    userRecoil,
} from "../states/recoil";

import DrawerMenu from "./DrawerMenu";
import DropDownNav from "./DropDownNav";
import LangDropdown from "./LangDropdown";
import Logo from "./Logo";
import NavTab from "./NavTab";

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const isMobile = useMediaQuery(tabletMaxWidthMediaQuery);
    const userData = useRecoilValue(userRecoil);
    const navTheme = useRecoilValue(navThemeRecoil);
    const [themeObj, setThemeObj] = useState<{
        color: "white" | "black";
        bg: "transparent" | "white" | "black";
    }>({
        color: "black",
        bg: "white",
    });
    const isUserLoaded = useRecoilValue(isUserLoadedRecoil);
    const { t } = useTranslation();

    useEffect(() => {
        switch (navTheme) {
            case "white":
                setThemeObj({
                    color: "black",
                    bg: "white",
                });
                break;
            case "black":
                setThemeObj({
                    color: "white",
                    bg: "black",
                });
                break;
            case "white_trans":
                setThemeObj({
                    color: "white",
                    bg: "transparent",
                });
                break;
            case "black_trans":
                setThemeObj({
                    color: "black",
                    bg: "transparent",
                });
                break;
        }
    }, [navTheme]);

    return (
        <Toolbar
            sx={{
                alignItems: "center",
                display: "flex",
                height: "64px",
                justifyContent: "center",
                position: "fixed",
                width: "100%",

                backgroundColor: themeObj.bg,
                zIndex: 999,
                transition: "background-color 0.2s ease-in-out",
                flexWrap: "nowrap",
            }}
        >
            <Grid
                container
                justifyContent={"space-between"}
                width={"100%"}
                maxWidth={"1600px"}
            >
                <Grid item container alignItems="center" xs={isMobile ? 10 : 2}>
                    <Logo inverse={themeObj.color === "black"} />
                </Grid>
                {isMobile ? (
                    <Grid
                        container
                        item
                        flex={1}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <IconButton
                            size="small"
                            onClick={() => setOpenDrawer(true)}
                        >
                            <img
                                src={`/images/icon/menu-01_${themeObj.color}.png`}
                                alt="logo"
                                width={24}
                                height={24}
                            />
                        </IconButton>
                        <DrawerMenu
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                        />
                    </Grid>
                ) : (
                    <>
                        <Grid
                            item
                            flexWrap="nowrap"
                            display={"flex"}
                            justifyContent={"center"}
                            xs={8}
                        >
                            <NavTab color={themeObj.color} />
                        </Grid>
                        <Grid container item display={"block"} xs={2}>
                            <Grid
                                container
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                height={"100%"}
                                flexWrap="nowrap"
                            >
                                {!isUserLoaded ? (
                                    <></>
                                ) : userData ? (
                                    <>
                                        {userData.role === UserRole.ADMIN && (
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    height: "38px",
                                                    mr: 1,
                                                }}
                                                href={"/admin"}
                                            >
                                                <Typography
                                                    color={themeObj.color}
                                                    variant={"f14h20"}
                                                >
                                                    {"관리페이지"}
                                                </Typography>
                                            </Button>
                                        )}
                                        {(userData.role === UserRole.GUEST ||
                                            userData.role ===
                                                UserRole.UNAPPROVED_EXPERT) && (
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    height: "38px",
                                                    mr: 1,
                                                }}
                                                href={"/verification"}
                                            >
                                                <Typography
                                                    color={themeObj.color}
                                                    variant={"f14h20"}
                                                >
                                                    {t("nav.verification")}
                                                </Typography>
                                            </Button>
                                        )}
                                        <DropDownNav
                                            contents={[
                                                {
                                                    text: t("nav.mypage"),
                                                    href: "/mypage",
                                                },
                                            ]}
                                            buttonComponent={() => (
                                                <Button
                                                    sx={{
                                                        pl: 2,
                                                        pr: 2,
                                                    }}
                                                >
                                                    <Avatar
                                                        src={
                                                            userData.image ||
                                                            DEFAULT_PROFILE_IMG_PATH
                                                        }
                                                        sx={{
                                                            width: 32,
                                                            height: 32,
                                                            mr: 1,
                                                        }}
                                                    />
                                                    <Typography
                                                        color={themeObj.color}
                                                        sx={{
                                                            ...generateFontStyles(
                                                                18
                                                            ),
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        {userData.nickname}
                                                    </Typography>
                                                </Button>
                                            )}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            sx={{
                                                pl: 2,
                                                pr: 2,
                                            }}
                                            href={"/login"}
                                        >
                                            <Typography
                                                color={themeObj.color}
                                                sx={{
                                                    ...generateFontStyles(18),
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {t("nav.login")}
                                            </Typography>
                                        </Button>
                                        <Button
                                            sx={{
                                                pl: 2,
                                                pr: 2,
                                                mr: 3,
                                            }}
                                            href={"/signup"}
                                        >
                                            <Typography
                                                color={themeObj.color}
                                                sx={{
                                                    ...generateFontStyles(18),
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {t("nav.signup")}
                                            </Typography>
                                        </Button>
                                    </>
                                )}
                                <LangDropdown color={themeObj.color} />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </Toolbar>
    );
};

export default Navigation;
