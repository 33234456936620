import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface StepButtonProps {
    title: string;
    subtitle: string;
    icon: string;
    isMobile: boolean;
}

const StepButton: React.FC<StepButtonProps> = ({
    title,
    subtitle,
    icon,
    isMobile,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                width: isMobile ? "95%" : "25%",
                height: isMobile ? 171 : 273,
                borderRadius: 0,
                backgroundColor: "#191919",
                p: 3.75,
                ml: isMobile ? 0 : 3,
                mr: isMobile ? 0 : 3,
                position: "relative",
                flexDirection: "column",
            }}
            mb={2}
        >
            <Typography
                sx={{
                    opacity: 1.0,
                    fontSize: isMobile ? 18 : 24,
                    color: "white",
                }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    opacity: 1.0,
                    fontSize: isMobile ? 18 : 24,
                    color: "white",
                }}
            >
                {subtitle}
            </Typography>
            <Box
                sx={{
                    position: "absolute",
                    bottom: 25,
                    right: 25,
                }}
            >
                <img src={icon} />
            </Box>
        </Box>
    );
};

export default StepButton;
